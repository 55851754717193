import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { LuUserPlus } from "react-icons/lu";
import { BsTrash3 } from "react-icons/bs";
/*import { LuEdit2 } from "react-icons/lu"*/
import { TbCheckbox } from "react-icons/tb";
import { RiCheckboxBlankLine } from "react-icons/ri";
import blankprofile from "../../Images/blankprofile.png";
import { TfiCheckBox } from "react-icons/tfi";

import { useDispatch, useSelector } from "react-redux"; //this import redux functions
import { Link } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageCard from "../cards/ImageCard";

const CaseAssignedBy = ({ setShowSideBar, case_cd, accessBy, setAccessBy, officeAttornyTeam}) => {
    const [areasMode, setAreasMode] = useState("List");
    const [viewMode, setViewMode] = useState("");
    const [branchUsers, setBranchUsers] = useState([]);
    const [updating, setUpdating] = useState([]);
 
    useEffect(() => {
    	get_staff_and_accesby()
    }, []);

    const set_clear_mode = () => {
        switch (areasMode) {
            case "Edit":
                setAreasMode("List");
                break;
            case "View":
                setAreasMode("Edit");
                break;
            case "List":
                alert("ok")
                setShowSideBar(false);
                break;
            default:
        }
    };

    function set_icon(employee_image_name, user_image_name,) {
        if (user_image_name) {
            return <img src={"https://files.amanrow.com/user/" + user_image_name} alt=" " className="user_profile_image" />
        } else
            if (employee_image_name) {
                return <img src={employee_image_name} alt=" " className="user_profile_image" />
            } else {
                return <img src={blankprofile} className="user_profile_image" alt="" />
            }
    }

    /* Read ----------------------*/
    const get_staff_and_accesby = async (field_name, field_value) => {
               
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_staff_and_accesby",
                    case_cd: case_cd,
                    branch_cd:  userDataLocal.branch_cd,
                    

                },
            };
            console.log("get_staff_and_accesby input ", data);
            

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/get_staff_and_accesby`,

                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("get_staff_and_accesby output ", res);
            if (res.data.output.stafs_access_by && res.data.output.stafs_access_by.length > 0) {
                setBranchUsers(res.data.output.stafs_access_by)
                }else{
                    setBranchUsers([])
                }
            setAreasMode("List")

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    /* update -------------------------*/
    const update_acces_by = async (attorny_user_cd, update_function, index) => {
        setUpdating([attorny_user_cd, ...updating])
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "update_case_acces_by",
                    case_cd: case_cd,
                    attorny_user_cd: attorny_user_cd,
                    update_function: update_function,
                },
            };
            console.log("update_case_acces_by input ", data);

            let res = await axios.post(
            `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_case_acces_by`,
                data,
                {
                    headers: {
                    Authorization: `Bearer ${userDataLocal.token}`,
                    },
            },
            );

            console.log("update_case_acces_by output ", res);

            if (res.data.output.case_accessed_by && res.data.output.case_accessed_by.length > 0) {  
                setAccessBy(res.data.output.case_accessed_by);
            }else{
                setAccessBy([]);
            }
            if(update_function ==="delete"){
                branchUsers[index].case_cd = null
            }else{
               branchUsers[index].case_cd = case_cd
            }
            const newArray = updating.filter(item => item !== res.data.output.updating_cd);
            setUpdating(newArray);
            //setAreasMode("Edit");
        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    return (
    <>
        <div>
            {/*Buttomn bar*/}
            <div>
                <div className="normal_float" style={{display:"flex", textAlign:"center", height: "46px" }}>             
                    <Tooltip title="Back" placement="bottom" arrow>
                        <IconButton onClick={() => {set_clear_mode()}}>
                            <ArrowBackIcon color="inherit" fontSize="small" />
                        </IconButton>
                    </Tooltip>  
                </div>
                <div className="opposite_float"  style={{display:"flex", textAlign:"center", height: "46px" }}>
                    {areasMode === "List" && (
                    <>
                        <Tooltip title="Edit" placement="bottom" arrow>
                            <IconButton onClick={() => {setAreasMode("Edit")}}>
                                <ModeOutlinedIcon color="inherit" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                    )}
                    {areasMode === "View" && (
                    <>
                        {viewMode === "insert" && (
                            <Tooltip title="add New" placement="bottom" arrow>
                                <IconButton onClick={() => {update_acces_by("insert")}}>
                                    <LuUserPlus color="inherit" size={18} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {viewMode === "delete" && (
                            <Tooltip title="add New" placement="bottom" arrow>
                                <IconButton onClick={() => {update_acces_by("delete")}}>
                                    <BsTrash3 color="inherit" size={18} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                    )}
                </div>
                <div style={{ clear: "both" }}></div>
            </div>
            {/*users area*/}
            <div style={{ marginTop: "6px" }}> 
                {branchUsers.length > 0 && branchUsers.map((branch_users, index) => {
                    if(branch_users.case_cd === case_cd)
                    return(
                    <div className="row_area" key={branch_users.user_cd + index}>
                        <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {updating.length > 0 && updating.find((update_user_cd) => update_user_cd === branch_users.user_cd) ?
                                    <div className="icon_tree_area"><span className='loader-spiner'></span></div>
                                    :
                                    <div className="icon_tree_area" onClick={() => {update_acces_by(branch_users.user_cd, "delete", index)}}>
                                        {areasMode === "Edit" && (<TfiCheckBox size="16" />)}
                                    </div>
                                }
                                <div className="user_line">
                                    <div className="user_line_area">
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                {set_icon(null, branch_users.image_name)}
                                            </div>
                                            <div className="space-betw-vertical" style={{ margin: "0px 10px" }}>
                                                <div> {branch_users.full_name}</div>
                                                <div>{branch_users.job_title}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>
            {areasMode === "Edit" && (
                <>
                    {branchUsers.length > 0 && branchUsers.map((branch_users, index) => {
                        if(branch_users.case_cd !== case_cd)
                            return(
                            <div className="row_area" key={branch_users.user_cd + index}>
                                <div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {updating.length > 0 && updating.find((update_user_cd) => update_user_cd === branch_users.user_cd) ?
                                            <div className="icon_tree_area"><span className='loader-spiner'></span></div>
                                            :
                                            <div className="icon_tree_area" >
                                                {areasMode === "Edit" && (<div className="unchecked_checkbox" style={{ width: "15px", height: "15px" }} onClick={() => {update_acces_by(branch_users.user_cd, "insert", index)}} ></div>)}
                                            </div>
                                        }
                                        
                                        <div className="user_line">
                                            <div className="user_line_area">
                                                <div style={{ display: "flex" }}>
                                                    <div>
                                                        {set_icon(null, branch_users.image_name)}
                                                    </div>
                                                    <div className="space-betw-vertical" style={{ margin: "0px 10px" }}>
                                                        <div> {branch_users.full_name}</div>
                                                        <div>{branch_users.job_title}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                </>
            )}
             
        </div>
    </>
    );
};

export default CaseAssignedBy;
