import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import ImageCard from "../cards/ImageCard";

const OrganizationStructure = () => {
    const { user } = useSelector((state) => ({ ...state })); //importing from redux
    
    const [branchDetails, setBranchDetails] = useState({});
    const [branchMode, setBranchMode] = useState("initial");
    const [branchDetailsMode, setBranchDetailsMode] = useState("List");
    const [branchLanguage, setBranchLanguage] = useState([]);
    const [branchEmployee, setBranchEmployee] = useState([]);
    const [branchBanks, setBranchBanks] = useState([]);
    const [branchCashiers, setBranchCashiers] = useState([]);
    const [branchType, setBranchType] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [countries, setCountries] = useState([]);
    const [dateFormat, setDateFormat] = useState([]);
    const [orgenizationStructure, setOrgenizationStructure] = useState([]);
    const [pageLables, setPageLables] = useState([]);
    const [states, setStates] = useState([]); 
    const [tapActive, setTapActive] = useState("Location");

    useEffect(() => {
        if (user.userData) {
            get_branch_initials();
            get_systemss_administrators_initial();
        }
    }, [user]);
    
    const set_tab_active = (active_tab) => {
        setTapActive(active_tab);
    };

    const set_clear_mode = () => {
        if (branchDetailsMode === "Edit") {
            setBranchDetailsMode("View");
        } else {
            setBranchDetails({})
            setBranchDetailsMode("List");
        }
    };

    const set_draft_mode = () => {
        setBranchDetails({})
        setBranchDetailsMode("Draft")
    };

    function toggle_branch(branch_id, branch_cd) {       
        if (document.getElementById(branch_id).style.display === "block") {
            document.getElementById(branch_id).style.display = "none";
        } else {
            document.getElementById(branch_id).style.display = "block";
        }
    }

    const subNode = (parent_id) => {
        return orgenizationStructure.map((tree_node, i) => {
            if (parent_id === tree_node.parent_id) {
                return (
                    <div key={i}>
                        <div key={i} className="user_line">
                            <div className="node_line">
                                <div style={{ display: "flex" }} onClick={() => { toggle_branch(`branch_${tree_node.branch_id}`, tree_node.branch_cd) }}>
                                    <div className="icon_tree_area"><ImageCard icon={tree_node.branch_type_cd} iconType={"organization tree"} /></div>
                                    <div className="node_name_area">{tree_node.branch_name}</div>
                                    <div style={{ padding: "0px 4px" }} >{tree_node.branch_type_cd}</div>
                                    <div style={{ padding: "0px 4px" }}>{tree_node.branch_cd}</div>
                                </div>
                                <div className="node_buttoms_area">
                                    <InsertDriveFileOutlinedIcon className="node_buttoms" fontSize="small" onClick={() => { set_draft_mode() }} />
                                    <ModeOutlinedIcon color="inherit" className="node_buttoms" fontSize="small" onClick={() => { GetBranch(tree_node.branch_cd); }} />                                     
                                </div>
                            </div>
                        </div>
                        <div id={`branch_${tree_node.branch_id}`} className="node_block_section left_block_border" style={{ display: "none" }}>
                            {subNode(tree_node.branch_id)}
                        </div>
                    </div>
                );
            }
        });
    };

    // Read ----------------------

 
    const get_systemss_administrators_initial = async () => {
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: user.userData.user_language_cd,
                    company: user.userData.company_cd,
                    user_cd: user.userData.user_cd,
                },
                input: {
                    actioncode: "get_systemss_administrators_initial",
                    branch_cd: user.userData.branch_cd,
                },
            };

            console.log("get_systemss_administrators_initial input = ", data);
        
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/v1/auth/get_systemss_administrators_initial`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("get_systemss_administrators_initial output = ", res);
            //setBranchDetails(res.data.output.branch);
            //if (res.data.output.pages_lables) { setPageLables(res.data.output.pages_lables) }
            //if (res.data.output.branch_language) { setBranchLanguage(res.data.output.branch_language) }
            //if (res.data.output.branch_employee) { setBranchEmployee(res.data.output.branch_employee) }
            //if (res.data.output.branch_type) { setBranchType(res.data.output.branch_type) }
            //if (res.data.output.orgenization_strucure) { setOrgenizationStructure(res.data.output.orgenization_strucure) }
            //if (res.data.output.currency) { setCurrency(res.data.output.currency) }
            //if (res.data.output.country) { setCountries(res.data.output.country) }
            //if (res.data.output.state) { setStates(res.data.output.state) }
            //if (res.data.output.date_format) { setDateFormat(res.data.output.date_format) }

            if (res.data.output.branch.branch_cd) {
                setBranchDetailsMode("View");

            } else {
                setBranchDetailsMode("List");
            }

        } catch (err) {
            console.log(err);
            //alert("error message" + err.message);
        }
    };

    const get_branch_initials = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: user.userData.user_language_cd,
          company: user.userData.company_cd,
          user_cd: user.userData.user_cd,
        },
        input: {
          actioncode: "initials",
          branch_cd: user.userData.branch_cd,
        },
        };

        console.log("branch initials input = ", data);
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/org/get_orgenization_initials`,
          data,
          {
              headers: {
                  Authorization: `Bearer ${userDataLocal.token}`,
              }
          }
      );

        console.log("branch initials output = ", res);
        setBranchDetails(res.data.output.branch);
        if (res.data.output.pages_lables) { setPageLables(res.data.output.pages_lables) }
        if (res.data.output.branch_language) { setBranchLanguage(res.data.output.branch_language) }
        if (res.data.output.branch_employee) {setBranchEmployee(res.data.output.branch_employee)}
        if (res.data.output.branch_type) { setBranchType(res.data.output.branch_type) }
        if (res.data.output.orgenization_strucure) { setOrgenizationStructure(res.data.output.orgenization_strucure) }
        if (res.data.output.currency) { setCurrency(res.data.output.currency) }
        if (res.data.output.country) { setCountries(res.data.output.country) } 
        if (res.data.output.state) { setStates(res.data.output.state) } 
        if (res.data.output.date_format) { setDateFormat(res.data.output.date_format) }
              
        if (res.data.output.branch.branch_cd) {
            setBranchDetailsMode("View");
        
        } else {
            setBranchDetailsMode("List");
        }
 
    } catch (err) {
      console.log(err);
      //alert("error message" + err.message);
    }
    };
    
    const GetBranch = async (branch_cd) => {      
        setStates([])
        setBranchEmployee([])
        setBranchLanguage([])
        setBranchBanks([])
        setBranchCashiers([])
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: user.userData.user_language_cd,
                    company: user.userData.company_cd,
                    user_cd: user.userData.user_cd,
                },
                input: {
                    actioncode: "get_branch",
                    branch_cd: branch_cd,
                },
            };

            console.log("GetBranch input = ", data);
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/v1/org/get_branch`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );


            console.log("GetBranch output = ", res);
            
            if (res.data.output.branch) {
                setBranchDetails(res.data.output.branch)
                if (res.data.output.branch_employee) { setBranchEmployee(res.data.output.branch_employee) }
                if (res.data.output.branch_language) { setBranchLanguage(res.data.output.branch_language) }
                if (res.data.output.state) { setStates(res.data.output.state) }
                if (res.data.output.branch.branch_cd) {
                    setBranchDetailsMode("View");

                } else {
                    setBranchDetailsMode("List");
                }
            }
            

            
        } catch (err) {
            console.log(err);
            //alert("error message" + err.message);
        }
    };

    // Update ----------------------  
    const UpdatBranch = async () => {
        alert("UpdatBranch")
        return
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: user.userData.user_language_cd,
                    company: user.userData.company_cd,
                    user_cd: user.userData.user_cd,
                },
                input: {
                    actioncode: "initials",
                    branch_cd: user.userData.branch_cd,
                },
            };

            console.log("the input = ", data);
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/v1/org/get_orgenization_initials`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("the result = ", res);
            setBranchDetails(res.data.output.branch);
            console.log(res.data.output.pages_lables)
            if (res.data.output.pages_lables) { setPageLables(res.data.output.pages_lables) }
            if (res.data.output.branch_language) { setBranchLanguage(res.data.output.branch_language) }
            if (res.data.output.branch_employee) { setBranchEmployee(res.data.output.branch_employee) }
            if (res.data.output.branch_type) { setBranchType(res.data.output.branch_type) }
            if (res.data.output.orgenization_strucure) { setOrgenizationStructure(res.data.output.orgenization_strucure) }

            if (res.data.output.branch.branch_cd) {
                setBranchDetailsMode("View");

            } else {
                setBranchDetailsMode("List");
            }

            //console.log(res.data.output.customer_type)
            //setCustomerTypetData(res.data.output.customer_type)

            //console.log("initials ", res)
            //setRequesInitialstData(res.data.output)
        } catch (err) {
            console.log(err);
            //alert("error message" + err.message);
        }
    };

    var inActiveTap = "normal_float normal_text_align tap_head_area";
    var activeTap = "normal_float normal_text_align tap_head_area active_tap_head_area";

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 border-right" >
            {/*Orgenization Structure*/}
            <div className="buttoms_bar_height"></div>
            <div style={{ height: "calc(100vh - 122px)", overflow: "auto", overflowX: "hidden", padding:"0px 0px 0px 4px" }} >
                <div>
                    {orgenizationStructure.map((tree_node, i) => {
                        if (tree_node.parent_id === "0")
                        return (
                        <>
                            <div key={i} className="user_line">
                                <div className="node_line">
                                    <div style={{ display: "flex" }} onClick={() => { toggle_branch(`branch_${tree_node.branch_id}`, tree_node.branch_cd) }}>
                                        <div className="icon_tree_area"><ImageCard icon={tree_node.branch_type_cd} iconType={"organization tree"} /></div>
                                        <div className="node_name_area">{tree_node.branch_name}</div>
                                        <div>{tree_node.branch_type_cd}</div>
                                        <div>{tree_node.branch_cd}</div>
                                    </div>
                                    <div className="node_buttoms_area">
                                        <InsertDriveFileOutlinedIcon className="node_buttoms" fontSize="small" onClick={() => {set_draft_mode()}} />
                                        <ModeOutlinedIcon color="inherit" className="node_buttoms" fontSize="small" onClick={() => { GetBranch(tree_node.branch_cd); }} />
                                    </div>
                                </div>
                                          
                            </div>
                            <div id={`branch_${tree_node.branch_id}`} className="node_block_section left_block_border">
                                {subNode(tree_node.branch_id)}
                            </div>
                        </>);
                    })}
                          </div>
            </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
            {/*buttomns bar*/}
            <div className="buttoms_bar_height">
                <div className="normal_float normal_text_align ">
                {branchDetailsMode !== "List" && (
                    <>
                        <Tooltip title="Back" placement="bottom" arrow>
                        <IconButton onClick={set_clear_mode}>
                            <ArrowBackIcon color="inherit" fontSize="small" />
                        </IconButton>
                        </Tooltip>
                        <span>
                            {(branchDetailsMode === "Edit" || branchDetailsMode === "Draft") && branchDetailsMode}
                        </span>{" "}
                        <span className="separator_border">
                                      {(branchDetailsMode === "Edit" || branchDetailsMode === "View") && branchDetails.branch_cd}
                        </span>
                    </>)}
                </div>
                <div className="opposite_float opposite_text_align">
                    {(branchDetailsMode === "Edit" || branchDetailsMode === "Draft") && (
                    <>
                        <Tooltip title="Save" placement="bottom" arrow>
                            <IconButton onClick={() => { UpdatBranch() }}>
                            <SaveIcon color="inherit" fontSize="small" />
                        </IconButton>
                        </Tooltip>
                    </>
                    )}
                    {branchDetailsMode === "View" && (
                    <>
                        <Tooltip title="Edit" placement="bottom" arrow>
                            <IconButton onClick={() => {setBranchDetailsMode("Edit")}}>
                                <ModeOutlinedIcon color="inherit" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                    )}
                </div>
            <div style={{ clear: "both" }}></div>
            </div>
            {/*Branch details*/}
            <div style={{ height: "calc(100vh - 122px)", overflow: "auto", overflowX: "hidden" }} >
            {branchDetailsMode !== "List" && (
                <>
                    {(branchDetails && branchDetailsMode === "View" ) &&(
                    <> 
                        {branchLanguage.length > 0 && branchLanguage.map((branch) => {
                            return <div className="data_line" key={branch.code}>
                                <label className="label_area">{pageLables[0].description} {branch.Language}</label>                            
                                <div className="viewing_field">{branch.Description}</div> 
                            </div>
                        })}
                        <div className="data_line">
                            <label className="label_area">{pageLables[1].description}</label>
                            <div className="viewing_field">{(branchType.length > 0 && branchDetails.branch_type_cd) && branchType.find((el) => el.code === branchDetails.branch_type_cd,).name}</div>
                        </div>
                        {branchDetails.branch_type_cd === "1" && (
                            <>
                                <div className="data_line">
                                    <label className="label_area">{pageLables[18].description}</label>
                                    <div className="viewing_field" style={{border:"none"}}>
                                        {branchDetails.logo_image_name && (
                                            <>
                                            <img src={branchDetails.logo_image_name} style={{height:"60px"}}  alt="" />
                                            </>)}               
                                    </div>
                                </div>
                            </>)}
                        {branchDetails.branch_type_cd > 1 && (
                        <> 
                        <div className="data_line">
                            <label className="label_area">{pageLables[2].description}</label>
                            <div className="viewing_field">
                                {(branchEmployee.length > 0 && branchDetails.head_person) && branchEmployee.find((el) => el.emp_cd === branchDetails.head_person,).full_name} 
                            </div>
                        </div>
                        <div className="data_line">
                            <label className="label_area">{pageLables[4].description}</label>
                            <div className="viewing_field">{branchDetails.format_date && dateFormat.find((el) => el.code === branchDetails.format_date,).name}</div>
                        </div>
                        <div className="data_line">
                            <label className="label_area">{pageLables[5].description}</label>
                            <div className="viewing_field">{branchDetails.branch_curruncy && currency.find((el) => el.code === branchDetails.branch_curruncy,).name}</div>
                        </div>
                        <div className="data_line">
                            <label className="label_area">{pageLables[6].description}</label>
                            <div className="viewing_field">{branchDetails.email}</div>
                        </div>
                        <div className="data_line">
                            <label className="label_area">{pageLables[7].description}</label>
                            <div className="viewing_field">{'(' + branchDetails.area_cd + ') ' + branchDetails.phone_no}</div>
                        </div>
                        <div className="data_line">
                            <label className="label_area">{pageLables[8].description}</label>
                            <div className="viewing_field">{branchDetails.fax_no}</div>
                        </div>
                        </>)}
                    </>
                    )}            
                    {(branchDetailsMode === "Draft" || branchDetailsMode === "Edit") && (
                    <> 
                        {branchLanguage.length > 0 && branchLanguage.map((branch) => {
                            return <div className="data_line" key={branch.code}>
                                <label className="label_area">{pageLables[0].description} {branch.Language}</label>
                                <div className="editing_field">{branch.Description}</div>
                            </div>
                        })}
                        <div className="data_line">
                            <label className="label_area">{pageLables[1].description}</label>
                            <select className="editing_field" style={{ outline: "0" }} value={(branchDetails && branchDetails.branch_type_cd) && branchDetails.branch_type_cd} onChange={(e) => setBranchDetails({ ...branchDetails, branch_type_cd: e.target.value })}>
                                <option value="0"></option>
                                    {branchType.length >= 0 && branchType.map((branch_type, index) => {
                                    return (
                                        <option key={index} value={branch_type.code}>
                                            {branch_type.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        {branchDetails.branch_type_cd === "1" && (
                            <>
                                <div className="data_line">
                                    <label className="label_area">{pageLables[18].description}</label>
                                    <div className="viewing_field" style={{ border: "none" }}>
                                        {branchDetails.logo_image_name && (
                                            <>
                                                <img src={branchDetails.logo_image_name} style={{ height: "60px" }} alt="" />
                                            </>)}
                                    </div>
                                </div>
                            </>)}
                        {branchDetails.branch_type_cd > 1 && (
                            <>
                                <div className="data_line">
                                    <label className="label_area">{pageLables[2].description}</label>
                                                  <select className="editing_field" style={{ outline: "0" }} value={(branchDetails && branchDetails.head_person) && branchDetails.head_person} onChange={(e) => setBranchDetails({ ...branchDetails, head_person: e.target.value })}>
                                        <option value="0"></option>
                                        {branchEmployee.length >= 0 && branchEmployee.map((employee_list, index) => {
                                        return (
                                            <option key={index} value={employee_list.emp_cd}>
                                                {employee_list.full_name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div className="data_line">
                                    <label className="label_area">{pageLables[4].description}</label>
                                    <select className="editing_field" style={{ outline: "0" }} value={(branchDetails && branchDetails.format_date) && branchDetails.format_date} onChange={(e) => setBranchDetails({ ...branchDetails, format_date: e.target.value })}>
                                        <option value="0"></option>
                                            {dateFormat.length >= 0 && dateFormat.map((date_format, index) => {
                                            return (
                                                <option key={index} value={date_format.code}>
                                                    {date_format.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="data_line">
                                    <label className="label_area">{pageLables[5].description}</label>
                                    <select className="editing_field" style={{ outline: "0" }} value={(branchDetails && branchDetails.branch_curruncy) && branchDetails.branch_curruncy} onChange={(e) => setBranchDetails({ ...branchDetails, branch_curruncy: e.target.value })}>
                                    <option value="0"></option>
                                        {currency.length >= 0 && currency.map((currency_list, index) => {
                                        return (
                                            <option key={index} value={currency_list.code}>
                                                {currency_list.name}
                                            </option>
                                        );
                                    })}
                                    </select>
                                </div> 
                                <div className="data_line">
                                    <label className="label_area">{pageLables[6].description}</label>
                                    <input type="text" className="editing_field" autoComplete="0"
                                        value={branchDetails && branchDetails.email} onChange={(e) => setBranchDetails({ ...branchDetails, email: e.target.value })} />
                                </div>
                                <div className="data_line">
                                    <label className="label_area">{pageLables[7].description}</label>
                                    <input type="text" className="editing_field" autoComplete="0"
                                    value={branchDetails && branchDetails.phone_no} onChange={(e) => setBranchDetails({ ...branchDetails, phone_no: e.target.value })} />
                                </div>
                                <div className="data_line">
                                    <label className="label_area">{pageLables[8].description}</label>
                                    <input type="text" className="editing_field" autoComplete="0"
                                    value={branchDetails && branchDetails.fax_no} onChange={(e) => setBranchDetails({ ...branchDetails, fax_no: e.target.value })} />    
                                </div>
                            </>)}
                    </>
                )}
                    {branchDetails && branchDetails.branch_type_cd > 1 && (
                        <>
                            <div id="tabs_div" className="profilemenu-scroll" style={{ borderBottom: "solid 1px #ccc", marginTop:"10px" }}>
                                <div className={tapActive === "Location" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Location"); }}>Location</div>
                                <div className={tapActive === "Banks" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Banks"); }}>Banks</div>
                                <div className={tapActive === "Cashiers" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Cashiers"); }}>Cashiers</div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                            {tapActive === "Location" && (
                                <>         
                                    {(branchDetails && branchDetailsMode === "View") && (
                                        <>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[12].description}</label>
                                                <div className="viewing_field">{countries.find((el) => el.code === branchDetails.country_cd,).name}</div>
                                            </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[13].description}</label>
                                                <div className="viewing_field">{(states.length > 0 && branchDetails.state_cd) && states.find((el) => el.code === branchDetails.state_cd,).name}</div>
                                            </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[14].description}</label>
                                                <div className="viewing_field">{branchDetails.city}</div>
                                            </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[15].description}</label>
                                                <div className="viewing_field">{branchDetails.address}</div>
                                            </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[16].description}</label>
                                                <div className="viewing_field">{branchDetails.latitude}</div>
                                            </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[17].description}</label>
                                                <div className="viewing_field">{branchDetails.longitude}</div>
                                            </div>
                                        </>)}
                                    {(branchDetails && branchDetailsMode === "Edit") && (
                                        <>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[12].description}</label>
                                                    <select className="editing_field" style={{ outline: "0" }} value={(branchDetails && branchDetails.country_cd) && branchDetails.country_cd} onChange={(e) => setBranchDetails({ ...branchDetails, country_cd: e.target.value })}>
                                                    <option value="0"></option>
                                                        {countries.length >= 0 && countries.map((country_list, index) => {
                                                        return (
                                                            <option key={index} value={country_list.code}>
                                                                {country_list.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                      </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[13].description}</label>
                                                    <select className="editing_field" style={{ outline: "0" }} value={(branchDetails && branchDetails.state_cd) && branchDetails.state_cd} onChange={(e) => setBranchDetails({ ...branchDetails, state_cd: e.target.value })}>
                                                    <option value="0"></option>
                                                    {states.length >= 0 && states.map((states_list, index) => {
                                                        return (
                                                            <option key={index} value={states_list.code}>
                                                                {states_list.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                      </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[14].description}</label>
                                                <input type="text" className="editing_field" autoComplete="0"
                                                    value={branchDetails && branchDetails.city} onChange={(e) => setBranchDetails({ ...branchDetails, city: e.target.value })} />
                                                      </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[15].description}</label>
                                                <input type="text" className="editing_field" autoComplete="0"
                                                    value={branchDetails && branchDetails.address} onChange={(e) => setBranchDetails({ ...branchDetails, address: e.target.value })} />
                                            </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[16].description}</label>
                                                <input type="text" className="editing_field" autoComplete="0"
                                                    value={branchDetails && branchDetails.latitude} onChange={(e) => setBranchDetails({ ...branchDetails, latitude: e.target.value })} />
                                            </div>
                                            <div className="data_line">
                                                <label className="label_area">{pageLables[17].description}</label>
                                                <input type="text" className="editing_field" autoComplete="0"
                                                    value={branchDetails && branchDetails.longitude} onChange={(e) => setBranchDetails({ ...branchDetails, longitude: e.target.value })} />
                                            </div>
                                    </>)}
                                </>)}
                            {tapActive === "Banks" && (
                                <>
                                    <div>Banks</div>
                                </>
                            )}
                            {tapActive === "Cashiers" && (
                            <>
                                <div>Cashiers</div>
                            </>
                                    )}
                    </>)}
                </>)}
            </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationStructure;
