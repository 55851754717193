import React,{useRef, useState} from 'react'
import plusIcon from './SVG_images/plus.svg'
import checkIcon from './SVG_images/check.svg'
import deleteIcon from './SVG_images/delete.svg'
import editIcon  from './SVG_images/edit.svg'
import closeIcon from './SVG_images/close.svg'
import saveIcon from './SVG_images/save.svg'

import {edit_learn_line,update_learn_line,show_new_learn_line,save_new_learn,close_learn_line,save_new_prerequired} from './Learn'

import './tolearn.css';
function To_learn() {

 const [divAdd, setDivAdeded] = useState([]); 
 const [divAdd1, setDivAdeded1] = useState([]); 

    return (
    <>
        <div><h2>What you'll learn</h2></div>
        <div style={{borderTop:'solid 1px gray', width:'800px', padding:'20px'}}>

            {divAdd.length>0 && divAdd.map((name,i)=>{

                return (
                    <div id={`line_container${i}`} className="learn_line_div" key={i}>
                        <div   id={`'learn_checked_area${i}`} className="checked_area"><img src={checkIcon} style={{height:'16px', verticalAlign:'middle'}} /></div>
                        <div  id={`learn_line${i}`} className="learn_line">{name}</div>
                    <div style={{float:'right', width:'10%', textAlign:'right'}}>
                        <img id={`learn_delete_image${i}`} src={deleteIcon} style={{height:'18px', float:'right', marginLeft:'14px'}}/>
                        <img  id={`learn_edit_image${i}`} src={editIcon} style={{height:'18px', float:'right', marginLeft:'14px'}} onClick={()=>{edit_learn_line('learn',i)}} />
                        <img  id={`learn_close_image${i}`} src={closeIcon} style={{height:'18px', float:'right', marginLeft:'14px', display:'none'}} onClick={()=>{close_learn_line('learn',i)}}/>
                        <img  id={`learn_update_image${i}`}src={saveIcon} style={{height:'18px', float:'right', marginLeft:'14px', display:'none'}} onClick={()=>{update_learn_line('learn',i)}} />
                     </div>
                    <div style={{clear:"both"}}></div>
                </div>                
                )
            })}
        <div id="lears_lines"></div>                                             
        <div style={{lineHeight:'28px', marginTop:'15px'}}>
            <div id="learn_insert_new_line_icon" onClick={()=>{show_new_learn_line('learn')}}><img src={plusIcon} style={{height:'30px', color:'cornflowerblue'}}/></div>
            <div id="learn_insert_new_line_area" style={{display:'none'}}>
                <div style={{float:'left', width:'90%'}}>
                    <input id="learn_text" type="text" className="input_field"/>
                </div>
                    <div style={{float:'right'}}><input id="save_learn" type="button" value="save" onClick={()=>{save_new_learn(setDivAdeded,divAdd)}}/></div>
                <div style={{clear:'both'}}></div>
            </div>                             
        </div>               
    </div>

    <h2>pre Required</h2>
        <div style={{borderTop:'solid 1px gray', width:'800px', padding:'20px'}}>

            {divAdd1.length>0 && divAdd1.map((name,i)=>{

                return (
                    <div id={`prerequired_container${i}`} className="learn_line_div" key={i}>
                        <div   id={`'prerequired_checked_area${i}`} className="checked_area"><img src={checkIcon} style={{height:'16px', verticalAlign:'middle'}} /></div>
                        <div  id={`prerequired_line${i}`} className="learn_line">{name}</div>
                    <div style={{float:'right', width:'10%', textAlign:'right'}}>
                        <img id={`prerequired_delete_image${i}`} src={deleteIcon} style={{height:'18px', float:'right', marginLeft:'14px'}}/>
                        <img  id={`prerequired_edit_image${i}`} src={editIcon} style={{height:'18px', float:'right', marginLeft:'14px'}} onClick={()=>{edit_learn_line('prerequired',i)}} />
                        <img  id={`prerequired_close_image${i}`} src={closeIcon} style={{height:'18px', float:'right', marginLeft:'14px', display:'none'}} onClick={()=>{close_learn_line('prerequired',i)}}/>
                        <img  id={`prerequired_update_image${i}`}src={saveIcon} style={{height:'18px', float:'right', marginLeft:'14px', display:'none'}} onClick={()=>{update_learn_line('prerequired',i)}} />
                    </div>
                    <div style={{clear:"both"}}></div>
                </div>                
                )
            })}
        <div id="prerequired_lines"></div>                                             
        <div style={{lineHeight:'28px', marginTop:'15px'}}>
            <div id="prerequired_insert_new_line_icon" onClick={()=>{show_new_learn_line('prerequired')}}><img src={plusIcon} style={{height:'30px', color:'cornflowerblue'}}/></div>
            <div id="prerequired_insert_new_line_area" style={{display:'none'}}>
                <div style={{float:'left', width:'90%'}}>
                    <input id="prerequired_text" type="text" className="input_field"/>
                </div>
                    <div style={{float:'right'}}><input id="save_prerequired" type="button" value="save" onClick={()=>{save_new_prerequired(setDivAdeded1,divAdd1)}}/></div>
                <div style={{clear:'both'}}></div>
            </div>                             
        </div>               
    </div>
    
 </>
    )
  }
  




    
    
  export default To_learn;