
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { HiOutlineHandThumbUp } from "react-icons/hi2";
import { HiOutlineHandThumbDown } from "react-icons/hi2";


const Decisions = () => {

    const [countriesList, setCountriesList] = useState([]);    
    const [departmentsList, setDepartmentsList] = useState([]);   
    const [decisionsList, setDecisionsList] = useState([]);   
    const [decisionMode, setDecisionMode] = useState("initial");
    const [decisionItemEditMode, setDecisionItemEditMode] = useState(false);
    const [decisionsDetails, setDecisionsDetails] = useState();
    const [serch, setSerch] = useState();
    const [decisionsItems, setDecisionsItems] = useState([]);  
    const [pageLables, setPageLables] = useState([]);
    const [sectionsList, setSectionsList] = useState([]);
    const [universitiesList, setUniversitiesList] = useState([]);

    const [uploadprecentage, setUploadPercentage] = useState(0)
    const [pdfFileBase64, setPdfFileBase64] = useState("");
    const [pickedPDFFile, setPickedPDFFile] = useState("");

    useEffect(() => {
        getDecisionInitial();
    }, []);

    /*file uploading -----------------------------*/
    const [fileUploading, setFileUploading] = useState(false)
    const filePickerRef = useRef(null)    

    const uploadDession = (pickedFile) => {
          
    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    let formData = new FormData();
    formData.append("file", pickedFile);
    formData.append("fname", "decision")
    formData.append("flocation", "acadamic");
    formData.append("file_old_name", "");

    const options = {headers: {Authorization: `Bearer ${userDataLocal.token}`,},onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
    };

    axios
      .post(`${process.env.REACT_APP_BACK_END_URL}/api/iipsys/v1/uploadFiles`, formData, options)
      .then((res) => {  
          if (res.data.result.rstatus === "1") {           
              setUploadPercentage(0);
              setFileUploading(false) 
              update_decision_url(res.data.file_url)
              
          } else {
              throw new Error("File not uploaded")
          }      
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionWay");
            window.location.replace("/");
          }
        }
        
        setUploadPercentage(0);
        setFileUploading(false)
      });      
    }

    const filePickedHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      let fileExttype = event.target.files[0].type.split("/")[0];
      let filExt = event.target.files[0].type.split("/")[1];
      console.log(fileExttype, filExt, event.target.files[0].type);
      if (filExt === "pdf") {
        pickedFile = event.target.files[0];
          const reader = new FileReader();
          setPickedPDFFile(pickedFile)
          reader.readAsDataURL(event.target.files[0])
          reader.addEventListener('load', () => {
              setPdfFileBase64(reader.result)
          })
          setDecisionsDetails()
          setDecisionsItems([])
          setDecisionMode("Draft");
        //uploadDession(pickedFile);
      } else {
          alert("upload only pdf files")
            return
      }
    }
    };

    const set_clear_mode = () => {
        setPdfFileBase64("")
        switch (decisionMode) {
            case "Edit":
                setDecisionMode("view");
                alert("view")
                break;

            case "view":
                setDecisionsDetails()
                setDecisionMode("initial");
                alert("initial")
               
                break;

            //case "Initial":
            //    setDecisionMode("Upload document");
            //    alert("Upload document")
            //    break;
            default:
                
        }

        //if (decisionMode === "Edit") {
        //    setDecisionMode("view");
        //} else {
        //   /* setDecisionMode();*/
        //    setDecisionMode("initial");
        //}
    };

    /* Read -------------------------*/
    const getDecisionInitial = async () => {
        
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
           
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_academic_initials",
                   
                },
            };

            //console.log("academic input ", `Bearer ${userDataLocal.token}`)
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_academic_initials`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }

            );

            //console.log("academic initials output", res);
            setPageLables(res.data.output.page_lables);
            setCountriesList(res.data.output.country);
            
        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getUniversities = async (e) => {
        setUniversitiesList([]);
        //setDepartmentsList([]);
        //setSectionsList([])
        setDecisionsDetails({ ...decisionsDetails, country_cd: e.target.value, university_cd: 0, department_cd: 0, section_cd: 0 })

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_universities_list",
                    country_cd: e.target.value
                },
            };

            //console.log("getUniversities input ", data)
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_university_list`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            //console.log("getUniversities output ", res.data.output.univercities)
            if (res.data.output.univercities) {setUniversitiesList(res.data.output.univercities)} 
            
        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };
    
    const getDepartment = async (e) => {
        setDepartmentsList([]);
        setSectionsList([])
        setDecisionsDetails({ ...decisionsDetails, university_cd: e.target.value, department_cd:0, section_cd: 0 })

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_university_departments",
                    university_cd: e.target.value
                },
            };

            //console.log("getDepartment input ", data)
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_university_departments`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            //console.log("getDepartment output ", res)
            if (res.data.output.departments) {setDepartmentsList(res.data.output.departments)} 
            
        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getSections = async (e) => {
        setSectionsList([]);
        setDecisionsDetails({ ...decisionsDetails, department_cd: e.target.value })
        
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_department_sections",
                    university_cd: decisionsDetails.university_cd,
                    department_cd: e.target.value,
                },
            };

            //console.log("getSections input ", data)

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_department_sections`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            //console.log("getSections output ", res.data.output.department_sections)
            if (res.data.output.department_sections) {setSectionsList(res.data.output.department_sections)}

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getDecisionsList = async (e) => {
        
        setDecisionsList([])
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_decisions_list",
                    university_cd: e.target.value
                },
            };

            console.log("getDecisionsList input ", data)
           
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_decisions_list`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getDecisionsList output ", res)
            if (res.data.output.decisions_list) { setDecisionsList(res.data.output.decisions_list) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getDecisionDetails = async (decision_no) => {
        alert("decision_no = " + decision_no)
        setDecisionsDetails()
        setDecisionsItems([])

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_decision_details",
                    decision_no: decision_no
                },
            };

            console.log("getDecisionDetails input ", data)

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_decision_details`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getDecisionDetails output ", res)
            if (res.data.output.decisions) {
                setDecisionsDetails(res.data.output.decisions)
                if (res.data.output.decision_items) { setDecisionsItems(res.data.output.decision_items)}
                setDecisionMode("view")
            }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };
    
    /* Updates ----------------*/
    const updatDession = async () => {

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "update_decision",
                    decision_no: decisionsDetails.decision_no,
                    decision_date: decisionsDetails.decision_date,
                    decision_title: decisionsDetails.decision_title,
                    country_cd: decisionsDetails.country_cd,
                    university_cd: decisionsDetails.university_cd,
                    decision_url: decisionsDetails.decision_url,
                },
            };
            console.log("add_decision input ", data);

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/update_decision`,

                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("add_decision output ", res);

            if (res.data.output.decision) { setDecisionsDetails(res.data.output.decision) };
            setDecisionMode("view");
            setDecisionItemEditMode(true)
            let url_name = uploadDession(pickedPDFFile)

            console.log(url_name)
            //update_decision_url(url_name)

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };
   
    const update_decision_url = async (url_name) => {
        
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "update_decision_url",
                    decision_no: decisionsDetails.decision_no,                   
                    decision_url: url_name,
                },
            };
            console.log("update_decision_url input ", data);
            
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/update_decision_url`,

                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("update_decision_url output ", res);


            setDecisionsDetails({ ...decisionsDetails, decision_url: res.data.output.decision_url })

            //if (res.data.output.decision) { setDecisionsDetails(res.data.output.decision) };
            //setDecisionMode("view");
            //setDecisionItemEditMode(true)
            //let fileName = uploadDession(pickedPDFFile)
            //update_decision_url(fileName)

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };
 
  return (

    <div className="row">        
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5  border-right">
              <div style={{ height: "calc(100vh - 100px)", overflow: "auto" }}>
        {/*Button bar ---------------------*/} 
        <div>
            <div className="normal_float normal_text_align ">
                {decisionMode !== "initial" && (
                    <>
                        <Tooltip title="Back" placement="bottom" arrow>
                            <IconButton onClick={set_clear_mode}>
                                <ArrowBackIcon color="inherit" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <span>
                            {(decisionMode === "Edit" || decisionMode === "Draft") && 
                                decisionMode}
                        </span>{" "}
                        <span className="separator_border">
                            {decisionsDetails && decisionsDetails.decision_no}
                        </span>
                    </>
                )}
            </div>
            <div className="opposite_float opposite_text_align">
                {(decisionMode === "Edit" || decisionMode === "Draft") && (
                <>
                    <Tooltip title="Save" placement="bottom" arrow>
                    <IconButton onClick={updatDession}>
                            <SaveIcon color="inherit" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>)}
                {decisionMode === "initial" && (
                <>
                    <Tooltip title="add New" placement="bottom" arrow>
                        <IconButton onClick={()=>filePickerRef.current.click()}>
                            <InsertDriveFileOutlinedIcon color="inherit" fontSize="small" />
                        </IconButton>
                             
                    </Tooltip>
                    <Tooltip title="Search" placement="bottom" arrow>
                        <IconButton> <SearchOutlinedIcon color="inherit" fontSize="small" />
                        </IconButton>
                    </Tooltip>

                    {fileUploading && <span>Uploading ... </span>}
                    <input ref={filePickerRef} style={{ display: "none" }} type="file" onChange={filePickedHandler} accept="application/pdf"/>
                </>)}
                {decisionMode === "view" && (
                    <>
                        <Tooltip title="Edit" placement="bottom" arrow>
                            <IconButton onClick={() => { setDecisionMode("Edit"); }}>
                                <ModeOutlinedIcon color="inherit" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </div>
            <div style={{ clear: "both" }}></div>
        </div>

        {/*Descision body ------------------*/}
        <div>              
            {decisionMode === "initial" && pageLables.length > 0 &&
            (<>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[3].description}</label>
                    <select className="editing_field" value={setSerch && setSerch.country_cd}
                    onChange={(e) => getUniversities(e)}>
                    <option value="0"></option>
                    {countriesList.length >= 0 &&
                        countriesList.map((country, index) => {
                            return (
                                <option key={index} value={country.code}>
                                    {country.description}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[4].description}</label>
                        <select className="editing_field" value={setSerch && setSerch.university_cd}
                        onChange={(e) => getDecisionsList(e)}>
                        <option value="0"></option>
                        {universitiesList.length >= 0 &&
                            universitiesList.map((universiy, index) => {
                                return (
                                    <option key={index} value={universiy.code}>
                                        {universiy.description}
                                    </option>
                                );
                            })}
                    </select>

                </div>
                <div style={{borderBottom: "solid 1px lightgray", marginTop:"20px" }}></div>
                {decisionsList.length >= 0 && decisionsList.map((decision, index) => (                          
                    <div style={{ lineHeight: "32px", margin: "0px 2px", borderBottom: "solid 1px lightgray" }} onClick={() => getDecisionDetails(decision.decision_no)}>
                    {decision.decision}
                    </div>
                )
                )}
                     
            </>)}

            {decisionMode === "Draft" && pageLables.length > 0 &&
            (<>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[0].description}</label>
                    <input type="text" className="editing_field" autoComplete="0" style={{ width: "200px" }}
                        value={decisionsDetails && decisionsDetails.decision_no} onChange={(e) => setDecisionsDetails({ ...decisionsDetails, decision_no: e.target.value })} />
                </div>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[1].description}</label>
                    <input type="text" className="editing_field" autoComplete="0" style={{ width: "200px" }}
                        value={decisionsDetails && decisionsDetails.decision_date} onChange={(e) => setDecisionsDetails({ ...decisionsDetails, decision_date: e.target.value })} />
                </div>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[2].description}</label>
                    <input type="text" className="editing_field" autoComplete="0"
                        value={decisionsDetails && decisionsDetails.decision_title} onChange={(e) => setDecisionsDetails({ ...decisionsDetails, decision_title: e.target.value })} />
                </div>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[3].description}</label>
                    <select className="editing_field" value={decisionsDetails && decisionsDetails.country_cd}
                        onChange={(e) => getUniversities(e)}>
                        <option value="0"></option>
                        {countriesList.length >= 0 &&
                            countriesList.map((country, index) => {
                                return (
                                    <option key={index} value={country.code}>
                                        {country.description}
                                    </option>
                                );
                            })}
                    </select>
                </div>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[4].description}</label>
                    <select className="editing_field" value={decisionsDetails && decisionsDetails.university_cd}
                        onChange={(e) => getDepartment(e)}>
                        <option value="0"></option>
                        {universitiesList.length >= 0 &&
                            universitiesList.map((universiy, index) => {
                                return (
                                    <option key={index} value={universiy.code}>
                                        {universiy.description}
                                    </option>
                                );
                            })}
                    </select>

                </div>

            </>)}

            {decisionMode === "view" && pageLables.length > 0 &&
            (<>                         
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[0].description}</label>
                    <div className="viewing_field">{decisionsDetails && decisionsDetails.decision_no}</div>
                </div>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[1].description}</label>
                    <div className="viewing_field">{decisionsDetails && decisionsDetails.decision_date}</div>                          
                </div>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[2].description}</label>
                    <div className="viewing_field">{decisionsDetails && decisionsDetails.decision_title}</div>      
                </div>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[3].description}</label>
                    <div className="viewing_field">{countriesList.find((el) => el.code === decisionsDetails.country_cd,).description}</div> 
                </div>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[4].description}</label>
                    <div className="viewing_field">{universitiesList.find((el) => el.code === decisionsDetails.university_cd,).description}</div> 
                </div>
            </>)}
        </div>
                          
        {/*Descision items ------------------*/}
        {decisionMode === "view" && pageLables.length > 0 && 
        (<>
            {/*Descision items header ------------------*/}
            <div style={{ marginTop: "20px"}}>
                <label className="decision_items_line" style={{ borderBottom: "solid 2px gray" }} >{pageLables[9].description}</label>
                </div>

            {/*Descision items editing area ------------------*/}
            {decisionItemEditMode &&
            (<>
                <div className="data_line" style={{ display: "flex" }} >
                    <label className="label_area">{pageLables[8].description}</label>
                    <input type="text" className="editing_field" autoComplete="0"
                    value={decisionsDetails && decisionsDetails.start_date} onChange={(e) => setDecisionsDetails({ ...decisionsDetails, start_date: e.target.value })} />
                </div>
                    <textarea className="text_area_edit" maxlength="1500"></textarea>
            </>)}

            {/*Descision items list ------------------*/}
            {decisionsItems.length > 0 && decisionsItems.map((decisionItem, index) => (
                    
                <div style={{ display: "flex", borderBottom:"solid 1px lightgray", paddin:"4px 0px"}} key={decisionItem.ser_no}>
                    <div style={{lineHeight: "32x", margin:"0px 2px" }}>                        
                        {decisionItem.approved === "1" ?
                            (<HiOutlineHandThumbUp style={{ margin:"5px", fontSize: "22px", color: "green" }} />)
                        :
                            (<HiOutlineHandThumbDown style={{ margin: "5px", fontSize: "22px", color: "red" }} />)
                        }                       
                    </div>
                    <div style={{ lineHeight: "32px", margin: "0px 2px" }}>{decisionItem.item_desc}</div>
                </div>
            )
            )}
        </>)}
            </div>
        </div>
          {/*attachment area ----------------------*/}
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">  
              <div style={{ height: "calc(100vh - 100px)"}}>
                  {(decisionMode === "view" && decisionsDetails && decisionsDetails.decision_url) &&
                  (<div className="pdf-viewer">
                      <object data={decisionsDetails.decision_url} type="application/pdf" className="pdf-viewer" >
                          <embed src={decisionsDetails.decision_url} type="application/pdf" />
                        </object>
                  </div>)}

                  {pdfFileBase64 !== "" &&
                  (<div className="pdf-viewer">
                  <object data={pdfFileBase64} type="application/pdf" className="pdf-viewer" >
                      <embed src={pdfFileBase64} type="application/pdf" />
                      </object>
                  </div>)}
            </div>
          </div>
      </div>
  
  );
};

export default Decisions;
