import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Clients from "../Crm/Clients";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { FiEdit } from "react-icons/fi";
import { TiAttachment } from "react-icons/ti";
import { RiPlayListAddFill } from "react-icons/ri";
import { BsTrash3 } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux"; //this import redux functions

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CaseFinance = ({ case_cd, pageLables }) => {
  const dispatch = useDispatch({});
  const [pageMode, setPageMode] = useState("Draft");
  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentCaseAmount, setPaymentCaseAmount] = useState({});
  const [paymentsType, setPaymentType] = useState([]);
  const [newPayment, setNewPayment] = useState({
    case_amount: "",
    payment_date: "",
    payment_amount_amount: "",
    payment_type_cd: "",
  });

  const filePickerRef = useRef(null);
  const { filePercentageReducers } = useSelector((state) => state);

  useEffect(() => {
    getPaymentsList();
  }, []);

  const totalAmountPayed = paymentsList?.reduce((total, payment) => {
    return total + parseFloat(payment.payment_amount_amount);
  }, 0); // 0 is the initial value of total

  console.log(totalAmountPayed);

  const set_clear_mode = () => {
    switch (pageMode) {
      case "Edit":
        setPageMode("View");
        break;
      case "View":
      case "Draft":
        //clear_data()
        setPageMode("List");
        break;
      default:
      // code block
    }
  };

  const getPaymentsList = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_payments",
          case_cd: case_cd,
        },
      };

      console.log("get_payments_list input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/get_payments_list`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_payments_list output", res);
      if (res.data.output.case_amount.case_amount) {
        setPaymentCaseAmount(res.data.output.case_amount);
        setNewPayment({ case_amount: res.data.output.case_amount.case_amount });
        setPageMode("List");
      } else {
        setPageMode("Draft");
      }
      if (res.data.output.payments_list) {
        setPaymentsList(res.data.output.payments_list);
      }
      if (res.data.output.payment_type)
        setPaymentType(res.data.output.payment_type);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const update_case_payment = async (update_function) => {
    try {
      if (!newPayment.case_amount) {
        alert("case amount needed");
        return;
      }
      if (!newPayment.payment_amount_amount) {
        alert("crrent amount missing");
        return;
      }
      if (!newPayment.payment_date) {
        alert("payment date missing");
        return;
      }
      if (!newPayment.payment_type_cd) {
        alert("payment type missing");
        return;
      }
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_case_payment",
          case_cd: case_cd,
          functionaction: update_function,
          case_amount: newPayment.case_amount,
          payment_date: newPayment.payment_date,
          payment_amount_amount: newPayment.payment_amount_amount,
          payment_type_cd: newPayment.payment_type_cd,
        },
      };
      console.log("payments_list_dml input ", data);
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/payments_list_dml`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );
      console.log("payments_list_dml output ", res.data.output);
      if (res.data.output.case_amount.case_amount) {
        setPaymentCaseAmount(res.data.output.case_amount);
        setNewPayment({ case_amount: res.data.output.case_amount.case_amount });
        setPageMode("List");
      } else {
        setPageMode("Draft");
      }
      if (res.data.output.payments_list) {
        setPaymentsList(res.data.output.payments_list);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  return (
    <>
      <div className="row">
        <div
          style={{
            height: "calc(100vh - 86px)",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <>
            {console.log(pageMode, "pageMode")}
            {/*Button bar ---------------------*/}
            <div>
              <div className="normal_float normal_text_align ">
                {pageMode !== "List" && (
                  <>
                    <Tooltip title="Back" placement="bottom" arrow>
                      {/* <IconButton onClick={set_clear_mode}> */}
                      <IconButton onClick={() => setPageMode("List")}>
                        <ArrowBackIcon color="inherit" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
              <div className="opposite_float opposite_text_align">
                {/* {(pageMode === "Edit" || pageMode === "Draft") && (
                  <>
                    <Tooltip title="Save" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          update_case_payment("update");
                        }}
                      >
                        <SaveIcon color="inherit" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )} */}

                {pageMode === "Draft" && (
                  <>
                    <Tooltip title="Save" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          update_case_payment("insert");
                        }}
                      >
                        <SaveIcon color="inherit" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {pageMode === "Edit" && (
                  <>
                    <Tooltip title="Save" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          update_case_payment("update");
                        }}
                      >
                        <SaveIcon color="inherit" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {pageMode === "List" && (
                  <>
                    <Tooltip
                      title="Add remaining amount"
                      placement="bottom"
                      arrow
                    >
                      <IconButton
                        onClick={() => {
                          setPageMode("Edit");
                        }}
                      >
                        <RiPlayListAddFill color="inherit" size="20" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {pageMode === "View" && (
                  <>
                    <Tooltip title="Edit" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          setPageMode("Edit");
                        }}
                      >
                        <ModeOutlinedIcon color="inherit" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Search" placement="bottom" arrow>
                      <IconButton>
                        <TiAttachment
                          color="inherit"
                          fontSize="20px"
                          onClick={() => filePickerRef.current.click()}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
            {/*Attachment area*/}
            {pageMode === "List" && (
              <>
                <div
                  className="table_header_div top_border_div table_header_background"
                  style={{ display: "flex" }}
                >
                  <div style={{ width: "20%" }}>
                    {pageLables[37].description}
                  </div>
                  <div style={{ width: "50%" }}>
                    {pageLables[39].description}
                  </div>
                  <div style={{ width: "30%" }}>
                    {pageLables[38].description}
                  </div>
                </div>
                {paymentsList &&
                  paymentsList.length > 0 &&
                  paymentsList.map((payment) => {
                    return (
                      <div className="row_area">
                        <div className="table_line_div bottom_border_div">
                          <div style={{ width: "20%" }}>
                            {payment.payment_date}
                          </div>
                          <div style={{ width: "50%" }}>
                            {payment.payment_type}
                          </div>
                          <div style={{ width: "30%" }}>
                            {payment.payment_amount_amount}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {paymentCaseAmount && paymentCaseAmount.case_amount && (
                  <div>
                    <div
                      className="table_header_div top_border_div"
                      style={{ display: "flex" }}
                    >
                      <div style={{ width: "20%" }}>
                        {pageLables[40].description}
                      </div>
                      <div style={{ width: "40%" }}>
                        {paymentCaseAmount.case_amount}
                      </div>
                      <div style={{ width: "10%" }}>
                        {pageLables[41].description}
                      </div>
                      <div style={{ width: "30%" }}>{totalAmountPayed}</div>
                    </div>
                    <div
                      className="table_header_div top_border_div"
                      style={{ display: "flex" }}
                    >
                      <div style={{ width: "20%" }}>
                        {pageLables[42].description}
                      </div>
                      <div style={{ width: "40%" }}>
                        {paymentCaseAmount.case_amount - totalAmountPayed}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* {pageMode === "View" &&
                        (<>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[11].description}</label>
                                <div class="viewing_field">{procedureDetail && procedureDetail.procedure_date}</div>
                            </div>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[35].description}</label>
                                <div class="viewing_field">{procedureDetail && procedureDetail.procedure_type_cd}</div>
                            </div>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[36].description}</label>
                                <div class="viewing_field">{procedureDetail && procedureDetail.procedure_name}</div>
                            </div>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[27].description}</label>
                                <div class="viewing_field">{procedureDetail && procedureDetail.procedure_desc}</div>
                            </div>
                        </>)} */}
            {(pageMode === "Edit" || pageMode === "Draft") && (
              <>
                {/*Client details*/}
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[40].description}
                  </label>
                  <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={newPayment.case_amount}
                    onChange={(e) =>
                      setNewPayment({
                        ...newPayment,
                        case_amount: e.target.value,
                      })
                    }
                    disabled={
                      paymentCaseAmount && paymentCaseAmount.case_amount
                        ? true
                        : false
                    }
                  />
                  {console.log(
                    paymentCaseAmount.case_amount,
                    " paymentCaseAmount.case_amount.case_amount ",
                  )}
                </div>

                {/* {paymentCaseAmount.case_amount && (
                  <div style={{ margin: "5px" }}>
                    <h6>Add remaning payment</h6>
                  </div>
                )} */}
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {"Current " + pageLables[38].description}
                  </label>
                  <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={newPayment && newPayment.payment_amount_amount}
                    onChange={(e) =>
                      setNewPayment({
                        ...newPayment,
                        payment_amount_amount: e.target.value,
                      })
                    }
                  />
                </div>

                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[37].description}
                  </label>
                  <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={newPayment && newPayment.payment_date}
                    onChange={(e) =>
                      setNewPayment({
                        ...newPayment,
                        payment_date: e.target.value,
                      })
                    }
                  />
                </div>

                <div style={{ display: "flex" }}>
                  <label className="label_area">Payment Type</label>

                  <select
                    onChange={(e) => {
                      setNewPayment({
                        ...newPayment,
                        payment_type_cd: e.target.value,
                      });
                    }}
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "1px solid grey",
                      background: "none",
                    }}
                    value={newPayment && newPayment.payment_type_cd}
                  >
                    <option value={0}>select payment type</option>
                    {paymentsType &&
                      paymentsType.length > 0 &&
                      paymentsType.map((paymentmode) => {
                        return (
                          <option value={paymentmode.payment_type_cd}>
                            {paymentmode.payment_type}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default CaseFinance;
