



import React, { useEffect, useState, useRef } from "react";
import UsersAndPrivileges from "../../component/Auth/UsersAndPrivileges";




const HrsUsersAndPrivileges = ({ branch_code }) => {
    useEffect(() => {
     
    }, []);

    return <> 
        <div>
            <UsersAndPrivileges system_code={"Hrs"} branch_code={branch_code} />
        </div>
    </>
}

export default HrsUsersAndPrivileges