import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { IoSearch } from "react-icons/io5";
import InvoiceItems from "../Invoice/Invoice";

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import InvoiceBody from "./InvoiceBody";

import { useSelector } from "react-redux"; //to get the value from the redux

const Invoice = () => {
    const [shipToType, setShipToType] = React.useState("female");
    const [clienttData, setclienttData] = useState({});
    const [requestTypetData, setrequestTypetData] = useState();
    const [customerTypetData, setCustomerTypetData] = useState();
    const [requesInitialstData, setRequesInitialstData] = useState();
    const [invoiceMode, setInvoiceMode] = useState("Initial");
    const [invoiceData, setInvoiceData] = useState();
    const [shipToData, setShipToData] = useState("byer");
    const [tapActive, setTapActive] = useState("Items");
    const [itemCodeRow, setItemCodeRow] = useState();
    const [itemData, setItemData] = useState([]);
    const [clientInvoices, setClientInvoices] = useState([]);
    const [pageLables, setPageLables] = useState();
    const [actions, setActions] = useState([]);
    const [invoiceYear, setInvoiceYear] = useState();
    const [branchCd, setBranchCd] = useState();
    const [invoiceCd, setInvoiceCd] = useState();
    const [countries, setCountries] = useState(false);
    const [countryIndex, setCountryIndex] = useState(0);
    const [selctedDailCode, setSelctedDailCode] = useState("965");

    const [media, setMedia] = useState({ mediaType: "", mediaURL: "" });
    const media_path = "http://files.amanrow.com/item_media/69/";

    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const [RequestInput, setRequestInput] = useState({
    request_year: 0,
    branch_cd: 0,
    request_cd: 0,
    });

    const { user } = useSelector((state) => {
    return state;
    });
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
    getCountries();
    getRequestInitial();
    }, []);

    const getCountries = async () => {
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
        info: {
            lang: userDataLocal.lang,
        },
        input: {
            actioncode: "COUNTRY_DAILCODES",
        },
        };

        console.log("get_counties ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/countries`,
        data,
        );
        console.log("get_counties output ", res.data.output.countries);

        const countryIndex = res.data.output.countries.findIndex(
        (country) => country.dial_cd === "965",
        );

        console.log("kuwait index", countryIndex);
        setCountryIndex(countryIndex);

        let options = res.data.output.countries.map((country) => {
        return {
            value: country.dial_cd,
            label: "+" + country.dial_cd,
            _id: country.country_cd,
            country_id: country.country_id,
            dial_cd: country.dial_cd,
        };
        });

        console.log("kuwait dailcd", options[countryIndex]);
        setCountries(options);
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    const handleClose = () => {
    setAnchorEl(null);
    };

    const set_new_mode = () => {
        setInvoiceMode("Draft");
        setShipToData("byer");
    };

    const mobilekeyEvent = (e, dailcd) => {
    if (e.charCode === 13) {
        get_client_detail_invoices(
        document.getElementById("client_mobile").value,
        dailcd,
        );
    }
    };

    /* Read*/
    const getRequestInitial = async () => {
    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    try {
        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "get_invoice_initials",
            company_cd: userDataLocal.company_cd,
            branch_cd: userDataLocal.branch_cd,
            system_cd: "Acc",
            function_cd: "11",
        },
        };
        console.log("get_invoice_initials input", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_invoice_initials`,
        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );

        console.log("get_invoice_initials output", res);
        if (
        res.data.output.pages_lables &&
        res.data.output.pages_lables.length > 0
        ) {
        setPageLables(res.data.output.pages_lables);
        }
        if (res.data.output.actions && res.data.output.actions.length > 0) {
        setActions(res.data.output.actions);
        }
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    const get_client_detail_invoices = async (mobile_no, dailcd) => {
    setclienttData();
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        /*let client_mobil_no = document.getElementById('client_mobile').value*/
        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "get_client_invoices",
            country_area_cd: dailcd,
            mobile_no: mobile_no,
        },
        };
        console.log("get_client_detail_invoices input", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_client_invoices`,
        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );
        console.log("get_client_detail_invoices output", res);

        if (res.data.output.client_details) {
        setclienttData(res.data.output.client_details);
        if (
            res.data.output.client_invoices &&
            res.data.output.client_invoices.length > 0
        ) {
            setClientInvoices(res.data.output.client_invoices);
        }
        } else {
        alert("no data");
        }
        console.log("client invoices", res);
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    const getInvoice = async (invoice_year, branch_cd, invoice_cd) => {
    setInvoiceYear(invoice_year);
    setBranchCd(branch_cd);
    setInvoiceCd(invoice_cd);
    setInvoiceMode("Retrieve");
    };

    return (
    <>
        <div
        claasName="page-inside-containe" style={{ marginLeft: "15px", martginRight: "10px" }}>
        {invoiceMode === "Initial" && (
            <div>
            {/*Menu bar*/}
            <div>
                <div className="normal_float normal_text_align "> </div>
                <div className="opposite_float opposite_text_align">
                {invoiceMode === "Initial" && (
                    <>
                    <Tooltip title={actions.length > 0 && actions[1].tap_name} placement="bottom" arrow>
                        <IconButton onClick={() => {set_new_mode()}}>{" "} <InsertDriveFileOutlinedIcon color="inherit" fontSize="small" />{" "}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={pageLables && pageLables[1].description} placement="bottom" arrow>
                        <IconButton>
                        {" "} <SearchOutlinedIcon color="inherit" fontSize="small" />{" "}
                        </IconButton>
                    </Tooltip>
                    </>
                )}
                </div>
                <div style={{ clear: "both" }}></div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
                {/* <input id="country_code" type="text" className="editing_field" style={{ outline: "none", width:"40px"}} value="965"/>
                    <input id="client_mobile" type="text" className="editing_field" style={{ outline: "none", width:"auto" }} autoComplete="0" placeholder={pageLables && pageLables[0].description} onKeyPress={mobilekeyEvent} /> */}
                <div className="search-input-container">
                {countries.length > 0 && (
                    <Select
                        options={countries} defaultValue={countries[117]}
                        formatOptionLabel={(country) => (
                            <div className="country-option">
                                <img src={`https://files.amanrow.com/flags/${country.country_id.toLowerCase()}.png`} alt="country-image"/>
                                <span style={{ marginLeft: "10px" }}>{country.label}</span>
                            </div>
                        )}
                        onChange={(e) => {
                            console.log(e);
                            setSelctedDailCode(e.value);
                        }}
                    />
                )}

                <input type="text" id="client_mobile" name="phone" className="phoneno-input-container" placeholder={pageLables && pageLables[0].description} onKeyPress={(e) => mobilekeyEvent(e, selctedDailCode)}/>
                <button id="search-button">
                    <IoSearch size={15} />
                </button>
                </div>
            </div>
            {clienttData && (
                <div>
                    {clientInvoices && (
                        <div id="client_invoices_box" className="dropdown_menu highlighted_background">
                            <div style={{ pading: "10px", marginTop: "15px" }}>
                                <div style={{ fontWeight: "600" }}>{" "} {clienttData.client_name}{" "}</div>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Total Amount</th>
                                            <th>Due Amout</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clientInvoices.length > 0 && clientInvoices.map((invoices, index) => (
                                            <tr onClick={() => getInvoice(invoices.invoice_year, invoices.branch_cd, invoices.invoice_cd)}>
                                                {/*<td className="field_text_alighn">{"INV-" + invoices.invoice_year + "-" + invoices.branch_cd + "-" + invoices.invoice_cd} </td>*/}
                                                <td className="field_text_alighn">
                                                    {invoices.invoice_date}
                                                </td>
                                                <td className="field_text_alighn">
                                                    {invoices.invoice_amount}
                                                </td>
                                                <td className="field_text_alighn"> {" "} {invoices.invoice_amount - invoices.recieved_amount}{" "} </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            )}
            </div>
        )}
        {(invoiceMode === "Draft" || invoiceMode === "Retrieve") && (
            <InvoiceBody parent="Invoice" invoiceMode={invoiceMode} setInvoiceMode={setInvoiceMode} pageLables={pageLables} invoiceYear={invoiceYear} branchCd={branchCd} invoiceCd={invoiceCd} clienttData={clienttData} setclienttData={setclienttData}/>
        )}
        </div>
    </>
    );
};

export default Invoice;
