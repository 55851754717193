import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import { BsThreeDotsVertical } from "react-icons/bs";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineBorderBottom,
} from "react-icons/ai";
import saveIcon from "../../../SVG_images/save.svg";
import editIcon from "../../../SVG_images/edit.svg";
import attacmentsIcon from "../../../SVG_images/attachment.png";
import remarksIcon from "../../../SVG_images/note.png";

import { Dropdown, Collapse } from "react-bootstrap";

const ComplaintRequest = ({ setPageMode, requestTypetData,  customerTypetData, requestMode, setRequestMode, requestData, invoiceData, setInvoiceData, request_type_index, }) => {
  const [clienttData, setclienttData] = useState();
  const [openInvoices, setOpenInvoices] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestMode === "add request") {
      setRequestMode("draft request");
      document.getElementById("request_type_name").innerText = requestTypetData.find( (el) => el.request_type_cd === request_type_index,).request_type;
    } else {
      var request = requestData.request_details;
      document.getElementById("edit_status").innerText = "";
      document.getElementById("request_type_name").innerText = requestTypetData.find( (el) => el.request_type_cd === requestData.request_details.request_type_cd, ).request_type;
      document.getElementById("request_code").innerText = "#" + requestData.request_details.request_year + "/" + requestData.request_details.branch_cd + "/" + requestData.request_details.request_cd;
    }
  }, []);

  const set_clear_mode = () => {
    if (requestMode === "edit request") {
      setRequestMode("view request");
      document.getElementById("edit_status").innerText = "";
    } else {
      setPageMode("initial");
    }
  };

  const set_edit_mode = () => {
    setRequestMode("edit request");
    document.getElementById("edit_status").innerText = "Edit";
    
  };

  const keyEvent = (e) => {
    if (e.charCode === 13) {
      get_client_details(document.getElementById("client_mobile").value);
    }
  };

  const get_client_details = async (mobile_no) => {
    setclienttData();
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      /*let client_mobil_no = document.getElementById('client_mobile').value*/
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client_invoices",
          country_area_cd: "965",
          mobile_no: mobile_no,
        },
      };
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_client_invoices`,
        data,
      );
      if (res.data.output.client_name) {
        setclienttData(res.data.output);
      } else {
        alert("no data");
      }
      console.log("client invoices", res.data.output);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const setInvoice = async (invoice_year, branch_cd, invoice_cd) => {
    document.getElementById("invoice_no").innerHTML = "INV-" + invoice_year + "-" + branch_cd + "-" + invoice_cd 
    document.getElementById("invoice_collapse").style.display = "none";

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_invoice",
          invoice_year: "2022",
          branch_cd: "69",
          invoice_cd: "1",
        },
      };
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_invoice`,
        data,
      );

      console.log("invoice", res);
      setInvoiceData(res.data.output);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  return (
    <>
      <div>
        <div id="request_header" className="buttons_div ">
          <div className="subbutton_div normal_float" onClick={set_clear_mode}>
            <div className="buton_icon normal_float"> {" "} <AiOutlineArrowLeft style={{ fontSize: "18px" }} /></div>
            <div id="edit_status" className="normal_float"></div>
            <div style={{ clear: "both" }}></div>
          </div>
          <div className="subbutton_div normal_float" style={{ paddingLeft: "10px", borderLeft: "solid 1px gray" }} >
            <div id="request_type_name" className=" normal_float"></div>
            <div id="request_code" className=" normal_float" style={{ paddingRight: "20px", paddingLeft: "2px" }}></div>
            <div style={{ clear: "both" }}></div>
          </div>
          <div id="request_buttons" className="opposite_float subbutton_div">
            {requestMode === "view request" && (
              <BsThreeDotsVertical className="buton_icon opposite_float" />
            )}
            {(requestMode === "edit request" || requestMode === "draft request") && (<img id="item_save_btn" src={saveIcon} className="buton_icon opposite_float "/>)}

            {requestMode === "view request" && (
              <>
                <img src={remarksIcon} className="buton_icon opposite_float" onClick={() => { dispatch({ type: "attachmentInfo",payload: { data1: "good morening" }, })}} alt=""/>
                <img src={attacmentsIcon} className="buton_icon opposite_float" onClick={() => { dispatch({ type: "attachmentInfo", payload: { data1: "good morening" }, });}}  alt=""/>
                <img src={editIcon} className="buton_icon opposite_float" onClick={() => { set_edit_mode() }}  alt=""/>
              </>
            )}
            <div style={{ clear: "both" }}></div>
          </div>
          <div style={{ clear: "both" }}></div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-7">
          {requestMode === "draft request" && (
            <>
              <table className="table-verticle-middle table-frm table-frm-ed">
                <tbody>
                  <tr>
                    <th style={{ width: "35%" }} />
                    <th style={{ width: "65%" }} />
                  </tr>
                  <tr>
                    <th id="customer_type_cd_l">Applicant Type</th>
                    <td>
                      <select
                        id="customer_type_cd"
                        className="form-control input-sm "
                      >
                        <option value=""></option>
                        {customerTypetData.length >= 0 &&
                          customerTypetData.map((customer, index) => {
                            return (
                              <option key={index} value={customer.code}>
                                {customer.name_txt}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th id="customer_id_l">Client Mobile</th>
                    <td>
                      <input id="client_mobile" type="text" className="form-control input-sm" style={{ outline: "none" }} placeholder="Client Mobile" autoComplete="0" onKeyPress={keyEvent}/>
                    </td>
                  </tr>
                  {clienttData && (
                    <tr>
                      <th id="client_name_l" style={{ verticalAlign: "top" }}>Client</th>
                      <td>
                        <div className="form-control  input-sm ">
                          <div>
                            <div id="client_name" className="normal_float field_text_alighn" >{clienttData.client_name}</div>

                            <div className="opposite_float" onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>X</div>
                            <div style={{ clear: "both" }}></div>
                          </div>
                          <Collapse in={open}>
                            <div id="example-collapse-text" className="field_text_alighn" style={{ marginTop: "10px" }}>
                              {clienttData.country_area_cd +
                                " " +
                                clienttData.mobil_no}
                              <br />
                              {clienttData.email_address}
                              <br />
                              {clienttData.country_name +
                                ", " +
                                clienttData.state_name +
                                ", " +
                                clienttData.city_name}
                              <br />
                              {clienttData.client_address}
                            </div> </Collapse> </div>
                      </td>
                    </tr>
                  )}
                  {clienttData && (
                    <tr>
                      <th id="invoice_no_l" style={{ verticalAlign: "top" }}>Invoice No.</th>
                      <td>
                        <div className="form-control  input-sm ">
                          <div>
                            {clienttData.client_invoices.length == 1 && (
                              <div id="invoice_no" className="normal_float field_text_alighn">
                                {"INV -" +
                                  clienttData.client_invoices[0].invoice_year +
                                  "-" +
                                  clienttData.client_invoices[0].branch_cd +
                                  "-" +
                                  clienttData.client_invoices[0].invoice_cd}
                              </div>
                            )}
                            {clienttData.client_invoices && clienttData.client_invoices.length >= 1 && (
                                <div>
                                  <div id="invoice_no" className="normal_float field_text_alighn" ></div>
                                  <div className="opposite_float" aria-controls="invoice_collapse" aria-expanded={openInvoices} onClick={() => setOpenInvoices(!openInvoices)} >X</div>
                                  <div style={{ clear: "both" }}></div>
                                </div>
                              )}
                          </div>
                        </div>
                        <Collapse in={openInvoices}> <div id="invoice_collapse" className="field_text_alighn input-sm">
                            {clienttData.client_invoices && (
                              <div style={{ pading: "10px", marginTop: "15px" }} >
                                <table className="table table-hover">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "50%" }}> Invoice No. </th>
                                      <th style={{ width: "50%" }}> Invoice Date </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {clienttData.client_invoices.length > 0 &&
                                      clienttData.client_invoices.map(
                                        (invoices, index) => (
                                          <tr
                                            onClick={() =>
                                              setInvoice(
                                                invoices.invoice_year,
                                                invoices.branch_cd,
                                                invoices.invoice_cd,
                                              )
                                            }
                                          >
                                            <td className="field_text_alighn">
                                              {"INV-" +
                                                invoices.invoice_year +
                                                "-" +
                                                invoices.branch_cd +
                                                "-" +
                                                invoices.invoice_cd}{" "}
                                            </td>
                                            <td className="field_text_alighn">
                                              {invoices.invoice_date}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th id="request_desc_l">Complaint Desc.</th>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <textarea id="requext_text_v" className="form-control input-sm" style={{ width: "100%", height: "70px" }} Placeholder="enter the complaint her" ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          {requestData && (
            <>
              <table className="table-verticle-middle table-frm table-frm-ed">
                <tbody>
                  <tr>
                    <th style={{ width: "35%" }} />
                    <th style={{ width: "65%" }} />
                  </tr>

                  {(requestMode === "edit request" ||
                    requestMode === "add request") && (
                    <>
                      <tr>
                        <th id="requested_by_l">Requested By</th>
                        <td>
                          <div
                            id="request_user_name_v"
                            className="form-control input-sm view_field_no_border field_text_alighn "
                          >
                            {requestData.request_details.request_user_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th id="request_type_cd_l">Request Type</th>
                        <td>
                          <select
                            id="request_type_cd"
                            className="form-control input-sm "
                            selectedValue={
                              requestData.request_details.request_type_cd
                            }
                          >
                            {requestTypetData.length >= 0 &&
                              requestTypetData.map((request_type, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={request_type.request_type_cd}
                                  >
                                    {request_type.request_type}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th id="customer_type_cd_l">Applicant Type</th>
                        <td>
                          {console.log(
                            "code = ",
                            requestData.request_details.code,
                          )}
                          <select
                            id="customer_type_cd"
                            className="form-control input-sm "
                            selectedValue={requestData.request_details.code}
                          >
                            {customerTypetData.length >= 0 &&
                              customerTypetData.map((customer, index) => {
                                return (
                                  <option key={index} value={customer.code}>
                                    {customer.name_txt}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th id="customer_id_l">Mobile</th>
                        <td>
                          <input
                            id="client_mobile"
                            type="text"
                            className="form-control input-sm"
                            style={{ outline: "none" }}
                            placeholder="Client Mobile"
                            autoComplete="0"
                            onChange={get_client_details}
                          />
                        </td>
                      </tr>
                      {clienttData && (
                        <tr>
                          <th
                            id="client_name_l"
                            style={{ verticalAlign: "top" }}
                          >
                            Client
                          </th>
                          <td>
                            <div className="form-control  input-sm ">
                              <div>
                                <div
                                  id="client_name"
                                  className="normal_float field_text_alighn"
                                >
                                  {clienttData.client_name}
                                </div>
                                <div
                                  className="opposite_float"
                                  onClick={() => setOpen(!open)}
                                  aria-controls="example-collapse-text"
                                  aria-expanded={open}
                                ></div>
                                <div style={{ clear: "both" }}></div>
                              </div>
                              <Collapse in={open}>
                                <div
                                  id="example-collapse-text"
                                  className="field_text_alighn"
                                  style={{ marginTop: "10px" }}
                                >
                                  {clienttData.country_area_cd +
                                    " " +
                                    clienttData.mobil_no}
                                  <br />
                                  {clienttData.email_address}
                                  <br />
                                  {clienttData.country_name +
                                    ", " +
                                    clienttData.state_name +
                                    ", " +
                                    clienttData.city_name}
                                  <br />
                                  {clienttData.client_address}
                                </div>
                              </Collapse>
                            </div>
                          </td>
                        </tr>
                      )}
                      {clienttData && (
                        <tr>
                          <th
                            id="invoice_no_l"
                            style={{ verticalAlign: "top" }}
                          >
                            Invoice No.
                          </th>
                          <td>
                            <div className="form-control  input-sm ">
                              <div>
                                <div
                                  id="Ivoive_no"
                                  className="normal_float field_text_alighn"
                                ></div>
                                <div
                                  className="opposite_float"
                                  onClick={() => setOpenInvoices(!openInvoices)}
                                  aria-controls="invoice_collapse"
                                  aria-expanded={openInvoices}
                                >
                                  X
                                </div>
                                <div style={{ clear: "both" }}></div>
                              </div>
                            </div>
                            <Collapse in={openInvoices}>
                              <div
                                id="invoice_collapse"
                                className="field_text_alighn input-sm"
                              >
                                {clienttData.client_invoices && (
                                  <div
                                    style={{
                                      pading: "10px",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <table className="table table-hover">
                                      <thead>
                                        <tr>
                                          <th style={{ width: "50%" }}>
                                            Invoice No.
                                          </th>
                                          <th style={{ width: "50%" }}>
                                            Invoice Date
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {/* {clienttData.client_invoices.length >= 0 && clienttData.client_invoices.map((invoices, index) => ( */}
                                        {/*     <tr onClick={() => setInvoice(invoices.invoice_year, invoices.branch_cd, invoices.invoice_cd)}> */}
                                        {/*         <td className="field_text_alighn">{"INV-" + invoices.invoice_year + "-" + invoices.branch_cd + "-" + invoices.invoice_cd} </td> */}
                                        {/*         <td className="field_text_alighn">{invoices.invoice_date}</td> */}
                                        {/*     </tr> */}
                                        {/* ) */}
                                        {/*)  */}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th id="request_desc_l">Complaint Desc.</th>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <textarea
                            id="requext_text_v"
                            className="form-control input-sm"
                            style={{ width: "100%", height: "70px" }}
                            Placeholder="enter the complaint her"
                          >
                            {requestData.request_details.requext_text}
                          </textarea>
                        </td>
                      </tr>
                    </>
                  )}

                  {requestMode === "view request" && (
                    <>
                      <tr>
                        <th id="requested_by_l">Requested By</th>
                        <td>
                          <div
                            id="request_user_name_v"
                            className="form-control input-sm view_field_no_border field_text_alighn "
                          >
                            {requestData.request_details.request_user_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th id="request_type_cd_l">Request Type</th>
                        <td>
                          <td>
                            <div
                              id="request_type_cd"
                              className="form-control input-sm view_field_no_border field_text_alighn "
                            >
                              {requestData.request_details.request_type}{" "}
                            </div>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <th id="customer_type_cd_l">Applicant Type</th>
                        <td>
                          <td>
                            <div
                              id="customer_type_cd"
                              className="form-control input-sm view_field_no_border field_text_alighn "
                            >
                              {
                                customerTypetData.find(
                                  (el) =>
                                    el.code ===
                                    requestData.request_details
                                      .customer_type_cd,
                                ).name_txt
                              }
                            </div>
                          </td>
                        </td>
                      </tr>
                      <tr>
                        <th id="request_desc_l">Complaint Desc.</th>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <textarea
                            id="requext_text_v"
                            className="form-control input-sm"
                            style={{
                              width: "100%",
                              height: "70px",
                              backgroundColor: "transparent",
                            }}
                            readOnly
                          >
                            {requestData.request_details.requext_text}
                          </textarea>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>

        {invoiceData && (
          <>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ marginTop: "20px" }} >
              {/*Bootstrap CSS jQuery first, then Bootstrap JS. Nav tabs */}
              <ul id="nav-main-section" className="nav nav-tabs tabdrop-section tabs" style={{ borderBottom: "solid 1px #F5F5F5" }} role="tablist" >
                <li id="drop_down" style={{ position: "absolute", right: "0px" }} className="dropdown pull-right tabdrop show" >
                  <span className="dropdown-toggle" data-bs-toggle="dropdown" href="#" aria-expanded="false" >
                    <span className="display-tab"> </span>
                    <b className="caret"></b>
                  </span>
                  <ul className="dropdown-menu" id="drop-down-menu-con" style={{}} ></ul>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a id="items-tab" className="nav-link active" data-bs-toggle="tab" data-bs-target="#items" role="tab" aria-controls="spicifications" aria-selected="true" >
                    Items
                  </a>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a
                    id="item_in_stores-tab" className="nav-link" data-bs-toggle="tab" data-bs-target="#item_in_stores" role="tab" aria-controls="item_in_stores" aria-selected="true" >
                    Reciepts
                  </a>
                </li>
              </ul>

              {/* -------------Tab panes --------------------------------------------*/}
              <div className="tab-content" id="myTabContent">
                <div id="items" className="tab-pane fade show active" role="tabpanel" aria-labelledby="spicifications-tab" >
                  <div className="col-sm-12 col-md-7 col-lg-6 col-xl-6">
                    {invoiceData.invoice_details.item_or_service_type_cd == 1 && (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "50%" }}>Item</th>
                            <th style={{ width: "25%" }}>Unit</th>
                            <th style={{ width: "20%" }}>Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceData.invoice_items.length >= 0 && invoiceData.invoice_items.map( (invoiceItems, index) => (
                                <tr className="highlited_row">
                                  <td> <input type="checkbox" /> </td>
                                  <td>{invoiceItems.item_name}</td>
                                  <td>{invoiceItems.unit_name}</td>
                                  <td>{invoiceItems.quantity}</td>
                                </tr>
                              ),
                            )}
                        </tbody>
                      </table>
                    )}

                    {invoiceData.invoice_details.item_or_service_type_cd == 2 && (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "95%" }}>Item</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceData.invoice_items.length >= 0 && invoiceData.invoice_items.map( (invoiceItems, index) => (
                                <tr>
                                  <td> <input type="checkbox" /> </td>
                                  <td>{invoiceItems.item_name}</td>
                                </tr>
                              ),
                            )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div id="item_in_stores" className="tab-pane fade" role="tabpanel" aria-labelledby="item_in_stores-tab" >
                  Reciepts
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ComplaintRequest;
