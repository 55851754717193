const initialState = {};

const FilePercentageReducers = (state = initialState, action) => {
    switch (action.type) {
        case "FILEUPLOADS":
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};


export default FilePercentageReducers