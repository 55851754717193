import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";

import { LuUserPlus } from "react-icons/lu";
import SaveIcon from "@mui/icons-material/Save";
import { TfiCheckBox } from "react-icons/tfi";
import blankprofile from "../../Images/blankprofile.png";
import { FiShoppingCart } from "react-icons/fi";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckOutFatoora from "../CheckOut/CheckOutFatoora";

const Users = ({
  pageLables,
  branchSystemUsers,
  setBranchSystemUsers,
  setCurrentUserIndex,
  systemCd,
  branchCode,
  branchemployees,
  setBranchemployees,
  usersAreasMode,
  setUsersAreasMode,
  setAdminUser,
  setUserPreveleges,
  setUserGroupsPreveleges,
  jobTitles,
  setJobTitles,
  userTypes,
  setUserTypes,
  systemTypemode,
  inActiveUserCount,
  notActiveUsers,
  setNotActiveUsers,
  setSelectedEmployees,
  selectedEmployees,
}) => {
  const [employeIndex, setEmployeIndex] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [credential, setCredential] = useState({});
  const [CheckOutPage, setCheckOutPage] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [updating, setUpdating] = useState([]);
  const [employExist, setEmployExist] = useState(false);
  const [employError, setEmployError] = useState(false);
  const [logInNameExist, setLogInNameExist] = useState(false);
  const [emplyeAdded, setEmployeeAdded] = useState({});
  const [checkOutLoader, setCheckOutLoder] = useState(false);

  useEffect(() => {}, []);

  function select_user(index) {
    setCurrentUserIndex(index);
    get_user_prevelegs(index);
  }

  const set_employee_mode = () => {
    setEmployExist(false);
    setUsersAreasMode("Edit employee");
    setEmployeeDetails({});
    setCredential({});
    setEmployError(false);
  };

  const set_clear_mode = () => {
    setUsersAreasMode("List system user");
    setEmployError(false);
  };

  const handleCheckboxChange = (employee) => {
    setSelectedEmployees((prevSelected) => {
      const updatedSelection = new Set(prevSelected);
      if (updatedSelection.has(employee)) {
        updatedSelection.delete(employee); // Deselect if already selected
      } else {
        updatedSelection.add(employee); // Select if not already selected
      }
      return updatedSelection;
    });
  };
  function set_icon(employee_image_name, user_image_name) {
    if (user_image_name) {
      return (
        <img
          src={"https://files.amanrow.com/user/" + user_image_name}
          alt=" "
          className="user_profile_image"
        />
      );
    } else if (employee_image_name) {
      return (
        <img src={employee_image_name} alt=" " className="user_profile_image" />
      );
    } else {
      return <img src={blankprofile} className="user_profile_image" alt="" />;
    }
  }

  const validate_employee_error = () => {
    const requiredFields = [
      "first_name",
      "middle_name",
      "last_name",
      "email_address",
      "job_cd",
      "user_type_cd",
    ];

    for (let field of requiredFields) {
      if (!employeeDetails[field]) {
        alert(`The field "${field}" is missing or empty.`);
        return true; //there is error
      }
    }
    return false; //no error
    // If all fields are present, return true or continue the process
  };

  // Read ----------------------
  const get_user_prevelegs = async (index) => {
    setAdminUser({});
    setUserPreveleges([]);
    setUserGroupsPreveleges([]);
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_user_authintication",
          system_cd: systemCd,
          prevelege_user_cd: branchSystemUsers[index].user_cd,
        },
      };

      console.log("get_user_prevelegsl input ", data);
      return;
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/auth/get_user_authintication`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_user_prevelegsl output", res);

      if (res.data.output.admin_user) {
        setAdminUser(res.data.output.admin_user);
      }
      if (res.data.output.user_preveleges) {
        setUserPreveleges(res.data.output.user_preveleges);
      }
      if (res.data.output.user_groups_preveleges) {
        setUserGroupsPreveleges(res.data.output.user_groups_preveleges);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_employees = async () => {
    setUsersAreasMode("List branch employees");
    setCurrentUserIndex(null);
    if (branchemployees && branchemployees.length > 0) {
      return;
    }
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_branch_employees",
          system_cd: systemCd,
          branch_cd: branchCode,
        },
      };

      console.log("get_employees input ", data);
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/auth/get_branch_employees`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );
      console.log("get_employees output", res);
      if (
        res.data.output.branch_employees &&
        res.data.output.branch_employees.length > 0
      ) {
        setBranchemployees(res.data.output.branch_employees);
      }
      if (res.data.output.job_titles && res.data.output.job_titles.length > 0) {
        setJobTitles(res.data.output.job_titles);
      }
      if (res.data.output.user_types && res.data.output.user_types.length > 0) {
        setUserTypes(res.data.output.user_types);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  // Update ----------------------
  const insert_employee = async (emp_code) => {
    if (validate_employee_error()) {
      setEmployError(true);
      return;
    }

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "insert_employee",
          update_branch_cd: branchCode,
          emp_cd: emp_code,
          system_cd: systemCd,
          first_name: employeeDetails.first_name,
          middle_name: employeeDetails.middle_name,
          last_name: employeeDetails.last_name,
          email: employeeDetails.email_address,
          job_cd: employeeDetails.job_cd,
          user_type_cd: employeeDetails.user_type_cd,
        },
      };

      console.log("insert_employee input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/auth/insert_employee`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("insert_employee output", res);

      if (
        res.data.output.barnch_system_users &&
        res.data.output.barnch_system_users.length > 0
      ) {
        setBranchSystemUsers(res.data.output.barnch_system_users);
      } else {
        setBranchSystemUsers([]);
      }
      if (res.data.output.emp_users && res.data.output.emp_users.length > 0) {
        setBranchemployees(res.data.output.emp_users);
      } else {
        setBranchemployees([]);
      }
      if (res.data.output.credential) {
        setCredential(res.data.output.credential);
      } else {
        setCredential({});
      }
      /* setUsersAreasMode("List branch employees")*/
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const add_user = (
    update_user_cd,
    update_emp_cd,
    update_function,
    employee_index,
    email,
  ) => {
    alert(
      "index = " +
        employee_index +
        " , user_cd = " +
        update_user_cd +
        " , emp_cd = " +
        update_emp_cd,
    );

    setEmployeIndex(employee_index);

    if (systemCd === "Lcc") {
      /*see how to use one variable to know if we need to display edit uuser type */
      setUsersAreasMode("Edit user");
      setEmployeeAdded({ update_user_cd, update_emp_cd, email });
    } else {
      alert("will will directrly for update");
      update_system_users(update_user_cd, 0, "add");
    }
  };

  const update_system_users = async (
    update_user_cd,
    user_type_cd,
    update_function,
    update_emp_cd,
    email,
  ) => {
    setUpdating([update_user_cd, ...updating]);
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_system_user",
          update_branch_cd: branchCode,
          system_cd: systemCd,
          user_type_cd: user_type_cd,
          update_user_cd: update_user_cd,
          update_emp_cd: update_emp_cd,
          update_function: update_function,
          email: email,
        },
      };
      console.log("update_system_usersinput ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/auth/update_system_user`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );
      console.log("update_system_usersoutput ", res);
      if (res.data.output.branch_users.length > 0) {
        setBranchSystemUsers(res.data.output.branch_users);
        setUsersAreasMode("List branch employees");
      }

      const newArray = updating.filter(
        (item) => item !== res.data.output.update_user_cd,
      );
      setUpdating(newArray);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const CheckOutInitiate = async () => {
    try {
      if (selectedEmployees.size <= 0) {
        throw new Error("No users selected");
      }

      setCheckOutLoder(true);
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "checkout_initiate",
          price: "1.00",
          currecncy: "KWD",
        },
      };

      console.log("checkout_initiate input");
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/auth/purchase_user_initiate`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );
      console.log(typeof res.data.result.rstatus);
      if (res.data.result.rstatus === "1") {
        setCheckOutPage(true);
        console.log(res.data.output.myftpm);
        setPaymentMethods(res.data.output.myftpm);
      } else {
        throw new Error("something went wrong on checkout initiate");
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    } finally {
      setCheckOutLoder(false);
    }
  };

  return (
    <>
      <div
        style={{
          height: "calc(100vh - 176px)",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        {/*Buttons bar*/}
        <div
          style={{
            height: "36px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {usersAreasMode !== "List system user" && (
              <>
                <Tooltip title="Back" placement="bottom" arrow>
                  <IconButton
                    onClick={() => {
                      set_clear_mode();
                      setCheckOutPage(false);
                      setEmployError(false);
                    }}
                  >
                    <ArrowBackIcon color="inherit" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {employError && (
              <div
                style={{
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  float: "left",
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {pageLables[21].description}
              </div>
            )}
          </div>
          <div>
            {selectedEmployees.size > 0 && (
              <>
                <span style={{ marginRight: "1rem" }}>
                  Total price:
                  {Array.from(selectedEmployees)
                    .map((employee) =>
                      employee.price ? employee.price + 1 : 1,
                    ) // Add 13 to price if it exists, otherwise just 13
                    .reduce((acc, price) => acc + price, 0)}
                </span>
                {usersAreasMode === "Not active useres" && !CheckOutPage && (
                  <div
                    className="p-1 btn btn-primary"
                    onClick={CheckOutInitiate}
                  >
                    CheckOut
                  </div>
                )}
              </>
            )}

            <div>
              {usersAreasMode === "List system user" && (
                <>
                  {inActiveUserCount > 0 && (
                    <Tooltip
                      title={pageLables && pageLables[2].description}
                      placement="bottom"
                      arrow
                    >
                      <IconButton
                        onClick={() => {
                          setUsersAreasMode("Not active useres");
                        }}
                      >
                        <sup
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            fontWeight: "700",
                          }}
                        >
                          {inActiveUserCount}
                        </sup>
                        <FiShoppingCart color="inherit" fontSize="18" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={pageLables && pageLables[2].description}
                    placement="bottom"
                    arrow
                  >
                    <IconButton
                      onClick={() => {
                        get_employees();
                      }}
                    >
                      <ModeOutlinedIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {usersAreasMode === "List branch employees" && (
                <>
                  <Tooltip
                    title={pageLables && pageLables[4].description}
                    placement="bottom"
                    arrow
                  >
                    <IconButton>
                      <IconButton onClick={() => set_employee_mode()}>
                        <LuUserPlus color="inherit" fontSize="18px" />
                      </IconButton>
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {usersAreasMode === "Edit employee" && (
                <>
                  <Tooltip title="add New" placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        insert_employee(null);
                      }}
                    >
                      <IconButton>
                        <SaveIcon color="inherit" fontSize="small" />
                      </IconButton>
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {usersAreasMode === "Edit user" && (
                <>
                  <Tooltip title="Save" placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        console.log(emplyeAdded);
                        update_system_users(
                          emplyeAdded.update_user_cd,
                          emplyeAdded.user_type_cd,
                          "add",
                          emplyeAdded.update_emp_cd,
                          emplyeAdded.email,
                        );
                      }}
                    >
                      <SaveIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
        {(usersAreasMode === "List system user" ||
          usersAreasMode === "List branch employees") && (
          <>
            <div style={{ marginTop: "6px" }}>
              {/*users area*/}
              {branchSystemUsers.length > 0 &&
                branchCode &&
                branchSystemUsers.map((system_users, index) => {
                  if (system_users.branch_no === branchCode)
                    return (
                      <div
                        className="user_line"
                        onClick={() => {
                          if (usersAreasMode === "List system user") {
                            select_user(index);
                          }
                        }}
                      >
                        <div
                          key={branchSystemUsers.user_cd + index}
                          className="user_line_area"
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {updating.length > 0 &&
                            updating.find(
                              (update_user_cd) =>
                                update_user_cd === system_users.user_cd,
                            ) ? (
                              <div
                                className="icon_tree_area"
                                style={{ width: "26px" }}
                              >
                                <span className="loader-spiner"></span>
                              </div>
                            ) : (
                              <div
                                className="icon_tree_area"
                                style={{ width: "26px" }}
                                onClick={() => {
                                  update_system_users(
                                    system_users.user_cd,
                                    0,
                                    "remove",
                                  );
                                }}
                              >
                                {usersAreasMode === "List branch employees" && (
                                  <TfiCheckBox size="16" />
                                )}
                              </div>
                            )}

                            <div>{set_icon(null, system_users.image_name)}</div>
                            <div
                              className="space-betw-vertical"
                              style={{ margin: "0px 10px" }}
                            >
                              <div> {system_users.full_name}</div>
                              <div>
                                {system_users.job_title}
                                {system_users.user_type && (
                                  <span style={{ color: "gray" }}>
                                    {" "}
                                    | {system_users.user_type}{" "}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                })}
            </div>
          </>
        )}
        {usersAreasMode === "List branch employees" && (
          <>
            <div style={{ marginTop: "20px" }}>
              {branchemployees.length > 0 &&
                branchemployees.map((branch_employees, index) => (
                  <div className="user_line">
                    {branchSystemUsers.find(
                      (user) => user.user_cd === branch_employees.user_cd,
                    ) ? (
                      <div></div>
                    ) : (
                      <div
                        key={branch_employees.user_cd + index}
                        className="user_line_area"
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {updating.length > 0 &&
                          updating.find(
                            (update_user_cd) =>
                              update_user_cd === branch_employees.user_cd,
                          ) ? (
                            <div
                              className="icon_tree_area"
                              style={{ width: "26px" }}
                            >
                              <span className="loader-spiner"></span>
                            </div>
                          ) : (
                            <div
                              className="icon_tree_area"
                              style={{ width: "26px" }}
                              onClick={() => {
                                add_user(
                                  branch_employees.user_cd,
                                  branch_employees.emp_cd,
                                  "insert",
                                  index,
                                  branch_employees.email,
                                );
                              }}
                            >
                              {usersAreasMode === "List branch employees" && (
                                <div
                                  className="unchecked_checkbox"
                                  style={{ width: "15px", height: "15px" }}
                                ></div>
                              )}
                            </div>
                          )}
                          <div>
                            {set_icon(
                              branch_employees.employee_image_name,
                              branch_employees.user_image_name,
                            )}
                          </div>
                          <div
                            className="space-betw-vertical"
                            style={{ margin: "0px 10px" }}
                          >
                            <div> {branch_employees.full_name}</div>
                            <div>{branch_employees.job_title}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}
        {usersAreasMode === "Edit user" && (
          <>
            <div style={{ padding: "0px 6px" }}>
              <div className="row data_line">
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[8].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={
                      employeIndex && branchemployees[employeIndex].full_name
                    }
                    onChange={(e) =>
                      setEmployeeDetails({
                        ...employeeDetails,
                        first_name: e.target.value,
                      })
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[9].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={
                      employeIndex && branchemployees[employeIndex].job_title
                    }
                  />
                </div>
                {systemTypemode && (
                  <>
                    <div className="data_line" style={{ display: "flex" }}>
                      <label className="label_area">
                        {pageLables[10].description}
                      </label>
                      <select
                        id="user_type_cd"
                        className="editing_field"
                        onChange={(e) => {
                          setEmployeeAdded({
                            ...emplyeAdded,
                            user_type_cd: e.target.value,
                          });
                        }}
                      >
                        <option value="0"></option>
                        {userTypes &&
                          userTypes.length >= 0 &&
                          userTypes.map((user_type, index) => {
                            return (
                              <option
                                key={index}
                                value={user_type.user_type_cd}
                              >
                                {user_type.description}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {usersAreasMode === "Edit employee" && (
          <>
            <div style={{ padding: "0px 6px" }}>
              <div className="title_area">
                <label>{pageLables[11].description}</label>
              </div>

              {employExist && (
                <>
                  <div style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[8].description}
                    </label>
                    <input
                      type="text"
                      className="editing_field"
                      autoComplete="0"
                      value={
                        employeIndex && branchemployees[employeIndex].full_name
                      }
                      onChange={(e) =>
                        setEmployeeDetails({
                          ...employeeDetails,
                          first_name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[9].description}
                    </label>
                    <input
                      type="text"
                      className="editing_field"
                      autoComplete="0"
                      value={
                        employeIndex && branchemployees[employeIndex].job_title
                      }
                    />
                  </div>
                </>
              )}
              {!employExist && (
                <>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[12].description}
                    </label>
                    <input
                      id="first_name"
                      type="text"
                      className="editing_field"
                      autoComplete="0"
                      value={employeeDetails && employeeDetails.first_name}
                      onChange={(e) =>
                        setEmployeeDetails({
                          ...employeeDetails,
                          first_name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[13].description}
                    </label>
                    <input
                      id="middle_name"
                      type="text"
                      className="editing_field"
                      autoComplete="0"
                      value={employeeDetails && employeeDetails.middle_name}
                      onChange={(e) =>
                        setEmployeeDetails({
                          ...employeeDetails,
                          middle_name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[14].description}
                    </label>
                    <input
                      id="last_name"
                      type="text"
                      className="editing_field"
                      autoComplete="0"
                      value={employeeDetails && employeeDetails.last_name}
                      onChange={(e) =>
                        setEmployeeDetails({
                          ...employeeDetails,
                          last_name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[16].description}
                    </label>
                    <input
                      id="email_address"
                      type="text"
                      className="editing_field"
                      style={{ outline: "none" }}
                      autoComplete="off"
                      value={employeeDetails && employeeDetails.email_address}
                      onChange={(e) =>
                        setEmployeeDetails({
                          ...employeeDetails,
                          email_address: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="row data_line">
                    <div style={{ display: "flex" }}>
                      <label className="label_area">
                        {pageLables[15].description}
                      </label>
                      <select
                        id="job_cd"
                        className="editing_field"
                        value={employeeDetails && employeeDetails.job_cd}
                        onChange={(e) =>
                          setEmployeeDetails({
                            ...employeeDetails,
                            job_cd: e.target.value,
                          })
                        }
                      >
                        <option value="0"></option>
                        {jobTitles &&
                          jobTitles.length >= 0 &&
                          jobTitles.map((job_titel, index) => {
                            return (
                              <option key={index} value={job_titel.code}>
                                {job_titel.description}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {systemTypemode && (
                    <>
                      <div className="data_line" style={{ display: "flex" }}>
                        <label className="label_area">
                          {pageLables[10].description}
                        </label>
                        <select
                          id="user_type_cd"
                          className="editing_field"
                          value={
                            employeeDetails && employeeDetails.user_type_cd
                          }
                          onChange={(e) =>
                            setEmployeeDetails({
                              ...employeeDetails,
                              user_type_cd: e.target.value,
                            })
                          }
                        >
                          <option value="0"></option>
                          {userTypes &&
                            userTypes.length >= 0 &&
                            userTypes.map((user_type, index) => {
                              return (
                                <option
                                  key={index}
                                  value={user_type.user_type_cd}
                                >
                                  {user_type.description}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </>
                  )}
                </>
              )}
              {credential && credential.login_name && (
                <>
                  <div className="title_area" style={{ marginTop: "20px" }}>
                    <label>{pageLables[17].description}</label>
                  </div>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[18].description}
                    </label>
                    <div className="viewing_field">
                      {credential && credential.login_name}
                    </div>
                  </div>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[19].description}
                    </label>
                    <div className="viewing_field">
                      {credential && credential.passowrd_id}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {usersAreasMode === "Not active useres" && (
          <>
            <div
              className="table_header_div top_border_div table_header_background"
              style={{ display: "flex" }}
            >
              <div style={{ width: "5%" }}></div>
              <div>Select</div>
              <div style={{ width: "45%" }}>{pageLables[22].description}</div>
              <div style={{ width: "50%" }}>{pageLables[23].description}</div>
              <div style={{ width: "50%" }}>User type</div>
            </div>
            <div style={{ maxHeight: "400px", overflow: "auto" }}>
              {notActiveUsers.length > 0 &&
                notActiveUsers.map((not_active_users, index) => (
                  <div className="row_area" key={not_active_users.user_cd}>
                    <div className="table_line_div bottom_border_div">
                      <div style={{ width: "5%" }}></div>
                      <input
                        type="checkbox"
                        id={`checkbox-${not_active_users.user_cd}`}
                        style={{ marginRight: "10px" }} // Space between checkbox and text
                        checked={selectedEmployees.has(not_active_users)} // Control checkbox state// Control checkbox state
                        onChange={() => {
                          let not_active_usersalterd = {
                            ...not_active_users,
                            price: 13,
                          };
                          handleCheckboxChange(not_active_users);
                        }} // Handle change
                      />
                      <div style={{ width: "45%" }}>
                        {not_active_users.full_name}
                      </div>
                      <div style={{ width: "50%" }}>
                        {not_active_users.branch_name}
                      </div>
                      <div style={{ width: "50%" }}>
                        {not_active_users.nonactive_type}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="m-1"
            >
              {/* <div
                className="p-1 btn btn-primary"
                onClick={() => {
                  setCheckOutPage(true);
                }}
              >
                Check Out
              </div> */}
            </div>
            {checkOutLoader && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="loader-spiner"></span>
              </div>
            )}

            {CheckOutPage && paymentMethods.length > 0 && (
              <div className="mt-4">
                <div
                  className="ml-5 cursor-pointer"
                  onClick={() => setCheckOutPage(false)}
                >
                  <ArrowBackIcon />
                </div>
                <CheckOutFatoora
                  paymentMethods={paymentMethods}
                  selectedEmployees={selectedEmployees}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Users;
