import React, { useEffect } from "react";
import { language_return } from "../../JSfiles/inventory/ItemLabelsLoad";
import { BsThreeDotsVertical } from "react-icons/bs";

const PurchaseOrder = () => {
  useEffect(() => {
    // {document.getElementById("page_name_area") = "Purchase Order"}
    language_return();
  }, []);

  return (
    <>
      <div>
        <div className="page-inside-container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              {/* //---------------- detail area */}
              <div className="py-3">
                <div id="item_header">
                  <div
                    id="item_code"
                    style={{ float: "left", fontSize: "20px" }}
                  >
                    <div id="purchase_order" style={{ float: "left" }}>
                      Draft
                    </div>
                    <div
                      id="purchase_order_status"
                      style={{
                        float: "left",
                        color: "red",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      ok
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                  <div id="item_buttons" className="buttons_div">
                    <img
                      id="item_close_btn"
                      src="../images/close_icon.png"
                      className="header_menu_icon"
                      style={{
                        height: "20px",
                        marginTop: "8px",
                        float: "right",
                      }}
                      onclick="display_daily_entry('initial')"
                    />
                    <img
                      id="item_menu_btn"
                      src="../images/menu.png"
                      className="header_menu_icon"
                      style={{
                        height: "16px",
                        marginTop: "8px",
                        float: "right",
                      }}
                      onclick="show_top_menu('voucher_header')"
                    />
                    {/* <img id="item_search_btn" src="../images/standard/search.png" className="header_menu_icon" style={{height:"20px", marginTop:"8px", float:"right"}} onclick="display_daily_entry('search')" />
                <img id="itemy_add_btn" src="../images/standard/add.png" className="header_menu_icon " style={{height:"20px", marginTop:"8px", float:"right"}} onclick="display_daily_entry('add')" /> */}
                    <div
                      id="item_search_btn"
                      className="underlined_Buton header_button_float"
                      onclick="clear_invoice()"
                    >
                      Search
                    </div>
                    <div
                      id="itemy_add_btn"
                      className="underlined_Buton header_button_float"
                      onclick="clear_invoice()"
                    >
                      New Item
                    </div>
                    <div
                      id="itemy_clear_btn"
                      className="underlined_Buton header_button_float"
                      onclick="clear_invoice()"
                    >
                      Clear
                    </div>
                  </div>

                  <div className="hedden">
                    <div id="item_add_auth"></div>
                    <div id="item_edit_auth"></div>
                    <div id="item_log_auth"></div>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>

                <div
                  id="case_details_edit"
                  className="panel-body  mostly-customized-scrollbar"
                >
                  <table className="table-verticle-middle table-frm table-frm-ed">
                    <tbody>
                      <tr>
                        <th style={{ width: "25%" }} />
                        <th style={{ width: "20%" }} />
                        <th style={{ width: "20%" }} />
                        <th style={{ width: "35%" }} />
                      </tr>
                      <tr>
                        <th id="purchase_order_date_l">date</th>
                        <td colSpan={2}>
                          <div className="input-icon right">
                            <i
                              id="purchase_order_date_btn"
                              className="fa fa-calendar"
                            />
                            <input
                              id="purchase_order_date"
                              type="text"
                              className="form-control input-sm mcalender"
                              onchange="case_changed('case_date', 'case_level_cd', 'date')"
                              placeholder="DD-MM-YYYY"
                            />
                          </div>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <th id="purchase_order_type_cd_l">Order Type </th>
                        <td colSpan={3}>
                          <select
                            id="purchase_order_type_cd"
                            className="form-control input-sm "
                            onchange="case_changed('lawsuit_type_cd', 'case_subject', 'droplist')"
                          >
                            <option />
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th id="item_type_cd_l">item Type </th>
                        <td colSpan={3}>
                          <select
                            id="item_type_cd"
                            className="form-control input-sm "
                            onchange="case_changed('lawsuit_type_cd', 'case_subject', 'droplist')"
                          >
                            <option />
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th id="purchase_order_desc_l">Desccription</th>
                        <td colSpan={3}>
                          <input
                            id="purchase_order_desc"
                            type="text"
                            className="form-control input-sm "
                            onchange="case_changed('unique_number', 'case_type_cd', 'string')"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              {/* Bootstrap CSS */}
              {/* jQuery first, then Bootstrap JS. */}
              {/* Nav tabs */}
              <ul
                className="nav nav-tabs tabdrop-section tabs"
                role="tablist"
                id="nav-main-section"
              >
                <li
                  style={{ position: "absolute", right: "0px" }}
                  className="dropdown pull-right tabdrop show"
                  id="drop_down"
                >
                  <span
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    href="#"
                    aria-expanded="false"
                  >
                    <span className="display-tab">
                      <BsThreeDotsVertical />
                    </span>
                    <b className="caret"></b>
                  </span>
                  <ul
                    className="dropdown-menu"
                    id="drop-down-menu-con"
                    style={{}}
                  ></ul>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a
                    className="nav-link active"
                    id="spicifications-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#spicifications"
                    role="tab"
                    aria-controls="spicifications"
                    aria-selected="true"
                  >
                    Spicifications
                  </a>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a
                    className="nav-link"
                    id="item_in_stores-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#item_in_stores"
                    role="tab"
                    aria-controls="item_in_stores"
                    aria-selected="true"
                  >
                    Item In Stores
                  </a>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a
                    className="nav-link"
                    id="descount-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#descount"
                    role="tab"
                    aria-controls="descount"
                    aria-selected="true"
                  >
                    Descount
                  </a>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a
                    className="nav-link"
                    id="photo-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#photo"
                    role="tab"
                    aria-controls="photo"
                    aria-selected="true"
                  >
                    Photo
                  </a>
                </li>
              </ul>
              {/* -------------Tab panes --------------------------------------------*/}
              <div className="tab-content" id="myTabContent">
                <div
                  id="spicifications"
                  className="tab-pane fade show active"
                  role="tabpanel"
                  aria-labelledby="spicifications-tab"
                >
                  Spicifications
                </div>
                <div
                  id="item_in_stores"
                  className="tab-pane fade"
                  role="tabpanel"
                  aria-labelledby="item_in_stores-tab"
                >
                  <div style={{ pading: "10px", marginTop: "15px" }}>
                    <table className="table table-hover table-verticle-middle table-first-td">
                      <tr>
                        <th style={{ width: "35%" }}>Store Name</th>
                        <th style={{ width: "13%" }}>Arrived</th>
                        <th style={{ width: "12%" }}>Shelf</th>
                        <th style={{ width: "13%" }}>Expire</th>
                        <th style={{ width: "12%" }}>qty</th>
                        <th style={{ width: "15%" }}>Same Unit ?</th>
                      </tr>
                    </table>
                  </div>
                </div>
                <div
                  id="descount"
                  className="tab-pane fade"
                  role="tabpanel"
                  aria-labelledby="descount-tab"
                >
                  Descount
                </div>

                <div
                  id="photo"
                  className="tab-pane fade"
                  role="tabpanel"
                  aria-labelledby="photo-tab"
                >
                  Photo
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrder;
