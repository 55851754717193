import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux"; //import useSelector for redux

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//import EmptyUserIcon from '/images/blank_employee.jpg';

import SaveIcon from "@mui/icons-material/Save";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";

import blankprofile from "../../Images/blankprofile.png";
import { RiMoreLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";

import CaseProgress from "./CaseProgress";
import CaseAttachments from "./CaseAttachments";
import CaseProcedures from "./CaseProcedures";
import CaseRemarks from "./CaseRemarks";
import CaseChatting from "./CaseChatting";

import PrintCaseDetails from "./PrintCaseDetails";
import CaseFinance from "./CaseFinance";
import CaseClientsSideBar from "../sidebar/CaseClientSidebar";
import CaseOpenentSidebar from "../sidebar/CaseOpenentSidebar";
import CaseAcessBySideBar from "../sidebar/CaseAcessBySideBar";
import CommonSidebar from "../sidebar/CommonSidebar";

import Modal from "../../utils/Modal";
import ImageCrop from "../../utils/ImageCrop";
import { dataURLtoBlob } from "../../utils/sharedfunction";

const CaseDetails = () => {
  const { user } = useSelector((state) => state); //define redux

  const [accessBy, setAccessBy] = useState([]);
  const [officeAttornyTeam, setOfficeAttornyTeam] = useState([]);
  const [deviceMedia, setDeviceMedia] = useState();
  const [displayDetail, setDisplayDetail] = useState(true);
  const [tapActive, setTapActive] = useState("Details");
  const [caseMode, setCaseMode] = useState("initial");
  const [caseDetails, setCaseDetails] = useState();
  const [draftCaseLevels, setDraftCaseLevels] = useState([]);
  const [caseLevels, setCaseLevels] = useState([]);
  const [caseClients, setCaseClients] = useState([]);
  const [caseOpponent, setCaseOpponent] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [courtLevels, setCourtLevels] = useState([]);
  const [courtHearings, setCourtHearings] = useState([]);
  const [clientAs, setClientAs] = useState([]);
  const [courtsNames, setCourtsNames] = useState([]);
  const [states, setStates] = useState([]);
  const [judgements, setJudgements] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [pageLables, setPageLables] = useState();
  const [showClient, setShowClient] = useState(false);
  const [showCaseOpponent, setShowCaseOpponent] = useState(false);
  const [showCaseAccessBy, setShowCaseAccessBy] = useState(false);
  const [showCommonSidebar, setShowSCommonsideBar] = useState(false);
  const [commonSidebarComponenet, setCommonSidebarComponenet] = useState(false);

  const [printComponent, setPrintComponent] = useState(false);

  const [showCrop, setShowCrop] = useState(false);

  const [uploadprecentage, setUploadPercentage] = useState(0);
  const filePickerRef = useRef(null);
  const profileimgRef = useRef(null);

  var inActiveTap = "tap_head_area";
  var activeTap = "tap_head_area active_tap_head_area";
  var inActiveTapOposit = "opposite_float opposite_text_align tap_head_area";
  var activeTapOposit =
    "opposite_float opposite_text_align tap_head_area active_tap_head_area";

  useEffect(() => {
    checkDevice();
  }, []);

  useEffect(() => {
    getCaseInitial();
  }, []);

  function checkDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setTapActive("MobileDetails");
      setDeviceMedia("Mobile");
    } else {
      setTapActive("Details");
      setDeviceMedia("Desktop");
    }
  }

  function clear_data() {
    setCaseDetails({});
    setDraftCaseLevels({});
    setCaseLevels([]);
    setCaseClients([]);
    setCaseOpponent([]);
    setAccessBy([]);
    setCourtHearings([]);
  }

  const set_tab_active = (active_tab) => {
    setTapActive(active_tab);
    if (deviceMedia === "Mobile" && active_tab !== "MobileDetails") {
      setDisplayDetail(false);
    } else {
      setDisplayDetail(true);
    }
  };

  const set_clear_mode = () => {
    switch (caseMode) {
      case "Edit":
        setCaseMode("view");
        break;
      case "view":
      case "Draft":
        clear_data();
        setCaseMode("initial");
        setShowSCommonsideBar(false);
        break;
      default:
      // code block
    }
  };

  const set_draft_case = () => {
    setCaseDetails({});
    setDraftCaseLevels({});
    setCaseMode("Draft");
  };

  const case_search_onblure = () => {
    var code_str = document.getElementById("case_cd_s").value.trim();
    if (code_str.length > 0) {
      getCaseDetails("case_cd_s", code_str);
      //} else {
      //    getCaseDetails("phone_no",document.getElementById("phone_no_s").value.trim(),
      //    );
    }
  };

  const case_search_keyEvent = (e) => {
    if (e.charCode === 13) {
      var code_str = document.getElementById("case_cd_s").value.trim();
      if (code_str.length > 0) {
        getCaseDetails("case_cd_s", code_str);
        //} else {
        //    getCaseDetails("phone_no",document.getElementById("phone_no_s").value.trim(),
        //    );
      }
    }
  };

  // Validation ----------------------

  const validate_case = () => {
    //handel auoto no if not entered when entering first hearing session
    if (!caseDetails) {
      alert("case type requerd ");
      return false;
    }
    if (!caseDetails.case_type_cd) {
      alert("case type requerd ");
      return false;
    }
    if (!draftCaseLevels) {
      alert("empty case no.requerd ");
      return false;
    }
    if (draftCaseLevels && !draftCaseLevels.case_no) {
      alert("case no. requerd ");
      return false;
    }
    if (draftCaseLevels && !draftCaseLevels.level_date) {
      alert("case date requerd ");
      return false;
    }
    if (draftCaseLevels && !draftCaseLevels.level_cd) {
      alert("level_cd requerd ");
      return false;
    }
    if (draftCaseLevels && !draftCaseLevels.client_as) {
      alert("client_as requerd ");
      return false;
    }
    if (!caseDetails.case_subject) {
      alert("case case_subject requerd ");
      return false;
    }
    return true;
  };

  // Read ----------------------
  const getCaseInitial = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      console.log(userDataLocal.token);
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_case_initial",
          system_cd: "Lcc",
          function_cd: "20",
          system_rout_cd: "19",
          branch_cd: user.userData.branch_cd, //from redux
          //branh_cd: userDataLocal.branvh_cd
        },
      };

      console.log("case initials  input", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/get_case_initial`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("case initials  output", res);
      setPageLables(res.data.output.pages_lables);
      if (res.data.output.case_type) {
        setCaseTypes(res.data.output.case_type);
      }
      if (res.data.output.court_levels) {
        setCourtLevels(res.data.output.court_levels);
      }
      if (res.data.output.client_as) {
        setClientAs(res.data.output.client_as);
      }
      if (res.data.output.case_status) {
        setCaseStatus(res.data.output.case_status);
      }
      if (res.data.output.court_names) {
        setCourtsNames(res.data.output.court_names);
      }
      if (res.data.output.judgments) {
        setJudgements(res.data.output.judgments);
      }
      if (res.data.output.state_names) {
        setStates(res.data.output.state_names);
      }
      if (res.data.output.office_attorny_team) {
        setOfficeAttornyTeam(res.data.output.office_attorny_team);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const getCaseDetails = async (field_name, field_value) => {
    clear_data();

    var case_code = null;
    var unique_number = null;
    if (field_name === "case_cd_s") {
      case_code = field_value;
    } else {
      unique_number = field_value;
    }

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_case_details",
          case_cd: case_code,
          unique_number: unique_number,
          case_no: null,
        },
      };
      console.log("getCaseDetails input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/get_case_details`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getCaseDetails output ", res);
      if (res.data.output.case_details.case_cd) {
        setCaseDetails(res.data.output.case_details);
        if (res.data.output.case_levels) {
          setCaseLevels(res.data.output.case_levels);
          setDraftCaseLevels(res.data.output.case_levels[0]);
        }
        if (res.data.output.case_client) {
          setCaseClients(res.data.output.case_client);
        }
        if (res.data.output.case_oponent) {
          setCaseOpponent(res.data.output.case_oponent);
        }
        if (res.data.output.case_access_by) {
          setAccessBy(res.data.output.case_access_by);
        }
        if (res.data.output.case_progress) {
          setCourtHearings(res.data.output.case_progress);
        }

        setCaseMode("view");
      } else {
        alert("no case exist");
        /*also we shoul check if case is exist but he dos not hase previliges*/
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  // Update ----------------------
  const updatCaseDetails = async () => {
    if (!validate_case()) {
      return;
    }
    var case_code = caseDetails.case_cd;
    if (!case_code) {
      case_code = 0;
    }
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_case",
          branch_no: "2" /*add branch_cd to local storage and assign here*/,
          case_cd: case_code,
          unique_number: caseDetails.unique_number,
          case_type_cd: caseDetails.case_type_cd,
          case_no: draftCaseLevels.case_no,
          case_date: draftCaseLevels.level_date,
          level_cd: draftCaseLevels.level_cd,
          client_as: draftCaseLevels.client_as,
          case_subject: caseDetails.case_subject,
          other_details: caseDetails.other_details,
          status_cd: "0",
        },
      };

      console.log("updatCaseDetails input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/update_case`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("updatCaseDetails output ", res);
      if (res.data.output.case_details.case_cd) {
        setCaseDetails(res.data.output.case_details);
        if (res.data.output.case_levels) {
          setCaseLevels(res.data.output.case_levels);
        }
        setCaseMode("view");
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const updat_case_assigned_to = async (e) => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_case_assigned_to",
          update_case_cd: caseDetails.case_cd,
          case_assigned_to_user_cd: e.target.value,
          update_function: "assigned to",
          updating_status_flag: "user_cd",
        },
      };

      console.log("updat_case_assigned_to input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_case_assigned_to`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log(
        "updat_case_assigned_to output ",
        res.data.output.returned_values.case_assigned_to_user_cd,
      );
      if (res.data.output?.returned_values?.case_assigned_to_user_cd) {
        setCaseDetails({
          ...caseDetails,
          case_assigned_to: e.target.value,
        });
      }
      //if (res.data.output.case_details.case_cd) {
      //    setCaseDetails(res.data.output.case_details)
      //    if (res.data.output.case_levels) { setCaseLevels(res.data.output.case_levels) }
      //    setCaseMode("view")
      //}
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  // upload ----------------------
  const filePickedHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      let fileExttype = event.target.files[0].type.split("/")[0];
      let filExt = event.target.files[0].type.split("/")[1];
      console.log(fileExttype, filExt, event.target.files[0].type);
      if (filExt === "pdf") {
        pickedFile = event.target.files[0];
        const reader = new FileReader();
        uploadFile(pickedFile);
      } else {
        alert("upload only pdf files");
        return;
      }
    }
  };

  const uploadFile = (pickedFile) => {
    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    let formData = new FormData();
    formData.append("file", pickedFile);
    formData.append("fname", caseDetails.emp_id);
    formData.append("company", userDataLocal.company_cd);
    formData.append("flocation", "agrement");

    const options = {
      headers: { Authorization: `Bearer ${userDataLocal.token}` },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iipsys/v1/uploadFiles`,
        formData,
        options,
      )
      .then((res) => {
        if (res.data.result.rstatus === "1") {
          alert(res.data.file_url);
        }
        setUploadPercentage(0);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionWay");
            window.location.replace("/");
          }
        }

        setUploadPercentage(0);
      });
  };

  const closeProfileHandler = () => setShowCrop(false);

  const imagefileInputHandler = async (file) => {
    profileimgRef.current.src = file;
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_emp_image",
          file_name: userDataLocal.company_cd + "-" + caseDetails.emp_cd,
          file_location: "employee_profile",
          file_old_name: "",
          emp_cd: caseDetails.emp_cd,
          img: file,
        },
      };

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iipsys/v1/update_emp_image`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );
      profileimgRef.current.src = file;
      console.log("employee details output ", res);
    } catch (err) {
      alert(err.message);
      profileimgRef.current.src = "";
    }

    //var imgeFile = dataURLtoBlob(file);npm start
    //var path = `${url}/api/users/updateProfileImage`;
    //var type = "image";
    //uploadProfileHandler(path, imgeFile, file);
  };

  return (
    <>
      <Modal
        show={showCrop}
        onCancel={closeProfileHandler}
        headerClass="text-center"
        containerStyle={{ width: "500px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeProfileHandler}
            onSave={imagefileInputHandler}
            aspectRatio={1 / 1}
            profile={true}
            cropSize={{ width: 300, height: 300 }}
            objectFit="vertical-cover"
          />
        </div>
      </Modal>

      {showCommonSidebar && (
        <CommonSidebar
          setShowSideBar={setShowSCommonsideBar}
          showCommonSidebar={showCommonSidebar}
          commonSidebarComponenet={commonSidebarComponenet}
          caseOpponent={caseOpponent}
          accessBy={accessBy}
        />
      )}
      {/* {showClient && (
        <CaseClientsSideBar
            setShowSideBar={setShowClient}
            case_cd={caseDetails.case_cd}
            caseClients={caseClients}
            setCaseClients={setCaseClients}
        />
        )}
        {showCaseOpponent && (
        <CaseOpenentSidebar
            setShowSideBar={setShowCaseOpponent}
            case_cd={caseDetails.case_cd}
            caseOpponent={caseOpponent}
            setCaseOpponent={setCaseOpponent}
            pageLables={pageLables}
        />
        )}
        {showCaseAccessBy && (
        <CaseAcessBySideBar
            setShowSideBar={setShowCaseAccessBy}
            case_cd={caseDetails.case_cd}
            accessBy={accessBy}
            setAccessBy={setAccessBy}
            officeAttornyTeam={officeAttornyTeam}
        />
        )} */}
      <div className="container">
        {pageLables && (
          <>
            <div className="tap_menu">
              <div
                className={`${
                  tapActive === "MobileDetails" ? activeTap : inActiveTap
                } header-tab-deatils-mobile`}
                onClick={() => {
                  set_tab_active("MobileDetails");
                }}
              >
                {pageLables[0].description}
              </div>
              <div
                className={`${
                  tapActive === "Details" ? activeTap : inActiveTap
                } header-tab-deatils-desktop`}
                onClick={() => {
                  set_tab_active("Details");
                }}
              >
                {pageLables[0].description}
              </div>
              <div
                className={tapActive === "Progress" ? activeTap : inActiveTap}
                onClick={() => {
                  set_tab_active("Progress");
                }}
              >
                {pageLables[1].description}
              </div>
              <div
                className={
                  tapActive === "Attachments" ? activeTap : inActiveTap
                }
                onClick={() => {
                  set_tab_active("Attachments");
                }}
              >
                {pageLables[28].description}
              </div>
              <div
                className={tapActive === "Remarks" ? activeTap : inActiveTap}
                onClick={() => {
                  set_tab_active("Remarks");
                }}
              >
                {pageLables[3].description}
              </div>
              <div
                className={tapActive === "Actions" ? activeTap : inActiveTap}
                onClick={() => {
                  set_tab_active("Actions");
                }}
              >
                {pageLables[4].description}
              </div>
              <div
                className={tapActive === "Finance" ? activeTap : inActiveTap}
                onClick={() => {
                  set_tab_active("Finance");
                }}
              >
                {pageLables[5].description}
              </div>
              <div
                className={
                  tapActive === "conversation" ? activeTap : inActiveTap
                }
                onClick={() => {
                  set_tab_active("conversation");
                }}
              >
                {pageLables[6].description}
              </div>
            </div>
            <div className="row" style={{ padding: "0px" }}>
              {displayDetail && (
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 border-right"
                  style={{ padding: "0px" }}
                >
                  <div
                    style={{
                      height: "calc(100vh - 140px)",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <div>
                      {/*Button bar ---------------------*/}
                      <div>
                        <div className="normal_float normal_text_align ">
                          {caseMode !== "initial" && (
                            <>
                              <Tooltip title="Back" placement="bottom" arrow>
                                <IconButton onClick={set_clear_mode}>
                                  <ArrowBackIcon
                                    color="inherit"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                              {caseMode !== "Draft" && (
                                <>
                                  <span className="separator_border">
                                    {caseDetails && caseDetails.case_cd}
                                    {" - " +
                                      caseStatus.find(
                                        (el) =>
                                          el.code ===
                                          caseLevels[0].case_status_cd,
                                      ).description}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div className="opposite_float opposite_text_align">
                          {(caseMode === "Edit" || caseMode === "Draft") && (
                            <>
                              <Tooltip title="Save" placement="bottom" arrow>
                                <IconButton onClick={updatCaseDetails}>
                                  <SaveIcon color="inherit" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                          {caseMode === "initial" && (
                            <>
                              <Tooltip title="add New" placement="bottom" arrow>
                                <IconButton
                                  onClick={() => {
                                    set_draft_case();
                                  }}
                                >
                                  <InsertDriveFileOutlinedIcon
                                    color="inherit"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Search" placement="bottom" arrow>
                                <IconButton>
                                  <SearchOutlinedIcon
                                    color="inherit"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                          {caseMode === "view" && (
                            <>
                              <Tooltip title="Edit" placement="bottom" arrow>
                                <IconButton
                                  onClick={() => {
                                    setCaseMode("Edit");
                                  }}
                                >
                                  <ModeOutlinedIcon
                                    color="inherit"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Print" placement="bottom" arrow>
                                <IconButton
                                  onClick={() => {
                                    setPrintComponent(true);
                                  }}
                                >
                                  <AiFillPrinter
                                    color="inherit"
                                    fontSize="19px"
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                      {/*case body ---------------------*/}
                      <div style={{ padding: "0px 6px" }}>
                        {caseMode === "initial" && (
                          <>
                            <div
                              className="data_line"
                              style={{ display: "flex" }}
                            >
                              <label className="label_area">
                                {" "}
                                {pageLables[7].description}{" "}
                              </label>
                              <input
                                id="case_cd_s"
                                type="text"
                                className="editing_field"
                                autoComplete="0"
                                onKeyPress={case_search_keyEvent}
                                onBlur={case_search_onblure}
                              />
                            </div>
                            <div
                              className="data_line"
                              style={{ display: "flex" }}
                            >
                              <label className="label_area">
                                {pageLables[8].description}
                              </label>
                              <input
                                id="case_no_s"
                                type="text"
                                className="editing_field"
                                autoComplete="0"
                                onKeyPress={case_search_keyEvent}
                              />
                            </div>
                            <div
                              className="data_line"
                              style={{ display: "flex" }}
                            >
                              <label className="label_area">
                                {pageLables[9].description}
                              </label>
                              <input
                                id="court_auto_no_s"
                                type="text"
                                className="editing_field"
                                autoComplete="0"
                                onKeyPress={case_search_keyEvent}
                              />
                            </div>
                          </>
                        )}
                        {caseMode === "view" && caseDetails && (
                          <>
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[9].description}
                                </label>
                                <div className="viewing_field">
                                  {caseDetails && caseDetails.unique_number}
                                </div>
                              </div>
                            </div>
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[10].description}
                                </label>
                                <div className="viewing_field">
                                  {
                                    caseTypes.find(
                                      (el) =>
                                        el.code === caseDetails.case_type_cd,
                                    ).description
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[8].description}
                                </label>
                                <div className="viewing_field">
                                  {caseLevels.length > 0 &&
                                    caseLevels[0].case_no}
                                </div>
                              </div>
                            </div>
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[11].description}
                                </label>
                                <div className="viewing_field">
                                  {caseLevels.length > 0 &&
                                    caseLevels[0].level_date}
                                </div>
                              </div>
                            </div>
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[12].description}
                                </label>
                                <div className="viewing_field">
                                  {
                                    courtLevels.find(
                                      (el) =>
                                        el.code === caseLevels[0].level_cd,
                                    ).description
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[13].description}
                                </label>
                                <div className="viewing_field">
                                  {
                                    clientAs.find(
                                      (el) =>
                                        el.code === caseLevels[0].client_as,
                                    ).description
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[14].description}
                                </label>
                                <div className="viewing_field">
                                  {caseDetails && caseDetails.case_subject}
                                </div>
                              </div>
                            </div>
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[19].description}
                                </label>
                                <div className="viewing_field">
                                  {caseDetails && caseDetails.other_details}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {(caseMode === "Edit" || caseMode === "Draft") && (
                          <>
                            {courtHearings.length > 0 ? (
                              <>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[9].description}
                                    </label>
                                    <div className="viewing_field">
                                      {caseDetails && caseDetails.unique_number}
                                    </div>
                                  </div>
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[10].description}
                                    </label>
                                    <div className="viewing_field">
                                      {
                                        caseTypes.find(
                                          (el) =>
                                            el.code ===
                                            caseDetails.case_type_cd,
                                        ).description
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[8].description}
                                    </label>
                                    <div className="viewing_field">
                                      {caseLevels.length > 0 &&
                                        caseLevels[0].case_no}
                                    </div>
                                  </div>
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[11].description}
                                    </label>
                                    <div className="viewing_field">
                                      {caseLevels.length > 0 &&
                                        caseLevels[0].level_date}
                                    </div>
                                  </div>
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[12].description}
                                    </label>
                                    <div className="viewing_field">
                                      {
                                        courtLevels.find(
                                          (el) =>
                                            el.code === caseLevels[0].level_cd,
                                        ).description
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[13].description}
                                    </label>
                                    <div className="viewing_field">
                                      {
                                        clientAs.find(
                                          (el) =>
                                            el.code === caseLevels[0].client_as,
                                        ).description
                                      }
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[9].description}
                                    </label>
                                    <input
                                      type="text"
                                      className="editing_field"
                                      autoComplete="0"
                                      value={
                                        caseDetails && caseDetails.unique_number
                                      }
                                      onChange={(e) =>
                                        setCaseDetails({
                                          ...caseDetails,
                                          unique_number: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div
                                  className="data_line"
                                  style={{ display: "flex" }}
                                >
                                  <label className="label_area">
                                    {pageLables[10].description}
                                  </label>
                                  <select
                                    className="editing_field"
                                    style={{ outline: "0" }}
                                    value={
                                      caseDetails && caseDetails.case_type_cd
                                    }
                                    onChange={(e) =>
                                      setCaseDetails({
                                        ...caseDetails,
                                        case_type_cd: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="0"></option>
                                    {caseTypes.length >= 0 &&
                                      caseTypes.map((case_type, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={case_type.code}
                                          >
                                            {case_type.description}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div
                                  className="data_line"
                                  style={{ display: "flex" }}
                                >
                                  <label className="label_area">
                                    {pageLables[8].description}
                                  </label>
                                  <input
                                    id="case_no"
                                    type="text"
                                    className="editing_field"
                                    autoComplete="0"
                                    value={
                                      draftCaseLevels && draftCaseLevels.case_no
                                    }
                                    onChange={(e) =>
                                      setDraftCaseLevels({
                                        ...draftCaseLevels,
                                        case_no: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[11].description}
                                    </label>
                                    <input
                                      id="level_date"
                                      type="text"
                                      className="editing_field"
                                      autoComplete="0"
                                      value={
                                        draftCaseLevels &&
                                        draftCaseLevels.level_date
                                      }
                                      onChange={(e) =>
                                        setDraftCaseLevels({
                                          ...draftCaseLevels,
                                          level_date: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[12].description}
                                    </label>
                                    <select
                                      id="level_cd"
                                      className="editing_field"
                                      value={
                                        draftCaseLevels &&
                                        draftCaseLevels.level_cd
                                      }
                                      onChange={(e) =>
                                        setDraftCaseLevels({
                                          ...draftCaseLevels,
                                          level_cd: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0"></option>
                                      {courtLevels.length >= 0 &&
                                        courtLevels.map((level, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={level.code}
                                            >
                                              {level.description}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[13].description}
                                    </label>
                                    <select
                                      id="client_as"
                                      className="editing_field"
                                      value={
                                        draftCaseLevels &&
                                        draftCaseLevels.client_as
                                      }
                                      onChange={(e) =>
                                        setDraftCaseLevels({
                                          ...draftCaseLevels,
                                          client_as: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="0"></option>
                                      {clientAs.length >= 0 &&
                                        clientAs.map((client_as, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={client_as.code}
                                            >
                                              {client_as.description}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[14].description}
                                </label>
                                <input
                                  id="case_subject"
                                  type="text"
                                  className="editing_field"
                                  autoComplete="0"
                                  value={
                                    caseDetails && caseDetails.case_subject
                                  }
                                  onChange={(e) =>
                                    setCaseDetails({
                                      ...caseDetails,
                                      case_subject: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="row data_line">
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style={{ display: "flex" }}
                              >
                                <label className="label_area">
                                  {pageLables[19].description}
                                </label>
                                <input
                                  type="text"
                                  className="editing_field"
                                  autoComplete="0"
                                  value={
                                    caseDetails && caseDetails.other_details
                                  }
                                  onChange={(e) =>
                                    setCaseDetails({
                                      ...caseDetails,
                                      other_details: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {/*clints and attornies part*/}
                        {caseDetails &&
                          (caseMode === "view" || caseMode === "Edit") && (
                            <>
                              <div style={{ marginTop: "10px" }}>
                                <div className="row">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                    onClick={() => {
                                      setCommonSidebarComponenet({
                                        componentName: "CaseClient",
                                        case_cd: caseDetails.case_cd,
                                        caseClients: caseClients,
                                        setCaseClients: setCaseClients,
                                      });
                                      setShowSCommonsideBar(true);
                                    }}
                                  >
                                    <label className="label_area">
                                      {pageLables[15].description}
                                    </label>
                                    <div
                                      className="viewing_field"
                                      style={{ border: "0" }}
                                    >
                                      {caseClients.length > 0 &&
                                        caseClients.map((client, index) => {
                                          if (caseClients.length === 1) {
                                            return (
                                              <div key={client.id_no}>
                                                {client.client_name}
                                              </div>
                                            );
                                          } else {
                                            if (
                                              index ===
                                              caseClients.length - 1
                                            ) {
                                              return (
                                                <span key={client.id_no}>
                                                  {client.client_name}
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <span key={client.id_no}>
                                                  {client.client_name},{" "}
                                                </span>
                                              );
                                            }
                                          }
                                        })}
                                    </div>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                    onClick={() => {
                                      setCommonSidebarComponenet({
                                        componentName: "CaseOpenent",
                                        case_cd: caseDetails.case_cd,
                                        caseOpponent: caseOpponent,
                                        setCaseOpponent: setCaseOpponent,
                                        pageLables: pageLables,
                                      });
                                      setShowSCommonsideBar(true);
                                    }}
                                  >
                                    <label className="label_area">
                                      {pageLables[16].description}
                                    </label>
                                    <div
                                      className="viewing_field"
                                      style={{ border: "0" }}
                                    >
                                      {caseOpponent.length > 0 &&
                                        caseOpponent.map((opponent, index) => {
                                          if (caseOpponent.length === 1) {
                                            return (
                                              <div key={opponent.id_no}>
                                                {opponent.opponent_name}
                                              </div>
                                            );
                                          } else {
                                            if (
                                              index ===
                                              caseOpponent.length - 1
                                            ) {
                                              return (
                                                <span key={opponent.id_no}>
                                                  {opponent.opponent_name}
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <span key={opponent.id_no}>
                                                  {opponent.opponent_name},{" "}
                                                </span>
                                              );
                                            }
                                          }
                                        })}
                                    </div>
                                  </div>
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                  >
                                    <label className="label_area">
                                      {pageLables[17].description}
                                    </label>
                                    {caseDetails.case_assigned_to && (
                                      <>
                                        <div
                                          className="viewing_field"
                                          style={{
                                            display: "flex",
                                            border: "0",
                                          }}
                                        >
                                          {/*<div className="viewing_field">{caseDetails && caseDetails.unique_number}</div>*/}
                                          <div
                                            style={{
                                              lineHeight: "30x",
                                              margin: "0px 2px",
                                            }}
                                          >
                                            <select
                                              defaultValue={
                                                caseDetails.case_assigned_to
                                              }
                                              onChange={updat_case_assigned_to}
                                            >
                                              {officeAttornyTeam.length > 0 &&
                                                officeAttornyTeam.map(
                                                  (officeAttorny) => {
                                                    return (
                                                      <option
                                                        value={
                                                          officeAttorny.user_cd
                                                        }
                                                      >
                                                        {
                                                          officeAttorny.full_name
                                                        }
                                                      </option>
                                                    );
                                                  },
                                                )}
                                            </select>
                                            {/* {officeAttornyTeam.find(
                                              (el) =>
                                                el.user_cd ===
                                                caseDetails.case_assigned_to,
                                            ).image_name ? (
                                              <img
                                                src={`https://files.amanrow.com/user/${
                                                  officeAttornyTeam.find(
                                                    (el) =>
                                                      el.user_cd ===
                                                      caseDetails.case_assigned_to,
                                                  ).image_name
                                                }`}
                                                className="profile_image"
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src={blankprofile}
                                                className="profile_image"
                                                alt=""
                                              />
                                            )} */}
                                          </div>
                                          {/* <div
                                            style={{
                                              lineHeight: "28px",
                                              padding: "0px 6px",
                                            }}
                                          >
                                            {
                                              officeAttornyTeam.find(
                                                (el) =>
                                                  el.user_cd ===
                                                  caseDetails.case_assigned_to,
                                              ).full_name
                                            }
                                          </div> */}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="row data_line">
                                  <div
                                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{ display: "flex" }}
                                    onClick={() => {
                                      setCommonSidebarComponenet({
                                        componentName: "CaseAssignedBy",
                                        case_cd: caseDetails.case_cd,
                                        accessBy: accessBy,
                                        setAccessBy: setAccessBy,
                                        officeAttornyTeam: officeAttornyTeam,
                                      });
                                      setShowSCommonsideBar(true);
                                    }}
                                  >
                                    <label className="label_area">
                                      {pageLables[18].description}
                                    </label>
                                    <div
                                      className="viewing_field"
                                      style={{ border: "0" }}
                                    >
                                      {accessBy.length > 0 &&
                                        accessBy.map((access_by, index) => (
                                          <div
                                            key={access_by.user_cd}
                                            className="viewing_field"
                                            style={{
                                              display: "flex",
                                              border: "0",
                                            }}
                                          >
                                            <div style={{ lineHeight: "30x" }}>
                                              {access_by.image_name ? (
                                                <img
                                                  src={`https://files.amanrow.com/user/${access_by.image_name}`}
                                                  className="profile_image"
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src={blankprofile}
                                                  className="profile_image"
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                            <div
                                              style={{
                                                lineHeight: "28px",
                                                padding: "0px 6px",
                                              }}
                                            >
                                              {access_by.full_name}
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7"
                style={{ padding: "0px" }}
              >
                {(tapActive === "Progress" || tapActive === "Details") &&
                  caseDetails &&
                  caseDetails.case_cd && (
                    <>
                      <div style={{ height: "calc(100vh - 140px)" }}>
                        {caseLevels.length > 0 &&
                          officeAttornyTeam.length > 0 && (
                            <CaseProgress
                              case_cd={caseDetails.case_cd}
                              caseClients={caseClients}
                              courtLevels={courtLevels}
                              clientAs={clientAs}
                              caseLevels={caseLevels}
                              courtHearings={courtHearings}
                              setCourtHearings={setCourtHearings}
                              pageLables={pageLables}
                              courtsNames={courtsNames}
                              judgements={judgements}
                              caseStatus={caseStatus}
                              states={states}
                              officeAttornyTeam={officeAttornyTeam}
                            />
                          )}
                      </div>
                    </>
                  )}
                {tapActive === "Attachments" && (
                  <>
                    <div style={{ height: "calc(100vh - 140px)" }}>
                      {caseDetails && caseDetails.case_cd && (
                        <CaseAttachments case_cd={caseDetails.case_cd} />
                      )}
                    </div>
                  </>
                )}
                {tapActive === "Remarks" && (
                  <>
                    <div style={{ height: "calc(100vh - 140px)" }}>
                      {caseDetails && caseDetails.case_cd && (
                        <CaseRemarks case_cd={caseDetails.case_cd} />
                      )}
                    </div>
                  </>
                )}
                {tapActive === "Actions" && (
                  <>
                    {caseDetails && caseDetails.case_cd && (
                      <CaseProcedures
                        case_cd={caseDetails.case_cd}
                        pageLables={pageLables}
                      />
                    )}
                  </>
                )}
                {tapActive === "Finance" && (
                  <>
                    {caseDetails && caseDetails.case_cd && (
                      <CaseFinance
                        case_cd={caseDetails.case_cd}
                        ser_no={caseDetails}
                        pageLables={pageLables}
                      />
                    )}
                  </>
                )}
                {tapActive === "conversation" && (
                  <>
                    <div style={{ height: "calc(100vh - 140px)" }}>
                      {caseDetails && caseDetails.case_cd && (
                        <CaseChatting
                          case_cd={caseDetails.case_cd}
                          accessBy={accessBy}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {printComponent && (
        <>
          <div
            style={{
              border: "solid 1px gray",
              marginTop: "50px",
              display: "none",
            }}
          >
            <PrintCaseDetails
              caseDetails={caseDetails}
              caseTypes={caseTypes}
              clientAs={clientAs}
              caseOpponent={caseOpponent}
              accessBy={accessBy}
              caseClients={caseClients}
              courtLevels={courtLevels}
              caseLevels={caseLevels}
              courtHearings={courtHearings}
              pageLables={pageLables}
              courtsNames={courtsNames}
              judgements={judgements}
              caseStatus={caseStatus}
              states={states}
              setPrintComponent={setPrintComponent}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CaseDetails;
