
import React, { useEffect, useState } from "react"

const Item_discount = ({ itemData }) => {
   
    return <>
        {itemData.discount && (
            <div style={{ pading: "10px", marginTop: "15px" }}>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th style={{ width: "25%" }}>country</th>
                            <th style={{ width: "25%" }}>state</th>
                            <th style={{ width: "25%" }}>store</th>
                            <th style={{ width: "15%" }}>option</th>
                            <th style={{ width: "10%" }}>discount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itemData.discount.length >= 0 && itemData.discount.map((descount, index) => (
                            <tr>
                                <td>{descount.country_name}</td>
                                <td>{descount.state_name}</td>
                                <td>{descount.store_name}</td>
                                <td>{descount.discount_option}</td>
                                <td>{descount.maxemum_discount}</td>
                            </tr>
                        )

                        )}
                    </tbody>
                </table>
            </div>
        )}
    </>
}


export default Item_discount