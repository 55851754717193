import React from "react";
import pdf from "../../Images/icons/pdf.png";
import cam from "../../Images/icons/cam.png";
import pdfsolid from "../../Images/icons/pdfsolid.png";
import camsolid from "../../Images/icons/camsolid.png";
import company from "../../Images/icons/1.png";
import inventory from "../../Images/icons/21.png";
import restaurant from "../../Images/icons/26.png";
import department from "../../Images/icons/9.png";
import lawCase from "../../Images/icons/20.svg";
import Hrs from "../../Images/icons/Hrs.png";
import Acc from "../../Images/icons/Acc.jpg";
import Inv from "../../Images/icons/Inv.png";
import Pos from "../../Images/icons/Pos.png";
import Pur from "../../Images/icons/Pur.png";
import Sup from "../../Images/icons/Sup.png";
import Wkf from "../../Images/icons/Wkf.png";
import Aca from "../../Images/icons/Aca.png";
import { RiScales3Line } from "react-icons/ri";
import { TiFolderOpen } from "react-icons/ti";
import { PiSubtitlesLight } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { GoDot } from "react-icons/go";
import { BsPlusSquareDotted } from "react-icons/bs";
import { MdOutlineExpandMore } from "react-icons/md";
import { PiFunction } from "react-icons/pi";
import { HiUserGroup } from "react-icons/hi2";
import { IoDocumentOutline } from "react-icons/io5";
import { IoDocumentTextOutline } from "react-icons/io5";
import { AiFillPrinter } from "react-icons/ai";
import { PiEyeLight } from "react-icons/pi";
import SaveIcon from "@mui/icons-material/Save";
import { LiaSave } from "react-icons/lia";
import { IoSaveOutline } from "react-icons/io5";
import { BsTrash3 } from "react-icons/bs";
import { TfiSave } from "react-icons/tfi";
import { RiPlayListAddFill } from "react-icons/ri";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { FiEdit2 } from "react-icons/fi";
import { GrGroup } from "react-icons/gr";
import { TbFileFunction } from "react-icons/tb";
import { LiaHistorySolid } from "react-icons/lia";
import { TiAttachment } from "react-icons/ti";

import assets from "../../Images/icons/assets.png";
import liabilities from "../../Images/icons/liability-equity.png";
import revenue from "../../Images/icons/revenue.png";
import expences from "../../Images/icons/expences.png";
import account from "../../Images/icons/account.png";
import folder from "../../Images/icons/folder.png";
import { LiaPagerSolid } from "react-icons/lia";
import { FcFolder } from "react-icons/fc";
import { PiFolderOpen } from "react-icons/pi";
import { BsFileText } from "react-icons/bs";
import { MdOutlineRequestPage } from "react-icons/md";
import { BsLayoutTextSidebarReverse } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { PiFileTextDuotone } from "react-icons/pi";
import { PiTextAlignJustify } from "react-icons/pi";
import { GoDotFill } from "react-icons/go";
import { IoAdd } from "react-icons/io5";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import { FaHome } from "react-icons/fa";

const ImageCard = ({ icon, iconType }) => {
  return (
    <>
      {/*chart of account*/}
      {iconType === "Account tree" && (
        <>
          {icon === "Assets" && <GoDotFill size="16" color="gray" />}
          {icon === "Capital" && <GoDotFill size="16" color="gray" />}
          {icon === "Liabilities" && <GoDotFill size="16" color="gray" />}
          {icon === "Revenue" && <GoDotFill size="16" color="gray" />}
          {icon === "Expences" && <GoDotFill size="16" color="gray" />}
          {icon === "Account" && <PiTextAlignJustify size="16" color="gray" />}
          {icon === "Folder" && <PiFolderOpen size="16" color="gray" />}
          {/*{icon === "Folder" && <div style={{ padding: "4px" }}><img src={folder} alt="" style={{ height: "14px" }} /></div>}*/}
        </>
      )}

      {/*attachments*/}
      {iconType === "attachments" && (
        <>
          {icon === "pdf" && (
            <div style={{ padding: "4px" }}>
              <img src={pdfsolid} alt="" style={{ height: "22px" }} />
            </div>
          )}
          {icon === "image" && (
            <div style={{ padding: "4px" }}>
              <img src={camsolid} alt="" style={{ height: "22px" }} />
            </div>
          )}
        </>
      )}

      {/*organization tree*/}
      {iconType === "organization tree" && (
        <>
          {icon === "1" && (
            <img src={company} alt="" style={{ height: "16px" }} />
          )}
          {icon === "2" && <FaHome size="16" />}
          {icon === "21" && (
            <img src={inventory} alt="" style={{ height: "16px" }} />
          )}
          {icon === "26" && (
            <img src={restaurant} alt="" style={{ height: "14px" }} />
          )}
          {icon === "9" && (
            <img src={department} alt="" style={{ height: "12px" }} />
          )}
          {icon === "20" && (
            <img src={lawCase} alt="" style={{ height: "14px" }} />
          )}
        </>
      )}

      {/*system menu tree*/}
      {iconType === "system menu tree" && (
        <>
          {console.log(icon)}
          {icon === "Empty" && <GoDot size="16" className="icon-togle" />}
          {icon === "Lcc" && <RiScales3Line size="20" className="icon-togle" />}
          {icon === "Folder" && <MdOutlineExpandMore size="18" />}
          {icon === "Page" && <IoDocumentTextOutline size="14" />}
          {icon === "Config" && <IoSettingsOutline size="16" />}
          {icon === "Tap" && <TbFileFunction size="14" />}
          {icon === null && <HiUserGroup className="icon-togle" size="16" />}
          {icon === "Hrs" && (
            <img
              src={Hrs}
              alt=""
              style={{ height: "20px" }}
              className="icon-togle"
            />
          )}
          {icon === "Acc" && <img src={Acc} alt="" className="icon-togle" />}
          {icon === "Inv" && <img src={Inv} alt="" className="icon-togle" />}
          {icon === "Pos" && <img src={Pos} alt="" className="icon-togle" />}
          {icon === "Pur" && <img src={Pur} alt="" className="icon-togle" />}
          {icon === "Sup" && <img src={Sup} alt="" className="icon-togle" />}
          {icon === "Wkf" && <img src={Wkf} alt="" className="icon-togle" />}
          {icon === "Aca" && <img src={Aca} alt="" className="icon-togle" />}
        </>
      )}
      {/*taps actions*/}
      {iconType === "taps actions" && (
        <>
          {icon === "1" && <PiEyeLight color="inherit" fontSize="16px" />}
          {icon === "2" && (
            <IoDocumentOutline color="inherit" fontSize="16px" />
          )}
          {icon === "3" && <IoAdd color="inherit" fontSize="18px" />}
          {icon === "4" && <FiEdit2 color="inherit" size="14px" />}
          {icon === "5" && <BsTrash3 color="inherit" fontSize="14px" />}
          {icon === "7" && <TiAttachment color="inherit" fontSize="18px" />}
          {icon === "8" && <AiFillPrinter color="inherit" fontSize="16px" />}
          {icon === "9" && <LiaHistorySolid color="inherit" fontSize="14px" />}
        </>
      )}
    </>
  );
};

export default ImageCard;
