import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//import EmptyUserIcon from '/images/blank_employee.jpg';

import SaveIcon from "@mui/icons-material/Save";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { MdOutlineExpandMore } from "react-icons/md";

import Modal from "../../utils/Modal"
import ImageCrop from "../../utils/ImageCrop"
import { dataURLtoBlob } from "../../utils/sharedfunction"

import EmployeeAttachments from "./EmployeeAttachments"; 
import EmployeeSalary from "./EmployeeSalary"; 
import EmployeeLeaves from "./EmployeeLeaves";
import EmployeeBonus from "./EmployeeBonus";
import EmployeePenalties from "./EmployeePenalties"; 

const EmployeeDetails = () => {

    const [tapActive, setTapActive] = useState("Details");
    const [sectionActive, setsectionActive] = useState("");
    const [employeeMode, setEmployeeeMode] = useState("initial");
    const [sectionMode, setSectionMode] = useState("");
    const [nationality, setNationality] = useState();
    const [idType, setIdType] = useState();
    const [sex, setSex] = useState();
    const [titel, setTitel] = useState();
    const [employeeDetailsLable, setemployeeDetailsLable] = useState([]);
    const [taps, setTaps] = useState([]); 
    const [actions, setActions] = useState([]);
    const [salaryType, setSalaryType] = useState([]);
    const [relashinship, setRelashinship] = useState([]); 
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [personalContact, setPersonalContact] = useState(false);
    const [emergancyContact, setemergancyContact] = useState(false);
    const [showCrop,setShowCrop] = useState(false);
    const [medicalDetales, setmedicalDetales] = useState(false);
    
    const [updateSection, setUpdateSection] = useState(false);

    const [uploadprecentage, setUploadPercentage] = useState(0)
    const filePickerRef = useRef(null)   
    const profileimgRef = useRef(null)  
   

  var inActiveTap = "normal_float normal_text_align tap_head_area";
  var activeTap =
    "normal_float normal_text_align tap_head_area active_tap_head_area";
  var inActiveTapOposit = "opposite_float opposite_text_align tap_head_area";
  var activeTapOposit ="opposite_float opposite_text_align tap_head_area active_tap_head_area";

    useEffect(() => {
    getEmployeeInitial();
  }, []);

    const set_tab_active = (active_tab, tap_cd) => {
       
        if (tap_access_denied(tap_cd)) { return } 
        setTapActive(active_tab);
    };

    const tap_access_denied = (tap_code) => {

        const tapFilter = actions.find((tap_filter) => {
            return tap_filter.tap_cd == tap_code
        });

         if (tapFilter && tapFilter.tap_cd) { return false }
        alert("sorry. you don't have permistion for this tap")
        return true
    };

    const action_access_denied = (action_code) => {

        const actionFilter = actions.find((action_filter) => {
            return action_filter.action_cd == action_code;
        });
        
        if (actionFilter && actionFilter.action_cd) {return false} 
        alert("sorry. you don't have permistion for this function")
        return true   
    };

    const set_clear_mode = () => {
        if (employeeMode === "Edit") {
          setEmployeeeMode("view");
        } else {
          setEmployeeDetails();
          setEmployeeeMode("initial");
        }
    };
    
    const set_employeeeMode = (mode, action_code) => {

        if (action_access_denied(action_code)){return } 
        setEmployeeeMode(mode)    
    };

    const set_sectionActive = (section) => {
        if (sectionActive === section) {
            setsectionActive("")
            setSectionMode("")
        }
        else {
            setsectionActive(section)
            setSectionMode("View")
        }
    };

    const set_workplace = (branvh_cd) => {
        setEmployeeDetails({ ...employeeDetails, branvh_cd: branvh_cd })
    };

    const employee_search_keyEvent = (e) => {
    if (e.charCode === 13) {
      var code_str = document.getElementById("emp_cd_s").value.trim();
      if (code_str.length > 0) {
        getEmployeeDetails("emp_cd", code_str);
      } else {
        getEmployeeDetails(
          "phone_no",
          document.getElementById("phone_no_s").value.trim(),
        );
      }
    }
    };
    
    // Read ----------------------
    const getEmployeeInitial = async () => {

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            console.log(userDataLocal.token)
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,

                },
                input: {
                    actioncode: "get_employee_initials",
                    company_cd: userDataLocal.company_cd,
                    branch_cd: "2",
                    system_cd: "Emp",
                    function_cd: "20"
                },
            };

            console.log("get_employee_initials input ", data)

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/v1/hrs/get_employee_initials`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }

            );
            console.log("get_employee_initials output  ", res);

            if (res.data.output.nationality_ref && res.data.output.nationality_ref.length > 0) { setNationality(res.data.output.nationality_ref) }
            if (res.data.output.taps && res.data.output.taps.length > 0) { setTaps(res.data.output.taps) }
            if (res.data.output.actions && res.data.output.actions.length > 0) { setActions(res.data.output.actions) }
            if (res.data.output.salary && res.data.output.salary.length > 0) { setSalaryType(res.data.output.salary) }
            if (res.data.output.relationship_ref && res.data.output.relationship_ref.length > 0) { setRelashinship(res.data.output.relationship_ref) } 
            setIdType(res.data.output.id_type_ref);
            setSex(res.data.output.sex_ref);
            setTitel(res.data.output.job_title_ref);

            setemployeeDetailsLable(res.data.output.pages_lables);

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getEmployeeDetails = async (field_name, field_value) => {
    
    var employee_code = null;
    var phone_number = null;
    if (field_name == "emp_cd") {
      employee_code = field_value;
    } else {
      phone_number = field_value;
    }

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_employee",
          emp_cd: employee_code,
          phone_no: phone_number,
        },
      };
      console.log("employee details input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/hrs/get_employee`,

        data,
        {
          headers:{
            Authorization: `Bearer ${userDataLocal.token}`,
          }
        }
      );

        console.log("employee details output ", res.data.output.employee)

        if (res.data.output.employee && res.data.output.employee.emp_cd) {
            setEmployeeDetails(res.data.output.employee)
            setEmployeeeMode("view")
        } else {
            alert("no record found")
        }
        
      
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };   

    // Update ----------------------
    const updatemployeeDetails = async () => {
        alert("ok")
        let emploiyee_code = null
        if (employeeDetails && employeeDetails.emp_cd) { emploiyee_code = employeeDetails.emp_cd }
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "update_employee",
                    updated_section: updateSection,
                    branch_cd: employeeDetails.branch_cd,                    
                    emp_cd: emploiyee_code,
                    first_name: employeeDetails.first_name,
                    middle_name: employeeDetails.middle_name,
                    last_name: employeeDetails.last_name,
                    id_type_cd: employeeDetails.id_type_cd, 
                    emp_id: employeeDetails.emp_id,
                    date_of_birth: employeeDetails.date_of_birth,
                    country_area_cd : employeeDetails.country_area_cd,
                    phone_no: employeeDetails.phone_no,
                    email: employeeDetails.email,
                    sex_cd: employeeDetails.sex_cd,
                    nationality_cd: employeeDetails.nationality_cd,
                    marital_status_cd: employeeDetails.marital_status_cd,
                    job_cd: employeeDetails.job_cd,
                    job_start_date: employeeDetails.job_start_date,
                    education_level_cd: employeeDetails.education_level_cd,
                    salary_typ_cd: employeeDetails.salary_typ_cd,
                    no_of_depends: employeeDetails.no_of_depends,
                    other_details: employeeDetails.other_details,
                    country_cd: employeeDetails.country_cd,
                    state_cd: employeeDetails.state_cd,
                    city: employeeDetails.city,
                    zip_cd: employeeDetails.zip_cd,
                    employee_address: employeeDetails.employee_address,
                    address2: employeeDetails.address2,
                    emergency_contact_name: employeeDetails.emergency_contact_name,
                    relationship: employeeDetails.relationship,
                    emergency_prime_phone: employeeDetails.emergency_prime_phone,
                    emergency_alternate_phone: employeeDetails.emergency_alternate_phone,
                    emergencey_address1: employeeDetails.emergencey_address1,
                    emergencey_address2: employeeDetails.emergencey_address2,
                    medical_contact_name: employeeDetails.medical_contact_name,
                    medical_contact_phone: employeeDetails.medical_contact_phone    
                },
            };
            console.log("update_employee input ", data);

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/v1/hrs/get_employee`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("update_employee output ", res);
            if (res.data.output.employee && res.data.output.employee.emp_cd) {
                setEmployeeDetails(res.data.output.employee);
                setEmployeeeMode("view");
            } else {
                alert("somthing went wrong")
            }
            
            

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const filePickedHandler = (event) => {
        let pickedFile;
        if (event.target.files && event.target.files.length === 1) {
            let fileExttype = event.target.files[0].type.split("/")[0];
            let filExt = event.target.files[0].type.split("/")[1];
            console.log(fileExttype, filExt, event.target.files[0].type);
            if (filExt === "pdf") {
                pickedFile = event.target.files[0];
                const reader = new FileReader();
               uploadFile(pickedFile);
            } else {
                alert("upload only pdf files")
                return
            }
        }
    };

    const uploadFile = (pickedFile) => {

        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        let formData = new FormData();
        formData.append("file", pickedFile);
        formData.append("fname", employeeDetails.emp_id)
        formData.append("company", userDataLocal.company_cd)
        formData.append("flocation", "agrement");

        const options = {
            headers: { Authorization: `Bearer ${userDataLocal.token}`, }, onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;

                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                    setUploadPercentage(percent);
                }
            },
        };

        axios
            .post(`${process.env.REACT_APP_BACK_END_URL}/api/iipsys/v1/uploadFiles`, formData, options)
            .then((res) => {
                if (res.data.result.rstatus === "1") {
                    alert(res.data.file_url)
          
                }
                setUploadPercentage(0);
              
            })
            .catch((err) => {
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        localStorage.removeItem("userDataSessionWay");
                        window.location.replace("/");
                    }
                }

                setUploadPercentage(0);
            });
    }

    const closeProfileHandler = () => setShowCrop(false);

    const imagefileInputHandler = async (file) => {
        profileimgRef.current.src = file
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));


            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "update_emp_image",
                    file_name: userDataLocal.company_cd + "-" + employeeDetails.emp_cd,
                    file_location: "employee_profile",
                    file_old_name: "",
                    emp_cd: employeeDetails.emp_cd,
                    img: file,
                },
            };
        
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iipsys/v1/update_emp_image`,

                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );
            profileimgRef.current.src = file
            console.log("employee details output ", res);


        } catch (err) {
            alert(err.message)
            profileimgRef.current.src = ""
        }

        //var imgeFile = dataURLtoBlob(file);
        //var path = `${url}/api/users/updateProfileImage`;
        //var type = "image";
        //uploadProfileHandler(path, imgeFile, file);
    };

return (
    <> 
        <Modal
            show={showCrop} onCancel={closeProfileHandler} headerClass="text-center" containerStyle={{ width: "500px" }}>
            <div className="map-container">
                <ImageCrop close={closeProfileHandler} onSave={imagefileInputHandler} aspectRatio={1 / 1} profile={true}  cropSize={{ width: 300, height: 300 }} objectFit="vertical-cover"/>
            </div>
        </Modal>
        <div className="container">
            <div className="profilemenu-scroll" style={{ borderBottom: "solid 1px #ccc" }}>
                {/*<div className={`${tapActive === "Details" ? "activeTap" : "inActiveTap"} employee-menu-tab-deatils-mobile normal_float normal_text_align tap_head_area`} style={{ color: "red" }} onClick={() => { set_tab_active("Details") }}>{taps && taps.length > 0 && taps[0].tap_name}</div>*/}
                <div className={`${tapActive === "Details" ? "activeTap" : "inActiveTap"} employee-menu-tab-deatils-desktop normal_float normal_text_align tap_head_area`} onClick={() => { set_tab_active("Details") }}>{taps && taps.length > 0 && taps[0].tap_name}</div>
                <div className={tapActive === "Agreement" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Agreement", 2); }} >{taps && taps.length > 0 && taps[1].tap_name}</div>
                <div className={tapActive === "Documents" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Documents", 3); }}>{taps && taps.length > 0 && taps[2].tap_name}</div>
                <div className={tapActive === "Employment history" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Employment history", 4); }}>{taps && taps.length > 0 && taps[3].tap_name}</div>
                <div className={tapActive === "Education" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Education", 5); }}>{taps && taps.length > 0 && taps[4].tap_name}</div>
                <div className={tapActive === "Leaves" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Leaves", 6); }}>{taps && taps.length > 0 && taps[5].tap_name}</div>
                <div className={tapActive === "Salary" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Salary", 7); }}>{taps && taps.length > 0 && taps[6].tap_name}</div>
                <div className={tapActive === "Bonus" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Bonus", 8); }}>{taps && taps.length > 0 && taps[7].tap_name}</div>
                <div className={tapActive === "Penalties" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Penalties", 9); }}>{taps && taps.length > 0 && taps[8].tap_name}</div>
                <div className={tapActive === "Attendance" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Attendance", 10); }}>{taps && taps.length > 0 && taps[9].tap_name}</div>                                
                <div className={tapActive === "Reports" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Reports", 11); }}>{taps && taps.length > 0 && taps[10].tap_name}</div>                
                <div style={{ clear: "both" }}></div>
            </div>
            {employeeDetailsLable.length > 0 && (
                <>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 border-right">
                            {/*Button bar ---------------------*/}  
                            <div>
                                <div className="normal_float normal_text_align ">
                                    {employeeMode !== "initial" && (
                                        <>
                                            <Tooltip title="Back" placement="bottom" arrow>
                                                <IconButton onClick={set_clear_mode}><ArrowBackIcon color="inherit" fontSize="small" /></IconButton>
                                            </Tooltip>
                                            <span>
                                                {(employeeMode === "Edit" || employeeMode === "Draft") && employeeMode}
                                            </span>{" "}
                                            <span className="separator_border">
                                                {employeeDetails && employeeDetails.emp_cd}
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div className="opposite_float opposite_text_align">
                                    {(employeeMode === "Edit" || employeeMode === "Draft") && (
                                        <>
                                            <Tooltip title="Save" placement="bottom" arrow onClick={() => { setUpdateSection("detail"); updatemployeeDetails() }}>
                                                <IconButton><SaveIcon color="inherit" fontSize="small" /></IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                    {employeeMode === "initial" && (
                                        <>
                                            <Tooltip title={actions.length > 0 && actions[1].tap_name} placement="bottom" arrow>
                                                <IconButton onClick={() => { set_employeeeMode("Draft", 28); }}>
                                                    <InsertDriveFileOutlinedIcon color="inherit" fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Search" placement="bottom" arrow>
                                                <IconButton>
                                                    <SearchOutlinedIcon color="inherit" fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                    {employeeMode === "view" && (
                                        <>
                                            <Tooltip title={actions.length > 0 && actions[2].tap_name} placement="bottom" arrow>
                                                <IconButton onClick={() => { set_employeeeMode("Edit", 29); }}><ModeOutlinedIcon color="inherit" fontSize="small" /></IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                            <div style={{ height: "calc(100vh - 162px)", overflow: "auto", overflowX: "hidden" }} >          
                                {/*top section containing employee name anf employee image and search area  --------------------*/}                                   
                                <div className="row">
                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        {employeeMode === "initial" && (
                                            <>
                                                <div className="data_line" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable.length > 0 && employeeDetailsLable[0].description}</label>
                                                    <input id="emp_cd_s" type="text" className="editing_field" autoComplete="0" onKeyPress={employee_search_keyEvent}/>
                                                </div>
                                                <div className="data_line" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[18].description}</label>
                                                    <input id="phone_no_s" type="text" className="editing_field" autoComplete="0" onKeyPress={employee_search_keyEvent} />
                                                </div>
                                                <div className="data_line" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[4].description}</label>
                                                    <input id="employee_Name" type="text" className="editing_field" autoComplete="0" onKeyPress={employee_search_keyEvent} />
                                                </div>
                                            </>
                                        )}
                                        {employeeMode === "view" && employeeDetails && (
                                        <>  
                                            <div className="row data_line">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[1].description}</label>
                                                    <div className="viewing_field">{employeeDetails && employeeDetails.first_name}</div>
                                                </div>
                                            </div>
                                            <div className="row data_line">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[2].description}</label>
                                                    <div className="viewing_field">{employeeDetails && employeeDetails.middle_name}</div>
                                                </div>
                                            </div>
                                            <div className="row data_line">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[3].description}</label>
                                                    <div className="viewing_field">{employeeDetails && employeeDetails.last_name}</div>
                                                </div>
                                            </div>  
                                            <div className="row data_line">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[5].description}</label>
                                                    <div className="viewing_field">{employeeDetails && nationality.find((el) => el.code === employeeDetails.nationality_cd).description}</div>
                                                </div>
                                            </div>                                
                                        </>
                                        )}
                                        {(employeeMode === "Edit" || employeeMode === "Draft") && (
                                            <>                  
                                                <div className="data_line" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[1].description}</label>
                                                    <input type="text" className="editing_field" autoComplete="0"
                                                        value={employeeDetails && employeeDetails.first_name} onChange={(e) => setEmployeeDetails({ ...employeeDetails, first_name: e.target.value })} />
                                                </div>
                                                <div className="data_line" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[2].description}</label>
                                                    <input type="text" className="editing_field" autoComplete="0"
                                                        value={employeeDetails && employeeDetails.middle_name} onChange={(e) => setEmployeeDetails({ ...employeeDetails, middle_name: e.target.value })} />
                                                </div>
                                                <div className="data_line" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[3].description}</label>
                                                    <input type="text" className="editing_field" autoComplete="0"
                                                        value={employeeDetails && employeeDetails.last_name} onChange={(e) => setEmployeeDetails({ ...employeeDetails, last_name: e.target.value })} />  
                                                </div>          
                                                <div className="data_line" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[5].description}</label>
                                                    <select className="editing_field" value={employeeDetails && employeeDetails.nationality_cd} onChange={(e) => setEmployeeDetails({ ...employeeDetails, nationality_cd: e.target.value })}>
                                                        <option value="0"></option>
                                                        {nationality.length >= 0 &&
                                                            nationality.map((nationality, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={nationality.code}
                                                                    >
                                                                        {nationality.description}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                </div>          
                                            </>
                                        )}
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 opposite_text_align">
                                        {(employeeMode === "view" || employeeMode === "Edit") && (
                                        <div onClick={() => setShowCrop(true)}>
                                        {employeeDetails && employeeDetails.image_name ?
                                                (<img src={employeeDetails.image_name} className="employee_image" alt="emp_image" ref={profileimgRef} />)
                                            :
                                                (<img src="/images/blank_employee.jpg" style={{ width: "120px" }} alt="emp_image" ref={profileimgRef} />)
                                            }
                                        </div>)}
                                    </div> 
                                </div>
                             
                                {employeeMode === "view" && employeeDetails.emp_id && (
                                    <>
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[6].description}</label>
                                                <div class="viewing_field">{employeeDetails.emp_id}</div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[7].description}</label>
                                                <div class="form-control input-sm view_field field_text_alighn">{idType.find((el) => el.code === employeeDetails.id_type_cd, ).description }</div>
                                            </div>                                        
                                        </div>    
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[8].description}</label>
                                                <div class="viewing_field">{employeeDetails.date_of_birth}</div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[9].description}</label>
                                                <div class="viewing_field">{ sex.find((el) => el.code === employeeDetails.sex_cd).description }</div>
                                            </div>                                        
                                        </div>   
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[10].description}</label>
                                                <div class="viewing_field">{titel.find((el) => el.code === employeeDetails.job_cd,).description}</div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[11].description}</label>
                                                <div class="viewing_field">{employeeDetails.education_level}</div>
                                            </div>
                                        </div>   
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[38].description}</label>
                                                <div class="viewing_field">{salaryType.find((el) => el.code === employeeDetails.salary_typ_cd,).description}</div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[37].description}</label>
                                                <div class="viewing_field">{employeeDetails.no_of_depends}</div>
                                            </div>
                                        </div>
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[12].description}</label>
                                                <div class="viewing_field">{employeeDetails && employeeDetails.workplace}</div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[13].description}</label>
                                                <div class="viewing_field">{employeeDetails.supervisor}</div>
                                            </div>
                                        </div>   
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[14].description}</label>
                                                <div class="viewing_field">{employeeDetails.job_start_date}</div>
                                            </div>
                                            {employeeDetails && employeeDetails.job_end_date &&
                                            (
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[15].description}</label>
                                                <div class="viewing_field">{employeeDetails.job_end_date}</div>
                                            </div>
                                            )}
                                        </div>   
                                        <div className="row data_line">
                                            <div style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[16].description}</label>
                                                <div class="viewing_field">{employeeDetails.other_details}</div>
                                            </div>
                                        </div>     
                                    </>
                                )}
                                {(employeeMode === "Edit" || employeeMode === "Draft") && (
                                    <>
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[6].description}</label>
                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.emp_id} onChange={(e) => setEmployeeDetails({ ...employeeDetails, emp_id: e.target.value })} /> 
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[7].description}</label>
                                                <select className="editing_field" value={employeeDetails && employeeDetails.id_type_cd} onChange={(e) => setEmployeeDetails({ ...employeeDetails, id_type_cd: e.target.value })}>
                                                    <option value="0"></option>
                                                    {idType.length >= 0 && idType.map((idType, index) => {
                                                        return (
                                                            <option key={index} value={idType.code}>
                                                                {idType.description}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>                                        
                                        </div>
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[8].description}</label>
                                                <input type="text" className="editing_field" autoComplete="0"
                                                    value={employeeDetails && employeeDetails.date_of_birth} onChange={(e) => setEmployeeDetails({ ...employeeDetails, date_of_birth: e.target.value })} />
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[9].description}</label>
                                                <select className="editing_field" value={employeeDetails && employeeDetails.sex_cd} onChange={(e) => setEmployeeDetails({ ...employeeDetails, sex_cd: e.target.value })}>
                                                    <option value="0"></option>
                                                    {sex.length >= 0 &&
                                                        sex.map((sex, index) => {
                                                            return (
                                                                <option key={index} value={sex.code}>
                                                                    {sex.description}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[10].description}</label>
                                                <select className="editing_field" value={employeeDetails && employeeDetails.job_cd} onChange={(e) => setEmployeeDetails({ ...employeeDetails, job_cd: e.target.value })}>
                                                <option value="0"></option>
                                                {titel.length >= 0 &&
                                                    titel.map((titel, index) => {
                                                        return (
                                                            <option key={index} value={titel.code}>
                                                                {titel.description}
                                                            </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[11].description}</label>
                                                <div className="editing_field">{employeeDetails && employeeDetails.education_level}</div>
                                            </div>
                                        </div>     
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[38].description}</label>
                                                <select className="editing_field" value={employeeDetails && employeeDetails.salary_typ_cd} onChange={(e) => setEmployeeDetails({ ...employeeDetails, salary_typ_cd: e.target.value })}>
                                                    <option value="0"></option>
                                                    {salaryType.length >= 0 &&
                                                        salaryType.map((salary_type, index) => {
                                                            return (
                                                                <option key={index} value={salary_type.code}>
                                                                    {salary_type.description}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[37].description}</label>
                                                <input type="text" className="editing_field" style={{ outline: "none" }} autoComplete="off"
                                                    value={employeeDetails && employeeDetails.no_of_depends} onChange={(e) => setEmployeeDetails({ ...employeeDetails, no_of_depends: e.target.value })}/>
                                            </div>
                                        </div>
                                        <div className="row data_line">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                            <label className="label_area">{employeeDetailsLable[12].description}</label>
                                            <div className="editing_field">{employeeDetails && employeeDetails.workplace}</div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                            <label className="label_area">{employeeDetailsLable[13].description}</label>
                                            <div className="editing_field">{employeeDetails && employeeDetails.supervisor}</div>
                                        </div>
                                    </div>
                                        <div className="row data_line">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[14].description}</label>
                                                <input type="text" className="editing_field" autoComplete="0"
                                                value={employeeDetails && employeeDetails.job_start_date} onChange={(e) => setEmployeeDetails({ ...employeeDetails, job_start_date: e.target.value })}/>                          
                                            </div>
                                            {employeeDetails && employeeDetails.job_end_date &&
                                                (<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                    <label className="label_area">{employeeDetailsLable[15].description}</label>
                                                    <div className="editing_field">{employeeDetails && employeeDetails.job_end_date}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row data_line" >
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                                                <label className="label_area">{employeeDetailsLable[16].description}</label>
                                                <input type="text" className="editing_field" autoComplete="0"
                                                        value={employeeDetails && employeeDetails.other_details} onChange={(e) => setEmployeeDetails({ ...employeeDetails, other_details: e.target.value })}/>
                                            </div>                     
                                        </div>   
                                    </>
                                )}
                                {(employeeMode === "view") && (
                                    <>
                                        <div className="section_details_aria">
                                            <label className="normal_float normal_text_align " onClick={() => set_sectionActive("Personal contact")}>{employeeDetailsLable[17].description}</label>
                                            <div className="opposite_float opposite_text_align">
                                                {sectionActive !== "Personal contact" && <MdOutlineExpandMore size="18" onClick={() => set_sectionActive("Personal contact")} /> }
                                                {sectionActive === "Personal contact" &&(
                                                    <>
                                                        {sectionMode === "View" ?
                                                            <ModeOutlinedIcon color="inherit" fontSize="small" onClick={() => setSectionMode("Edit")} />
                                                            :
                                                            <SaveIcon color="inherit" fontSize="small" onClick={() => { setUpdateSection("Personal contact"); updatemployeeDetails() }} />
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                        {sectionActive === "Personal contact" && (
                                            <div style={{marginTop:"10px"}}>
                                                {sectionMode === "View" && (
                                                    <>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[18].description}</label>
                                                                <div class="viewing_field">{employeeDetails.phone_no}</div>
                                                            </div>
                                                        </div>   
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[19].description}</label>
                                                                <div class="viewing_field">{employeeDetails.email}</div>
                                                            </div>
                                                        </div>   
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[23].description}</label>
                                                                <div class="viewing_field">{employeeDetails.employee_address}</div>
                                                            </div> 
                                                        </div>   
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area"></label>
                                                                <div class="viewing_field">{employeeDetails.address2}</div>
                                                            </div>
                                                        </div>   
                                                    </>
                                                )}
                                                {sectionMode === "Edit" && (
                                                    <>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[18].description}</label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.phone_no} onChange={(e) => setEmployeeDetails({ ...employeeDetails, phone_no: e.target.value })} /> 
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[19].description}</label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.email} onChange={(e) => setEmployeeDetails({ ...employeeDetails, email: e.target.value })} /> 
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[23].description}</label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.employee_address} onChange={(e) => setEmployeeDetails({ ...employeeDetails, employee_address: e.target.value })} /> 
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area"></label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.address2} onChange={(e) => setEmployeeDetails({ ...employeeDetails, address2: e.target.value })} />
                                                            </div>
                                                        </div>   
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        <div className="section_details_aria">
                                            <label className="normal_float normal_text_align " onClick={() => set_sectionActive("Emergancy Contact Info")}>{employeeDetailsLable[25].description}</label>
                                            <div className="opposite_float opposite_text_align">
                                                {sectionActive !== "Emergancy Contact Info" && <MdOutlineExpandMore size="18" onClick={() => set_sectionActive("Emergancy Contact Info")} />}
                                                {sectionActive === "Emergancy Contact Info" && (
                                                    <>
                                                        {sectionMode === "View" ?
                                                            <ModeOutlinedIcon color="inherit" fontSize="small" onClick={() => setSectionMode("Edit")} />
                                                            :
                                                            <SaveIcon color="inherit" fontSize="small" onClick={() => { setUpdateSection("Personal contact"); updatemployeeDetails() }} />
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                        {sectionActive === "Emergancy Contact Info" && (
                                            <div style={{ marginTop: "10px" }}>
                                                {sectionMode === "View" && (
                                                    <>
                                                        <div className="row data_line">
                                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[26].description}</label>
                                                                <div class="viewing_field">{employeeDetails.emergency_contact_name}</div>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[27].description}</label>
                                                                <div class="form-control input-sm view_field field_text_alighn">{relashinship.find((el) => el.code === employeeDetails.relationship,).description}</div>
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[28].description}</label>
                                                                <div class="viewing_field">{employeeDetails.emergency_prime_phone}</div>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[29].description}</label>
                                                                <div class="form-control input-sm view_field field_text_alighn">{employeeDetails.emergency_alternate_phone}</div>
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[23].description}</label>
                                                                <div class="viewing_field">{employeeDetails.emergencey_address1}</div>
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area"></label>
                                                                <div class="viewing_field">{employeeDetails.emergencey_address2}</div>
                                                            </div>
                                                        </div>   
                                                    </>
                                                )}
                                                {sectionMode === "Edit" && (
                                                    <>
                                                        <div className="row data_line">
                                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[26].description}</label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.emergency_contact_name} onChange={(e) => setEmployeeDetails({ ...employeeDetails, emergency_contact_name: e.target.value })} />
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[27].description}</label>
                                                                <select className="editing_field" value={employeeDetails && employeeDetails.relationship} onChange={(e) => setEmployeeDetails({ ...employeeDetails, relashinship: e.target.value })}>
                                                                    <option value="0"></option>
                                                                    {relashinship.length >= 0 && relashinship.map((relashinship, index) => {
                                                                        return (
                                                                            <option key={index} value={relashinship.code}>
                                                                                {relashinship.description}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[28].description}</label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.emergency_prime_phone} onChange={(e) => setEmployeeDetails({ ...employeeDetails, emergency_prime_phone: e.target.value })} />
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[29].description}</label>        
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.emergency_alternate_phone} onChange={(e) => setEmployeeDetails({ ...employeeDetails, emergency_alternate_phone: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[23].description}</label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.emergencey_address1} onChange={(e) => setEmployeeDetails({ ...employeeDetails, emergencey_address1: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area"></label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.emergencey_address2} onChange={(e) => setEmployeeDetails({ ...employeeDetails, emergencey_address2: e.target.value })} />
                                                            </div>
                                                        </div>   
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        <div className="section_details_aria">
                                            <label className="normal_float normal_text_align " onClick={() => set_sectionActive("Medical Contact")}>{employeeDetailsLable[32].description}</label>
                                            <div className="opposite_float opposite_text_align">
                                                {sectionActive !== "Medical Contact" && <MdOutlineExpandMore size="18" onClick={() => set_sectionActive("Medical Contact")} />}
                                                {sectionActive === "Medical Contact" && (
                                                    <>
                                                        {sectionMode === "View" ?
                                                            <ModeOutlinedIcon color="inherit" fontSize="small" onClick={() => setSectionMode("Edit")} />
                                                            :
                                                            <SaveIcon color="inherit" fontSize="small" onClick={() => { setUpdateSection("Personal contact"); updatemployeeDetails() }} />
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                        {sectionActive === "Medical Contact" && (
                                            <div style={{ marginTop: "10px" }}>
                                                {sectionMode === "View" && (
                                                    <>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[33].description}</label>
                                                                <div class="viewing_field">{employeeDetails.medical_contact_name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[34].description}</label>
                                                                <div class="viewing_field">{employeeDetails.medical_contact_phone}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {sectionMode === "Edit" && (
                                                    <>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[33].description}</label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.medical_contact_name} onChange={(e) => setEmployeeDetails({ ...employeeDetails, medical_contact_phone: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="row data_line">
                                                            <div style={{ display: "flex" }} >
                                                                <label className="label_area">{employeeDetailsLable[34].description}</label>
                                                                <input type="text" className="editing_field" autoComplete="0" value={employeeDetails && employeeDetails.medical_contact_phone} onChange={(e) => setEmployeeDetails({ ...employeeDetails, medical_contact_phone: e.target.value })} />
                                                            </div>
                                                        </div>      
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                            {tapActive === "Agreement" && employeeDetails.emp_id && (
                                <>
                                    <div onClick={() => filePickerRef.current.click()}>upload aggreement</div>

                                    {uploadprecentage > 0 && <div>uploading...</div>}

                                    <input ref={filePickerRef} style={{ display: "none" }} type="file" onChange={filePickedHandler} accept="application/pdf" />     
                                </>
                            )}
                            {tapActive === "Documents" && (
                                <>
                                    {employeeDetails && employeeDetails.emp_cd &&
                                        <EmployeeAttachments case_cd={employeeDetails.emp_cd} actions={actions } />
                                    }
                                </>
                            )}    
                            {tapActive === "Employment history" && (
                                <>
                                <div>Employment history</div>
                                </>
                            )}
                            {tapActive === "Education" && (
                                <>
                                <div>Education</div>
                                </>
                            )}
                            {tapActive === "Leaves" && (
                                <>
                                    {employeeDetails && employeeDetails.emp_cd &&
                                        <EmployeeLeaves emp_cd={employeeDetails.emp_cd} pageLables={employeeDetailsLable} />
                                    }
                                </>
                            )}
                            {tapActive === "Salary" && (
                                <>
                                    {employeeDetails && employeeDetails.emp_cd &&
                                        <EmployeeSalary emp_cd={employeeDetails.emp_cd} pageLables={employeeDetailsLable} />
                                    }
                                </>
                            )}
                            {tapActive === "Bonus" && (
                                <> 
                                    {employeeDetails && employeeDetails.emp_cd &&
                                        <EmployeeBonus emp_cd={employeeDetails.emp_cd} pageLables={employeeDetailsLable} />
                                    }
                                </>
                            )}
                            {tapActive === "Penalties" && (
                                <>
                                    {employeeDetails && employeeDetails.emp_cd &&
                                        <EmployeePenalties emp_cd={employeeDetails.emp_cd} pageLables={employeeDetailsLable} />
                                    }
                                </>
                            )}
                            {tapActive === "Attendance" && (
                                <>
                                    <div>Attendance</div>
                                </>
                            )}
                            {tapActive === "Reports" && (
                                <>
                                    <div>Reports</div>
                                </>
                            )}  
                        </div>
                    </div>
                </>
            )}
        </div>
    </>
  );
};

export default EmployeeDetails;
