import React, { useEffect, useState } from "react";
import axios from "axios";
// import {language_return} from '../../JSfiles/inventory/ItemLabelsLoad'

import InitialPage from "../Request/InitialPage";
import RequestPage from "../Request/RequestPage";
import ComplaintPage from "../Request/complaint/ComlpaintPage";
import Menu_1 from "../Request/complaint/Menu_1";

import { useSelector } from "react-redux"; //to get the value from the redux

const Request = () => {
  /*const [PageMode, setPageMode] = useState("initial")*/
  const [PageMode, setPageMode] = useState("initial");
  const [requesInitialstData, setRequesInitialstData] = useState();
  const [requestTypetData, setrequestTypetData] = useState();
  const [customerTypetData, setCustomerTypetData] = useState();
  const [listMode, setlistMode] = useState();
  const [RequestsListData, setRequestsListData] = useState();

  const [RequestInput, setRequestInput] = useState({
    request_year: 0,
    branch_cd: 0,
    request_cd: 0,
  });

  const { user } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
   
    getRequestInitial();
  }, []);

  /*Read ------------*/
  const getRequestInitial = async () => {
      try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
          info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
          },
          input: {
            actioncode: "get_request_initials",
          },
        };

        console.log("get_request_initials input ", data)

        let res = await axios.post(
          `${process.env.REACT_APP_BACK_END_URL}/api/iip/wf/get_request_initials`,
          data,
          {
            headers: {
                Authorization: `Bearer ${userDataLocal.token}`,
            }
        }
        );
        console.log("get_request_initials output ", res)

        if(res.data.output.request_type && res.data.output.request_type.length > 0){setrequestTypetData(res.data.output.request_type)}
        if(res.data.output.customer_type && res.data.output.customer_type.length > 0){setCustomerTypetData(res.data.output.customer_type)}
        setRequesInitialstData(res.data.output);
       
      } catch (err) {
        console.log(err);
        alert("error message" + err.message);
      }
    };
  return (
    <>
      <div>
        {PageMode === "menu" && <Menu_1 />}
        {PageMode === "initial" && (<InitialPage listMode={listMode} setlistMode={setlistMode} RequestsListData={RequestsListData} setRequestsListData={setRequestsListData} PageMode={PageMode} RequestInput={RequestInput} setRequestInput={setRequestInput} setPageMode={setPageMode}/>)}
        {PageMode === "request" && (<RequestPage requestTypetData={requestTypetData} customerTypetData={customerTypetData}  PageMode={PageMode} setPageMode={setPageMode} RequestInput={RequestInput} setRequestInput={setRequestInput}/>)}
        {/*   {PageMode === "request" && <ComplaintPage requesInitialstData={requesInitialstData} requestTypetData={requestTypetData} customerTypetData={customerTypetData} PageMode={PageMode} />}*/}
      </div>
    </>
  );
};

export default Request;
