import React, { useState } from 'react'
import ModeIcon from '@mui/icons-material/Mode'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
/*import Typography from '@mui/material/Typography'*/
import PersonAdd from '@mui/icons-material/PersonAdd'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import CachedIcon from '@mui/icons-material/Cached';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HistoryIcon from '@mui/icons-material/History';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReplyIcon from '@mui/icons-material/Reply';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SaveIcon from '@mui/icons-material/Save';

const Demo = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const set_spam = () => {
        alert("okay")
        document.getElementById("place_id").innerText = "New value"
    }

    return (
        <>
            <div>
                <div className="normal_float normal_text_align ">
                   {/*<Tooltip title='Back' placement='bottom' arrow>*/}
                   {/*     <IconButton>*/}
                   {/*         <EastOutlinedIcon color='inherit' fontSize='medium' onClick={set_spam}/>*/}
                   {/*     </IconButton>*/}
                   {/* </Tooltip>*/}
                    <Tooltip title='Back' placement='bottom' arrow>
                        <IconButton>
                            <ArrowBackIcon color='inherit' fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <span>Draft</span> <span id="place_id" className="separator_border">Self-driving cars</span>
                     
                </div>
                <div className="opposite_float opposite_text_align">                                    
                    <Tooltip title='Save' placement='bottom' arrow>
                        <IconButton>
                            <SaveIcon color='inherit' fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Search' placement='bottom' arrow>
                        <IconButton>
                            <SearchOutlinedIcon color='inherit' fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='add New' placement='bottom' arrow>
                        <IconButton>
                                <InsertDriveFileOutlinedIcon color='inherit' fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Share To' placement='bottom' arrow>
                        <IconButton>
                                <ReplyIcon color='inherit' fontSize='medium' />
                        </IconButton>
                    </Tooltip>                
                    <Tooltip title='Log History' placement='bottom' arrow>
                        <IconButton>
                                <HistoryIcon color='inherit' fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Close' placement='bottom' arrow>
                        <IconButton>
                                <HighlightOffIcon color='inherit' fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Refresh' placement='bottom' arrow>
                        <IconButton>
                                <CachedIcon color='inherit' fontSize='medium' />
                        </IconButton>
                        </Tooltip>                   
                    <Tooltip title='Edit' placement='bottom' arrow>
                        <IconButton>
                                <ModeOutlinedIcon color='inherit' fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Edit' placement='bottom' arrow>
                        <IconButton>
                                <ModeIcon color='inherit' fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Menu' placement='bottom' arrow>
                        <IconButton onClick={handleClick} aria-controls={open ? 'account-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined}>
                                <MoreVertIcon fontSize='medium' sx={{ cursor: 'pointer' }} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div style={{clear:"both"}}></div>
            </div>
            <Menu id='account-menu' anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}
                PaperProps={{                    
                    sx: {overflow: 'visible',filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {width: 32, height: 32, ml: -0.5, mr: 1,},
                        '&:before': {top: 0, right: 14, width: 10, height: 10, zIndex: 0, elevation: 0, content: '""', display: 'block', position: 'absolute', bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <Avatar /> Profile
                </MenuItem>
                <MenuItem>
                    <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem>
                    <ListItemIcon><PersonAdd fontSize='small' /></ListItemIcon>
                    Add another account
                </MenuItem>
                <MenuItem>
                    <ListItemIcon><Settings fontSize='small' /></ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem>
                    <ListItemIcon><Logout fontSize='small' /></ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    )
}

export default Demo