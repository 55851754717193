import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { HiOutlineHandThumbUp } from "react-icons/hi2";
import { HiOutlineHandThumbDown } from "react-icons/hi2";
import { FiUserPlus } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";
import { GoTrash } from "react-icons/go";

const PowerOfAttorny = () => {
  const { user } = useSelector((state) => state); //define redux

  const [countriesList, setCountriesList] = useState([]);
  const [decisionsList, setDecisionsList] = useState([]);
  const [pageMode, setPageMode] = useState("initial");
  const [clientMode, setClientMode] = useState("initial");
  const [clientDetails, setClientDetails] = useState();
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [isNameLoader, setNameLoader] = useState(false);
  const [decisionItemEditMode, setDecisionItemEditMode] = useState(false);
  const [poaDetails, setPoaDetails] = useState();
  const [serch, setSerch] = useState();
  const [poaItems, setPoaItems] = useState([]);
  const [pageLables, setPageLables] = useState([]);
  const [actions, setActions] = useState([]);
  const [country_area_cd, setCountryAreaCD] = useState(965);
  const [countries, setCountries] = useState(false);
  const [uploadprecentage, setUploadPercentage] = useState(0);
  const [pdfFileBase64, setPdfFileBase64] = useState("");
  const [pickedPDFFile, setPickedPDFFile] = useState("");

  useEffect(() => {
    get_poa_initial();
    getCountries();
    return () => {
      setNameSuggestions([]); // Clear the suggestions on unmount
    };
  }, []);

  /*file uploading -----------------------------*/
  const [fileUploading, setFileUploading] = useState(false);
  const filePickerRef = useRef(null);

  const getCountries = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
        },
        input: {
          actioncode: "COUNTRY_DAILCODES",
        },
      };

      console.log("get_counties ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/countries`,
        data,
      );
      console.log("get_counties output ", res.data.output.countries);

      const countryIndex = res.data.output.countries.findIndex(
        (country) => country.dial_cd === "965",
      );

      console.log("kuwait index", countryIndex);

      let options = res.data.output.countries.map((country) => {
        return {
          value: country.dial_cd,
          label: country.dial_cd,
          _id: country.country_cd,
          country_id: country.country_id,
          dial_cd: country.dial_cd,
        };
      });

      console.log("kuwait dailcd", options[countryIndex]);
      setCountries(options);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };
  const poa_code_search = (e) => {
    if (e.charCode === 13) {
      var code_str = document
        .getElementById("power_of_attorney_cd")
        .value.trim();
      if (code_str.length > 0) {
        get_poa_details();
      }
    }
  };

  const upload_poa = (pickedFile, code) => {
    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    let formData = new FormData();
    formData.append("file", pickedFile);
    formData.append("fname", "poa");
    formData.append("flocation", "poa");
    formData.append("company", userDataLocal.company_cd);
    formData.append("file_old_name", "");

    const options = {
      headers: { Authorization: `Bearer ${userDataLocal.token}` },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iipsys/v1/uploadFiles`,
        formData,
        options,
      )
      .then((res) => {
        if (res.data.result.rstatus === "1") {
          setUploadPercentage(0);
          setFileUploading(false);
          update_decision_url(res.data.file_url, code);
        } else {
          throw new Error("File not uploaded");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionWay");
            window.location.replace("/");
          }
        }

        setUploadPercentage(0);
        setFileUploading(false);
      });
  };

  const filePickedHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      let fileExttype = event.target.files[0].type.split("/")[0];
      let filExt = event.target.files[0].type.split("/")[1];
      console.log(fileExttype, filExt, event.target.files[0].type);
      if (filExt === "pdf") {
        pickedFile = event.target.files[0];
        const reader = new FileReader();
        setPickedPDFFile(pickedFile);
        reader.readAsDataURL(event.target.files[0]);
        reader.addEventListener("load", () => {
          setPdfFileBase64(reader.result);
        });
        setPoaDetails({});
        setPoaItems([]);
        setPageMode("Draft");
        setClientMode("Initial");
        //uploadDession(pickedFile);
      } else {
        alert("upload only pdf files");
        return;
      }
    }
  };

  const set_clear_mode = () => {
    setPdfFileBase64("");
    switch (pageMode) {
      case "Edit":
        setPageMode("view");
        break;

      case "view":
        setPoaDetails({});
        setPageMode("initial");
        setClientMode("initial");
        break;

      default:
    }
  };

  const code_search_keyEvent = (e) => {
    if (e.charCode === 13) {
      getClient();
    }
  };

  const phone_search_keyEvent = (e) => {
    if (e.charCode === 13) {
      getClient();
    }
  };

  /* Read -------------------------*/
  const get_poa_initial = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_poa_initial",
          system_cd: "Lcc",
          function_cd: "97",
          system_rout_cd: "19",
          branch_cd: user.userData.branch_cd, //from redux
          //branh_cd: userDataLocal.branvh_cd
        },
      };

      console.log("poa initials input", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_poa_initial`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("poa initials output", res);
      setPageLables(res.data.output.pages_lables);
      if (res.data.output.actions && res.data.output.actions.length > 0) {
        setActions(res.data.output.actions);
      }
      setPageMode("initial");
      setClientMode("initial");
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_poa_details = async (power_of_attorney_cd) => {
    setPoaDetails({});
    setPoaItems([]);

    var power_of_attorney_cd = document.getElementById(
      "power_of_attorney_cd",
    ).value;

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_power_of_arrorny_detals",
          power_of_attorney_cd: power_of_attorney_cd,
        },
      };

      console.log("get_poa_details input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_power_of_arrorny_detals`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_poa_details output ", res);
      if (res.data.output.powr_of_attorny) {
        setPoaDetails(res.data.output.powr_of_attorny);
        if (
          res.data.output.poa_clients &&
          res.data.output.poa_clients.length > 0
        ) {
          setPoaItems(res.data.output.poa_clients);
        }
        setPageMode("view");
        setClientMode("List");
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const getClient = async (clientName, clientCd) => {
    setClientDetails();
    var client_code = null;
    var phone_number = null;
    var client_name = null;

    if (!clientName && !clientCd) {
      if (document.getElementById("client_cd_s")) {
        client_code = document.getElementById("client_cd_s").value;
      }
      if (document.getElementById("phone_no_s")) {
        phone_number = document.getElementById("phone_no_s").value;
      }
      if (document.getElementById("client_name_s")) {
        client_name = document.getElementById("client_name_s").value;
      }
    }
    console.log("beofore", phone_number, client_code, client_name);
    if (!client_code && !phone_number && !client_name) {
      console.log("all null");
      client_name = clientName;
      client_code = clientCd;
    }
    console.log("after", phone_number, client_code, client_name);

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client",
          client_cd: client_code,
          country_area_cd: country_area_cd,
          phone_no: phone_number,
          client_name: client_name,
          system_cd: "lcc",
        },
      };
      console.log("getClient input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getClient output ", res);

      if (res.data.output.client.client_cd) {
        setClientDetails(res.data.output.client);
        setClientMode("view");
      } else {
        alert("no record found");
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  /* Updates ----------------*/
  const updat_poa = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_poa",
          power_of_attorney_cd: poaDetails.power_of_attorney_cd,
          power_of_attorney_start_date: poaDetails.power_of_attorney_start_date,
          power_of_attorney_end_date: poaDetails.power_of_attorney_end_date,
          title: poaDetails.title,
          poa_url: poaDetails.poa_url,
        },
      };
      console.log("update_poa input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_poa`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("update_poa output ", res);

      if (res.data.output.powr_of_attorny) {
        setPoaDetails(res.data.output.powr_of_attorny);
        setPageMode("view");
      }
      let url_name = upload_poa(
        pickedPDFFile,
        res.data.output.powr_of_attorny.power_of_attorney_cd,
      );
      console.log(url_name);
      //update_decision_url(url_name)
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const updat_poa_client = async (update_mode, client_code) => {
    /*check if client_cd exit then return*/

    if (update_mode === "insert") {
      const isClientCodePresent = poaItems.some(
        (item) => item.client_cd === client_code,
      );

      if (isClientCodePresent) {
        alert("Client already exists!");
        return;
      }
    }

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_poa_client",
          update_mode: update_mode,
          power_of_attorney_cd: poaDetails.power_of_attorney_cd,
          client_cd: client_code,
        },
      };
      console.log("updat_poa_client input ", data);
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_poa_client`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("update_poa output ", res);

      if (
        res.data.output.poa_clients &&
        res.data.output.poa_clients.length > 0
      ) {
        if (update_mode === "insert") {
          setClientMode("List");
          setNameSuggestions([]);
        }
        setPoaItems(res.data.output.poa_clients);
      }
      //update_decision_url(url_name)
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const update_decision_url = async (url_name, code) => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_poa_url",
          power_of_attorney_cd: code,
          attachment_url_address: url_name,
        },
      };
      console.log("update_decision_url input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_poa_url`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("update_decision_url output ", res);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const getClientSuggest = async (searchText) => {
    if (searchText.length <= 0) {
      setNameSuggestions([]);
      return;
    }
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client_search_suggestion",
          client_name: searchText,
          system_cd: "lcc",
        },
      };
      console.log("getClientSuggestions input ", data);
      setNameLoader(true);
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client_name_suggestions`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getClientSuggestions output ", res.data.output.clients);

      if (res.data.output.clients.length > 0) {
        setNameSuggestions(res.data.output.clients);
      } else {
        setNameSuggestions([]);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    } finally {
      setNameLoader(false);
    }
  };

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5  border-right">
        <div style={{ height: "calc(100vh - 100px)", overflow: "auto" }}>
          {/*Button bar ---------------------*/}
          <div>
            <div className="normal_float normal_text_align ">
              {pageMode !== "initial" && (
                <>
                  <Tooltip title="Back" placement="bottom" arrow>
                    <IconButton onClick={set_clear_mode}>
                      <ArrowBackIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <span>
                    {(pageMode === "Edit" || pageMode === "Draft") && pageMode}
                  </span>{" "}
                  <span className="separator_border">
                    {poaDetails && poaDetails.decision_no}
                  </span>
                </>
              )}
            </div>
            <div className="opposite_float opposite_text_align">
              {(pageMode === "Edit" || pageMode === "Draft") && (
                <>
                  <Tooltip title="Save" placement="bottom" arrow>
                    <IconButton onClick={updat_poa}>
                      <SaveIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {pageMode === "initial" && (
                <>
                  <Tooltip
                    title={actions.length > 0 && actions[1].tap_name}
                    placement="bottom"
                    arrow
                  >
                    <IconButton onClick={() => filePickerRef.current.click()}>
                      <InsertDriveFileOutlinedIcon
                        color="inherit"
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Search" placement="bottom" arrow>
                    <IconButton>
                      {" "}
                      <SearchOutlinedIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  {fileUploading && <span>Uploading ... </span>}
                  <input
                    ref={filePickerRef}
                    style={{ display: "none" }}
                    type="file"
                    onChange={filePickedHandler}
                    accept="application/pdf"
                  />
                </>
              )}
              {pageMode === "view" && (
                <>
                  <Tooltip title="Edit" placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        setPageMode("Edit");
                      }}
                    >
                      <ModeOutlinedIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
            <div style={{ clear: "both" }}></div>
          </div>

          {/*Power of attorny body ------------------*/}
          <div style={{ padding: "6px" }}>
            {pageMode === "initial" && pageLables.length > 0 && (
              <>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[0].description}
                  </label>
                  <input
                    id="power_of_attorney_cd"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    onKeyPress={poa_code_search}
                  />
                </div>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[7].description}
                  </label>
                  <input
                    id="Client_name"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                  />
                </div>
              </>
            )}
            {pageMode === "view" && pageLables.length > 0 && (
              <>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[0].description}
                  </label>
                  <div className="viewing_field">
                    {poaDetails && poaDetails.power_of_attorney_cd}
                  </div>
                </div>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[1].description}
                  </label>
                  <div className="viewing_field">
                    {poaDetails && poaDetails.power_of_attorney_start_date}
                  </div>
                </div>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[2].description}
                  </label>
                  <div className="viewing_field">
                    {poaDetails && poaDetails.power_of_attorney_end_date}
                  </div>
                </div>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[3].description}
                  </label>
                  <div className="viewing_field">
                    {poaDetails && poaDetails.title}
                  </div>
                </div>
              </>
            )}
            {(pageMode === "Draft" || pageMode === "Edit") &&
              pageLables.length > 0 && (
                <>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[0].description}
                    </label>
                    <div className="viewing_field">
                      {poaDetails &&
                        poaDetails.power_of_attorney_cd &&
                        poaDetails.power_of_attorney_cd}
                    </div>
                  </div>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[1].description}
                    </label>
                    <input
                      type="text"
                      className="editing_field"
                      autoComplete="0"
                      value={
                        poaDetails && poaDetails.power_of_attorney_start_date
                      }
                      onChange={(e) =>
                        setPoaDetails({
                          ...poaDetails,
                          power_of_attorney_start_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[2].description}
                    </label>
                    <input
                      type="text"
                      className="editing_field"
                      autoComplete="0"
                      value={
                        poaDetails && poaDetails.power_of_attorney_end_date
                      }
                      onChange={(e) =>
                        setPoaDetails({
                          ...poaDetails,
                          power_of_attorney_end_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="data_line" style={{ display: "flex" }}>
                    <label className="label_area">
                      {pageLables[3].description}
                    </label>
                    <input
                      type="text"
                      className="editing_field"
                      autoComplete="0"
                      value={poaDetails && poaDetails.title}
                      onChange={(e) =>
                        setPoaDetails({ ...poaDetails, title: e.target.value })
                      }
                    />
                  </div>
                </>
              )}

            {/*POA clients ------------------*/}
            {clientMode === "List" && pageLables.length > 0 && (
              <>
                <div
                  className="table_header_div"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    borderBottom: "solid 1px gray",
                    marginTop: "15px",
                  }}
                >
                  <div style={{ width: "15%" }}>
                    {pageLables[0].description}
                  </div>
                  <div style={{ width: "20%" }}>
                    {pageLables[5].description}
                  </div>
                  <div style={{ width: "60%" }}>
                    {pageLables[6].description}
                  </div>
                  <div style={{ width: "5%" }}>
                    <Tooltip
                      title={pageLables[8].description}
                      placement="bottom"
                      arrow
                    >
                      <IconButton
                        onClick={() => {
                          setClientMode("Inquery");
                        }}
                      >
                        <FiUserPlus color="inherit" fontSize="18" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                {poaItems.length > 0 &&
                  poaItems.map((poa_items, index) => (
                    <div className="row_area" key={poa_items.client_cd}>
                      <div className="table_line_div bottom_border_div">
                        <div style={{ width: "15%" }}>
                          {poa_items.client_cd}
                        </div>
                        <div style={{ width: "20%" }}>
                          {poa_items.client_id}
                        </div>
                        <div style={{ width: "60%" }}>
                          {poa_items.client_name}
                        </div>
                        <div style={{ width: "5%" }}>
                          <Tooltip
                            title={pageLables[8].description}
                            placement="bottom"
                            arrow
                          >
                            <IconButton
                              onClick={() => {
                                updat_poa_client("delete", poa_items.client_cd);
                              }}
                            >
                              <GoTrash color="inherit" fontSize="16" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
            {clientMode === "Inquery" && (
              <div>
                <div
                  className="table_header_div"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    borderBottom: "solid 2px gray",
                    marginTop: "15px",
                  }}
                >
                  <div style={{ width: "90%" }}>
                    {" "}
                    {pageLables[8].description}{" "}
                  </div>
                  <div>
                    <Tooltip title="Close" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          setClientMode("List");
                          setNameSuggestions([]);
                        }}
                      >
                        <IoMdClose color="inherit" fontSize="18" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[0].description}
                  </label>
                  <input
                    id="client_cd_s"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    onKeyPress={code_search_keyEvent}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label className="label_area">
                    {" "}
                    {pageLables[9].description}{" "}
                  </label>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      borderBottom: "1px solid gray",
                      width: "100%",
                    }}
                  >
                    {countries.length > 0 && (
                      <Select
                        options={countries}
                        defaultValue={countries[117]}
                        formatOptionLabel={(country) => (
                          <div className="country-option">
                            <img
                              src={`https://files.amanrow.com/flags/${country.country_id.toLowerCase()}.png`}
                              alt="country-image"
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {country.label}
                            </span>
                          </div>
                        )}
                        onChange={(e) => {
                          setCountryAreaCD(e.value);
                        }}
                      />
                    )}
                    <input
                      id="phone_no_s"
                      type="text"
                      className="editing_field"
                      style={{
                        border: "none",
                        outline: "none",
                        flex: 1,
                      }}
                      autoComplete="0"
                      onKeyPress={phone_search_keyEvent}
                    />
                  </div>
                </div>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[7].description}
                  </label>
                  <input
                    id="client_name_s"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    onChange={(e) => getClientSuggest(e.target.value)}
                  />
                </div>

                {isNameLoader && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span className="loader-spiner"></span>
                  </div>
                )}
                {nameSuggestions.length > 0 &&
                  nameSuggestions.map((client) => (
                    <div
                      key={client.client_cd}
                      style={{
                        width: "100%",
                        padding: "16px",
                        margin: "5px 0",
                        boxShadow: "25px 6px 46px 9px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        getClient(client.client_name, client.client_cd)
                      }
                    >
                      {client.client_name}
                    </div>
                  ))}
              </div>
            )}
            {clientMode === "view" && (
              <>
                <div
                  className="table_header_div"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    borderBottom: "solid 2px gray",
                    marginTop: "15px",
                  }}
                >
                  <div style={{ width: "90%" }}>
                    {pageLables[8].description}{" "}
                  </div>
                  <div style={{ display: "flex" }}>
                    <Tooltip title="Add client" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          if (clientDetails) {
                            console.log(clientDetails.client_cd);
                            updat_poa_client("insert", clientDetails.client_cd);
                          }
                        }}
                      >
                        <GiCheckMark color="inherit" fontSize="16" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Close" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          setClientMode("Inquery");
                          setNameSuggestions([]);
                        }}
                      >
                        <IoMdClose color="inherit" fontSize="18" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[0].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.client_cd}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[7].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.client_name}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[10].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.client_id}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[11].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.sex}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {" "}
                    {pageLables[9].description}{" "}
                  </label>
                  <div class="viewing_field">
                    <img
                      src={`https://files.amanrow.com/flags/${clientDetails.country_id.toLowerCase()}.png`}
                      alt="flag"
                      className="flag_icon"
                    />
                    {clientDetails &&
                      "  (" +
                        clientDetails.country_area_cd +
                        ")" +
                        "  " +
                        clientDetails.phone_no}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {pageLables[12].description}
                  </label>
                  <div class="viewing_field">
                    <img
                      src={`https://files.amanrow.com/flags/${clientDetails.country_id.toLowerCase()}.png`}
                      alt="flag"
                      className="flag_icon"
                    />
                    {clientDetails && " " + clientDetails.country_name}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/*attachment area ----------------------*/}
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
        <div style={{ height: "calc(100vh - 100px)" }}>
          {pageMode === "view" &&
            poaDetails &&
            poaDetails.attachment_url_address && (
              <div className="pdf-viewer">
                <object
                  data={poaDetails.attachment_url_address}
                  type="application/pdf"
                  className="pdf-viewer"
                >
                  <embed
                    src={poaDetails.attachment_url_address}
                    type="application/pdf"
                  />
                </object>
              </div>
            )}

          {pdfFileBase64 !== "" && (
            <div className="pdf-viewer">
              <object
                data={pdfFileBase64}
                type="application/pdf"
                className="pdf-viewer"
              >
                <embed src={pdfFileBase64} type="application/pdf" />
              </object>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PowerOfAttorny;
