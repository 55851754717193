import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CaseClient from "./CaseClient";
import CaseOpenent from "./CaseOpenent";
import CaseAssignedBy from "./CaseAcessBy";

const CommonSidebar = ({
  setShowSideBar,
  showCommonSidebar,
  commonSidebarComponenet,
  caseOpponent,
  accessBy,
}) => {
  //    useEffect(() => {
  //  if (setShowSideBar) {
  //    document.getElementById("commonsidebar").style.width = "25%";
  //  }
  //}, [setShowSideBar]);

  const closeNav = () => {
    /*    document.getElementById("commonsidebar").style.width = "0";*/
    setTimeout(() => {
      setShowSideBar(false);
    }, 300);
  };

  console.log("commonSidebarComponenet", commonSidebarComponenet);

  return (
    <>
      <div
        id="commonsidebar"
        className={`sidenav border-box-right-sidebar ${
          showCommonSidebar ? "commonSidebar" : "w-zero"
        }`}
        style={{ padding: "40px 10px 10px 10px" }}
      >
        {commonSidebarComponenet.componentName === "CaseClient" && (
          <CaseClient
            setShowSideBar={setShowSideBar}
            {...commonSidebarComponenet}
          />
        )}
        {commonSidebarComponenet.componentName === "CaseOpenent" && (
          <CaseOpenent
            setShowSideBar={setShowSideBar}
            {...commonSidebarComponenet}
            caseOpponent={caseOpponent}
          />
        )}
        {commonSidebarComponenet.componentName === "CaseAssignedBy" && (
          <CaseAssignedBy
            setShowSideBar={setShowSideBar}
            {...commonSidebarComponenet}
          />
        )}
      </div>
    </>
  );
};

export default CommonSidebar;
