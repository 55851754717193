    import React, { useEffect, useState } from "react";
    import axios from "axios";
    import { useDispatch } from "react-redux";

    import InvoiceItems from "../Invoice/Invoice";

    import RadioGroup from "@mui/material/RadioGroup";
    import FormControlLabel from "@mui/material/FormControlLabel";
    import Radio from "@mui/material/Radio";
    import FormControl from "@mui/material/FormControl";

    import Tooltip from "@mui/material/Tooltip";
    import IconButton from "@mui/material/IconButton";

    import ArrowBackIcon from "@mui/icons-material/ArrowBack";
    import SaveIcon from "@mui/icons-material/Save";
    import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
    import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
    import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
    import MoreVertIcon from "@mui/icons-material/MoreVert";
    import Menu from "@mui/material/Menu";
    import MenuItem from "@mui/material/MenuItem";
    import Avatar from "@mui/material/Avatar";
    import Divider from "@mui/material/Divider";
    import ListItemIcon from "@mui/material/ListItemIcon";
    import PersonAdd from "@mui/icons-material/PersonAdd";
    import Settings from "@mui/icons-material/Settings";
    import Logout from "@mui/icons-material/Logout";
    import { BsThreeDotsVertical } from "react-icons/bs";
    import { AiTwotoneCloseCircle } from "react-icons/ai";
    import { AiOutlineCloseCircle } from "react-icons/ai";


    import { useSelector } from "react-redux"; //to get the value from the redux

const InvoiceBody = ({parent,invoiceMode, setInvoiceMode, pageLables, invoiceYear, branchCd, invoiceCd, clienttData, setclienttData }) => {

    const [shipToType, setShipToType] = React.useState("female");
    
    const [requestTypetData, setrequestTypetData] = useState();
    const [customerTypetData, setCustomerTypetData] = useState();
    const [requesInitialstData, setRequesInitialstData] = useState();
    
    const [pageMode, setPageMode] = useState();
    const [invoicDetail, setInvoicDetail] = useState({});
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [itemDtail, setItemDetail] = useState([]);
    const [shipToData, setShipToData] = useState("buyer");
    const [tapActive, setTapActive] = useState("Items");
    const [itemCodeRow, setItemCodeRow] = useState();
    const [itemData, setItemData] = useState([]);
    const [shipTo, setShipTo] = useState({});
    const [optionMenue, setOptionMenue] = useState(false);

    const [media, setMedia] = useState({ mediaType: "", mediaURL: "" });
    const media_path = "http://files.amanrow.com/item_media/69/";

    const label = { inputProps: { "aria-label": "Checkbox demo" } };

   

    const { user } = useSelector((state) => {
        return state;
    });
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        
        if(invoiceMode ==="Retrieve"){
            getInvoice(invoiceYear, branchCd, invoiceCd)
            }else{
                setPageMode("Draft")
            }   
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange_ship_to_type = (event) => {
        setShipToData(event.target.value);
    };

    const set_clear_mode = () => {
        if (pageMode === "Edit") {
            setPageMode("View");
            document.getElementById("edit_status").innerText = "";
        }

        if (pageMode === "View" || pageMode === "Draft") {
            setclienttData();
            setInvoiceMode("Initial");
            document.getElementById("edit_status").innerText = "";
            document.getElementById("invoice_no").innerText = "";
        }
    };

    const set_edit_mode = () => {
        setPageMode("Edit");
    };

    const set_tab_active = (active_tab) => {
        setTapActive(active_tab);
    };

    const set_item_row = (item_cd, brach_cd) => {
        if (item_cd == itemCodeRow) {return}
        setItemCodeRow(item_cd);
        get_item_details(item_cd, brach_cd);
    };

    const set_new_mode = () => {
        setPageMode("Draft");
        setShipToData("buyer");
    };

    const mobilekeyEvent = (e) => {
        if (e.charCode === 13) {get_client_details(document.getElementById("country_cd").value, document.getElementById("client_mobile").value)}
    };

    /* Read*/

    const get_client_details = async (country_cd, phone_no) => {
    
    setclienttData();
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        /*let client_mobil_no = document.getElementById('client_mobile').value*/
        const data = {
            info: {
                lang: userDataLocal.lang,
                company: userDataLocal.company_cd,
                user_cd: userDataLocal.user_cd,
            },
            input: {
                actioncode: "get_client",
                client_cd:"0",
                country_area_cd: country_cd,
                mobile_no: phone_no,
            },
        };
        console.log("get_client_details input", data);
        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client`,
        data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
        );
        console.log("get_client_details output", res);
        if (res.data.output.client_details) {
            setclienttData(res.data.output.client_details);
        } else {
            alert("no data");
        }
        
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    const getInvoice = async (invoice_year, branch_cd, invoice_cd) => {
       
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_invoice",
                    invoice_year: invoice_year,
                    branch_cd: branch_cd,
                    invoice_cd: invoice_cd,
                },
            };
            console.log("getInvoice input", data);
            
            let res = await axios.post(
            `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_invoice`,
            data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getInvoice output", res);
            
         
            if(res.data.output.invoice_details){setInvoicDetail(res.data.output.invoice_details)}
            if(res.data.output.ship_to){
                setShipTo(res.data.output.ship_to)
            }else{
                setShipTo({})
            }
            if(res.data.output.invoice_items){
                setInvoiceItems(res.data.output.invoice_items)
            }else{
                setInvoiceItems({})
            }
           
            setPageMode("View")
            
           
            
           
        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const get_item_details = async (item_cd, branch_cd) => {
   
    try {

        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data =  {
            info: {
                /*"lang": userDataLocal.lang,*/
                lang: "عربي",
                company: userDataLocal.company_cd,
                user_cd: userDataLocal.user_cd,
            },
            input: {
                actioncode: "get_item_detail",
                item_cd: item_cd,
                branch_cd: branch_cd,
            },
        }


            console.log("get_item_detail input ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/get_item_detail`,
       
            data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
        );
        console.log("get_item_detail output ", res);

        if (res.data.output.item.item_cd) {
            if (res.data.output.item) {setItemDetail(res.data.output.item)}
            setItemData(res.data.output); //intilization of the values of the item object from database
        } else {
        alert("item is not exist");
        }
    } catch (err) {
        console.log(err);
        alert(err.message);
    }
    };

    var inActiveTap = "normal_float normal_text_align tap_head_area";
    var activeTap ="normal_float normal_text_align tap_head_area active_tap_head_area";
    var inActiveTapOposit = "opposite_float opposite_text_align tap_head_area";
    var activeTapOposit ="opposite_float opposite_text_align tap_head_area active_tap_head_area";

    return (
    <>
        <div claasName="page-inside-containe" style={{ marginLeft: "15px", martginRight: "10px" }}> 
            {/*Butomn bar*/}
            {parent ==="Invoice" && (
                <div>                   
                    <div className="normal_float">
                        {invoiceMode !== "Initial" && (
                            <>
                                <Tooltip title="Back" placement="bottom" arrow>
                                    <IconButton onClick={set_clear_mode}> <ArrowBackIcon color="inherit" fontSize="small" /> </IconButton>
                                </Tooltip>
                                <span>{invoiceMode}</span>{" "}
                                <span className="separator_border">
                            
                                    {invoicDetail && ("INV-" + invoicDetail.invoice_year + "-" + invoicDetail.branch_cd + "-" + invoicDetail.invoice_cd)}
                                </span>
                            </>
                        )}
                    </div>
                    <div className="opposite_float opposite_text_align">
                        {(pageMode === "Edit" || pageMode === "Draft") && (
                            <>
                            <Tooltip title="Save" placement="bottom" arrow>
                                <IconButton onClick={set_clear_mode}> <SaveIcon color="inherit" fontSize="small" /> </IconButton>
                            </Tooltip>
                            </>
                        )}
                        {pageMode === "Initial" && (
                            <>
                            <Tooltip title="add New" placement="bottom" arrow>
                                <IconButton onClick={() => { set_new_mode() }}> <InsertDriveFileOutlinedIcon color="inherit" fontSize="small" /> </IconButton>
                            </Tooltip>
                            <Tooltip title="Search" placement="bottom" arrow>
                                <IconButton> <SearchOutlinedIcon color="inherit" fontSize="small" /> </IconButton>
                            </Tooltip>
                            </>
                        )}
                        {pageMode === "View" && (
                        <>
                            <Tooltip title="Edit" placement="bottom" arrow>
                                <IconButton onClick={() => { set_edit_mode() }} > <ModeOutlinedIcon color="inherit" fontSize="small" /> </IconButton>
                            </Tooltip>
                            <Tooltip title="Menu" placement="bottom" arrow>
                                <IconButton onClick={handleClick} aria-controls={open ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined}> <MoreVertIcon fontSize="small" sx={{ cursor: "pointer" }} /> </IconButton>
                            </Tooltip>
                        </>
                    )}
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
            )}
            <Menu id="account-menu" anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}
                PaperProps={{ sx: { overflow: "visible", filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", mt: 1.5,
                    "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1, },
                    "&:before": { top: 0, right: 14, width: 10, height: 10, zIndex: 0, elevation: 0, content: '""', display: "block", position: "absolute", bgcolor: "background.paper", transform: "translateY(-50%) rotate(45deg)", },
                },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem> <Avatar /> Profile </MenuItem>
                <MenuItem> <Avatar /> My account </MenuItem>
                <Divider />
                <MenuItem> <ListItemIcon> <PersonAdd fontSize="small" /> </ListItemIcon> Add another account </MenuItem>
                <MenuItem> <ListItemIcon> <Settings fontSize="small" /> </ListItemIcon> Settings </MenuItem>
                <MenuItem>
                <ListItemIcon><Logout fontSize="small" /></ListItemIcon>Logout</MenuItem>
            </Menu>
            <div style={{height: "calc(100vh - 150px)", marginRight: "0 !important", marginTop: "10px"}}>
                {/*Invoic header*/}
                <div style={{ marginBottom: "6px" }}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div style={{display:"flex", alignItems:"center", borderBottom: "solid 1px gray", height:"40px"}}>
                                    <div style={{fontWeight: "bold"}}>{pageLables && pageLables[2].description}</div>
                                        {pageMode === "Draft" && (
                                            <div style={{display:"flex", alignItems:"center", margin:"0px 6px"}}>
                                                <input id="country_cd" type="text" className="editing_field" style={{ outline: "none", width:"40px"}} value="965"/>
                                                <input id="client_mobile" type="text" className="editing_field" style={{ outline: "none", width:"auto" }} autoComplete="0" placeholder={pageLables && pageLables[0].description} onKeyPress={mobilekeyEvent} />
                                            </div>
                                        )}
                                </div>
                                {clienttData && (
                                    <div>
                                        <div style={{fontWeight:"600"}}> {clienttData.client_name} </div>
                                        <div>{"+" + clienttData.country_area_cd + " " + clienttData.phone_no}</div>
                                        <div>{clienttData.email_address}</div>             
                                    </div>
                                )}
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div style={{borderBottom: "solid 1px gray"}}>
                                    <div>
                                        <div className="normal_float" style={{display:"flex", alignItems:"center", height:"40px", fontWeight: "bold"}}>{pageLables && pageLables[3].description}</div>
                                        <div className="opposite_float"  style={{display:"flex", alignItems:"center", height:"40px"}}>
                                            {optionMenue &&
                                                <div className="branch_menu" style={{paddingTop:"0px"}}>
                                                    <div style={{textAlign:"right"}}><AiOutlineCloseCircle color="inherit" fontSize="16"  onClick={() => {setOptionMenue(false) }}/></div>
                                                        <div>{pageLables && pageLables[4].description}</div>
                                                        <div>{pageLables && pageLables[5].description}</div>
                                                </div>   
                                            }
                                            <Tooltip title={pageLables && pageLables[6].description} placement="bottom" arrow>
                                                <IconButton onClick={() => {setOptionMenue(true) }}> <BsThreeDotsVertical color="inherit" fontSize="14"/> </IconButton>
                                            </Tooltip>    
                                            
                                        </div>
                                        <div style={{ clear: "both" }}></div>
                                    </div>
                                </div>
                                <div style={{lineHeight: "20px"}}>
                                    {(pageMode === "Edit" || pageMode === "Draft") && clienttData && (
                                        <>
                                            {clienttData && (
                                                <div>
                                                    <div style={{fontWeight:"600"}}> {clienttData.client_name} </div>
                                                    <div>{"+ " + clienttData.country_area_cd + " " + clienttData.phone_no}</div>
                                                    <div id="example-collapse-text" className="field_text_alighn">
                                                        <div>{"+" + clienttData.country_area_cd + " " + clienttData.phone_no}</div> 
                                                        <br /> {clienttData.email_address} <br />
                                                        {clienttData.country_name + ", " + clienttData.state_name + ", " + clienttData.city_name}
                                                        <br />
                                                    {clienttData.client_address}
                                                    </div>
                                                </div>
                                            )}
                                            <FormControl>
                                                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={shipToData} onChange={handleChange_ship_to_type}>
                                                    <FormControlLabel value="buyer" control={<Radio />} label="To the buyer" />
                                                    <FormControlLabel value="third party" control={<Radio />} label="To third party" />
                                                </RadioGroup>
                                            </FormControl>

                                            {shipToData === "third party" && (
                                                <table className="table-verticle-middle table-frm table-frm-ed">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "30%" }} />
                                                            <th style={{ width: "70%" }} />
                                                        </tr>
                                                        <tr>
                                                            <th>Ship To</th>
                                                            <td> <input id="client_name" type="text" className="form-control input-sm " /> </td>
                                                        </tr>
                                                        <tr>
                                                            <th>contact No.</th>
                                                            <td> <input id="mobil_no" type="text" className="form-control input-sm " /> </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Email</th>
                                                            <td> <input id="email_address" type="text" className="form-control input-sm " /></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Country</th>
                                                            <td> <input id="country_cd" type="text" className="form-control input-sm " /> </td>
                                                        </tr>
                                                        <tr>
                                                            <th>State</th>
                                                            <td> <input id="state_cd" type="text" className="form-control input-sm " /></td>
                                                        </tr>
                                                        <tr>
                                                            <th>City</th>
                                                            <td> <input id="city_name" type="text" className="form-control input-sm " /></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Email</th>
                                                            <td> <input id="email_address" type="text" className="form-control input-sm " /></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Address</th>
                                                            <td> <input id="email_address" type="text" className="form-control input-sm " /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        </>
                                    )}
                                    {pageMode === "View" && (
                                        <>
                                            {shipTo && shipTo.client_name && (
                                                <>
                                                    <div style={{fontWeight:"600"}}> {shipTo && shipTo.client_name}</div>
                                                    <div>{"+" + shipTo.country_area_cd + " " + shipTo.mobil_no}</div>
                                                    <div>{shipTo.email_address}</div>
                                                    <div>{shipTo.country_name + ", " + shipTo.state_name + ", " + shipTo.city_name}</div>
                                                    <div>{shipTo.client_address}</div>
                                                </>
                                            )}
                                            {!shipTo.client_name && (
                                                <>
                                                    {clienttData && clienttData.country_name && (
                                                        <>
                                                            <div>{clienttData.country_name + ", " + clienttData.state_name + ", " + clienttData.city_name}</div>
                                                            <div>{clienttData.client_address}</div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                {clienttData && (
                                    <table className="table-verticle-middle table-frm table-frm-ed" style={{marginTop:"40px"}}>
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "30%" }} />
                                                <th style={{ width: "32%" }} />
                                                <th style={{ width: "6%" }} />
                                                <th style={{ width: "32%" }} />
                                            </tr>
                                            <tr>
                                                <th>{pageLables && pageLables[7].description}</th>
                                                <td colSpan={3}>
                                                    {pageMode === "Draft" && (
                                                        <div className="input-icon right">
                                                            <i id="case_date_btn" className="fa fa-calendar" />
                                                            <input id="invoice_date" type="text" className="form-control input-sm mcalender" placeholder="DD-MM-YYYY" />
                                                        </div>
                                                    )}
                                                    {pageMode === "Edit" && (
                                                        <div className="input-icon right">
                                                        <i id="case_date_btn" className="fa fa-calendar"/>
                                                        <input id="invoice_date" type="text" className="form-control input-sm mcalender" placeholder="DD-MM-YYYY"
                                                            value={ invoicDetail.invoice_date && invoicDetail.invoice_details.invoice_date} />
                                                        </div>
                                                    )}
                                                    {pageMode === "View" && (
                                                        <div id="invoice_date" className="field_text_alighn">
                                                            {invoicDetail.invoice_date && invoicDetail.invoice_date}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{pageLables && pageLables[8].description}</th>
                                                {pageMode === "Draft" && (
                                                <>
                                                    <td>
                                                        <select id="invoice_due" className="form-control input-sm " style={{ outline: "none" }} autoComplete="off">
                                                            <option value={1}>{pageLables && pageLables[9].description}</option>
                                                            <option value={2}>{pageLables && pageLables[10].description}</option>
                                                        </select>
                                                    </td>
                                                    <td>Or</td>
                                                    <td><input id="due_date" type="text" className="form-control input-sm mcalender" placeholder="DD-MM-YYYY"/> </td>
                                                </>
                                                )}
                                                {pageMode === "Edit" && (
                                                    <td colSpan="3">
                                                        <input id="due_date" type="text"className="form-control input-sm mcalender"placeholder="DD-MM-YYYY"
                                                        value={ invoicDetail.invoice_date && invoicDetail.invoice_date}
                                                        />
                                                    </td>
                                                )}
                                                {pageMode === "View" /*replace with due_date*/ && (
                                                    <td colSpan="3">
                                                        <div id="invoice_date"className="field_text_alighn">
                                                            {invoicDetail.invoice_date && invoicDetail.due_date}
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                {/*Invoic items*/}
                <div style={{ borderBottom: "solid 1px #ccc" }}>
                {/*<div className={tapActive === "Items" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Items", 2); }} >{taps && taps.length > 0 && taps[1].tap_name}</div>*/}
                    <div className={tapActive === "Items" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Items", 2); }} >{pageLables && pageLables[12].description}</div>
                    <div className={tapActive === "Details" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Details", 3); }}>{pageLables && pageLables[13].description}</div>
                    <div className={tapActive === "specifications" ? activeTap : inActiveTap} onClick={() => { set_tab_active("specifications", 4); }}>{pageLables && pageLables[14].description}</div>
                    <div className={tapActive === "Descount" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Descount", 5); }}>{pageLables && pageLables[15].description}</div>
                    <div className={tapActive === "Item In Stores" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Item In Stores", 6); }}>{pageLables && pageLables[16].description}</div>
                    <div className={tapActive === "Media" ? activeTap : inActiveTap} onClick={() => { set_tab_active("Media", 7); }}>{pageLables && pageLables[17].description}</div>
                    <div style={{ clear: "both" }}></div>
                </div>  
                {invoicDetail && (
                    <>
                        {tapActive === "Items" && (
                            <>
                                {invoiceItems && (
                                    <div>
                                        <table className="table table-hover" style={{ borderBottom: "solid 1px #ccc" }}>
                                            <thead>
                                                <tr>
                                                    <th className="normal_text_align"style={{ width: "10%" }}>{pageLables && pageLables[18].description}</th>
                                                    <th className="normal_text_align" style={{ width: "28%" }}>{pageLables && pageLables[19].description}</th>
                                                    <th className="normal_text_align" style={{ width: "12%" }}>{pageLables && pageLables[20].description}</th>
                                                    <th className="center_alighn" style={{ width: "10%" }}>{pageLables && pageLables[21].description}</th>
                                                    <th className="center_alighn" style={{ width: "10%" }}>{pageLables && pageLables[22].description}</th>
                                                    <th className="center_alighn" style={{ width: "10%" }}>{pageLables && pageLables[23].description}</th>
                                                    <th className="center_alighn" style={{ width: "10%" }}>{pageLables && pageLables[24].description}</th>
                                                    <th  className="mony_alighn" style={{ width: "10%" }}>{pageLables && pageLables[25].description}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceItems.length >= 0 && invoiceItems.map( (items, index) => (
                                                    <tr>
                                                        <td className="normal_text_align">{items.item_cd}</td>
                                                        <td className="normal_text_align">{items.item_name}</td>
                                                        <td className="normal_text_align">{items.unit_name}</td>
                                                        <td className="center_alighn">{items.unit_price}</td>
                                                        <td className="center_alighn">{items.quantity}</td>
                                                        <td className="center_alighn">{items.item_tax_amount}</td>
                                                        <td className="center_alighn">{items.item_discount_amount}</td>
                                                        <td className="mony_alighn">{items.quantity * items.unit_price}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </>
                        )}
                        {tapActive === "Details" && (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                        <div>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className="normal_text_align" style={{ width: "25%" }}>{pageLables && pageLables[18].description}</th>
                                                        <th className="normal_text_align" style={{ width: "75%" }}>{pageLables && pageLables[19].description}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {invoiceItems.length >= 0 && invoiceItems.map( (items, index) => (
                                                        <tr onClick={() => {set_item_row(items.item_cd, invoicDetail.branch_cd)}}>
                                                            <td className="normal_text_align">{items.item_cd}</td>
                                                            <td className="normal_text_align">{items.item_name}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-7"> 
                                        {itemDtail && itemDtail.item_name && (
                                        <>
                                            <div style={{display:"flex",alignItems:"center", fontWeight:"bold", fontSize:"16px", backgroundColor:"#f3f4f6", padding:"6px 12px"}}>{itemDtail.item_name}</div>
                                            <div style={{ borderLeft: "solid 1px #ccc", padding: "0px 10px"}}>
                                                    
                                                <table className="table-verticle-middle table-frm table-frm-ed">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "25%" }} />
                                                            <th style={{ width: "20%" }} />
                                                            <th style={{ width: "20%" }} />
                                                            <th style={{ width: "35%" }} />
                                                        </tr>
                                                        <tr>
                                                            <th id="item_group_l">{pageLables && pageLables[26].description}</th>
                                                            <td colSpan={3}>
                                                                <div id="item_category_cd_v" className="form-control input-sm view_field field_text_alighn ">{itemDtail.item_group_name}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th id="brand_cd_l">{pageLables && pageLables[27].description}</th>
                                                            <td colSpan={3}>
                                                                <div id="brand_cd_v" className="form-control input-sm view_field field_text_alighn ">{itemDtail.brand_name}</div></td>
                                                        </tr>
                                                            
                                                        <tr>
                                                            <th id="unit_cd_l">{pageLables && pageLables[20].description}</th>
                                                            <td colSpan={3}>
                                                                <div id="unit_cd_v" className="form-control input-sm view_field field_text_alighn ">{itemDtail.unit_name}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th id="unit_price_l">{pageLables && pageLables[21].description}</th>
                                                            <td colSpan={3}>
                                                                <div id="unit_price_v" className="form-control input-sm view_field field_text_alighn ">{itemDtail.unit_price}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th id="selling_price_l">{pageLables && pageLables[28].description}</th>
                                                            <td colSpan={3}>
                                                                <div id="selling_price_v" className="form-control input-sm view_field field_text_alighn ">{itemDtail.selling_price}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th id="tax_rate_l">{pageLables && pageLables[29].description}</th>
                                                            <td colSpan={3}>
                                                                <div id="tax_rate_v" className="form-control input-sm view_field field_text_alighn ">{itemDtail.tax_rate}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th id="item_status_cd_l">{pageLables && pageLables[30].description}</th>
                                                            <td colSpan={3}>
                                                                <div className="form-control input-sm view_field field_text_alighn ">{itemDtail.item_status}</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    )}
                                    </div>
                                </div>
                            </>
                        )}
                        {tapActive === "Item In Stores" && (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th className="normal_text_align" style={{ width: "25%" }}>Code</th>
                                                    <th className="normal_text_align" style={{ width: "75%" }}>item Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceItems.length >= 0 && invoiceItems.map( (items, index) => (
                                                    <tr onClick={() => {set_item_row(items.item_cd, invoicDetail.branch_cd) }} >
                                                        <td className="normal_text_align">{items.item_cd}</td>
                                                        <td className="normal_text_align">{items.item_name}</td>
                                                    </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-7">
                                        {itemData.item_in_store && (
                                            <div>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "35%" }}>{pageLables && pageLables[31].description}</th>
                                                        <th style={{ width: "13%" }}>{pageLables && pageLables[32].description}</th>
                                                        <th style={{ width: "12%" }}>{pageLables && pageLables[33].description}</th>
                                                        <th style={{ width: "13%" }}>{pageLables && pageLables[34].description}</th>
                                                        <th style={{ width: "12%" }}>{pageLables && pageLables[22].description}</th>
                                                        <th style={{ width: "15%" }}>{pageLables && pageLables[20].description}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {itemData.item_in_store.length >= 0 && itemData.item_in_store.map((inStore, index) => (
                                                        <tr>
                                                            <td>{inStore.branch_name}</td>
                                                            <td>{inStore.shipment_date}</td>
                                                            <td>{inStore.store_shelf_no}</td>
                                                            <td>{inStore.expire_date}</td>
                                                            <td>{inStore.quantity}</td>
                                                            <td>{inStore.unit_name}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {tapActive === "Media" && (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                        <div>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className="normal_text_align"style={{ width: "25%" }}>Code</th>
                                                        <th className="normal_text_align" style={{ width: "75%" }}>item Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {invoiceItems.length >= 0 && invoiceItems.map( (items, index) => (
                                                        <tr onClick={() => {set_item_row( items.item_cd, invoicDetail.branch_cd) }}>
                                                            <td className="normal_text_align">{items.item_cd}</td>
                                                            <td className="normal_text_align">{items.item_name}</td>
                                                        </tr>
                                                        ),
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-7">
                                        {itemData && itemData.item_videos && (
                                            <div style={{ pading: "10px", marginTop: "15px" }} >
                                                <div className="main_media_space">
                                                    {media.mediaType === "video" && (<video src={media.mediaURL} controls /> )}
                                                    {media.mediaType === "image" && (<img src={media.mediaURL} className="media_image" alt=""/>)}</div>
                                                <div>
                                                    {itemData.item_videos.length >= 0 && itemData.item_videos.map((videos, index) => {
                                                        return (
                                                            <div className="media_thumnails_div normal_float">
                                                                <img src={ media_path + "video/thumb_" + videos.video_link.split( ".", )[0] + ".png" } className="media_image" onClick={() => { setMedia({ mediaType: "video", mediaURL: `${media_path}/video/${videos.video_link}`,}) }} alt=""/>
                                                            </div>
                                                        );
                                                    })}
                                                    {itemData.item_images.length >= 0 && itemData.item_images.map((images, index) => {
                                                        return (
                                                            <div className="media_thumnails_div normal_float">
                                                                <img src={media_path + "images/" + images.image_link} className="media_image" onClick={() => {setMedia({ mediaType: "image", mediaURL: `${media_path}/images/${images.image_link}`,})}}  alt=""/>
                                                            </div>
                                                        );
                                                        },
                                                    )}
                                                    <div style={{ clear: "both" }}></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}               
            </div>
        </div>
    </>
    );
};

export default InvoiceBody;
