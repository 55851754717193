import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import SaveIcon from "@mui/icons-material/Save";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BsTrash3 } from "react-icons/bs";
import DatePickerCom from "../../utils/DatePickerCom";
import { RiPlayListAddFill } from "react-icons/ri";

import { Link } from "react-router-dom";
import ImageCard from "../cards/ImageCard";

const CaseProgress = ({
  case_cd,
  caseClients,
  courtLevels,
  clientAs,
  courtHearings,
  setCourtHearings,
  pageLables,
  caseLevels,
  courtsNames,
  judgements,
  caseStatus,
  states,
  officeAttornyTeam,
}) => {
  const [progressMode, setProgressMode] = useState("List");
  const [caseProgressDetails, setCaseProgressDetails] = useState({});
  const [clientAttachments, setClientAttachments] = useState([]);
  const [powerOfAttornyAttachments, setPowerOfAttornyAttachments] = useState(
    [],
  );
  const [caseProgresRow, setCaseProgresRow] = useState("0");

  useEffect(() => {
    console.log("Client as = ", clientAs);
  }, [caseProgressDetails]);

  const set_edit_mode = () => {
    if (caseClients.length === 0) {
      alert("no clients");
      return;
    }
    setCaseProgressDetails({ level_cd: caseLevels[0].level_cd });
    setProgressMode("Draft");
  };

  const set_clear_mode = () => {
    switch (progressMode) {
      case "Edit":
        setProgressMode("View");
        break;
      case "View":
      case "Draft":
        //clear_data()
        setProgressMode("List");
        break;
      default:
      // code block
    }
  };

  const addAttachment = () => {
    //
  };

  // read ---------------------
  const get_session_hearing_details = async (
    case_cd,
    level_cd,
    ser_no,
    index,
  ) => {
    setCaseProgresRow(index);
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_court_session_details",
          case_cd: case_cd,
          /*   level_cd: level_cd,*/
          ser_no: ser_no,
        },
      };
      console.log("get_session_hearing_details input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_court_session_details`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_session_hearing_details output ", res);

      if (res.data.output.session_details) {
        console.log(
          res.data.output.session_details,
          "res.data.output.session_details",
        );
        setCaseProgressDetails(res.data.output.session_details);
        if (res.data.output.client_attachments) {
          setClientAttachments(res.data.output.client_attachments);
        }
        if (res.data.output.attorneies_attachments) {
          setPowerOfAttornyAttachments(res.data.output.attorneies_attachments);
        }
        setProgressMode("View");
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  //Update ----
  const updat_case_progress = async (update_function) => {
    try {
      if (caseProgressDetails.representative_id === "00") {
        alert("Select Representative");
        return;
      }
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      console.log(
        caseProgressDetails.court_date,
        "console.log(caseProgressDetails.court_date)",
      );
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },

        input: {
          actioncode: "update_case_progress",
          update_function: update_function,
          case_cd: case_cd,
          level_cd: caseProgressDetails.level_cd,
          ser_no: caseProgressDetails.ser_no,
          court_date: caseProgressDetails.court_date,
          court_time: caseProgressDetails.court_time,
          level_type_cd: caseProgressDetails.level_type_cd,
          state_cd: caseProgressDetails.state_cd,
          state_name: caseProgressDetails.state_name,
          court_cd: caseProgressDetails.court_cd,
          court_name: caseProgressDetails.court_name,
          section_no: caseProgressDetails.section_no,
          floor_no: caseProgressDetails.floor_no,
          room_no: caseProgressDetails.room_no,
          judgement_cd: caseProgressDetails.judgement_cd,
          comment: caseProgressDetails.comment,
          representative_id: caseProgressDetails.representative_id,
        },
      };

      //console.log("updat progress input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_case_progress`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("updat Case progress output ", res.data.output);
      if (update_function === "delete") {
        if (res.data.output.rows_updated === "0") {
          alert("something went wrong");
        }
        setCaseProgressDetails({});
        setProgressMode("List");
      } else {
        if (res.data.output.rows_updated !== "1") {
          alert("something went wrong");
        } else {
          setCaseProgressDetails(res.data.output.get_single_progress);
          setProgressMode("View");
        }
      }
      if (res.data.output.get_case_progress) {
        setCourtHearings(res.data.output.get_case_progress);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  return (
    <>
      {/*Button bar ---------------------*/}
      <div>
        <div className="normal_float normal_text_align ">
          {progressMode !== "List" && (
            <>
              <Tooltip title="Back" placement="bottom" arrow>
                <IconButton onClick={set_clear_mode}>
                  <ArrowBackIcon color="inherit" fontSize="small" />
                </IconButton>
              </Tooltip>
              {progressMode !== "Draft" && (
                <>
                  <span className="separator_border">
                    {/*{caseDetails && caseDetails.case_cd}{' - ' + caseStatus.find((el) => el.code === caseLevels[0].case_status_cd).description}*/}
                  </span>
                </>
              )}
            </>
          )}
        </div>
        <div className="opposite_float opposite_text_align">
          {(progressMode === "Edit" || progressMode === "Draft") && (
            <>
              {caseProgresRow == "0" && (
                <>
                  <Tooltip title="Save" placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        updat_case_progress("update");
                      }}
                    >
                      <SaveIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        updat_case_progress("delete");
                      }}
                    >
                      <BsTrash3 color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
          {progressMode === "List" && (
            <>
              <Tooltip title="add New court hearring" placement="bottom" arrow>
                <IconButton
                  onClick={() => {
                    set_edit_mode();
                  }}
                >
                  <RiPlayListAddFill color="inherit" size="20" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {progressMode === "View" && (
            <>
              {caseProgresRow == "0" && (
                <>
                  <Tooltip title="Edit" placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        setProgressMode("Edit");
                      }}
                    >
                      <ModeOutlinedIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </div>
        <div style={{ clear: "both" }}></div>
      </div>
      {/*brogress area ---------------------*/}
      <div
        style={{
          height: "calc(100vh - 173px)",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <div style={{ padding: "0px 6px" }}>
          {progressMode === "List" && (
            <>
              {caseLevels.map((levels, index) => {
                return (
                  <>
                    {courtHearings.length > 0 && (
                      <>
                        {/*case level Header*/}
                        <div
                          style={{
                            display: "flex",
                            lineHeight: "30px",
                            marginBottom: "4px",
                          }}
                          key={levels.level_cd}
                        >
                          <div style={{ fontSize: "16px", fontWeight: "600" }}>
                            {
                              courtLevels.find(
                                (el) => el.code === levels.level_cd,
                              ).description
                            }
                            {": "}
                          </div>
                          <div style={{ margin: "0px 4px", color: "#686868" }}>
                            {levels.case_no}
                          </div>
                          <div style={{ margin: "0px 4px", color: "#686868" }}>
                            {" - " + clientAs &&
                              clientAs.length > 0 &&
                              clientAs.find(
                                (el) => el.code === levels.client_as,
                              ).description}
                          </div>
                          <div style={{ margin: "0px 4px", color: "#686868" }}>
                            {" - " +
                              caseStatus.find(
                                (el) => el.code === levels.case_status_cd,
                              ).description}
                          </div>
                        </div>
                        {/*case court hearing dtailes Header*/}
                        <div
                          className="table_header_div top_border_div table_header_background"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "15%" }}>
                            {pageLables[11].description}
                          </div>
                          <div style={{ width: "50%" }}>
                            {pageLables[20].description}
                          </div>
                          <div style={{ width: "35%" }}>
                            {pageLables[21].description}
                          </div>
                        </div>
                        {courtHearings.length > 0 &&
                          courtHearings.map((court_hearings, index) => {
                            if (
                              courtLevels.find(
                                (el) => el.code === levels.level_cd,
                              ).code === court_hearings.level_cd
                            )
                              return (
                                <div
                                  className="row_area"
                                  key={court_hearings.ser}
                                  onClick={() => {
                                    get_session_hearing_details(
                                      court_hearings.case_cd,
                                      court_hearings.level_cd,
                                      court_hearings.ser_no,
                                      index,
                                    );
                                  }}
                                >
                                  <div className="table_line_div bottom_border_div">
                                    <div style={{ width: "15%" }}>
                                      {court_hearings.court_date}
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      {court_hearings.court_name}
                                    </div>
                                    <div style={{ width: "35%" }}>
                                      {court_hearings.judgement_cd &&
                                        judgements.find(
                                          (el) =>
                                            el.code ===
                                            court_hearings.judgement_cd,
                                        ).description}
                                    </div>
                                  </div>
                                </div>
                              );
                          })}
                        <div style={{ marginTop: "14px" }}></div>
                      </>
                    )}
                  </>
                );
              })}
            </>
          )}
          {(progressMode === "Draft" || progressMode === "Edit") && (
            <>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[12].description}
                  </label>
                  <div className="viewing_field">
                    {
                      courtLevels.find(
                        (el) => el.code === caseLevels[0].level_cd,
                      ).description
                    }
                  </div>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[22].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={
                      caseProgressDetails && caseProgressDetails.state_name
                    }
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        state_name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[20].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={
                      caseProgressDetails && caseProgressDetails.court_name
                    }
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        court_name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[23].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={
                      caseProgressDetails && caseProgressDetails.section_no
                    }
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        section_no: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[25].description}
                  </label>
                  <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={caseProgressDetails && caseProgressDetails.floor_no}
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        floor_no: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[26].description}
                  </label>
                  <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={caseProgressDetails && caseProgressDetails.room_no}
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        room_no: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[11].description}
                  </label>
                  {/* <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={
                      caseProgressDetails && caseProgressDetails.court_date
                    }
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        court_date: e.target.value,
                      })
                    }
                  /> */}
                  <DatePickerCom
                    caseProgressDetails={caseProgressDetails}
                    setCaseProgressDetails={setCaseProgressDetails}
                    date={
                      caseProgressDetails.court_date
                        ? caseProgressDetails.court_date
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[24].description}
                  </label>
                  <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={
                      caseProgressDetails && caseProgressDetails.court_time
                    }
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        court_time: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[29].description}
                  </label>

                  {console.log(caseProgressDetails, "caseProgressDetails")}
                  {/* <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={caseProgressDetails && caseProgressDetails.staff_id}
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        staff_id: e.target.value,
                      })
                    }
                  /> */}

                  <select
                    style={{
                      border: "none",
                      background: "none",
                      borderBottom: "1px solid gray",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        representative_id: e.target.value,
                      });
                    }}
                    defaultValue={
                      caseProgressDetails.representative_id &&
                      caseProgressDetails.representative_id
                    }
                  >
                    <option value="00">Select representative</option>
                    {officeAttornyTeam.length > 0 &&
                      officeAttornyTeam.map((officeAttorny) => {
                        return (
                          <option value={officeAttorny.user_cd}>
                            {officeAttorny.full_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[30].description}
                  </label>
                  <div
                    className="normal_float"
                    style={{ display: "flex", height: "30px" }}
                  >
                    {/*<div style={{ padding: "4px" }}>{newattchlist.attactment_type}</div>*/}
                    <div style={{ padding: "4px" }}>
                      {pageLables[30].description}
                    </div>
                  </div>
                </div>
              </div>
              {/*this is only after the hearing date */}
              {caseProgressDetails.court_date &&
                new Date(caseProgressDetails.court_date) <= new Date() && (
                  <>
                    <div
                      className="row data_line"
                      style={{ marginTop: "16px" }}
                    >
                      <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        style={{ display: "flex" }}
                      >
                        <label className="label_area">
                          {pageLables[21].description}
                        </label>
                        <select
                          className="editing_field"
                          style={{ outline: "0" }}
                          value={
                            caseProgressDetails &&
                            caseProgressDetails.judgement_cd
                          }
                          onChange={(e) =>
                            setCaseProgressDetails({
                              ...caseProgressDetails,
                              judgement_cd: e.target.value,
                            })
                          }
                        >
                          <option value="0"></option>
                          {judgements.length >= 0 &&
                            judgements.map((Judgment_name, index) => {
                              return (
                                <option key={index} value={Judgment_name.code}>
                                  {Judgment_name.description}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="row data_line">
                      <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        style={{ display: "flex" }}
                      >
                        <label className="label_area">
                          {pageLables[27].description}
                        </label>
                        <input
                          id="case_subject"
                          type="text"
                          className="editing_field"
                          autoComplete="0"
                          value={
                            caseProgressDetails && caseProgressDetails.comment
                          }
                          onChange={(e) =>
                            setCaseProgressDetails({
                              ...caseProgressDetails,
                              comment: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              {/*make this drop down list*/}
            </>
          )}
          {progressMode === "View" && (
            <>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[12].description}
                  </label>
                  <div className="viewing_field">
                    {caseProgressDetails &&
                      caseProgressDetails.Litigation_level}
                  </div>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[22].description}
                  </label>
                  <div className="viewing_field">
                    {caseProgressDetails && caseProgressDetails.state_name}
                  </div>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[20].description}
                  </label>
                  <div className="viewing_field">
                    {caseProgressDetails && caseProgressDetails.court_name}
                  </div>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[23].description}
                  </label>
                  <div className="viewing_field">
                    {caseProgressDetails && caseProgressDetails.section_no}
                  </div>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[25].description}
                  </label>
                  <div className="viewing_field">
                    {caseProgressDetails && caseProgressDetails.floor_no}
                  </div>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[26].description}
                  </label>
                  <div className="viewing_field">
                    {caseProgressDetails && caseProgressDetails.room_no}
                  </div>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[11].description}
                  </label>
                  <div className="viewing_field">
                    {caseProgressDetails && caseProgressDetails.court_date}
                  </div>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[24].description}
                  </label>
                  <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={
                      caseProgressDetails && caseProgressDetails.court_time
                    }
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        court_time: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[29].description}
                  </label>
                  {/* <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={caseProgressDetails && caseProgressDetails.staff_id}
                    onChange={(e) =>
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        staff_id: e.target.value,
                      })
                    }
                  /> */}
                  {console.log(
                    caseProgressDetails.representative_id,
                    "caseProgressDetails.representative_id",
                  )}
                  <select
                    style={{
                      border: "none",
                      background: "none",
                      borderBottom: "1px solid lightgray",
                      width: "100%",
                    }}
                    defaultValue={
                      caseProgressDetails.representative_id &&
                      caseProgressDetails.representative_id
                    }
                    onChange={(e) => {
                      setCaseProgressDetails({
                        ...caseProgressDetails,
                        representative_id: e.target.value,
                      });
                    }}
                    disabled
                  >
                    <option value="00">Select representative</option>
                    {officeAttornyTeam.length > 0 &&
                      officeAttornyTeam.map((officeAttorny) => {
                        return (
                          <option value={officeAttorny.user_cd}>
                            {officeAttorny.full_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="row data_line">
                <div
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  style={{ display: "flex" }}
                >
                  <label className="label_area">
                    {pageLables[30].description}
                  </label>
                  <div
                    className="normal_float"
                    style={{ display: "flex", height: "30px" }}
                  >
                    {/*<div style={{ padding: "4px" }}>{newattchlist.attactment_type}</div>*/}
                    <div style={{ padding: "4px" }} onClick={addAttachment}>
                      {pageLables[30].description}
                    </div>
                  </div>
                </div>
              </div>
              {/*this is only after the hearing date */}

              {caseProgressDetails.court_date &&
                new Date(caseProgressDetails.court_date) <= new Date() && (
                  <>
                    <div
                      className="row data_line"
                      style={{ marginTop: "16px" }}
                    >
                      <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        style={{ display: "flex" }}
                      >
                        <label className="label_area">
                          {pageLables[21].description}
                        </label>
                        <div className="viewing_field">
                          {caseProgressDetails && caseProgressDetails.judjment}
                        </div>
                      </div>
                    </div>
                    <div className="row data_line">
                      <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        style={{ display: "flex" }}
                      >
                        <label className="label_area">
                          {pageLables[27].description}
                        </label>
                        <div className="viewing_field">
                          {caseProgressDetails && caseProgressDetails.comment}
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CaseProgress;
