import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import styles from "./utils.module.css";

import "react-datepicker/dist/react-datepicker.css";
const DatePickerCom = ({
  caseProgressDetails,
  setCaseProgressDetails,
  date,
}) => {
  const [startDate, setStartDate] = useState(date);

  useEffect(() => {
    console.log(startDate, "startDate");

    if (startDate) {
      const date = new Date(startDate);

      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-indexed
      const year = date.getFullYear();

      const formattedDate = `${month}/${day}/${year}`;

      console.log(formattedDate, "formattedDate");
      setCaseProgressDetails({
        ...caseProgressDetails,
        court_date: formattedDate,
      });
    }
  }, [startDate]);

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
      }}
      wrapperClassName="datpickerWrapper"
    />
  );
};

export default DatePickerCom;
