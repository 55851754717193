import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Clients from "../Crm/Clients";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { TiAttachment } from "react-icons/ti";
import { BsTrash3 } from "react-icons/bs";
import { GoCheckCircle } from "react-icons/go";
import { MdOutlineExpandMore } from "react-icons/md";
import { MdOutlineCheck } from "react-icons/md";
import blankprofile from "../../Images/blankprofile.png";
import { TfiCheckBox } from "react-icons/tfi";
import { RiMoreLine } from "react-icons/ri";
/*import { LuEdit2 } from "react-icons/lu"*/

import { useDispatch, useSelector } from "react-redux"; //this import redux functions

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ImageCard from "../cards/ImageCard";
import { startTransition } from "react";

const CasesAttornies = () => {
  var isName = "";
  const dispatch = useDispatch({});
  const [clientMode, setClientMode] = useState("Initial");
  const [systemUsers, setSystemUsers] = useState([]);
  const [orgenizationStructure, setOrgenizationStructure] = useState([]);
  const [systemCd, setSystemCd] = useState("Lcc");
  const [updating, setUpdating] = useState([]);
  const [branchCode, setBranchCode] = useState();
  const [branchTree, setBranchTree] = useState(false);
  const [branchIndex, setBranchIndex] = useState(null);
  const [caseCd, setCaseCd] = useState(null);
  const [currentCaseIndex, setCurrentCaseIndex] = useState(null);
  const [branchSystemUsers, setBranchSystemUsers] = useState([]);
  const [pageLables, setPageLables] = useState([]);
  const [cases, setCases] = useState([]);
  const [clients, setClients] = useState([]);
  const [opponents, setOpponents] = useState([]);
  const [caseListMode, setCaseListMode] = useState("List");

  useEffect(() => {
    get_attorney_cases_initial();
  }, []);

  function select_branch(branch_cd, index) {
    setBranchIndex(index);
    setBranchCode(branch_cd);
    setBranchTree(!branchTree);
    setCurrentCaseIndex(null);
    get_attorny_cases(branch_cd);
  }

  function select_case(index, case_cd) {
    setCurrentCaseIndex(index);
    setCaseListMode("List");
    setCaseCd(case_cd);
  }

  function set_icon(employee_image_name, user_image_name) {
    if (user_image_name) {
      return (
        <img
          src={"https://files.amanrow.com/user/" + user_image_name}
          alt=" "
          className="user_profile_image"
        />
      );
    } else if (employee_image_name) {
      return (
        <img src={employee_image_name} alt=" " className="user_profile_image" />
      );
    } else {
      return <img src={blankprofile} className="user_profile_image" alt="" />;
    }
  }

  function toggle_branch(branch_id, branch_cd) {
    setBranchCode(branch_cd);
    if (document.getElementById(branch_id).style.display === "block") {
      document.getElementById(branch_id).style.display = "none";
    } else {
      document.getElementById(branch_id).style.display = "block";
    }
  }

  const subnode = (parent_id) => {
    return orgenizationStructure.map((tree_node, i) => {
      if (parent_id === tree_node.parent_id) {
        return (
          <div key={i}>
            <div key={i} className="user_line">
              <div className="node_line">
                <div
                  style={{ display: "flex" }}
                  onClick={() => {
                    toggle_branch(
                      `branch_${tree_node.branch_id}`,
                      tree_node.branch_cd,
                    );
                  }}
                >
                  <div className="icon_tree_area">
                    <ImageCard type={tree_node.branch_type_cd} />
                  </div>
                  <div className="node_name_area">{tree_node.branch_name}</div>
                  <div style={{ padding: "0px 4px" }}>
                    {tree_node.branch_type_cd}
                  </div>
                  <div style={{ padding: "0px 4px" }}>
                    {tree_node.branch_id}
                  </div>
                </div>
              </div>
            </div>
            <div
              id={`branch_${tree_node.branch_id}`}
              className="node_block_section left_block_border"
              style={{ display: "none" }}
            >
              {subnode(tree_node.branch_id)}
            </div>
          </div>
        );
      }
    });
  };

  const orgenization_subnode = (parent_id) => {
    return orgenizationStructure.map((tree_node, i) => {
      if (parent_id === tree_node.parent_id) {
        return (
          <div key={i}>
            <div key={i} className="user_line">
              <div className="node_line">
                <div style={{ display: "flex" }}>
                  <div className="icon_tree_area">
                    <ImageCard
                      icon={tree_node.branch_type_cd}
                      iconType={"organization tree"}
                    />
                  </div>
                  <div
                    className="node_name_area"
                    onClick={() => {
                      toggle_branch(`branch_${tree_node.branch_id}`);
                    }}
                  >
                    {tree_node.branch_name}
                  </div>
                  <div style={{ padding: "0px 4px", width: "20px" }}>
                    {tree_node.branch_cd === branchCode ? (
                      <GoCheckCircle size="20" style={{ color: "green" }} />
                    ) : (
                      <div
                        className="flip_display_area"
                        style={{ width: "100%" }}
                        onClick={() => {
                          select_branch(tree_node.branch_cd, i);
                        }}
                      >
                        <MdOutlineCheck size="18" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              id={`branch_${tree_node.branch_id}`}
              className="node_block_section left_block_border"
              style={{ display: "none" }}
            >
              {orgenization_subnode(tree_node.branch_id)}
            </div>
          </div>
        );
      }
    });
  };

  // Read ----------------------
  const get_attorney_cases_initial = async (branch_cd) => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_attorney_cases_initial",
          branch_cd: userDataLocal.branch_cd,
          system_cd: systemCd,
        },
      };

      console.log("get_attorney_cases_initial input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_attorney_cases_initial`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_attorney_cases_initial output", res);
      setPageLables(res.data.output.page_lables);
      if (res.data.output.branch_system_users) {
        setBranchSystemUsers(res.data.output.branch_system_users);
      }
      if (
        res.data.output.orgenization_strucure &&
        res.data.output.orgenization_strucure.length > 0
      ) {
        setOrgenizationStructure(res.data.output.orgenization_strucure);
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        setBranchCode(userDataLocal.branch_cd);
        res.data.output.orgenization_strucure.map((tree_node, i) => {
          if (userDataLocal.branch_cd == tree_node.branch_cd) {
            setBranchIndex(i);
          }
        });
      }

      if (res.data.output.cases && res.data.output.cases.length > 0) {
        setCases(res.data.output.cases);
        if (res.data.output.clients && res.data.output.clients.length > 0) {
          setClients(res.data.output.clients);
        }
        if (res.data.output.opponents && res.data.output.opponents.length > 0) {
          setOpponents(res.data.output.opponents);
        }
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_attorny_cases = async (branch_cd) => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_attorny_cases",
          branch_cd: branch_cd,
          system_cd: systemCd,
        },
      };

      console.log("get_attorny_cases input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_attorny_cases`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_attorny_cases output", res);
      setCases([]);
      setOpponents([]);
      setClients([]);
      if (
        res.data.output.branch_system_users &&
        res.data.output.branch_system_users.length > 0
      ) {
        setBranchSystemUsers(res.data.output.branch_system_users);
      }
      if (res.data.output.cases && res.data.output.cases.length > 0) {
        setCases(res.data.output.cases);
        if (res.data.output.clients && res.data.output.clients.length > 0) {
          setClients(res.data.output.clients);
        }
        if (res.data.output.opponents && res.data.output.opponents.length > 0) {
          setOpponents(res.data.output.opponents);
        }
      }
      //if (res.data.output.orgenization_strucure.length > 0) {
      //    setOrgenizationStructure(res.data.output.orgenization_strucure)

      /*see what is the proplem with branch_code */

      //res.data.output.orgenization_strucure.map((tree_node, i) => {
      //    if (branch_code === tree_node.branch_cd) { setBranchIndex(i) }
      //});

      /*}*/
      /*setPageLables(res.data.output.page_lables);*/
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  // Update ----------------------
  const update_case_assigned_to = async (
    update_case_cd,
    update_user_cd,
    update_function,
  ) => {
    setUpdating([update_user_cd, ...updating]);
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_case_assigned_to",
          update_case_cd: update_case_cd,
          case_assigned_to_user_cd: update_user_cd,
          update_function: update_function,
          updating_status_flag: "user_cd",
        },
      };
      console.log("update_case_assigned_to input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_case_assigned_to`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );
      console.log("update_case_assigned_to output ", res);
      console.log("returned values ", res.data.output.returned_values);
      if (res.data.output.returned_values.rows_updated > 0) {
        cases[currentCaseIndex].case_assigned_to =
          res.data.output.returned_values.case_assigned_to_user_cd;
      }
      const newArray = updating.filter(
        (item) => item !== res.data.output.updating_cd,
      );
      setUpdating(newArray);
      setCaseListMode("List");
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8  border-right">
          <div
            style={{
              height: "calc(100vh - 100px)",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            {/*header bar*/}
            {/*Orgenization Structure*/}
            {branchTree && (
              <div className="branch_menu">
                {orgenizationStructure.map((tree_node, i) => {
                  if (i === 0)
                    return (
                      <>
                        <div key={i} className="user_line">
                          <div className="node_line">
                            <div style={{ display: "flex" }}>
                              <div className="icon_tree_area">
                                <ImageCard
                                  icon={tree_node.branch_type_cd}
                                  iconType={"organization tree"}
                                />
                              </div>
                              <div
                                className="node_name_area"
                                onClick={() => {
                                  toggle_branch(
                                    `branch_${tree_node.branch_id}`,
                                  );
                                }}
                              >
                                {tree_node.branch_name}
                              </div>
                              <div
                                style={{ padding: "0px 4px", width: "20px" }}
                              >
                                {tree_node.branch_cd === branchCode ? (
                                  <GoCheckCircle
                                    size="20"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <div
                                    className="flip_display_area"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                      select_branch(tree_node.branch_cd, i);
                                    }}
                                  >
                                    <MdOutlineCheck size="18" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={`branch_${tree_node.branch_id}`}
                          className="node_block_section left_block_border"
                        >
                          {orgenization_subnode(tree_node.branch_id)}
                        </div>
                      </>
                    );
                })}
              </div>
            )}
            <div
              className="prevelages_header"
              onClick={() => setBranchTree(!branchTree)}
            >
              <div>
                {orgenizationStructure.length > 0 &&
                  branchIndex !== null &&
                  orgenizationStructure[branchIndex].branch_name}
              </div>
              <div style={{ padding: "0px 6px" }}>
                <MdOutlineExpandMore size="18" />
              </div>
            </div>
            <div style={{ padding: "0px 8px" }}>
              {/*cases list*/}
              {/* <div
                className="table_header_div"
                style={{ display: "flex", borderBottom: "solid 1px gray" }}
              >
                <div style={{ width: "10%" }}>code</div>
                <div style={{ width: "29%" }}>Subject</div>
                <div style={{ width: "28%" }}>Client</div>
                <div style={{ width: "28%" }}>Opponent</div>
              </div>
              {cases.length > 0 &&
                cases.map((cases_list, index) => {
                  return (
                    <div className="row_area" key={cases_list.case_cd}>
                      <div
                        className="table_line_div bottom_border_div"
                        style={{ paddingBottom: "4px" }}
                        onClick={() => select_case(index, cases_list.case_cd)}
                      >
                        <div style={{ width: "10%" }}>{cases_list.case_cd}</div>
                        <div style={{ width: "29%" }}>
                          {cases_list.case_subject}
                        </div>
                        <div
                          style={{ width: "28%" }}
                          id={"name" + cases_list.case_cd}
                        >
                          {clients.length > 0 &&
                            clients.map((client_list, index) => {
                              if (cases_list.case_cd === client_list.case_cd) {
                                return <div>{client_list.client_name}</div>;
                              }
                            })}
                        </div>

                        <div style={{ width: "28%" }}>
                          {opponents.length > 0 &&
                            opponents.map((opponents_list, index) => {
                              if (
                                cases_list.case_cd === opponents_list.case_cd
                              ) {
                                return (
                                  <div>{opponents_list.opponent_name}</div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  );
                })} */}

              {cases.length > 0 &&
                cases.map((cases_list, index) => {
                  return (
                    <div
                      className="row_area"
                      key={cases_list.case_cd}
                      style={{}}
                    >
                      <div
                        style={{
                          padding: "5px",
                          width: "60%",
                          margin: "5px",
                          boxShadow: "0px 12px 25px 1px rgb(105 109 112 / 10%)",
                          borderRadius: "8px",
                          background: "#f7f8fa",
                        }}
                        onClick={() => select_case(index, cases_list.case_cd)}
                      >
                        <div style={{ display: "flex", gap: "4px" }}>
                          <label style={{ fontWeight: "700" }}>case:</label>
                          <div>
                            {cases_list.case_cd}, {cases_list.case_subject}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <label style={{ fontWeight: "700" }}>Client:</label>
                          <div
                            id={"name" + cases_list.case_cd}
                            style={{ display: "flex", gap: "4px" }}
                          >
                            {clients.length > 0 &&
                            clients.some(
                              (client_list) =>
                                cases_list.case_cd === client_list.case_cd,
                            ) ? (
                              clients.map((client_list, index) => {
                                if (
                                  cases_list.case_cd === client_list.case_cd
                                ) {
                                  // Check if it's the last matching element
                                  const nextElement = clients
                                    .slice(index + 1)
                                    .find(
                                      (el) => el.case_cd === cases_list.case_cd,
                                    );

                                  return (
                                    <div key={client_list.client_name}>
                                      {client_list.client_name}
                                      {nextElement ? "," : "."}
                                    </div>
                                  );
                                }
                              })
                            ) : (
                              <div>N/A</div>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <label style={{ fontWeight: "700" }}>Opponent:</label>
                          <div style={{ display: "flex", gap: "4px" }}>
                            {opponents.length > 0 &&
                            opponents.some(
                              (opponents_list) =>
                                cases_list.case_cd === opponents_list.case_cd,
                            ) ? (
                              opponents.map((opponents_list, index) => {
                                if (
                                  cases_list.case_cd === opponents_list.case_cd
                                ) {
                                  // Check if it's the last matching element
                                  const nextElement = opponents
                                    .slice(index + 1)
                                    .find(
                                      (el) => el.case_cd === cases_list.case_cd,
                                    );

                                  return (
                                    <div key={opponents_list.opponent_name}>
                                      {opponents_list.opponent_name}
                                      {nextElement ? "," : "."}
                                    </div>
                                  );
                                }
                              })
                            ) : (
                              <div>N/A</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div
            style={{
              height: "calc(100vh - 86px)",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            {currentCaseIndex !== null && (
              <>
                {/*Header bar*/}
                <div className="prevelages_header">
                  <div style={{ width: "100%" }}>
                    <div className="normal_float">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        <div> {cases[currentCaseIndex].case_cd}</div>
                        <div style={{ padding: "0px 6px" }}>
                          {" "}
                          {cases[currentCaseIndex].case_subject}
                        </div>
                      </div>
                    </div>
                    <div className="opposite_float">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0px 10px",
                        }}
                      >
                        {caseListMode === "List" && (
                          <Tooltip
                            title={pageLables[0].description}
                            placement="bottom"
                            arrow
                            onClick={() => {
                              setCaseListMode("Edit");
                            }}
                          >
                            <IconButton>
                              <ModeOutlinedIcon
                                color="inherit"
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {caseListMode === "Edit" && (
                          <Tooltip
                            title={pageLables[1].description}
                            placement="bottom"
                            arrow
                            onClick={() => {
                              setCaseListMode("List");
                            }}
                          >
                            <IconButton>
                              <ArrowBackIcon color="inherit" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                </div>
                {/*users area*/}
                <div style={{ marginTop: "6px" }}>
                  {branchSystemUsers.length > 0 &&
                    branchCode &&
                    branchSystemUsers.map((system_users, index) => {
                      if (
                        system_users.user_cd ===
                        cases[currentCaseIndex].case_assigned_to
                      )
                        return (
                          <div className="user_line">
                            <div
                              key={branchSystemUsers.user_cd + index}
                              className="user_line_area"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {updating.length > 0 &&
                                updating.find(
                                  (update_user_cd) =>
                                    update_user_cd === system_users.user_cd,
                                ) ? (
                                  <div
                                    className="icon_tree_area"
                                    style={{ width: "26px" }}
                                  >
                                    <span className="loader-spiner"></span>
                                  </div>
                                ) : (
                                  <div
                                    className="icon_tree_area"
                                    style={{ width: "26px" }}
                                  >
                                    {caseListMode === "Edit" && (
                                      <TfiCheckBox size="16" />
                                    )}
                                  </div>
                                )}

                                {/*<div style={{ width: "26px", display: "flex", alignItems: "center" }} onClick={() => { update_system_users(system_users.user_cd, 0, "remove") }}>{usersAreasMode === "List branch employees" && (<TbCheckbox size="22" />)}</div>*/}
                                <div>
                                  {set_icon(null, system_users.image_name)}
                                </div>
                                <div
                                  className="space-betw-vertical"
                                  style={{ margin: "0px 10px" }}
                                >
                                  <div> {system_users.full_name}</div>
                                  <div>
                                    {system_users.job_title}
                                    {system_users.user_type && (
                                      <span style={{ color: "gray" }}>
                                        {" "}
                                        | {system_users.user_type}{" "}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                    })}
                </div>

                {caseListMode === "Edit" && (
                  <>
                    {branchSystemUsers.length > 0 &&
                      branchCode &&
                      branchSystemUsers.map((system_users, index) => {
                        if (
                          system_users.user_cd !==
                          cases[currentCaseIndex].case_assigned_to
                        )
                          return (
                            <div className="user_line">
                              <div
                                key={branchSystemUsers.user_cd + index}
                                className="user_line_area"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {updating.length > 0 &&
                                  updating.find(
                                    (update_user_cd) =>
                                      update_user_cd === system_users.user_cd,
                                  ) ? (
                                    <div
                                      className="icon_tree_area"
                                      style={{ width: "26px" }}
                                    >
                                      <span className="loader-spiner"></span>
                                    </div>
                                  ) : (
                                    <div
                                      className="icon_tree_area"
                                      style={{ width: "26px" }}
                                    >
                                      {caseListMode === "Edit" && (
                                        <div
                                          className="unchecked_checkbox"
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                          onClick={() => {
                                            update_case_assigned_to(
                                              cases[currentCaseIndex].case_cd,
                                              system_users.user_cd,
                                              "assigned to",
                                            );
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                  )}
                                  <div>
                                    {set_icon(null, system_users.image_name)}
                                  </div>
                                  <div
                                    className="space-betw-vertical"
                                    style={{ margin: "0px 10px" }}
                                  >
                                    <div> {system_users.full_name}</div>
                                    <div>
                                      {system_users.job_title}
                                      {system_users.user_type && (
                                        <span style={{ color: "gray" }}>
                                          {" "}
                                          | {system_users.user_type}{" "}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                      })}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CasesAttornies;
