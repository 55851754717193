import React, { useRef, useEffect, useState } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { responseMessageDispaly } from "../../JSfiles/amanrow";
import Modal from "../../utils/Modal";
import ImageCrop from "../../utils/ImageCrop";
import { dataURLtoBlob } from "../../utils/sharedfunction";
import profileDefualt from "../../Images/profiledefualt.png";

const SideBar = ({ closeSideNav }) => {
  const [menuType, setMenuType] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [cfrmPassword, setCfrmPassword] = useState("");
  const [lang, setLang] = useState("");
  const [loginName, setLoginName] = useState("");
  const [email, setEmail] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const cancelFileUpload = useRef(null);
  const profileRef = useRef(null);
  const pMessagebox = useRef(null);
  const UMessagebox = useRef(null);
  const IMGMessagebox = useRef(null);

  const dispatch = useDispatch({});

  const {
    user: { userData },
  } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    if (userData) {
      setEmail(userData.email);
      setLoginName(userData.login_name);
      setLang(userData.user_language_cd);
    }
  }, [userData]);

  const toggleMenu = (menuTyp) => {
    if (menuTyp === menuType) {
      setMenuType("");
    } else {
      setMenuType(menuTyp);
    }
  };

  //password
  const chnagePwd = async () => {
    try {
      if (newPassword !== cfrmPassword) {
        responseMessageDispaly(
          pMessagebox,
          "New password mis match",
          "messege-danger",
        );
        return;
      }

      const data = {
        user_cd: userData.user_cd,
        info: {
          lang: userData.user_language_cd,
        },
        old_password: oldPassword,
        new_password: newPassword,
        user_type_cd: userData.user_type_cd,
      };

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_SERVICE}/ServiceItegrity.svc/userChangepwd`,
        JSON.stringify(data),
        {
          contentType: "text/plain",
        },
      );

      if (res.data.result.rstatus === "1") {
        responseMessageDispaly(
          pMessagebox,
          res.data.result.rdescription,
          "alert-success",
        );
        setMenuType("");
      } else {
        responseMessageDispaly(
          pMessagebox,
          res.data.result.rdescription,
          "messege-danger",
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cancelPwd = () => {
    setNewPassword("");
    setOldPassword("");
    setCfrmPassword("");
    setMenuType("");
  };

  //language
  const chnageLang = async () => {
    const data = {
      user_cd: userData.user_cd,
      lang: lang,
      user_type_cd: userData.user_type_cd,
    };

    let res = await axios.post(
      `${process.env.REACT_APP_BACK_END_URL}/api/iipsys/v1/changelang`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userData.token}`,
        },
      },
    );

    if (res.data.result.rstatus === "1") {
      responseMessageDispaly(
        UMessagebox,
        res.data.result.rdescription,
        "alert-success",
      );

      dispatch({
        type: "USERINFO",
        payload: { ...userData, user_language_cd: lang },
      }); //to store in redux
    } else {
      console.log(res.data.result.rdescription);
      responseMessageDispaly(
        UMessagebox,
        res.data.result.rdescription,
        "messege-danger",
      );
    }
    // try {
    //   const data = {
    //     user_cd: userData.user_cd,
    //     info: {
    //       lang: userData.user_language_cd,
    //     },
    //     old_password: oldPassword,
    //     new_password: newPassword,
    //     user_type_cd: userData.user_type_cd,
    //   };
    //   let res = await axios.post(
    //     `${process.env.REACT_APP_BACK_END_SERVICE}/ServiceItegrity.svc/userChangepwd`,
    //     JSON.stringify(data),
    //     {
    //       contentType: "text/plain",
    //     },
    //   );
    // } catch (err) {
    //   console.log(err);
    // }
  };

  //update user

  const changeUserDetails = async () => {
    try {
      if (loginName === "") {
        responseMessageDispaly(
          UMessagebox,
          "enter the loginName",
          "messege-danger",
        );
        return;
      }

      if (email === "") {
        responseMessageDispaly(
          UMessagebox,
          "enter the email",
          "messege-danger",
        );
        return;
      }

      const data = {
        user_cd: userData.user_cd,
        info: {
          lang: userData.user_language_cd,
        },
        login_name: loginName,
        email: email,
        user_type_cd: userData.user_type_cd,
      };

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_SERVICE}/ServiceItegrity.svc/userUpdatedetail`,
        JSON.stringify(data),
        {
          contentType: "text/plain",
        },
      );

      if (res.data.result.rstatus === "1") {
        responseMessageDispaly(
          UMessagebox,
          res.data.result.rdescription,
          "alert-success",
        );
      } else {
        responseMessageDispaly(
          UMessagebox,
          res.data.result.rdescription,
          "messege-danger",
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeProfileHandler = () => setShowCrop(false);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const imagefileInputHandler = async (file) => {
    profileRef.current.src = file;

    const data = {
      img: file,
      fname: userData.user_cd,
      flocation: "user",
    };

    let res = await axios.post(
      `${process.env.REACT_APP_BACK_END_URL}/api/iipsys/v1/uploadProfileImage`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      },
    );

    if (res.data.result.rstatus === "1") {
      profileRef.current.src = file;
      responseMessageDispaly(
        IMGMessagebox,
        res.data.result.rdescription,
        "alert-success",
      );
    } else {
      console.log(res.data.result.rdescription);
      responseMessageDispaly(IMGMessagebox, "system error", "messege-danger");
      profileRef.current.src =
        "http://files.amanrow.com/user/1178.png?ms=20230220082733";
    }
  };

  const logout = () => {
    localStorage.removeItem("amanrowuserData");
    window.location = "/";
  };

  return (
    <>
      <Modal
        show={showCrop}
        onCancel={closeProfileHandler}
        headerClass="text-center"
        containerStyle={{ width: "500px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeProfileHandler}
            onSave={imagefileInputHandler}
            aspectRatio={1 / 1}
            profile={true}
            cropSize={{ width: 300, height: 300 }}
            objectFit="vertical-cover"
          />
        </div>
      </Modal>

      <div id="mySidenav" class="profilesidenav border-box-right-sidebar">
        <div
          class="closebtn"
          onClick={() => {
            closeSideNav("mySidenav");
          }}
        >
          &times;
        </div>

        <div className="mt-3">
          <div className="menue_header">
            <div className="profile-image-div">
              {userData && (
                <img
                  ref={profileRef}
                  id="thumnail_bio"
                  src={`http://files.amanrow.com/user/${
                    userData.user_cd
                  }.png?ms=${Date.now()}`}
                  alt="profile"
                  height="80"
                  className="my-img"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = profileDefualt;
                  }}
                />
              )}
              <div
                className="p-1 img-chnage-text-box"
                onClick={() => {
                  setShowCrop(true);
                }}
              >
                <span>Change Image</span>
              </div>
            </div>
            <div ref={IMGMessagebox} className="mt-1"></div>
            <div className="mt-4">
              <span>ERP:{userData && userData.user_cd} </span>
            </div>
          </div>
          <div
            className="menue_header"
            onClick={() => {
              toggleMenu("password");
            }}
          >
            Change password
          </div>
          {menuType === "password" && (
            <div className="menu-edit-area">
              <div ref={pMessagebox}></div>
              <div className="form-group">
                <label className="control-label">Old Password</label>
                <input
                  type="password"
                  className="form-control input-sm"
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label className="control-label">New Password</label>\
                <input
                  type="password"
                  className="form-control input-sm"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label className="control-label">Confirm Password</label>
                <input
                  type="password"
                  className="form-control input-sm"
                  value={cfrmPassword}
                  onChange={(e) => {
                    setCfrmPassword(e.target.value);
                  }}
                />
              </div>
              <div class="mt-4">
                <button
                  type="button"
                  class="btn btn-green mr-1"
                  onClick={chnagePwd}
                >
                  Change Password
                </button>
                <button
                  type="button"
                  className="btn btn-defualt"
                  onClick={cancelPwd}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          <div
            className="menue_header"
            onClick={() => {
              toggleMenu("language");
            }}
          >
            My language
          </div>
          {menuType === "language" && (
            <div className="menu-edit-area">
              <div ref={UMessagebox}></div>
              <div className="form-group">
                <label className="control-label">Prefered</label>
                {console.log(userData.user_language_cd)}
                {userData && userData.user_language_cd === "en" && (
                  <select
                    className="form-control input-sm"
                    defaultValue={lang}
                    onChange={(e) => {
                      setLang(e.target.value);
                    }}
                  >
                    <option selected="selected" value="en">
                      English
                    </option>
                    <option value="ar">Arabic</option>
                  </select>
                )}
                {userData && userData.user_language_cd === "ar" && (
                  <select
                    className="form-control input-sm"
                    defaultValue={lang}
                    onChange={(e) => {
                      setLang(e.target.value);
                    }}
                  >
                    <option selected="selected" value="ar">
                      Arabic
                    </option>
                    <option value="en">English</option>
                  </select>
                )}
              </div>
              <div class="mt-4">
                <button
                  type="button"
                  class="btn btn-green mr-1"
                  onClick={chnageLang}
                >
                  Update
                </button>
              </div>
            </div>
          )}
          <div
            className="menue_header"
            onClick={() => {
              toggleMenu("chnagelogin");
            }}
          >
            Change Login Name
          </div>
          {menuType === "chnagelogin" && (
            <div className="menu-edit-area">
              <div ref={UMessagebox}></div>
              <div className="form-group">
                <label className="control-label">Login Name</label>
                <input
                  type="text"
                  className="form-control input-sm"
                  value={loginName}
                  onChange={(e) => {
                    setLoginName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label className="control-label">Email</label>
                <input
                  type="text"
                  className="form-control input-sm"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  class="btn btn-green mr-1"
                  onClick={changeUserDetails}
                >
                  Update
                </button>
              </div>
            </div>
          )}
          <div className="menue_header" onClick={logout}>
            Logout
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
