import React, { useEffect, useState } from "react";
import axios from "axios";

import ComplaintRequest from "../Request/ComplaintRequest";
import CommonRequest from "../Request/CommonRequest";

import { BsThreeDotsVertical } from "react-icons/bs";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineBorderBottom,
} from "react-icons/ai";

import { useDispatch } from "react-redux";

import leftIshareIcon from "../../../SVG_images/arrow curve left.svg";
import saveIcon from "../../../SVG_images/save.svg";
import editIcon from "../../../SVG_images/edit.svg";
import attacmentsIcon from "../../../SVG_images/attachment.png";
import remarksIcon from "../../../SVG_images/note.png";

const RequestPage = ({ RequestInput, PageMode, setPageMode, setRequestInput, requestTypetData, customerTypetData,}) => {
  const [requestMode, setRequestMode] = useState("");
  const [requestType, setrequestType] = useState();

  const [requestData, setRequestData] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [request_type_index, setrequest_type_index] = useState();
  //const [invoiceDetailsData, setInvoiceDetailsData] = useState()
  //const [invoiceItemsData, setinvoiceItemsDataData] = useState()

  const dispatch = useDispatch();

    useEffect(() => {
        alert("ok here")
        if (RequestInput.request_year == 0) {
            /*document.getElementById("edit_status").innerText = "Draft"*/
            setRequestMode("add request");
        } else {
            getrequest(RequestInput.request_year, RequestInput.branch_cd, RequestInput.request_cd);
        }
    }, []);

    const set_clear_mode = () => {
    setPageMode("initial");
    };

    const set_request_type = () => {
        setrequest_type_index(document.getElementById("request_type_cd").value);

        if (document.getElementById("request_type_cd").value == 1) {
          setrequestType("Complaint Request");
        } else {
          setrequestType("Common Request");
        }
      };

    const getrequest = async (request_year, branch_cd, request_cd) => {
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
            info: {
                lang: userDataLocal.lang,
                company: userDataLocal.company_cd,
                user_cd: userDataLocal.user_cd,
            },
            input: {
                actioncode: "get_request_details",
                request_year: request_year,
                branch_cd: branch_cd,
                request_cd: request_cd,
            },
            };
            let res = await axios.post(
            process.env.REACT_APP_BACK_END_URL + "/api/iip/wf/get_request_details",
            data,
            {
                headers: {
                    Authorization: `Bearer ${userDataLocal.token}`,
            }
        }
        );

      console.log("request_details", res);
      if(res.data.output){setRequestData(res.data.output)}
      setInvoiceData(res.data.output);

      var request = res.data.output.request_details;
      setRequestMode("view request");
      if (request.request_type_cd == 1) {
        setrequestType("Complaint Request");
      } else {
        setrequestType("Common Request");
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  }

    return (
        <>
            <div className="page-inside-container" style={{ height: "100%" }}>
                <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 border-right">
                        <div className="py-3">
                                <div className="panel-body  mostly-customized-scrollbar">
                                {requestMode === "view request" && (
                                    <>
                                        <div id="req_progress" style={{ padding: "4px !important" }} >
                                            {requestData.progres_list && requestData.progres_list.map((progress_list, index) => (
                                                <div className="progress_box">
                                                <div className="progress_header">
                                                    <div className="normal_float"> <img src="../assets/pages/media/users/avatar1.jpg" /> </div>
                                                    <div className="normal_float">
                                                    <div className="normal_text_align">
                                                        <span className="mt-comment-author" style={{ marginBottom: "6px" }} > {progress_list.progress_user_cd} </span>
                                                    </div>
                                                    <div className="normal_text_align"> <span className="mt-comment-date"> {progress_list.recieved_time} </span> </div>
                                                    </div>
                                                    <div className="opposite_float">
                                                    <div className="opposite_text_align">
                                                        <span className="mt-comment-author" style={{ marginBottom: "6px" }} > {progress_list.request_status_cd} </span>
                                                    </div>
                                                    <div className="opposite_text_align"> <span className="mt-comment-date"> {progress_list.forward_time} </span> </div>
                                                    </div>
                                                    <div style={{ clear: "both" }}></div>
                                                </div>
                                                <div style={{ height: "380px", overflow: "auto" }}>
                                                    <div style={{ backgroundColor: "gray", height: "35px" }} >
                                                    <BsThreeDotsVertical className="buton_icon opposite_float" style={{ fontSize: "18px" }} />
                                                    <img id="share_btn" src={leftIshareIcon} className="buton_icon opposite_float " />
                                                    </div>
                                                    conversation
                                                </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div id="row2" className="progress_box">
                                            <div className="progress_header">
                                                <div className="normal_float">
                                                    <img src="../assets/pages/media/users/avatar1.jpg" />
                                                </div>
                                                <div className="normal_float">
                                                    <div className="normal_text_align">
                                                    <span className="mt-comment-author"> Fadel Eissa Bohamad</span>
                                                    </div>
                                                    <div className="normal_text_align">
                                                    <span className="mt-comment-date">02/03/2021</span>
                                                    </div>
                                                </div>
                                                <div className="opposite_float">
                                                    <div className="opposite_text_align">
                                                    <span className="mt-comment-author" style={{ marginBottom: "6px" }}>Approved</span>
                                                    </div>
                                                    <div className="opposite_text_align">
                                                    <span className="mt-comment-date">02/03/2021</span>
                                                    </div>
                                                </div>
                                                <div style={{ clear: "both" }}></div>
                                            </div>
                                            <div style={{ height: "380px", overflow: "auto" }}>
                                                <div style={{ padding: "8px 4px 2px 4px", marginBottom: "10px" }} >
                                                    {/* <img src="../images/forword.png" style={{ height: '18px' }} /> */}
                                                    <div style={{ float: "left", textAlign: "left" }}>
                                                        <div id="chat_users_already_red">0</div>
                                                        <div id="chat_converstaion_already_red">0</div>
                                                        <div id="tap_1" className="active_tap" onclick="show_chat_div(1)" > Users </div>
                                                        <div id="tap_2" className="nonactive_tap" onclick="show_chat_div(2)" > Conversation </div>
                                                        <div style={{ clear: "both" }} />
                                                    </div>
                                                    {/*<div style={{ float: 'right', width: '25%', textAlign: 'right' }}>
                                                                            <img id="chatting_menu_btn" src="../images/menu.png" className="header_menu_icon" style={{ height: '24px', paddingTop: '7px' }} onclick="show_top_menu('voucher_header')" />
                                                                            <div id="chatt_menu" className="menu_box hedden">
                                                                                <div onclick="insert_action()">Forword</div>
                                                                                <div onclick="add_request_remark()">Accomplished</div>
                                                                                <div onclick="clear_action()">Approved</div>
                                                                                <div onclick="clear_action()">Rejected</div>
                                                                                <div style={{ clear: 'both' }} />
                                                                            </div>
                                                                        </div> */}
                                                    {/*<div style={{ clear: "both" }} />*/}
                                                </div>
                                                <div id="chat_tap1"> user list here</div>
                                                <div id="chat_tap2" style={{ display: "none" }}>
                                                    <div className="chatting-message_area reciever">
                                                        <div className="reciever" style={{ height: "30px" }} >
                                                            <img src="../assets/pages/media/users/avatar1.jpg" className="profile_image" />
                                                        </div>
                                                        <div className="mt-comment-info reciever_paddind">
                                                            <span className="mt-comment-author" style={{ margin: "0px !important" }} > Fadel Eissa Bohamad </span>
                                                        </div>
                                                        <div style={{ clear: "both" }} />
                                                        <div>
                                                            <span className="message_date ">02/03/2021</span>
                                                            <br />
                                                            fadel yoba ehnii kjug vlkjbkfikgh ;kjhkufiyug
                                                            ljhjydf jhgb
                                                        </div>
                                                    </div>
                                                    <div style={{ clear: "both" }} />
                                                    <div className=" chatting-message_area reciever">
                                                        <span className="message_date ">02/03/2021</span>
                                                        <div id="envelop1" style={{ height: "40px", marginTop: "8px" }} onclick="read_message('1')" > <img src="../images/unseen_chatt.png" style={{ height: "100%" }} /> </div>
                                                    </div>
                                                    <div className="chatting-message_area sender">
                                                        <div className="sender" style={{ height: "24px" }}>
                                                            <img src="../images/forworded_to.png" style={{ height: "100%" }} />
                                                        </div>
                                                        <div className="mt-comment-info sender_paddind">
                                                            <div className="sender" style={{ lineHeight: "15px" }} >
                                                            <span className="mt-comment-author" style={{ margin: "0px !important" }} > Fadel Eissa Bohamad </span>
                                                            </div>
                                                            {/* <div style={{ clear: 'both' }} /> */}
                                                        </div>
                                                        <div style={{ float: "left", textAlign: "left" }}>
                                                            <img src="../images/okay.png" style={{ height: "18px" }} />
                                                        </div>
                                                        <div style={{ clear: "both" }} />
                                                        <div>
                                                            <span className="message_date ">02/03/2021</span>
                                                            <br />
                                                            fadel yoba ehnii kjug vlkjbkfikgh ;kjhkufiyug
                                                            ljhjydf jhgb
                                                        </div>
                                                    </div>
                                                    <div style={{ clear: "both" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        </div>

                        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        {requestMode === "add request" && (
                            <div className="buttons_div ">
                            <div className="subbutton_div normal_float" style={{ border: "silid 1px gray" }} >
                                {/*<div><span className="buton_icon opposite_float"><AiOutlineArrowRight style={{ fontSize: "18px"}} /></span></div>*/}
                                <div className="buton_icon normal_float">
                                {" "} <AiOutlineArrowLeft style={{ fontSize: "18px" }} onClick={set_clear_mode} />
                                </div>
                                <div
                                className=" normal_float" style={{ paddingLeft: "10px", borderLeft: "solid 1px gray"}} >
                                <select id="request_type_cd" className="underlined_droplist" style={{ width: "200px" }} onChange={set_request_type} >
                                    <option value=""></option>
                                    {requestTypetData.length >= 0 &&
                                    requestTypetData.map((request_type, index) => {
                                        return (
                                        <option key={index} value={request_type.request_type_cd} >
                                            {request_type.request_type}
                                        </option>
                                        );
                                    })}
                                </select>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                            </div>
                        )}

                        {requestType === "Complaint Request" && (<ComplaintRequest requestTypetData={requestTypetData} customerTypetData={customerTypetData} setPageMode={setPageMode} requestMode={requestMode} setRequestMode={setRequestMode} requestData={requestData} invoiceData={invoiceData} setInvoiceData={setInvoiceData} request_type_index={request_type_index}/> )}
                        {requestType === "Common Request" &&  (<CommonRequest requestTypetData={requestTypetData} setPageMode={setPageMode} requestMode={requestMode} setRequestMode={setRequestMode} requestData={requestData} request_type_index={request_type_index} /> )}
                        </div>
                </div>
            </div>
        </>
  );
};

export default RequestPage;
