import React, { useState } from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import To_learn from "./To_learn";
import Login from "./Login";

import Aman from "./Aman";
import PYMstatus from "./component/pymstatus/PYMstatus";
//import Menu_1 from './component/Workflow/Request/complaint/Menu_1'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/main" component={Aman} />
          <Route exact path="/pymstatus" component={PYMstatus} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
