import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { LuUserPlus } from "react-icons/lu";

const Clients = ({ setClientCode, systemCd, clientCode }) => {
  const [clientMode, setClientMode] = useState("Initial");
  const [countries, setCountries] = useState(false);
  const [clientPageLables, setClientPageLables] = useState([]);
  const [clientDetails, setClientDetails] = useState({
    country_area_cd: "965",
  });
  const [searchClient, setSearchClient] = useState();
  const [clientType, setClientType] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [countryIndex, setCountryIndex] = useState(0);
  const [selctedDailCode, setSelctedDailCode] = useState("965");
  const [sex, setSex] = useState([]);
  const [serch, setSerch] = useState();
  const [nathionalityDdl, setNathionalityDdl] = useState(false);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [isNameLoader, setNameLoader] = useState(false);
  const [clientsCaseOponents, setClientsCaseOponents] = useState({});
  useEffect(() => {
    getClientInitial();
  }, []);

  useEffect(() => {
    if (clientCode > 0) {
      console.log(clientCode, "clientCode");
      getClientCasesAndOppenent(clientCode);
    }

    return () => {
      // Cleanup: set isMounted to false to prevent setting state after unmount
      setClientsCaseOponents({});
    };
  }, [clientCode]);

  const set_clear_mode = () => {
    switch (clientMode) {
      case "Edit":
        setClientMode("view");
        break;

      case "Draft":
      case "view":
        setSearchClient();
        setClientDetails({ country_area_cd: "965" });
        setClientMode("Initial");
        setClientCode("0");
        break;

      default:
        break;
    }
  };

  const search_keyEvent = (e) => {
    if (e.charCode === 13) {
      getClient();
    }
  };

  const getClientCasesAndOppenent = async (clientCode) => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client_cases_opponents_clients",
          client_cd: clientCode,
        },
      };

      console.log("getClientCasesAndOppenent input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_client_cases_opponents_clients`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getClientCasesAndOppenent output", res);
      if (res.data.output.cases.length > 0) {
        console.log(res.data.output);
        setClientsCaseOponents(res.data.output);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  /* Read -------------------------*/
  const getClientInitial = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client_initials",
          system_cd: "Hrs",
          function_cd: "20",
          system_rout_cd: "19",
        },
      };

      console.log("getClientInitial input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client_initials`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getClientInitial output", res);
      if (res.data.output.pages_lables) {
        setClientPageLables(res.data.output.pages_lables);
      }
      if (res.data.output.sex_ref) {
        setSex(res.data.output.sex_ref);
      }
      if (res.data.output.country) {
        setCountriesList(res.data.output.country);

        let options = res.data.output.country.map((country) => {
          return {
            value: country.dial_cd,
            label: country.dial_cd,
            _id: country.country_cd,
            country_id: country.country_id,
            dial_cd: country.dial_cd,
          };
        });

        console.log("kuwait dailcd", options[countryIndex]);
        setCountries(options);
      }
      if (res.data.output.client_type) {
        setClientType(res.data.output.client_type);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const getClient = async (clientName, clientCD) => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      let clinet_code = searchClient && searchClient.client_cd;
      let phone_no = searchClient && searchClient.phone_no;
      let client_name = null;

      console.log("before", clinet_code, phone_no, client_name);
      if (clientName && clientCD) {
        console.log("inside", clinet_code, phone_no, client_name);
        client_name = clientName;
        clinet_code = clientCD;
      }

      console.log("after", clinet_code, phone_no, client_name);

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client",
          client_cd: clinet_code,
          country_area_cd: selctedDailCode,
          phone_no: phone_no,
          system_cd: "lcc",
          client_name: client_name,
        },
      };
      console.log("getClient input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getClient output ", res);

      if (res.data.output.client.client_cd) {
        setClientCode(res.data.output.client.client_cd);
        setClientDetails(res.data.output.client);
        setClientMode("view");
      } else {
        alert("no record found");
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    } finally {
      setNameSuggestions([]);
    }
  };

  /* Updates ----------------*/
  const updateClient = async () => {
    var client_code = 0;
    if (clientDetails && clientDetails.client_cd) {
      client_code = clientDetails.client_cd;
    }

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_client",
          system_cd: systemCd,
          client_cd: client_code,
          client_type_cd: clientDetails.client_type_cd,
          client_id: clientDetails.client_id,
          client_name: clientDetails.client_name,
          sex_cd: clientDetails.sex_cd,
          country_area_cd: clientDetails.country_area_cd,
          phone_no: clientDetails.phone_no,
          phone_country_flag_id: clientDetails.phone_country_flag_id,
          nationality_cd: clientDetails.nationality_cd,
          country_cd: clientDetails.country_cd,
          state_cd: clientDetails.state_cd,
          state_name: clientDetails.state_name,
          city_name: clientDetails.city_name,
          email_address: clientDetails.email_address,
          client_address: clientDetails.client_address,
          postal_cd: clientDetails.postal_cd,
        },
      };
      console.log("updateClient input sss", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/update_client`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("updateClient output ", res);

      if (res.data.output.client.client_cd) {
        setClientDetails(res.data.output.client);
        setClientMode("view");
      } else {
        alert("something went wrong");
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const getClientSuggest = async (searchText) => {
    if (searchText.length <= 0) {
      setNameSuggestions([]);
      return;
    }
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client_search_suggestion",
          client_name: searchText,
          system_cd: "lcc",
        },
      };
      console.log("getClientSuggestions input ", data);
      setNameLoader(true);
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client_name_suggestions`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getClientSuggestions output ", res.data.output.clients);

      if (res.data.output.clients.length > 0) {
        setNameSuggestions(res.data.output.clients);
      } else {
        setNameSuggestions([]);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    } finally {
      setNameLoader(false);
    }
  };

  return (
    <>
      {clientPageLables.length > 0 && (
        <>
          {/*Button bar ---------------------*/}
          <div>
            <div className="normal_float normal_text_align ">
              {clientMode !== "Initial" && (
                <>
                  <Tooltip title="Back" placement="bottom" arrow>
                    <IconButton onClick={set_clear_mode}>
                      <ArrowBackIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <span>{clientMode === "Draft" && clientMode}</span>{" "}
                  <span className="separator_border">
                    {clientDetails && clientDetails.client_cd}
                  </span>
                </>
              )}
            </div>
            <div className="opposite_float opposite_text_align">
              {(clientMode === "Edit" || clientMode === "Draft") && (
                <>
                  <Tooltip title="Save" placement="bottom" arrow>
                    <IconButton onClick={updateClient}>
                      <SaveIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {clientMode === "Initial" && (
                <>
                  <Tooltip title="add New" placement="bottom" arrow>
                    <IconButton onClick={() => setClientMode("Draft")}>
                      <LuUserPlus color="inherit" size={18} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Search" placement="bottom" arrow>
                    <IconButton>
                      {" "}
                      <SearchOutlinedIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {clientMode === "view" && (
                <>
                  <Tooltip title="Edit" placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        setClientMode("Edit");
                      }}
                    >
                      <ModeOutlinedIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
          {/*Client body ------------------*/}
          <div style={{ padding: "0px 6px" }}>
            {clientMode === "Initial" && (
              <>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[0].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    onKeyPress={search_keyEvent}
                    value={searchClient && searchClient.client_cd}
                    onChange={(e) =>
                      setSearchClient({
                        ...searchClient,
                        client_cd: e.target.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <label className="label_area" style={{ width: "150px" }}>
                    {clientPageLables[6].description}
                  </label>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      borderBottom: "1px solid gray",
                      width: "100%",
                    }}
                  >
                    {countries.length > 0 && (
                      <Select
                        id="country_area_cd"
                        options={countries}
                        defaultValue={countries[117]}
                        formatOptionLabel={(country) => (
                          <div className="country-option">
                            <img
                              src={`https://files.amanrow.com/flags/${country.country_id.toLowerCase()}.png`}
                              alt="flag"
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {country.label}
                            </span>
                          </div>
                        )}
                        onChange={(e) => {
                          console.log(e);
                          setSelctedDailCode(e.value);
                        }}
                      />
                    )}
                    <input
                      id="phone_no_s"
                      type="text"
                      autoComplete="0"
                      style={{
                        border: "none",
                        outline: "none",
                        flex: 1,
                      }}
                      className="editing_field"
                      onKeyPress={search_keyEvent}
                      value={searchClient && searchClient.phone_no}
                      onChange={(e) =>
                        setSearchClient({
                          ...searchClient,
                          phone_no: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="data_line" style={{ display: "flex" }}>
                  <label className="label_area">
                    {" "}
                    {clientPageLables[1].description}{" "}
                  </label>
                  <input
                    id="name_s"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    onChange={(e) => getClientSuggest(e.target.value)}
                  />
                </div>
                {isNameLoader && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span className="loader-spiner"></span>
                  </div>
                )}
                {nameSuggestions.length > 0 &&
                  nameSuggestions.map((client) => (
                    <div
                      key={client.client_cd}
                      style={{
                        width: "100%",
                        padding: "16px",
                        margin: "5px 0",
                        boxShadow: "25px 6px 46px 9px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        getClient(client.client_name, client.client_cd)
                      }
                    >
                      {client.client_name}
                    </div>
                  ))}
              </>
            )}
            {clientMode === "view" && (
              <>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[1].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.client_name}
                  </div>
                </div>
                {clientDetails && clientDetails.client_type_cd !== "1" && (
                  <>
                    <div style={{ display: "flex" }}>
                      <label className="label_area">
                        {clientPageLables[13].description}
                      </label>
                      <div class="viewing_field">
                        {clientDetails && clientDetails.client_type}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <label className="label_area">
                        {clientPageLables[14].description}
                      </label>
                      <div class="viewing_field">
                        {clientDetails && clientDetails.contact_name}
                      </div>
                    </div>
                  </>
                )}
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[2].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.client_id}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[3].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.sex}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[4].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.nationality}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[7].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.email_address}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[6].description}
                  </label>
                  <div class="viewing_field">
                    <img
                      src={`https://files.amanrow.com/flags/${clientDetails.country_id.toLowerCase()}.png`}
                      alt="flag"
                      className="flag_icon"
                    />
                    {clientDetails &&
                      "  (" +
                        clientDetails.country_area_cd +
                        ")" +
                        "  " +
                        clientDetails.phone_no}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[5].description}
                  </label>
                  <div class="viewing_field">
                    <img
                      src={`https://files.amanrow.com/flags/${clientDetails.country_id.toLowerCase()}.png`}
                      alt="flag"
                      className="flag_icon"
                    />
                    {clientDetails && " " + clientDetails.country_name}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[8].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.state_name}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[9].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.city_name}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[10].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.postal_cd}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[11].description}
                  </label>
                  <div class="viewing_field">
                    {clientDetails && clientDetails.client_address}
                  </div>
                </div>
              </>
            )}
            {(clientMode === "Draft" || clientMode === "Edit") && (
              <>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[1].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={clientDetails && clientDetails.client_name}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        client_name: e.target.value,
                      })
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[13].description}
                  </label>
                  <select
                    className="editing_field"
                    value={clientDetails && clientDetails.client_type_cd}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        client_type_cd: e.target.value,
                      })
                    }
                  >
                    <option value="0"></option>
                    {clientType.length >= 0 &&
                      clientType.map((client_type, index) => {
                        return (
                          <option key={index} value={client_type.code}>
                            {client_type.description}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {clientDetails && clientDetails.client_type_cd !== "1" && (
                  <>
                    <div style={{ display: "flex" }}>
                      <label className="label_area">
                        {clientPageLables[14].description}
                      </label>
                      <input
                        type="text"
                        className="editing_field"
                        autoComplete="0"
                        value={clientDetails && clientDetails.contact_name}
                        onChange={(e) =>
                          setClientDetails({
                            ...clientDetails,
                            contact_name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </>
                )}
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[2].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={clientDetails && clientDetails.client_id}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        client_id: e.target.value,
                      })
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[3].description}
                  </label>
                  <select
                    className="editing_field"
                    value={clientDetails && clientDetails.sex_cd}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        sex_cd: e.target.value,
                      })
                    }
                  >
                    <option value="0"></option>
                    {sex.length >= 0 &&
                      sex.map((sex, index) => {
                        return (
                          <option key={index} value={sex.code}>
                            {sex.description}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[4].description}
                  </label>
                  <select
                    className="editing_field"
                    value={clientDetails && clientDetails.nationality_cd}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        nationality_cd: e.target.value,
                      })
                    }
                  >
                    <option value="0"></option>
                    {countriesList.length >= 0 &&
                      countriesList.map((nationality, index) => {
                        return (
                          <option key={index} value={nationality.country_cd}>
                            {nationality.country_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[7].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={clientDetails && clientDetails.email_address}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        email_address: e.target.value,
                      })
                    }
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label className="label_area">
                    {clientPageLables[6].description}{" "}
                  </label>
                  <div
                    className="editing_field"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* <select
                        style={{
                        width: "50px",
                        border: "0px",
                        background: "none",
                        }}
                        value={clientDetails && clientDetails.country_area_cd}
                        onChange={(e) =>
                        setClientDetails({
                            ...clientDetails,
                            country_area_cd: e.target.value,
                        })
                        }
                    >
                        <option value="0"></option>
                        {countriesList.length >= 0 &&
                        countriesList.map((areaCode, index) => {
                            return (
                            <option key={index} value={areaCode.country_cd}>
                                {areaCode.country_area_cd}
                            </option>
                            );
                        })}
                    </select> */}

                    {countries.length > 0 && (
                      <Select
                        options={countries}
                        defaultValue={countries[117]}
                        formatOptionLabel={(country) => (
                          <div className="country-option">
                            <img
                              src={`https://files.amanrow.com/flags/${country.country_id.toLowerCase()}.png`}
                              alt=""
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {country.label}
                            </span>
                          </div>
                        )}
                        onChange={(e) => {
                          console.log(e);
                          setClientDetails({
                            ...clientDetails,
                            country_area_cd: e.value,
                          });
                          setSelctedDailCode(e.value);
                        }}
                      />
                    )}
                    {clientMode === "Draft" && (
                      <input
                        id="phone_no_s"
                        type="text"
                        autoComplete="0"
                        style={{
                          border: "none",
                          outline: "none",
                          flex: 1,
                        }}
                        className="editing_field"
                        onKeyPress={search_keyEvent}
                        value={searchClient && searchClient.phone_no}
                        onChange={(e) => {
                          setClientDetails({
                            ...clientDetails,
                            phone_no: e.target.value,
                          });
                          setSearchClient({
                            ...searchClient,
                            phone_no: e.target.value,
                          });
                        }}
                      />
                    )}
                    {clientMode !== "Draft" && (
                      <div>{clientDetails && clientDetails.phone_no}</div>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[5].description}
                  </label>
                  <select
                    className="editing_field"
                    value={clientDetails && clientDetails.country_cd}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        country_cd: e.target.value,
                      })
                    }
                  >
                    <option value="0"></option>
                    {countriesList.length >= 0 &&
                      countriesList.map((nationality, index) => {
                        return (
                          <option key={index} value={nationality.country_cd}>
                            {nationality.country_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[8].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={clientDetails && clientDetails.state_name}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        state_name: e.target.value,
                        state_cd: clientDetails.state_cd
                          ? clientDetails.state_cd
                          : 1,
                      })
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[9].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={clientDetails && clientDetails.city_name}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        city_name: e.target.value,
                      })
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[10].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={clientDetails && clientDetails.postal_cd}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        postal_cd: e.target.value,
                      })
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <label className="label_area">
                    {clientPageLables[11].description}
                  </label>
                  <input
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={clientDetails && clientDetails.client_address}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        client_address: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            )}
          </div>
          {/*Clients and opponents------------------*/}
          {clientsCaseOponents?.cases?.length > 0 && (
            <div style={{ padding: "10px" }}>
              <hr />
              <div style={{ fontWeight: "700", fontSize: "20px" }}>
                Case Details
              </div>
              <hr />

              {clientsCaseOponents.cases.map((caselist) => {
                return (
                  <div
                    style={{
                      padding: "5px",
                      margin: "5px",
                      boxShadow: "0px 12px 25px 1px rgb(105 109 112 / 10%)",
                      borderRadius: "8px",
                      background: "#f7f8fa",
                    }}
                  >
                    {console.log(caselist, "caselist")}
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        marginBottom: "5px",
                      }}
                    >
                      <label style={{ fontWeight: "700" }}>Case:</label>
                      <div>
                        {caselist.case_cd}, {caselist.case_subject}.
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        marginBottom: "5px",
                      }}
                    >
                      <label style={{ fontWeight: "700" }}>Casetype:</label>
                      <div>{caselist.case_type}.</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      <label style={{ fontWeight: "700" }}>Client :</label>
                      {clientsCaseOponents.clients.length > 0 &&
                        clientsCaseOponents.clients.filter(
                          (client_list) =>
                            caselist.case_cd === client_list.case_cd,
                        ).length > 0 &&
                        clientsCaseOponents.clients
                          .filter(
                            (client_list) =>
                              caselist.case_cd === client_list.case_cd,
                          )
                          .map((client_list, index, arr) => (
                            <>
                              <div key={client_list.client_name}>
                                {client_list.client_name}
                                {index < arr.length - 1 ? "," : "."}
                              </div>
                            </>
                          ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      <label style={{ fontWeight: "700" }}>Opponent:</label>
                      <div style={{ display: "flex", gap: "4px" }}>
                        {clientsCaseOponents.opponents.length > 0 &&
                          clientsCaseOponents.opponents.filter(
                            (client_list) =>
                              caselist.case_cd === client_list.case_cd,
                          ).length > 0 &&
                          clientsCaseOponents.opponents
                            .filter(
                              (client_list) =>
                                caselist.case_cd === client_list.case_cd,
                            )
                            .map((client_list, index, arr) => (
                              <div key={client_list.opponent_name}>
                                {client_list.opponent_name}
                                {index < arr.length - 1 ? "," : "."}
                              </div>
                            ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Clients;
