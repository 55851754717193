
import React, { useEffect, useState } from "react"

const attachments = ({ itemData }) => {

    

    return <>
        <div>
           
            {itemData.data1}

        </div>

    </>
}


export default attachments