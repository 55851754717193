

import React, { useEffect, useState, useRef } from "react"
import axios, { CancelToken, isCancel } from 'axios'
import { AiOutlineClose } from 'react-icons/ai'

import upload_vedio_icon from '../../../SVG_images/upload_vedio_icon.png'
import upload_image_icon from '../../../SVG_images/upload_image_icon.png'

const ItemMedia = ({ itemData }) => {

    const [media, setMedia] = useState({ mediaType: "", mediaURL: "" })
    const [uploadPercentage, setUploadPercentage] = useState(0)
    const cancelFileUpload = useRef(null)
    
    const media_path = 'http://files.amanrow.com/item_media/69/'


    const fileUpload = async () => {
        var files = document.getElementById('fileUpload_vedios').files
        console.log(files)
        var formdata = new FormData()
        //test.append(files[i].name, files[i])



        for (var i = 0; i < files.length; i++) {
   
            //console.log(files[i].name)
            //console.log(files[i])

            uploadFileOneybyOne(files[i].name,files[i])
        
        }

        
    }

    const cancelUpload = () => {
        if (cancelFileUpload.current)
            cancelFileUpload.current('User has canceled the file upload.')
    }

    const uploadFileOneybyOne = (fileName, file) => {
        let formData = new FormData()
        const path ="https://www.amanrow.com/UploadItemFilesHandler.ashx?cid=69&itemcd=2&usrcd=3"
        formData.append(fileName, file)
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                console.log(loaded, total)
                let percent = Math.floor((loaded * 100) / total)
                if (percent <= 100) {
                    setUploadPercentage(percent)
                }
            },
            cancelToken: new CancelToken(
                (cancel) => (cancelFileUpload.current = cancel)
            ),
        }

        axios
            .post(path, formData, options)
            .then((res) => {
                setUploadPercentage(0)
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
           
                setUploadPercentage(0)
                if (isCancel(err)) {
                    alert(err.message)
                    
                } else {
                   
                }
            })
    }

    return <>


        {itemData && itemData.item_videos && (
            <div style={{ pading: "10px", marginTop: "15px" }}>
                <div className="main_media_space">

                    {media.mediaType === "video" && (
                        <video src={media.mediaURL} className="media_image" controls />)
                    }

                    {media.mediaType === "image" && (
                   
                        < img src={media.mediaURL} className="media_image"/>)
                    }

                </div>
                <div>
                    {itemData.item_videos.length >= 0 && itemData.item_videos.map((videos, index) => {                    
                        return (
                            <div className="media_thumnails_div normal_float">
                                <img src={media_path + "video/thumb_" + videos.video_link.split(".")[0] + ".png"} className="media_image" onClick={() => {
                                    setMedia({mediaType: "video", mediaURL: `${media_path}/video/${videos.video_link}`})
                                }} />
                            </div>
                        )
                    }
                    )}
                    {itemData.item_images.length >= 0 && itemData.item_images.map((images, index) => {
                        return (
                            <div className="media_thumnails_div normal_float">
                                <img src={media_path + "images/" + images.image_link} className="media_image" onClick={() => {
                                    setMedia({mediaType: "image", mediaURL: `${media_path}/images/${images.image_link}`})
                                }} />
                            </div>
                        )
                    })}
                    <div style={{clear: "both" }}></div>
                </div>
                    
                <div style={{ marginTop: "20px" }}>
                    <div className="normal_float" style={{ width: "50%" }}>
                        <div id="video_progres_bars_area" className="normal_float">progress here</div>
                        <div className="opposite_float" style={{ margin: "10px" }} onClick={() => { document.getElementById("fileUpload_vedios").click() }}>
                            <img style={{ height: "50px" }} src={upload_vedio_icon} />
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>
                    <div className="normal_float" style={{ width: "50%" }}>
                        <div className=" normal_float" style={{ margin: "10px" }}>
                            <img style={{ height: "50px" }} src={upload_image_icon}  />
                        </div>
                        <div id="image_progres_bars_area" className="opposite_float">progress here</div>
                        <div style={{ clear: "both" }}></div>
                    </div>                   
                    <div style={{ clear: "both" }}></div>
                </div>

                <input type="file" style={{ display: "none" }} id="fileUpload_vedios" multiple onChange={fileUpload} accept="video/*" />
                {/*   <input type="button" style={{ display: "inline" }} id="btnUpload" value="Upload Files" />*/}

                {uploadPercentage > 0 && (
                    <div className='row mt-3'>
                        <div className='col pt-1'>
                            <div className='progress'>
                                <div
                                    className='progress-bar bg-success progress-bar-striped'
                                    role='progressbar'
                                    style={{ width: `${uploadPercentage}%`, height: '100%' }}
                                    aria-valuenow='100'
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                >
                                    {uploadPercentage}%
                                </div>
                            </div>
                        </div>
                        <div className='col-auto'>
                            <span
                                className='text-primary cursor-pointer'
                                onClick={() => cancelUpload()}
                            >
                                <AiOutlineClose />
                            </span>
                        </div>
                    </div>
                )}
                           
            </div>
        )}
    </>
}


export default ItemMedia