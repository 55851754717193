import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const ThemeEn = React.lazy(() => import("./ThemeEn"));
const ThemeAr = React.lazy(() => import("./ThemeAr"));

const ThemeLanguageSelector = ({ children, isFrom }) => {
  const { user } = useSelector((state) => state);

  useEffect(() => {
    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    if (user.lang) {
      if (userDataLocal)
        if (userDataLocal.lang !== user.lang) {
          localStorage.setItem(
            "amanrowuserData",
            JSON.stringify({
              user_cd: userDataLocal.user_cd,
              company_cd: userDataLocal.company_cd,
              lang: user.lang,
              login_name: userDataLocal.login_name,
              email: userDataLocal.email_address,
              token: userDataLocal.token,
            }),
          );
          window.location.reload();
        }
    }
  }, [user.lang]);
  return (
    <>
      <React.Suspense fallback={<> </>}>
        {user.lang === "en" && <ThemeEn />}
        {user.lang === "ar" && <ThemeAr />}
      </React.Suspense>
      {children}
    </>
  );
};

export default ThemeLanguageSelector;
