import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { FaRegUser, FaUserLock } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";

import Select from "react-select";
import { IoSearch } from "react-icons/io5";

import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FaArrowRight } from "react-icons/fa";
import styles from "./style/LoginForm.module.css";
import "./style/login.css";

const Login = () => {
  const dispatch = useDispatch({});
  const [pageMode, setpageMode] = useState("Login");
  const [loginNameExist, setLoginNameExist] = useState(false);
  const [registrationDetail, setRegistrationDetail] = useState({
    country_area_cd: 965,
  });

  const [countries, setCountries] = useState(false);
  const [countryIndex, setCountryIndex] = useState(0);
  const [selctedDailCode, setSelctedDailCode] = useState("965");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
        },
        input: {
          actioncode: "COUNTRY_DAILCODES",
        },
      };

      console.log("get_counties ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/countries`,
        data,
      );
      console.log("get_counties output ", res.data.output.countries);

      setCountries(res.data.output.countries);

      //const countryIndex = res.data.output.countries.findIndex((country) => country.dial_cd === "965",);

      //console.log("kuwait index", countryIndex);
      setCountryIndex(countryIndex);

      //let options = res.data.output.countries.map((country) => {
      //return {
      //    value: country.dial_cd,
      //    label: "+" + country.dial_cd,
      //    _id: country.country_cd,
      //    country_id: country.country_id,
      //    dial_cd: country.dial_cd,
      //};
      //});

      //console.log("kuwait dailcd", options[countryIndex]);
      //setCountries(options);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const regester_client = () => {
    alert("will register here");
    /* check_login_name()*/
    client_registration();
  };

  const login_name_keyEvent = (e) => {
    if (e.charCode === 13) {
      check_login_name();
    }
  };

  // Read ----------------------
  const check_login_name = async () => {
    /*do validation here*/
    if (userName == "") {
      return;
    }
    try {
      const data = {
        info: {
          lang: "en",
          company: 0,
          user_cd: 0,
        },
        input: {
          actioncode: "check login",
          login_name: userName,
        },
      };

      console.log("check_login_name input ", data);

      let res = await axios.post(
        process.env.REACT_APP_BACK_END_URL + "/api/IIP/check_login",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        },
      );

      console.log("check_login_name output", res);
      if (!res.data.output.login_name) {
        setLoginNameExist(false);
      } else {
        setLoginNameExist(true);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const check_profile_exist = async () => {
    /*do validation here*/

    try {
      const data = {
        info: {
          lang: "en",
          company: 0,
          user_cd: 0,
        },
        input: {
          actioncode: "check client profile",
          country_area_cd: registrationDetail.country_area_cd.trim(),
          phone_no: registrationDetail.phone_no.trim(),
          client_id: registrationDetail.client_id.trim(),
        },
      };

      console.log("check client profile input ", data);
      return;
      let res = await axios.post(
        process.env.REACT_APP_BACK_END_URL + "/api/IIP/check client profile",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        },
      );

      console.log("check client profile output", res);
      if (!res.data.output.login_name) {
        setLoginNameExist(false);
      } else {
        setLoginNameExist(true);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  // Update ----------------------
  const client_registration = async (emp_code) => {
    try {
      const data = {
        info: {
          lang: "en",
          company: 0,
          user_cd: 0,
        },
        input: {
          actioncode: "client registration",
          country_area_cd: registrationDetail.country_area_cd,
          phone_no: registrationDetail.phone_no,
          client_id: registrationDetail.client_id,
          email: registrationDetail.email,
          login_name: registrationDetail.login_name,
          login_password: registrationDetail.login_password,
        },
      };

      console.log("client_registration input ", data);
      return;
      let res = await axios.post(
        process.env.REACT_APP_BACK_END_URL + "/api/IIP/client_registration",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        },
      );

      console.log("client_registration output", res);
      setpageMode("creditintial");
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_credietitial = async (e) => {
    //console.log(process.env.REACT_APP_BACK_END_URL);
    e.preventDefault();

    if (!password) {
      setError("password Required");
      return;
    }

    if (!userName) {
      setError("User Name Required");
      return;
    }
    try {
      const data = {
        info: {
          lang: "EN",
        },
        input: {
          login_name: userName,
          login_password: password,
          actioncode: "1",
        },
      };
      console.log("login input ", data);

      let res = await axios.post(
        process.env.REACT_APP_BACK_END_URL + "/api/iipsys/v1/login",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        },
      );

      if (res.data.output.user_cd) {
        if (typeof Storage !== undefined) {
          localStorage.setItem(
            "amanrowuserData",
            JSON.stringify({
              user_cd: res.data.output.user_cd,
              company_cd: res.data.output.company_cd,
              lang: res.data.output.language_cd,
              login_name: userName,
              email: res.data.output.email_address,
              token: res.data.result.token,
            }),
          );

          const data = {
            user_cd: res.data.output.user_cd,
            company_cd: res.data.output.company_cd,
            user_language_cd: res.data.output.language_cd,
            login_name: userName,
            email: res.data.output.email_address,
            token: res.data.result.token,
          };

          dispatch({ type: "USERINFO", payload: data }); //to store in redux
        } else {
          alert("update the browser");
        }
        window.location = "/main";
      } else {
        setError("something went wrong");
      }
    } catch (err) {
      // Check for custom error message from backend
      if (err.response && err.response.data && err.response.data.Message) {
        setError(err.response.data.Message); // Custom error message from the backend
      } else {
        setError("Failed to login: " + err.message); // Generic error handling
      }
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.brand}>
            <img
              src="/images/amanrow-logo.png"
              alt="amanrow"
              style={{ height: "35px" }}
            />
          </span>
          <span style={{ fontWeight: "700" }}>By ItegrityPro</span>
        </div>
        {pageMode === "Login" && (
          <>
            <form className={styles.form} onSubmit={get_credietitial}>
              <h2>Welcome Back</h2>
              {error && <p className={styles.errorMessage}>{error}</p>}
              <div className={styles.inputGroup}>
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <button
                type="submit"
                className={styles.signInButton}
                disabled={loading}
              >
                {loading ? "Loading..." : "Sign In"}
              </button>
              <div
                className={styles.createAccount}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setpageMode("Registration");
                }}
              >
                <span>As a Client</span>{" "}
                <span style={{ textDecoration: "underline" }}>
                  Create an account
                </span>
              </div>
            </form>
          </>
        )}
        {pageMode === "Registration" && (
          <div className={styles.form}>
            <div className="my-5">
              <h6
                className="text-center login-msg-text"
                style={{ lineHeight: "30px" }}
              >
                <div>Please enter your information in law firm office</div>
                <div>الرجاء إدخال المعلومات الخاصة بك في مكتب محاماة</div>
              </h6>
            </div>
            <div className="one_line_data">
              {console.log(
                "registrationDetail.country_area_cd",
                registrationDetail.country_area_cd,
              )}
              <select
                className="regester_editing_field"
                onChange={(e) =>
                  setRegistrationDetail({
                    ...registrationDetail,
                    country_area_cd: e.target.value,
                  })
                }
                defaultValue={registrationDetail.country_area_cd}
                value={registrationDetail.country_area_cd}
              >
                <option style={{ color: "gray" }} value="">
                  Select Country
                </option>
                {countries.length >= 0 &&
                  countries.map((country, index) => {
                    /*console.log(country)*/
                    return (
                      <option key={index} value={country.dial_cd}>
                        {country.country_name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="one_line_data">
              <input
                type="text"
                className="regester_editing_field"
                style={{ width: "50px" }}
                value={registrationDetail && registrationDetail.country_area_cd}
                onChange={(e) =>
                  setRegistrationDetail({
                    ...registrationDetail,
                    country_area_cd: e.target.value,
                  })
                }
              />
              <div className="mobile_seperator">|</div>
              <input
                type="text"
                className="regester_editing_field"
                placeholder="Mobile No. رقم الهاتف"
                value={registrationDetail && registrationDetail.phone_no}
                onChange={(e) =>
                  setRegistrationDetail({
                    ...registrationDetail,
                    phone_no: e.target.value,
                  })
                }
              />
            </div>
            {/*<div className="one_line_data">*/}
            {/*    */}
            {/* <div style={{ width: "12%" }}>*/}
            {/*
             */}
            {/*    <input type="text" className="regester_editing_field" placeholder="Mobile No. رقم الهاتف"*/}
            {/*
             */}
            {/*        value={registrationDetail && registrationDetail.country_area_cd}*/}
            {/*
             */}
            {/*        onChange={(e) => setRegistrationDetail({...registrationDetail, country_area_cd: e.target.value})}*/}
            {/*
             */}
            {/*    />*/}
            {/*
             */}
            {/*    </div> */}
            {/*    <div className="seachbox-main-container">*/}
            {/*        */}
            {/* <input type="text" className="regester_editing_field" placeholder="Mobile No. رقم الهاتف"*/}
            {/*
             */}
            {/*            value={registrationDetail && registrationDetail.phone_no} onChange={(e) => setRegistrationDetail({ ...registrationDetail, phone_no: e.target.value })} /> */}

            {/*        <div className="login-search-input-container">*/}
            {/*            {countries.length > 0 && (*/}
            {/*            <Select*/}
            {/*                options={countries} defaultValue={countries[117]} formatOptionLabel={(country) => (*/}
            {/*                    <div className="country-option">*/}
            {/*                        <img src={`https://files.amanrow.com/flags/${country.country_id.toLowerCase()}.png`}alt="country-image"/>*/}
            {/*                        <span style={{ marginLeft: "10px" }}>{country.label}</span>*/}
            {/*                    </div>*/}
            {/*                )}*/}
            {/*                onChange={(e) => {*/}
            {/*                console.log(e);*/}
            {/*                setSelctedDailCode(e.value)}}/>*/}
            {/*                )}*/}
            {/*            <input type="text" id="client_mobile" name="phone" className="phoneno-input-container" placeholder="Mobile No. رقم الهاتف" />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="one_line_data">
              <input
                id="user_name"
                type="text"
                className="regester_editing_field"
                placeholder="Id. number رقم الهوية"
                value={registrationDetail && registrationDetail.client_id}
                onChange={(e) =>
                  setRegistrationDetail({
                    ...registrationDetail,
                    client_id: e.target.value,
                  })
                }
              />
            </div>
            <div
              style={{
                minHeight: "50px",
                textAlign: "center",
                color: "red",
                marginTop: "20px",
              }}
            >
              Messages here
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "space-between",
                margin: "10px auto",
              }}
            >
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                onClick={() => {
                  setpageMode("Login");
                }}
              >
                <ArrowBackIcon color="inherit" fontSize="16" />
                Back
              </button>
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                onClick={() => {
                  check_profile_exist();
                }}
              >
                Next <FaArrowRight color="inherit" fontSize="16" />
              </button>
            </div>
          </div>
        )}
        {pageMode === "Verefication" && (
          <div>
            <div className="my-5">
              <h6
                className="text-center login-msg-text"
                style={{ lineHeight: "30px" }}
              >
                <div>Please enter your information in law firm office</div>
                <div>الرجاء إدخال المعلومات الخاصة بك في مكتب محاماة</div>
              </h6>
            </div>
            <div className="one_line_data">
              {/* <div style={{ width: "12%" }}>
                            <input type="text" className="regester_editing_field" placeholder="Mobile No. رقم الهاتف"
                                value={registrationDetail && registrationDetail.country_area_cd}
                                onChange={(e) => setRegistrationDetail({...registrationDetail, country_area_cd: e.target.value})}
                            />
                            </div> */}
              <div className="seachbox-main-container">
                {/* <input type="text" className="regester_editing_field" placeholder="Mobile No. رقم الهاتف"
                                    value={registrationDetail && registrationDetail.phone_no} onChange={(e) => setRegistrationDetail({ ...registrationDetail, phone_no: e.target.value })} /> */}

                <div className="login-search-input-container">
                  {countries.length > 0 && (
                    <Select
                      options={countries}
                      defaultValue={countries[117]}
                      formatOptionLabel={(country) => (
                        <div className="country-option">
                          <img
                            src={`https://files.amanrow.com/flags/${country.country_id.toLowerCase()}.png`}
                            alt="country-image"
                          />
                          <span style={{ marginLeft: "10px" }}>
                            {country.label}
                          </span>
                        </div>
                      )}
                      onChange={(e) => {
                        console.log(e);
                        setSelctedDailCode(e.value);
                      }}
                    />
                  )}
                  <input
                    type="text"
                    id="client_mobile"
                    name="phone"
                    className="phoneno-input-container"
                    placeholder="Mobile No. رقم الهاتف"
                  />
                </div>
              </div>
            </div>
            <div className="one_line_data">
              <input
                id="user_name"
                type="text"
                className="regester_editing_field"
                placeholder="Id. number رقم الهوية"
                value={registrationDetail && registrationDetail.client_id}
                onChange={(e) =>
                  setRegistrationDetail({
                    ...registrationDetail,
                    client_id: e.target.value,
                  })
                }
              />
            </div>
            <div className="one_line_data">
              <input
                id="email_address"
                type="text"
                className="regester_editing_field"
                placeholder="Email البريد الالكتروني"
                value={registrationDetail && registrationDetail.email}
                onChange={(e) =>
                  setRegistrationDetail({
                    ...registrationDetail,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div
              style={{
                minHeight: "50px",
                textAlign: "center",
                color: "red",
              }}
            >
              Fadel
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#04AA6D",
                  border: "none",
                  color: "white",
                  padding: "15px 32px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inlineBlock",
                  fontSize: "16px",
                }}
                onClick={() => {
                  setpageMode("Login");
                }}
              >
                <ArrowBackIcon color="inherit" fontSize="small" />
                back
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px 32px",
                  minWidth: "50px",
                  border: "solid 1px gray",
                }}
                onClick={() => {
                  client_registration();
                }}
              >
                Next <FaArrowRight color="inherit" fontSize="16" />
              </div>
            </div>
          </div>
        )}
        {pageMode === "creditintial" && (
          <div>
            <div className="my-5">
              <h6
                className="text-center login-msg-text"
                style={{ lineHeight: "30px" }}
              >
                <div>Please enter your login information</div>
                <div>رجى إدخال معلومات تسجيل الدخول الخاصة بك</div>
              </h6>
            </div>
            <div className="one_line_data">
              <div style={{ width: "50%" }}>
                <input
                  id="login_name"
                  type="text"
                  className="regester_editing_field"
                  placeholder="sgn in name رمز الدخول"
                  onKeyPress={(e) => login_name_keyEvent(e)}
                  onBlur={() => {
                    check_login_name();
                  }}
                />
              </div>
              <div style={{ width: "50%" }}>
                {loginNameExist && (
                  <span class="error_message">user name already taken</span>
                )}
              </div>
            </div>
            <div className="one_line_data" style={{ width: "50%" }}>
              <input
                id="login_password"
                type="text"
                className="regester_editing_field"
                placeholder="Password الرقم السري"
              />
            </div>
            <div className="login-form">
              <div className="login-icon">
                <i className="bi bi-person"></i>
              </div>
              <input
                id="login_password_confirm"
                type="text"
                className="regester_editing_field"
                placeholder="Password confirm  تاكيد الرقم السري"
              />
            </div>
            <div className="login-form text-center">
              <button
                className="btn login-btn"
                onClick={() => {
                  regester_client();
                }}
              >
                Register <i className="bi bi-arrow-right"></i>
              </button>
            </div>
            <div style={{ width: "50%", marginTop: "20px" }}>
              <div
                style={{
                  backgroundColor: "#04AA6D",
                  border: "none",
                  color: "white",
                  padding: "15px 32px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inlineBlock",
                  fontSize: "16px",
                  float: "left",
                }}
                onClick={() => {
                  setpageMode("Registration");
                }}
              >
                <ArrowBackIcon color="inherit" fontSize="small" />
                back
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 6px",
                  margin: "6px",
                  minWidth: "50px",
                  height: "30px",
                  border: "solid 1px gray",
                  float: "right",
                }}
                onClick={() => {
                  alert("will register");
                }}
              >
                Continue <FaArrowRight color="inherit" fontSize="16" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
