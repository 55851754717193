import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { IoSearch } from "react-icons/io5";


import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { AiFillPrinter } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";
import { BsSend } from "react-icons/bs";
import { TiAttachment } from "react-icons/ti"
import { GoCheckCircle } from "react-icons/go";
import { MdOutlineCheck } from "react-icons/md";
import { MdOutlineExpandMore } from "react-icons/md";


import ImageCard from "../cards/ImageCard";
import { useSelector } from "react-redux"; //to get the value from the redux

const DailyEntry = () => {

    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    const [branchCode, setBranchCode] = useState(userDataLocal.branch_cd);
    const [branchIndex, setBranchIndex] = useState(null);
    const [branchTree, setBranchTree] = useState(false);
    const [entryDetail, setEntryDetail] = useState({});
    const [entryMode, setEntryMode] = useState("Initial");
    const [requestTypetData, setrequestTypetData] = useState();
    const [customerTypetData, setCustomerTypetData] = useState();
    const [requesInitialstData, setRequesInitialstData] = useState();
    
    const [shipToData, setShipToData] = useState("byer");
    const [tapActive, setTapActive] = useState("Items");
    const [itemCodeRow, setItemCodeRow] = useState();
    const [itemData, setItemData] = useState([]);
    const [clientInvoices, setClientInvoices] = useState([]);
    const [orgenizationStructure, setOrgenizationStructure] = useState([]);
    const [pageLables, setPageLables] = useState([]);
    const [actions, setActions] = useState([]);
    const [invoiceYear, setInvoiceYear] = useState();
    const [branchCd, setBranchCd] = useState();
    const [invoiceCd, setInvoiceCd] = useState();
    const [countries, setCountries] = useState(false);
    const [countryIndex, setCountryIndex] = useState(0);
    const [selctedDailCode, setSelctedDailCode] = useState("965");

    const [media, setMedia] = useState({ mediaType: "", mediaURL: "" });
    const media_path = "http://files.amanrow.com/item_media/69/";

    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const [RequestInput, setRequestInput] = useState({
    request_year: 0,
    branch_cd: 0,
    request_cd: 0,
    });

    useEffect(() => {
        if (user.userData) {
           get_daily_entry_initial();
        }
    }, []);

    const onlytest = () => {
    alert("ok")
    };

    const set_clear_mode = () => {
        switch (entryMode) {
            case "Edit":
                setEntryMode("View");
            break;
            case "View":
                setEntryMode("Search")
                break
            case "Draft":
                
                setEntryMode("initial");
                
                break;
            default:
            // code block
        }
    };

    const { user } = useSelector((state) => {
    return state;
    });
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };

    const getCountries = async () => {
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
        info: {
            lang: userDataLocal.lang,
        },
        input: {
            actioncode: "COUNTRY_DAILCODES",
        },
        };

        console.log("get_counties ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/countries`,
        data,
        );
        console.log("get_counties output ", res.data.output.countries);

        const countryIndex = res.data.output.countries.findIndex(
        (country) => country.dial_cd === "965",
        );

        console.log("kuwait index", countryIndex);
        setCountryIndex(countryIndex);

        let options = res.data.output.countries.map((country) => {
        return {
            value: country.dial_cd,
            label: "+" + country.dial_cd,
            _id: country.country_cd,
            country_id: country.country_id,
            dial_cd: country.dial_cd,
        };
        });

        console.log("kuwait dailcd", options[countryIndex]);
        setCountries(options);
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    const handleClose = () => {
    setAnchorEl(null);
    };

    const set_new_mode = () => {
        setEntryMode("Draft");
       
    };

    const mobilekeyEvent = (e, dailcd) => {
    if (e.charCode === 13) {
        get_client_detail_invoices(
        document.getElementById("client_mobile").value,
        dailcd,
        );
    }
    };

    function select_branch(branch_cd, index) {
        setBranchIndex(index)
        setBranchCode(branch_cd)   
        setBranchTree(!branchTree)
    }

    function toggle_branch(branch_id) {      
        if (document.getElementById(branch_id).style.display === "block") {
            document.getElementById(branch_id).style.display = "none";
        } else {
            document.getElementById(branch_id).style.display = "block";
        }
    }

    const orgenization_subnode = (parent_id) => {
        return orgenizationStructure.map((tree_node, i) => {
            if (parent_id === tree_node.parent_id) {
                return (
                    <div key={i}>
                        <div key={i} className="user_line">
                            <div className="node_line">
                                <div style={{ display: "flex" }}>
                                    <div className="icon_tree_area"><ImageCard icon={tree_node.branch_type_cd} iconType={"organization tree"} /></div>
                                    <div className="node_name_area" onClick={() => { toggle_branch(`branch_${tree_node.branch_id}`) }}>{tree_node.branch_name}</div>
                                    <div style={{ padding: "0px 4px", width: "20px" }}>
                                        {tree_node.branch_cd === branchCode ?
                                            <GoCheckCircle size="20" style={{ color: "green" }} />
                                            :
                                            <div className="flip_display_area" style={{ width: "100%" }} onClick={() => { select_branch(tree_node.branch_cd, i) }}><MdOutlineCheck size="18" /></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id={`branch_${tree_node.branch_id}`} className="node_block_section left_block_border" style={{ display: "none" }}>
                            {orgenization_subnode(tree_node.branch_id)}
                        </div>
                    </div>
                );
            }
        });
    };

    /* Read*/
   const get_daily_entry_initial = async () => {
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: user.userData.user_language_cd,
                    company: user.userData.company_cd,
                    user_cd: user.userData.user_cd,
                },
                input: {
                    actioncode: "get_daily_entry_initials",
                    company_cd: userDataLocal.company_cd,
                    branch_cd: user.userData.branch_cd,
                    system_cd: "Acc",
                    function_cd: "3"
                },
            };

            console.log("get_daily_entry_initial input = ", data);
           
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_daily_entry_initials`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("get_daily_entry_initial output = ", res);

            if (res.data.output.pages_lables) { setPageLables(res.data.output.pages_lables) }
            if (res.data.output.orgenization_tree.length > 0) {
                setOrgenizationStructure(res.data.output.orgenization_tree)
                res.data.output.orgenization_tree.map((tree_node, i) => {
                    if (branchCode === tree_node.branch_cd) {setBranchIndex(i) }
                });
            }
           
            //if (res.data.output.chart_of_account && res.data.output.chart_of_account.length > 0) {
            //    setChartOfAccount(res.data.output.chart_of_account) 
            //}else{
            //    setChartOfAccount([])
            //}
            //if (res.data.output.account_types && res.data.output.account_types.length > 0) {
            //    setAccountTypes(res.data.output.account_types) 
            //}else{
            //    setAccountTypes([])
            //}
                
            //if (res.data.output.currency) { setCurrency(res.data.output.currency) }
            //if (res.data.output.country) { setCountries(res.data.output.country) }
            //if (res.data.output.state) { setStates(res.data.output.state) }
            //if (res.data.output.date_format) { setDateFormat(res.data.output.date_format) }

            //if (res.data.output.branch.branch_cd) {
            //    setAccountDetails("View");

            //} else {
            //    setAccountDetails("Initial");
            //}

        } catch (err) {
            console.log(err);
            //alert("error message" + err.message);
        }
    };

    const get_client_detail_invoices = async (mobile_no, dailcd) => {
    setEntryDetail({});
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        /*let client_mobil_no = document.getElementById('client_mobile').value*/
        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "get_client_invoices",
            country_area_cd: dailcd,
            mobile_no: mobile_no,
        },
        };
        console.log("get_client_detail_invoices input", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_client_invoices`,
        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );
        console.log("get_client_detail_invoices output", res);

        if (res.data.output.client_details) {
        setEntryDetail(res.data.output.client_details);
        if (
            res.data.output.client_invoices &&
            res.data.output.client_invoices.length > 0
        ) {
            setClientInvoices(res.data.output.client_invoices);
        }
        } else {
        alert("no data");
        }
        console.log("client invoices", res);
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    const getInvoice = async (invoice_year, branch_cd, invoice_cd) => {
        setInvoiceYear(invoice_year);
        setBranchCd(branch_cd);
        setInvoiceCd(invoice_cd);
        setEntryMode("Retrieve");
    };


    var inActiveTap = "normal_float normal_text_align tap_head_area";
    var activeTap = "normal_float normal_text_align tap_head_area active_tap_head_area";

    return (
    <>       
        <div>
            {/*Button bar ---------------------*/}
            <div className="button_bar"> 
                <div style={{display:"flex", alignItems:"center"}}>
                    {(entryMode !== "Draft" && entryMode !== "Search" && entryMode !== "Initial") && (
                        <>
                            <Tooltip title="Back" placement="bottom" arrow>
                                <IconButton onClick={set_clear_mode}>
                                    <ArrowBackIcon color="inherit"fontSize="small"/>
                                </IconButton>    
                            </Tooltip>
                            <span className="separator_border"></span> 
                        </>
                    )}
                    <div style={{padding:"0px 8px"}}>
                        {entryMode !== "Initial" && <>Entry cod</>}
                        {entryMode === "Initial" && <>{pageLables.length > 0 && pageLables[0].description}</>}
                        {entryMode === "Draft" && <><span className="separator_border"></span> {pageLables.length > 0 && pageLables[1].description}</>}                        
                        {entryMode === "Edit" && <><span className="separator_border"></span> {pageLables.length > 0 && pageLables[2].description}</>}
                    </div>
                </div>
                <div id="daily_entry_msg" className="worning_msg">error message</div>
                <div>
                    {entryMode === "Initial" && (
                        <>
                             <Tooltip title="Search" placement="bottom" arrow>
                                <IconButton  onClick={() => {setEntryMode("Search")}}>
                                    <SearchOutlinedIcon color="inherit" fontSize="small"/>
                                </IconButton>
                            </Tooltip> 
                            <Tooltip title="Save" placement="bottom" arrow>
                                <IconButton onClick={() => {setEntryMode("Draft")}}>
                                    <SaveIcon color="inherit" fontSize="small" />
                                </IconButton>
                            </Tooltip>       
                        </>
                    )}
                    {entryMode === "Draft" &&  (
                        <>
                            
                            <Tooltip title="Search" placement="bottom" arrow>
                                <IconButton  onClick={() => {setEntryMode("Search")}}>
                                    <SearchOutlinedIcon color="inherit" fontSize="small"/>
                                </IconButton>
                            </Tooltip> 
                            <Tooltip title="Attachment" placement="bottom" arrow>
                                <IconButton onClick={() => {onlytest()}}>
                                    <TiAttachment color="inherit"fontSize="24"/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Post" placement="bottom" arrow>
                                <IconButton onClick={() => { onlytest()}}>
                                    <BsSend color="inherit"fontSize="19px"/>
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    {entryMode === "View" && (
                        <> 
                            <Tooltip title="Edit" placement="bottom" arrow>
                                    <IconButton onClick={() => {setEntryMode("Edit")}}>
                                        <ModeOutlinedIcon color="inherit"fontSize="small"/>
                                    </IconButton>
                            </Tooltip>
                            <Tooltip title="Print" placement="bottom" arrow>
                                <IconButton onClick={() => {onlytest()}}>
                                    <AiFillPrinter color="inherit"fontSize="19px"/>
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    {entryMode === "Search" && (
                        <>
                           <Tooltip title="add New" placement="bottom" arrow>
                                <IconButton onClick={() => {setEntryMode("Draft")}}>
                                    <InsertDriveFileOutlinedIcon color="inherit"fontSize="small"/>
                                </IconButton>
                            </Tooltip>   
                        </>
                    )}
                    {entryMode === "Edit" && (
                        <>                           
                            <Tooltip title="Void" placement="bottom" arrow>
                                <IconButton onClick={() => { onlytest()}}>
                                    <FcCancel  color="inherit"fontSize="19px"/>
                                </IconButton>
                            </Tooltip>
                            
                            <Tooltip title="Delete" placement="bottom" arrow>
                                <IconButton onClick={() => { onlytest()}}>
                                    <BsTrash3 color="inherit"fontSize="19px"/>
                                </IconButton>
                            </Tooltip>
                            
                        </>
                    )}
                </div>
            </div> 
            
            {/*Daily entry body*/}
            <div>
                {entryMode === "Search" && (
                    <>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="data_line" style={{ display: "flex" }}>
                                    <label className="label_area"> {pageLables.length > 0 && pageLables[4].description} </label>
                                    <div className="editing_field" style={{display:"flex", alignItems: "center", border:"solid 1px gray"}} onClick={() => setBranchTree(!branchTree)}>                       
                                        <div>{orgenizationStructure.length > 0 && branchIndex !== null && orgenizationStructure[branchIndex].branch_name}</div>
                                        <div style={{ padding: "0px 6px" }}><MdOutlineExpandMore size="18" /></div>
                                                
                                        {branchTree &&
                                        <div className="branch_menu" >
                                            {orgenizationStructure.map((tree_node, i) => {
                                                if (i === 0)
                                                    return (
                                                        <>
                                                            <div key={i} className="user_line">
                                                                <div className="node_line">
                                                                    <div style={{ display: "flex" }}>
                                                                        <div className="icon_tree_area"><ImageCard icon={tree_node.branch_type_cd} iconType={"organization tree"} /></div>
                                                                        <div className="node_name_area" onClick={() => { toggle_branch(`branch_${tree_node.branch_id}`) }}>{tree_node.branch_name}</div>
                                                                        <div style={{ padding: "0px 4px", width: "20px" }} >
                                                                            {tree_node.branch_cd === branchCode ?
                                                                                <GoCheckCircle size="20" style={{color:"green"}} />
                                                                                :
                                                                                <div className="flip_display_area" style={{ width: "100%" }} onClick={() => { select_branch(tree_node.branch_cd, i) }}><MdOutlineCheck size="18"/></div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id={`branch_${tree_node.branch_id}`} className="node_block_section left_block_border">
                                                                {orgenization_subnode(tree_node.branch_id)}
                                                            </div>
                                                        </>
                                                    );
                                            })}
                                        </div>
                                    }
                                    </div>  
                                </div>
                                <div className="data_line" style={{ display: "flex" }}>
                                    <label className="label_area"> {pageLables.length > 0 && pageLables[8].description} </label>
                                    <div style={{ display: "flex", alignItems:"center", justifyContent: "space-between", width:"100%" }}>
                                        <input type="text" className="editing_field" autoComplete="0" placeholder="DD-MM-YYYY"/> 
                                        <label className="label_area"> {pageLables.length > 0 && pageLables[9].description} </label>
                                        <input type="text" className="editing_field" autoComplete="0" placeholder="DD-MM-YYYY"/> 
                                    </div>
                                </div>
                            </div>
                                                            
                                 <div id="tabs_div" className="profilemenu-scroll" style={{ borderBottom: "solid 1px #ccc", marginTop:"10px" }}>
                                    <div className={tapActive === "Location" ? activeTap : inActiveTap} onClick={() => { setTapActive("Drafts"); }}>Location</div>
                                    <div className={tapActive === "Banks" ? activeTap : inActiveTap} onClick={() => { setTapActive("Posted"); }}>Banks</div>
                                    <div className={tapActive === "Cashiers" ? activeTap : inActiveTap} onClick={() => { setTapActive("Void"); }}>Cashiers</div>
                                    <div style={{ clear: "both" }}></div>
                                </div>
     
                                                                     
                            <div id="entries_list_area" >
                                <div style={{borderBottom: "1px solid #ccc !important", fontWeight:"bold", height:"26px"}}>
                                    <div style={{float:"left", width:"20%"}}>Date</div>
                                    <div style={{float:"left", width:"60%"}}>Entry Description</div>
                                    <div style={{float:"left", width:"19%"}}>posted Date</div>
                                    <div style={{clear:"both"}}></div>
                                </div>
                                <div id="entries_lines"></div>
                            </div>
                        </div>
                    </>
                )}
                {(entryMode === "Draft" || entryMode === "Initial") && (
                    <>
                        <div style={{height: "calc(100vh - 150px)", marginRight:"0 !important", marginTop:"20px"}}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div style={{padding:"0px 6px"}}>
                                        <div className="data_line" style={{ display: "flex" }}>
                                            <label className="label_area"> {pageLables.length > 0 && pageLables[4].description} </label>
                                            <div className="editing_field">                       
                                                <div>{orgenizationStructure.length > 0 && branchIndex !== null && orgenizationStructure[branchIndex].branch_name}</div>
                                            </div>
                                        </div>
                                        
                                        <div className="data_line" style={{ display: "flex" }}>
                                            <label className="label_area"> {pageLables.length > 0 && pageLables[5].description} </label>
                                            <input id="Date" type="text" className="editing_field" autoComplete="0" /> 
                                        </div>
                                        <div className="data_line" style={{ display: "flex" }}>
                                            <label className="label_area"> {pageLables.length > 0 && pageLables[6].description} </label>
                                            <input id="Date" type="text" className="editing_field" autoComplete="0" /> 
                                        </div>
                                        <div className="data_line" style={{ display: "flex" }}>
                                            <label className="label_area"> {pageLables.length > 0 && pageLables[7].description} </label>
                                            <input id="Date" type="text" className="editing_field" autoComplete="0" placeholder="DD-MM-YYYY"/> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                Attacments
                                </div>
                                
                            </div>
                                         
                        </div>
                    </>
                )}
                {entryMode === "Others" && (
                    <>  
                        <div id="daily_entry_list" >
                            <div id="list_header">
                                <div style={{borderBottom: "2px solid #ccc !important", fontWeight:"bold", height:"26px", marginTop:"15px"}}>
                                    <div style={{float:"left", width:"2%", textAlign: "center", color:"transparent"}}>#</div>
                                    <div style={{float:"left", width:"30%"}} className="text_aligenment">Account</div>
                                    <div style={{float:"left", width:"30%"}} className="textAligenment">Description</div>
                                    <div id="taxrate_h" style={{float:"left", width:"10%", textAlign:"center"}} >Tax Rate</div>
                                    <div style={{float:"left", width:"13%"}} className="center_aligenment" >Debit</div>
                                    <div style={{float:"left", width:"13%"}} className="center_aligenment">Credit</div>
                                    <div style={{float:"left", width:"2%", color:"transparent"}} className="number_aligenment">#</div>
                                    <div style={{clear:"both"}}></div>
                                </div>
                                <div id="new_account_edit_area">
                                    <div className="inputs_div" style={{width:"2%"}}>
                                        <input id="account_no" className="inputs " type="text" />
                                    </div>
                                    <div  className="inputs_div "  style={{width:"30%"}}>
                                        <div id="account_name_area" style={{display:"block"}}>
                                            <input id="account_code"  type="text" className="inputs " style={{outline:"none"}} autocomplete="off" onfocus="check_fields_sequance('account_code')"  onKeyup="account_code_entry(this, event, 'transaction_description')"/>
                                            <div id="account_name" className="inputs " style={{display:"none"}} onclick="tugle_account_code()"></div>
                                        </div>
                                                        
                                    </div>
                                    <div className="inputs_div " style={{width:"30%"}}>
                                        <input id="transaction_description" className="inputs" style={{outline:"none"}} autocomplete="off" type="text" onfocus="check_fields_sequance('transaction_description')" onkeypress="javascript:return string_pass(event, 'tax_rate', 'debit_amount')" />
                                        </div>
                                    <div id="taxrate" className="inputs_div "  style={{width:"10%", textAlign:"center"}}>
                                        <input id="tax_rate" className="inputs center_aligenment" style={{outline:"none"}} autocomplete="off"  type="text" onfocus="check_fields_sequance('tax_rate')" onkeypress="javascript:return string_pass(event, '', 'debit_amount')"/>
                                    </div>
                                    <div  className="inputs_div" style={{width:"13%"}} >
                                        <input id="debit_amount" type="text" className="inputs center_aligenment" style={{outline:"none"}} onFocus="check_fields_sequance('debit_amount')" onchange="document.getElementById('credit_amount').focus()" />
                                    </div>
                                    <div className="inputs_div" style={{width:"13%"}}>
                                        <input id="credit_amount" className="inputs center_aligenment" style={{outline:"none"}} type="text" onfocus="check_fields_sequance('credit_amount')" onchange="add_entry_row()"/>
                                        {/* <input id="credit_amount" type="text" className="for_edit_field" onkeypress="javascript:return onEnterCredit(event)" onkeyup="BackSpaceFunction(this,event,'debit_amount')" onchange="goto('add_entry_row')"/>*/}
                                    </div>
                                    <div className="inputs_div inputs" style={{width:"2%"}} onclick="add_entry_row()"><img src="../images/images/go.png" style={{width:"16px"}} /></div>
                                    <div style={{clear:"both"}}></div>
                                </div>
                                <div>
                                    <div style={{width:"2%", float:"left", display:"block"}}>&nbsp;</div>
                                    <div id="accounts_popup" style={{width:"30%", float:"left"}}>
                                        {/*<div id="accounts_list_drop_list" className="accounts_list_box  hedden" >*/}
                                        {/*    <div id="accounts_list" className="account_drop_list ">*/}
                                        {/*        <%If Not IsNothing(TreeDT) Then*/}
                                        {/*                Dim i As Integer%>*/}
                                        {/*            <%For i = 0 To TreeDT.Rows.Count - 1%>*/}
                                        {/*                <div id="account_row<%=i%>">*/}
                                        {/*                    <div id="tabs<%=i%>" style={{display:"none"}}><% = TreeDT.Rows(i).Item("tabs")%></div>*/}
                                        {/*                    <div id="node_code<%=i%>" style={{display:"none"}}><% = TreeDT.Rows(i).Item("node_code")%></div>*/}
                                        {/*                    <div id="account_no<%=i%>" style={{display:"none"}}><% = TreeDT.Rows(i).Item("account_no")%></div>*/}
                                        {/*                    <div id="account_id<%=i%>" style={{display:"none"}}><% = TreeDT.Rows(i).Item("account_id")%></div>*/}
                                        {/*                    <div id="account_code<%=i%>" style={{display:"none"}}><% = TreeDT.Rows(i).Item("account_code")%></div>*/}
                                        {/*                    <div id="is_transaction_account<%=i%>" style={{display:"none"}}><% = TreeDT.Rows(i).Item("is_transaction_account")%></div>*/}
                                        {/*                    <div id="account_number<% =TreeDT.Rows(i).Item("account_code")%>" style={{display:"none"}}><% = TreeDT.Rows(i).Item("account_no")%></div>*/}
                                                                            
                                        {/*                    <div className="tree_level<% =TreeDT.Rows(i).Item("tabs") %> <%if TreeDT.Rows(i).Item("is_transaction_account") = 1 Then%> transaction_account_name <%End If %>" onclick="get_clicked_account(<%=i%>)")>*/}
                                        {/*                        <%--<div style={{float:"left"}}><%= TreeDT.Rows(i).Item("node_code")%></div>--%>*/}
                                        {/*                        <div style={{float:"left"}}><%= TreeDT.Rows(i).Item("account_code")%></div>*/}
                                        {/*                        <div id="account<% =TreeDT.Rows(i).Item("account_code")%>" style={{float:"left", marginLeft:"10px"}}><%=TreeDT.Rows(i).Item("account_name") %></div>*/}
                                        {/*    <div style={{clear:"both"}}></div>*/}
                                        {/*</div>*/}

                                    </div>
                                                {/*    <%Next%>*/}
                                                {/*<%End If%>*/}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {entryMode === "View" && (
                    <>
                        <div className="row" style={{backgroundColor:"gray", margin:"0px"}}>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-8" style={{margin:"20px auto 20px auto", padding:"10px", backgroundColor:"white"}}> 
                                <div style={{minHeight:"120px"}}>
                                    <div className="data_line" style={{ display: "flex" }}>
                                        <label className="label_area">Work_center</label>
                                        <div className="viewing_field">Main office</div>
                                    </div>
                                    <div className="data_line" style={{ display: "flex" }}>
                                        <label className="label_area">Date</label>
                                        <div className="viewing_field">010-01-2024ce</div>
                                    </div>
                                    <div className="data_line" style={{ display: "flex" }}>
                                        <label className="label_area">Currency</label>
                                        <div className="viewing_field">Kuwait diner</div>
                                    </div>
                                    <div className="data_line" style={{ display: "flex" }}>
                                        <label className="label_area">Date</label>
                                        <div className="viewing_field" style={{height:"auto"}}>hoiuhpio lkjjh;jlkjj sdgdg dgddgds dsgsddgdg dgdfggdf jjlj lkj;ljljlj;lkj; pijpoij fgdghhfdd sdgsdfg dfgsdfg dffgdgdgsdg sdfgs dfg sdfg sd gdffgsfdgsdfgsfe sg esfg sfgsgsgs s fdg gd</div>
                                    </div>
                                </div>  
                                <div className="table_header_div top_border_div table_header_background" style={{ display: "flex" }}>
                                    <div style={{ width: "30%" }}>account</div>
                                    <div style={{ width: "30%" }}>Description</div>
                                    <div style={{ width: "20%" }}>Dredit</div>
                                    <div style={{ width: "20%" }}>Debit</div>
                                </div>

                                <div className="row_area" >
                                    <div className="table_line_div bottom_border_div" >
                                        <div style={{ width: "30%" }}>0120233 Mait budget</div>
                                        <div style={{ width: "30%" }}>first desc.</div>
                                        <div style={{ width: "20%" }}>1,500</div>
                                        <div style={{ width: "20%" }}>0</div>
                                    </div>
                                </div>
                                <div className="row_area" >
                                    <div className="table_line_div bottom_border_div" >
                                        <div style={{ width: "30%" }}>0120 sedondary budget</div>
                                        <div style={{ width: "30%" }}>second desc.</div>
                                        <div style={{ width: "20%" }}>2,500</div>
                                        <div style={{ width: "20%" }}>0</div>
                                    </div>
                                </div>
                                <div className="row_area" >
                                    <div className="table_line_div bottom_border_div" >
                                        <div style={{ width: "30%" }}>0120233 Mait budget</div>
                                        <div style={{ width: "30%" }}>therd desc.</div>
                                        <div style={{ width: "20%" }}>0</div>
                                        <div style={{ width: "20%" }}>4,000</div>
                                    </div>
                                </div>                           
                                <div className="table_header_div top_border_div" style={{ display: "flex" }}>
                                    <div style={{ width: "30%" }}></div>
                                    <div style={{ width: "30%" }}>Total</div>
                                    <div style={{ width: "20%" }}>4,000</div>
                                    <div style={{ width: "20%" }}>4,000</div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {/*entry lines*/}
                <div style={{minHeight:"300px", borderBottom: "1px solid #ccc !important"}}></div>
            </div>
            
            <div style={{paddingBottom:"3px", paddingTop:"3px", height:"130px !important"}}>    
                <div style={{float:"left", width:"auto", paddingTop:"4px"}}>
                    <div>
                        <div id="post_text" style={{float:"left", padding:"10px 6px 4px 6px", fontWeight:"600"}}>Post</div>
                        <div className="user-grid-img" style={{float:"left", marginRight:"10px"}}>
                            <img id="post_user_image" src="" style={{width: "40px", height: "40px;"}}/>
			            </div>
                        <div style={{float:"left"}}>
                            <div id="post_user_name" className="user-grid-name" style={{fontWeight:"500"}}>Fadel</div>
			                <div id="post_created_date" className="user-grid-job">ok</div>
                        </div>
                        <div style={{clear:"both"}}></div>
                    </div>
                    <div style={{marginTop:"16px"}}>
                        <div id="void_text" style={{float:"left", padding:"10px 6px 4px 6px", fontWeight:"600"}}>Void</div>
                        <div className="user-grid-img" style={{float:"left", marginRight:"10px"}}/>
                            <img id="void_user_image" src="" style={{width: "40px", height: "40px"}}/>
			            </div>
                    <div style={{float:"left"}}>
                        <div id="void_user_name" className="user-grid-name" style={{fontWeight:"500"}}>Fadel</div>
			            <div id="void_created_date" className="user-grid-job">ok</div>
                    </div>
                    <div style={{clear:"both"}}></div>
                    <div style={{float:"right", width:"30%"}}>
                        <div className="total_area">
                            <div>
                                <div id="total_of_debit" className="sum_area number_aligened"  >0</div>
                                <div id="total_of_credit" className="sum_area number_aligened" >0</div>
                                <div style={{clear:"both"}}></div>
                            </div>
                            <div className="doubl_lines"></div>
                        </div>
                        <div style={{marginTop:"20px"}}>
                            <div id="daily_entry_clear_btn" className="voucher_button" style={{backgroundColor: "#A3A3A3"}} onclick="clear_display_entry()">
                                {/*<img  src="../images/standard/clean.png"style={{height:"23px", marginTop:"5px"}}  />*/}
                                Clear
                            </div>
                            <div id="daily_entry_post_btn" className="voucher_button" style={{backgroundColor: "#009CD3"}}  onclick="post_entry()">
                                {/*<img  src="../images/standard/post.png"style={{height:"23px", marginTop:"5px"}}  />*/}
                                Post
                            </div>
                            <div style={{clear:"both"}}></div>
                        </div>
                    </div>
                    <div style={{clear:"both"}}></div>
                </div>
            </div>
        </div>
        <input id="test_date" type="text" style={{display:"none"}} onkeypress="javascript:return invalid_date('test_date', event, 'ref_no', 'daily_entry_msg')" />
        <input id="test" type="text"  onchange="dodo()" />
    </>
    )}
   


export default DailyEntry;
