
import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { LuUserPlus } from "react-icons/lu";

const Agenda = ({ setClientCode, systemCd }) => {
    const [clientMode, setClientMode] = useState("Initial");

    const [clientPageLables, setClientPageLables] = useState([]);
    const [clientDetails, setClientDetails] = useState();
    const [searchClient, setSearchClient] = useState();
    const [clientType, setClientType] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [countries, setCountries] = useState(false);
    const [countryIndex, setCountryIndex] = useState(0);
    const [selctedDailCode, setSelctedDailCode] = useState("965");
    const [sex, setSex] = useState([]);
    const [serch, setSerch] = useState();
    const [nathionalityDdl, setNathionalityDdl] = useState(false);

    useEffect(() => {
    getClientInitial();
    }, []);

    const set_clear_mode = () => {
    switch (clientMode) {
        case "Edit":
            setClientMode("view");
            break;

        case "Draft":
        case "view":
            setSearchClient();
            setClientDetails();
            setClientMode("Initial");
            setClientCode("0");
            break;

        default:
            break;
    }
    };

    const search_keyEvent = (e) => {
    if (e.charCode === 13) {
        getClient();
    }
    };

    /* Read -------------------------*/
    const getClientInitial = async () => {
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "get_client_initials",
            system_cd: "Hrs",
            function_cd: "20",
            system_rout_cd: "19",
        },
        };

        console.log("getClientInitial input ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client_initials`,
        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );

        console.log("getClientInitial output", res);
        if (res.data.output.pages_lables) {
        setClientPageLables(res.data.output.pages_lables);
        }
        if (res.data.output.sex_ref) {
        setSex(res.data.output.sex_ref);
        }
        if (res.data.output.country) {
        setCountriesList(res.data.output.country);

        let options = res.data.output.country.map((country) => {
            return {
            value: country.dial_cd,
            label: "+" + country.dial_cd,
            _id: country.country_cd,
            country_id: country.country_id,
            dial_cd: country.dial_cd,
            };
        });

        console.log("kuwait dailcd", options[countryIndex]);
        setCountries(options);
        }
        if (res.data.output.client_type) {
        setClientType(res.data.output.client_type);
        }
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    const getClient = async () => {
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "get_client",
            client_cd: searchClient.client_cd,
            country_area_cd: 965,
            phone_no: searchClient.phone_no,
        },
        };
        console.log("getClient input ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client`,

        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );

        console.log("getClient output ", res);

        if (res.data.output.client.client_cd) {
        setClientCode(res.data.output.client.client_cd);
        setClientDetails(res.data.output.client);
        setClientMode("view");
        } else {
        alert("no record found");
        }
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    /* Updates ----------------*/
   
    return (
    <>
        {clientPageLables.length > 0 && (
        <>
           
            {/*Agenda body ------------------*/}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 border-right">
                    <div style={{padding:"6px 8px"}}>  
                        {/*Button bar ---------------------*/}
                        <div>
                            <div className="normal_float" style={{fontSize:"18px", fontWeight:"bold"}}>
                                Tasts to do
                            </div>
                            <div className="opposite_float opposite_text_align">
                                {(clientMode === "Edit" || clientMode === "Draft") && (
                                <>
                                    <Tooltip title="Save" placement="bottom" arrow>
                                        <IconButton>
                                            <SaveIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                )}
                                {clientMode === "Initial" && (
                                <>
                                    <Tooltip title="Back" placement="bottom" arrow>
                                        <IconButton onClick={set_clear_mode}>
                                            <ArrowBackIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="add New" placement="bottom" arrow>
                                        <IconButton onClick={() => setClientMode("Draft")}>
                                            <LuUserPlus color="inherit" size={18} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Search" placement="bottom" arrow>
                                        <IconButton>
                                            {" "}
                                            <SearchOutlinedIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                )}
                                {clientMode === "view" && (
                                <>
                                    <Tooltip title="Edit" placement="bottom" arrow>
                                        <IconButton onClick={() => {setClientMode("Edit")}}>
                                            <ModeOutlinedIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                )}
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                        <div style={{marginTop:"6px"}}>
                            tasks to be here
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                    <div style={{padding:"6px 8px", borderBottom:"solid 1px gray", marginBottom:"10px", height:"50%"}}>  
                        {/*Button bar ---------------------*/}
                        <div>
                            <div className="normal_float" style={{fontSize:"18px", fontWeight:"bold"}}>
                                Urgents
                            </div>
                            <div className="opposite_float opposite_text_align">
                                {(clientMode === "Edit" || clientMode === "Draft") && (
                                <>
                                    <Tooltip title="Save" placement="bottom" arrow>
                                        <IconButton>
                                            <SaveIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                )}
                                {clientMode === "Initial" && (
                                <>
                                    <Tooltip title="Back" placement="bottom" arrow>
                                        <IconButton onClick={set_clear_mode}>
                                            <ArrowBackIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="add New" placement="bottom" arrow>
                                        <IconButton onClick={() => setClientMode("Draft")}>
                                            <LuUserPlus color="inherit" size={18} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Search" placement="bottom" arrow>
                                        <IconButton>
                                            {" "}
                                            <SearchOutlinedIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                )}
                                {clientMode === "view" && (
                                <>
                                    <Tooltip title="Edit" placement="bottom" arrow>
                                        <IconButton onClick={() => {setClientMode("Edit")}}>
                                            <ModeOutlinedIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                )}
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                        <div style={{marginTop:"6px"}}>
                           Urgents
                        </div>
                    </div>
                    <div style={{padding:"6px 8px"}}>  
                        {/*Button bar ---------------------*/}
                        <div>
                            <div className="normal_float" style={{fontSize:"18px", fontWeight:"bold"}}>
                                 Dont forget
                            </div>
                            <div className="opposite_float opposite_text_align">
                                {(clientMode === "Edit" || clientMode === "Draft") && (
                                <>
                                    <Tooltip title="Save" placement="bottom" arrow>
                                        <IconButton>
                                            <SaveIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                )}
                                {clientMode === "Initial" && (
                                <>
                                    <Tooltip title="Back" placement="bottom" arrow>
                                        <IconButton onClick={set_clear_mode}>
                                            <ArrowBackIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="add New" placement="bottom" arrow>
                                        <IconButton onClick={() => setClientMode("Draft")}>
                                            <LuUserPlus color="inherit" size={18} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Search" placement="bottom" arrow>
                                        <IconButton>
                                            {" "}
                                            <SearchOutlinedIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                )}
                                {clientMode === "view" && (
                                <>
                                    <Tooltip title="Edit" placement="bottom" arrow>
                                        <IconButton onClick={() => {setClientMode("Edit")}}>
                                            <ModeOutlinedIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                )}
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                        <div style={{marginTop:"6px"}}>
                             Dont forget
                        </div>
                    </div>
                </div>   
            </div>
        </>
        )}
    </>
    );
};

export default Agenda;
