import react, { useState, useEffect } from "react";
import {
  load_initials,
  get_item_details,
  set_item_mode,
} from "../../../JSfiles/inventory/ItemControl_js";
import axios from "axios";

const ItemDetails = ({
  itemData,
  setItemData,
  itemMode,
  setItemMode,
  itemBrand,
  itemCategory,
  itemUnit,
}) => {
  console.log(itemCategory);

  console.log();

  const get_item_details = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/get_item_detail`,
        {
          info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
          },
          input: {
            actioncode: "get_item_detail",
            item_cd: "2",
            branch_cd: "2",
          },
        },
      );
      console.log(res);

      if (res.data.output.item.item_cd) {
        console.log(res.data.output);
        setItemData(res.data.output); //intilization of the values of the item object from database
        setItemMode("view item");
      } else {
        alert("item is not exist");
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  return (
    <>
      <table className="table-verticle-middle table-frm table-frm-ed">
        <tbody>
          <tr>
            <th style={{ width: "25%" }} />
            <th style={{ width: "20%" }} />
            <th style={{ width: "20%" }} />
            <th style={{ width: "35%" }} />
          </tr>
          {itemMode === "initial item" && (
            <>
              <tr>
                <th id="item_code_i">Item Code</th>
                <td colSpan={3}>
                  <input
                    id="item_cd"
                    type="text"
                    className="form-control input-sm "
                    onChange={get_item_details}
                  />
                </td>
              </tr>
              <tr>
                <th id="bar_code_i">bar Code</th>
                <td colSpan={3}>
                  <input
                    id="bar_code"
                    type="text"
                    className="form-control input-sm "
                  />
                </td>
              </tr>
            </>
          )}

          {itemMode === "edit item" && (
            <>
              <tr>
                <th id="item_code_l">Item Code</th>
                <td colSpan={3}>
                  <div
                    id="item_cd_v1"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.item_cd}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="bar_code_l">bar Code</th>
                <td colSpan={3}>
                  <div
                    id="bar_code_v1"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.bar_code}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="item_group_l">Item Group</th>
                <td colSpan={3}>
                  <select
                    id="item_category_cd"
                    className="form-control input-sm "
                    value={itemData.item.item_category_cd}
                  >
                    {itemCategory.length >= 0 &&
                      itemCategory.map((category, index) => {
                        return (
                          <option key={index} value={category.code}>
                            {category.name_txt}
                          </option>
                        );
                      })}
                  </select>
                </td>
              </tr>
              <tr>
                <th id="brand_cd_l">Brand Name</th>
                <td colSpan={3}>
                  <select
                    id="brand_cd"
                    className="form-control input-sm "
                    value={itemData.item.brand_cd}
                  >
                    {itemBrand.length >= 0 &&
                      itemBrand.map((brand, index) => {
                        return (
                          <option key={index} value={brand.code}>
                            {brand.name_txt}
                          </option>
                        );
                      })}
                  </select>
                </td>
              </tr>
              <tr>
                <th id="item_name_en_l">Item Name </th>
                <td colSpan={3}>
                  <input
                    id="item_name_en"
                    type="text"
                    className="form-control input-sm "
                    value={itemData.item.item_name_en}
                  />
                </td>
              </tr>
              <tr>
                <th id="item_name_ar_l">اسم المادة</th>
                <td colSpan={3}>
                  <input
                    id="item_name_ar"
                    type="text"
                    className="form-control input-sm "
                    value={itemData.item.item_name_ar}
                  />
                </td>
              </tr>
              <tr>
                <th id="unit_cd_l">Unit </th>
                <td colSpan={3}>
                  <select
                    id="unit_cd"
                    className="form-control input-sm "
                    value={itemData.item.unit_cd}
                  >
                    {itemUnit.length >= 0 &&
                      itemUnit.map((item_unit, index) => {
                        return (
                          <option key={index} value={item_unit.code}>
                            {item_unit.name_txt}
                          </option>
                        );
                      })}
                  </select>
                </td>
              </tr>
              <tr>
                <th id="unit_price_l">Unit Price</th>
                <td colSpan={3}>
                  <input
                    id="unit_price"
                    type="text"
                    className="form-control input-sm "
                    value={itemData.item.unit_price}
                  />
                </td>
              </tr>
              <tr>
                <th id="selling_price_l">Selling price</th>
                <td colSpan={3}>
                  <input
                    id="selling_price"
                    type="text"
                    className="form-control input-sm "
                    value={itemData.item.selling_price}
                  />
                </td>
              </tr>
              <tr>
                <th id="tax_rate_l">Tax Rate</th>
                <td colSpan={3}>
                  <input
                    id="tax_rate"
                    type="text"
                    className="form-control input-sm "
                    value={itemData.item.tax_rate}
                  />
                </td>
              </tr>
              <tr>
                <th id="min_in_stock_l">Min In Stock</th>
                <td colSpan={3}>
                  <input
                    id="min_in_stock"
                    type="text"
                    className="form-control input-sm "
                    value={itemData.item.min_in_stock}
                  />
                </td>
              </tr>
              <tr>
                <th id="max_in_stock_l">Max In Stock</th>
                <td colSpan={3}>
                  <input
                    id="max_in_stock"
                    type="text"
                    className="form-control input-sm "
                    value={itemData.item.max_in_stock}
                  />
                </td>
              </tr>
              <tr>
                <th id="item_status_cd">Item Status</th>
                <td colSpan={3}>
                  <div
                    id="item_status_cd_v"
                    className="form-control input-sm view_field field_text_alighn"
                  >
                    {itemData.item.item_status}
                  </div>
                </td>
              </tr>
            </>
          )}

          {/* view */}
          {itemMode === "view item" && (
            <>
              <tr>
                <th id="item_code_l">Item Code</th>
                <td colSpan={3}>
                  <div
                    id="item_cd_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.item_cd}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="bar_code_l">bar Code</th>
                <td colSpan={3}>
                  <div
                    id="bar_code_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.bar_code}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="item_group_l">Item Group</th>
                <td colSpan={3}>
                  <div
                    id="item_category_cd_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {
                      itemCategory.find(
                        (el) => el.code === itemData.item.item_cd,
                      ).name_txt
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <th id="brand_cd_l">Brand Name</th>
                <td colSpan={3}>
                  <div
                    id="brand_cd_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {
                      itemBrand.find((el) => el.code === itemData.item.brand_cd)
                        .name_txt
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <th id="item_name_en_l">Item Name </th>
                <td colSpan={3}>
                  <div
                    id="item_name_en_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.item_name_en}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="item_name_ar_l">اسم المادة</th>
                <td colSpan={3}>
                  <div
                    id="item_name_ar_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.item_name_ar}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="unit_cd_l">Unit </th>
                <td colSpan={3}>
                  <div
                    id="unit_cd_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {
                      itemUnit.find((el) => el.code === itemData.item.unit_cd)
                        .name_txt
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <th id="unit_price_l">Unit Price</th>
                <td colSpan={3}>
                  <div
                    id="unit_price_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.unit_price}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="selling_price_l">Selling price</th>
                <td colSpan={3}>
                  <div
                    id="selling_price_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.selling_price}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="tax_rate_l">Tax Rate</th>
                <td colSpan={3}>
                  <div
                    id="tax_rate_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.tax_rate}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="min_in_stock_l">Min In Stock</th>
                <td colSpan={3}>
                  <div
                    id="min_in_stock_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.min_in_stock}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="max_in_stock_l">Max In Stock</th>
                <td colSpan={3}>
                  <div
                    id="max_in_stock_v"
                    className="form-control input-sm view_field field_text_alighn "
                  >
                    {itemData.item.max_in_stock}
                  </div>
                </td>
              </tr>
              <tr>
                <th id="item_status_cd_l">Item Status</th>
                <td colSpan={3}>
                  <div className="form-control input-sm view_field field_text_alighn ">
                    {itemData.item.item_status}
                  </div>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default ItemDetails;
