

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { HiOutlineHandThumbUp } from "react-icons/hi2";
import { HiOutlineHandThumbDown } from "react-icons/hi2";
import { IoAddSharp } from "react-icons/io5";


const Universities = () => {

    const [countriesList, setCountriesList] = useState([]);
    const [universitiesList, setUniversitiesList] = useState([]);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [sectionsList, setSectionsList] = useState([])

    const [decisionsList, setDecisionsList] = useState([]);
    const [decisionMode, setDecisionMode] = useState("initial");
    const [decisionItemEditMode, setDecisionItemEditMode] = useState(false);
    const [decisionsDetails, setDecisionsDetails] = useState({});
    const [certificateDetail, setCertificateDetail] = useState({});
    const [serch, setSerch] = useState();
    const [equivalencyItems, setEquivalencyItems] = useState([]);
    const [itemDetails, setitemDetails] = useState([]);

    const [pageLables, setPageLables] = useState([]);


    useEffect(() => {
        getDecisionInitial();
    }, []);



    const set_clear_mode = () => {
        switch (decisionMode) {
            case "Edit":
                setDecisionMode("view");
                alert("view")
                break;

            case "view":
                setDecisionsDetails()
                setDecisionMode("initial");
                alert("initial")

                break;

            //case "Initial":
            //    setDecisionMode("Upload document");
            //    alert("Upload document")
            //    break;
            default:

        }

    };

    /* Read -------------------------*/
    const getDecisionInitial = async () => {

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_academic_initials",

                },
            };

            console.log("academic initials output", data);
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_academic_initials`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }

            );

            console.log("academic initials output", res);
            setPageLables(res.data.output.page_lables);
            setCountriesList(res.data.output.country);

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getUniversities = async (e) => {
        setUniversitiesList([]);
        setDepartmentsList([]);
        setSectionsList([])
        setCertificateDetail({ ...certificateDetail, country_cd: e.target.value, university_cd: 0, department_cd: 0, section_cd: 0 })

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_universities_list",
                    country_cd: e.target.value
                },
            };

            //console.log("getUniversities input ", data)
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_university_list`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

           console.log("getUniversities output ", res.data.output.univercities)
            if (res.data.output.univercities) { setUniversitiesList(res.data.output.univercities) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getDepartment = async (university_cd) => {
      
        setDepartmentsList([]);
        setSectionsList([])
        setCertificateDetail({ ...certificateDetail, university_cd: university_cd, department_cd: 0, section_cd: 0 })

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_university_departments",
                    university_cd: university_cd
                },
            };

            console.log("getDepartment input ", data)
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_university_departments`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getDepartment output ", res)
            if (res.data.output.departments) { setDepartmentsList(res.data.output.departments) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getSections = async (department_cd) => {
        setSectionsList([]);
        setCertificateDetail({ ...certificateDetail, department_cd: department_cd })

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_department_sections",
                    university_cd: certificateDetail.university_cd,
                    department_cd: department_cd,
                },
            };

            console.log("getSections input ", data)

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_department_sections`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getSections output ", res.data.output.department_sections)
            if (res.data.output.department_sections) { setSectionsList(res.data.output.department_sections) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    /* Update -----------------------------*/

    const add_new_university = async (e) => {
        alert("add_new_university")
        return
        setUniversitiesList([]);
        setDepartmentsList([]);
        setSectionsList([])
        setCertificateDetail({ ...certificateDetail, country_cd: e.target.value, university_cd: 0, department_cd: 0, section_cd: 0 })

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_universities_list",
                    country_cd: e.target.value
                },
            };

            //console.log("getUniversities input ", data)
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_university_list`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getUniversities output ", res.data.output.univercities)
            if (res.data.output.univercities) { setUniversitiesList(res.data.output.univercities) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    return (

        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  border-right">
                <div style={{ height: "calc(100vh - 100px)", overflow: "auto" }}>
                    {/*Header Bar*/}
                    <div className="prevelages_header">
                        <div className="normal_float normal_text_align ">
                            <div className="data_line" style={{ display: "flex" }} >
                                <label className="label_area" style={{width:"100px"}}>{pageLables.length > 0 && pageLables[3].description}</label>
                                <select className="editing_field" value={setSerch && setSerch.country_cd} onChange={(e) => getUniversities(e)}>
                                    <option value="0"></option>
                                    {countriesList.length >= 0 &&
                                        countriesList.map((country, index) => {
                                            return (
                                                <option key={index} value={country.code}>
                                                    {country.description}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                        <div className="opposite_float  opposite_text_align">
                            <Tooltip title="New tap" placement="bottom" arrow  onClick={() => { add_new_university() }}>
                                <IconButton ><IoAddSharp color="inherit" fontSize="mediam" /></IconButton>
                            </Tooltip>
                        </div>
                        <div style={{clear:"both"}}></div>

                    </div>
                   
                    {universitiesList.length > 0 && universitiesList.map((universities_list, index) => (
                        <div >
                            <div className="row_area" key={universities_list.ser_no} onClick={(e) => getDepartment(universities_list.code)}>
                                <div className="table_line_div " style={{ padding: "6px" }}>
                                    <div >{universities_list.description}</div>
                                </div>
                            </div>
                        </div>
                    ))} 
                </div>
            </div>           
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  border-right">
                <div style={{ height: "calc(100vh - 100px)" }}>
                    {/*Header Bar*/}
                    <div className="prevelages_header">
                        Fadel
                        <div className="opposite_float  opposite_text_align">
                            <Tooltip title="New tap" placement="bottom" arrow onClick={() => { add_new_university() }}>
                                <IconButton ><IoAddSharp color="inherit" fontSize="mediam" /></IconButton>
                            </Tooltip>
                        </div>
                        <div style={{ clear: "both" }}></div>

                    </div>

                    {departmentsList.length > 0 && departmentsList.map((departments_list, index) => (
                        <div >
                            <div className="row_area" key={departments_list.code} onClick={(e) => getSections(departments_list.code)}>
                                <div className="table_line_div " style={{ padding: "6px" }}>
                                    <div >{departments_list.description}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div style={{ height: "calc(100vh - 100px)" }}>
                    {/*Header Bar*/}
                    <div className="prevelages_header">
                        Fadel
                        <div className="opposite_float  opposite_text_align">
                            <Tooltip title="New tap" placement="bottom" arrow onClick={() => { add_new_university() }}>
                                <IconButton ><IoAddSharp color="inherit" fontSize="mediam" /></IconButton>
                            </Tooltip>
                        </div>
                        <div style={{ clear: "both" }}></div>

                    </div>

                    {sectionsList.length > 0 && sectionsList.map((sections_list, index) => (
                        <div >
                            <div className="row_area" key={sections_list.code}>
                                <div className="table_line_div " style={{ padding: "6px" }}>
                                    <div >{sections_list.description}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default Universities;
