import React from 'react'
import ReactDOM from 'react-dom'
import './Modal.css'

const ModalOverlay = (props) => {
  const content = (
    <div className={`model-bg ${props.className}`} style={props.style}>
      <div className='modal-container-content' style={props.containerStyle}>
        {props.header && (
          <header className={`modal__header ${props.headerClass}`}>
            <h2>{props.header}</h2>
          </header>
        )}
        <form
          onSubmit={
            props.onSubmit ? props.onSubmit : (event) => event.preventDefault()
          }
        >
          <div className={`modal__content ${props.contentClass}`}>
            {props.children}
          </div>
          <footer className={`modal__footer ${props.footerClass}`}>
            {props.footer}
          </footer>
        </form>
      </div>
    </div>
  )
  return ReactDOM.createPortal(
    content,
    document.getElementById('crop-modal-hook')
  )
}

const Modal = (props) => {
  return (
    <React.Fragment>{props.show && <ModalOverlay {...props} />}</React.Fragment>
  )
}

export default Modal
