
import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useDispatch } from 'react-redux'

import { BsThreeDotsVertical } from 'react-icons/bs'
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineBorderBottom } from 'react-icons/ai'
import saveIcon from '../../../SVG_images/save.svg'
import editIcon from '../../../SVG_images/edit.svg'
import attacmentsIcon from '../../../SVG_images/attachment.png'
import remarksIcon from '../../../SVG_images/note.png'
import closeIcon from '../../../SVG_images/close.svg'


    const CommonRequest = ({ setPageMode, requestTypetData, requestMode, setRequestMode, requestData, request_type_index }) => {

   
    const dispatch = useDispatch()

    useEffect(() => {

        if (requestMode === "add request") {
            setRequestMode("draft request")
            document.getElementById("request_type_name").innerText = requestTypetData.find(el => el.request_type_cd === request_type_index).request_type
        } else {
            var request = requestData.request_details
            document.getElementById("edit_status").innerText = ""
            document.getElementById("request_type_name").innerText = requestTypetData.find(el => el.request_type_cd === requestData.request_details.request_type_cd).request_type
            document.getElementById("request_code").innerText = "#" + requestData.request_details.request_year + "/" + requestData.request_details.branch_cd + "/" + requestData.request_details.request_cd
        }



    }, [])
       
        const toggle_menu = (menu) => {           
            if (document.getElementById(menu).style.display == "block") {
                document.getElementById(menu).style.display = "none"
            } else {
                document.getElementById(menu).style.display = "block"
            }
        }

    const set_clear_mode = () => {
        if (requestMode === "edit request") {
            setRequestMode('view request')
            document.getElementById("edit_status").innerText = ""
        } else {
            setPageMode("initial")
        }
    }

    const set_edit_mode = () => {
        setRequestMode('edit request')
        { document.getElementById("edit_status").innerText = "Edit" }
    }
  
    return <>
        <div>
            {requestMode === "view request" && (
                <>
                    <div>
                        <div id="request_menu" className="dropdown_menu opposite_float hedden">
                            <div className="opposite_text_align">
                                <img src={closeIcon} className="buton_icon" onClick={() => { toggle_menu("request_menu") }} />
                            </div>
                            <div>
                                <div className="normal_text_align">Delete</div>
                                <div className="normal_text_align">More request details</div>
                                <div className="normal_text_align">
                                    <table >
                                        <tr>
                                            <th style={{ width: "30%" }}></th>
                                            <th style={{ width: "70%" }}></th>
                                        </tr>
                                        <tr>
                                            <th>Enterd By</th>
                                            <td>Fade bohamad</td>
                                        </tr>
                                        <tr>
                                            <th>requested By</th>
                                            <td>Shafeeq Mohamad</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div style={{clear:"both"}}></div>
                    </div>
               </>
            )}
            <div id="request_header" className="buttons_div ">
                <div className="subbutton_div normal_float  " onClick={set_clear_mode} >
                    <div className="buton_icon normal_float" > <AiOutlineArrowLeft style={{ fontSize: "18px" }} /></div>
                    <div id="edit_status" className="normal_float" ></div>
                    <div style={{ clear: "both" }}></div>
                </div>
                <div className="subbutton_div normal_float" style={{ paddingLeft: "10px", borderLeft: "solid 1px gray" }}>
                    <div id="request_type_name" className=" normal_float"></div>
                    <div id="request_code" className=" normal_float" style={{ paddingRight: "20px", paddingLeft: "2px" }}></div>
                    <div style={{ clear: "both" }}></div>
                </div>
                <div id="request_buttons" className="opposite_float subbutton_div opposite_text_align">
                    {(requestMode === "edit request" || requestMode === "draft request") && (<img id="item_save_btn" src={saveIcon} className="buton_icon opposite_float " />)}
                  {/*  {requestMode === "view request" && (<BsThreeDotsVertical className="buton_icon opposite_float" onClick={() => { toggle_menu("request_menu") }}/>)}*/}
                    {requestMode === "view request" &&
                        (<>
                            <BsThreeDotsVertical className="buton_icon opposite_float" onClick={() => { toggle_menu("request_menu") }} />
                            <img src={remarksIcon} className="buton_icon opposite_float" onClick={() => { dispatch({ type: "attachmentInfo", payload: { data1: "good morening" } }) }} />
                            <img src={attacmentsIcon} className="buton_icon opposite_float" onClick={() => { dispatch({ type: "attachmentInfo", payload: { data1: "good morening" } }) }} />
                            <img src={editIcon} className="buton_icon opposite_float" onClick={() => { set_edit_mode() }} />
                        </>)}
                    <div style={{ clear: "both" }}></div>
                </div>
                <div style={{ clear: "both" }}></div>
            </div>

            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                {requestMode === "draft request" && (
                    <>
                       Draft Request
                    </>
                )}

                {requestData && (
                    <>
                        {(requestMode === "edit request" || requestMode === "add request") && (
                            <>
                            Edit Request
                            </>)}

                        {requestMode === "view request" && (
                            <>
                                {/*}
                                {requestData.request_details.document_url_address && (
                                    
                               )}
                                */}
                                {requestData.request_details.requext_text && (
                                < textarea id="requext_text_v" className="form-control input-sm" style={{ width: "100%", height: "auto", backgroundColor: "transparent" }} readOnly>{requestData.request_details.requext_text}</textarea>
                                )}
                            </>)}
                           
                      
                    </>
                )}
            </div>

        </div>

    </>
}


export default CommonRequest