const defaultState = {
  lang: "",
};

const userData = (state = defaultState, action) => {
  switch (action.type) {
    case "USERINFO":
      return {
        ...state,
        userData: action.payload,
        lang: action.payload.user_language_cd,
      };
    default:
      return state;
  }
};

export default userData;
