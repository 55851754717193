import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { FaUserLock } from "react-icons/fa";
import blankprofile from "../../Images/blankprofile.png";

import ImageCard from "../cards/ImageCard";

const ChartOfAccounts = () => {
    const { user } = useSelector((state) => ({ ...state })); //importing from redux
    
    const [accountDetails, setAccountDetails] = useState({});
    const [accountTypeDetails, setAccountTypeDetails] = useState({});
    const [accountNames, setAccountNames] = useState([]);
    const [accountUsers, setAccountUsers] = useState([]);
    const [branchMode, setBranchMode] = useState("initial");
    const [accountDetailsMode, setAccountDetailsMode] = useState("Initial");
    const [branchLanguage, setBranchLanguage] = useState([]);
    const [branchEmployee, setBranchEmployee] = useState([]);
    const [branchBanks, setBranchBanks] = useState([]);
    const [branchCashiers, setBranchCashiers] = useState([]);
    const [branchType, setBranchType] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [countries, setCountries] = useState([]);
    const [dateFormat, setDateFormat] = useState([]);
    const [chartOfAccount, setChartOfAccount] = useState([]);
    const [accountTypes, setAccountTypes] = useState([]);
    const [pageLables, setPageLables] = useState([]);
    const [states, setStates] = useState([]); 
    const [tapActive, setTapActive] = useState("Location");
    const [selectedAccountType, setSelectedAccountType] = useState("0");

    useEffect(() => {
        if (user.userData) {
           get_chart_of_account_initial();
        }
    }, [user]);
    
    
    const set_clear_mode = () => {
        if (accountDetailsMode === "Edit") {
            setAccountDetails("View");
        } else {
            setAccountDetails({})
            setAccountDetails("Initial");
        }
    };

    const set_draft_mode = () => {
        setAccountDetails({})
        setAccountDetails("Draft")
    };

    function toggle_account(node_id, account_no) {  
       if (document.getElementById(node_id).style.display === "block") {
            document.getElementById(node_id).style.display = "none";
        } else {
            document.getElementById(node_id).style.display = "block";
        }
    }

    function set_icon(user_image_name) {
        if (user_image_name) {
            return <img src={"https://files.amanrow.com/user/" + user_image_name} alt=" " className="progress_profile_image"/>
        }else {
            return <img src={blankprofile} className="progress_profile_image" alt="" />
        }
    }

    function set_account_type() {
        setSelectedAccountType(document.getElementById("accpunt_type").value)
    }
    
    const subNode = (parent_id) => {
        return chartOfAccount.map((tree_node, i) => {
            if (parent_id === tree_node.parent_id) {
                return (
                    <div key={i}>
                        <div key={i} className="user_line">
                            <div className="node_line">
                                <div style={{ display: "flex" }} onClick={() => { toggle_account(`branch_${tree_node.account_id}`, tree_node.account_no) }}>
                                    <div className="icon_tree_area"><ImageCard icon={tree_node.icon_name} iconType={"Account tree"} /></div>
                                    <div className="node_name_area">{tree_node.account_name}</div>
                                </div>
                                <div className="node_buttoms_area">
                                    <InsertDriveFileOutlinedIcon className="node_buttoms" fontSize="small" color="gray" onClick={() => { set_draft_mode() }} />
                                    <ModeOutlinedIcon className="node_buttoms" fontSize="small" color="gray" onClick={() => { get_account_detail(tree_node.account_no); }} />                                     
                                </div>
                            </div>
                        </div>
                        <div id={`branch_${tree_node.account_id}`} className="node_block_section left_block_border hedden">
                            {subNode(tree_node.account_id)}
                        </div>
                    </div>
                );
            }
        });
    };

    // Read ----------------------
    const get_chart_of_account_initial = async () => {
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: user.userData.user_language_cd,
                    company: user.userData.company_cd,
                    user_cd: user.userData.user_cd,
                },
                input: {
                    actioncode: "get_chart_of_account_initials",
                    company_cd: userDataLocal.company_cd,
                    branch_cd: user.userData.branch_cd,
                    system_cd: "Acc",
                    function_cd: "3"
                },
            };

            console.log("get_chart_of_account_initials input = ", data);
           
        
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_chart_of_account_initials`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("get_chart_of_account_initials output = ", res);
            //setAccountDetails(res.data.output.branch);
            if (res.data.output.pages_lables) { setPageLables(res.data.output.pages_lables) }
            //if (res.data.output.branch_language) { setBranchLanguage(res.data.output.branch_language) }
            //if (res.data.output.branch_employee) { setBranchEmployee(res.data.output.branch_employee) }
            //if (res.data.output.branch_type) { setBranchType(res.data.output.branch_type) }
            if (res.data.output.chart_of_account && res.data.output.chart_of_account.length > 0) {
                setChartOfAccount(res.data.output.chart_of_account) 
            }else{
                setChartOfAccount([])
            }
            if (res.data.output.account_types && res.data.output.account_types.length > 0) {
                setAccountTypes(res.data.output.account_types) 
            }else{
                setAccountTypes([])
            }
                
            //if (res.data.output.currency) { setCurrency(res.data.output.currency) }
            //if (res.data.output.country) { setCountries(res.data.output.country) }
            //if (res.data.output.state) { setStates(res.data.output.state) }
            //if (res.data.output.date_format) { setDateFormat(res.data.output.date_format) }

            //if (res.data.output.branch.branch_cd) {
            //    setAccountDetails("View");

            //} else {
            //    setAccountDetails("Initial");
            //}

        } catch (err) {
            console.log(err);
            //alert("error message" + err.message);
        }
    };
     
    const get_account_detail = async (account_no) => {        
       
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_account_detail",
                    branch_cd: userDataLocal.branch_cd,
                    account_no: account_no
                },
            };
            console.log("get_account_detail input ", data);
           
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_account_details`,

                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("get_account_detail output ", res);              
            if (res.data.output.account_details) {
                setAccountDetails(res.data.output.account_details) 
                if (res.data.output.account_names && res.data.output.account_names.length > 0) {
                    setAccountNames(res.data.output.account_names) 
                }else{
                    setAccountNames([])
                }
                if (res.data.output.account_names && res.data.output.account_names.length > 0) {
                    setAccountNames(res.data.output.account_names) 
                }else{
                    setAccountNames([])
                }
                 if (res.data.output.account_user && res.data.output.account_user.length > 0) {
                    setAccountUsers(res.data.output.account_user) 
                }else{
                    setAccountUsers([])
                }

                setAccountDetailsMode("View")
            }else{
                setAccountDetails({})
            }
        
        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    }; 

    // Update ----------------------  
   
   const update_account = async () => {
       alert("update_account")
    }


  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 border-right" >
            {/*menu bar */}
            <div className="buttoms_bar_height" style={{display:"flex", alignItems: "center",padding:"0px 10px"}}>
                <select id="accpunt_type" className="accounts_type_option" style={{ outline: "0" }}  onChange={(e) => set_account_type()}>
                   {accountTypes.length >= 0 && accountTypes.map((account_type, index) => {
                        return (
                            <option key={index} value={account_type.code}>
                                {account_type.description}
                            </option>
                        );
                    })}
                </select>
            </div>

            {/*Orgenization Structure*/}
            <div style={{ height: "calc(100vh - 122px)", overflow: "auto", overflowX: "hidden", padding:"0px 0px 0px 4px" }} >
                <div>
                    {chartOfAccount.map((tree_node, i) => {   
                        if (tree_node.parent_id === "0" && (tree_node.account_id.substr(0, 1) === selectedAccountType || selectedAccountType === "0"))
                        return (
                        <>
                            <div key={i} className="user_line">
                                <div className="node_line">
                                    <div style={{ display: "flex" }} onClick={() => { toggle_account(`branch_${tree_node.account_id}`, tree_node.account_no) }}>
                                        <div className="icon_tree_area"><ImageCard icon={tree_node.icon_name} iconType={"Account tree"} /></div>
                                        <div className="node_name_area">{tree_node.account_name}</div>

                                    </div>
                                    <div className="node_buttoms_area">
                                        <InsertDriveFileOutlinedIcon className="node_buttoms" fontSize="small" color="gray" onClick={() => {set_draft_mode()}} />
                                        <ModeOutlinedIcon  className="node_buttoms" fontSize="small" color="gray" onClick={() => { get_account_detail(tree_node.account_no); }} />
                                    </div>
                                </div>
                                          
                            </div>
                            <div id={`branch_${tree_node.account_id}`} className="node_block_section left_block_border hedden">
                                {subNode(tree_node.account_id)}
                            </div>
                        </>);
                    })}
                          </div>
            </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
            {/*buttomns bar*/}
            <div className="buttoms_bar_height">
                <div className="normal_float normal_text_align ">
                {accountDetailsMode !== "List" && (
                    <>
                        <Tooltip title="Back" placement="bottom" arrow>
                        <IconButton onClick={set_clear_mode}>
                            <ArrowBackIcon color="inherit" fontSize="small" />
                        </IconButton>
                        </Tooltip>
                        <span>
                            {(accountDetailsMode === "Edit" || accountDetailsMode === "Draft") && accountDetailsMode}
                        </span>{" "}
                        <span className="separator_border">
                            {(accountDetailsMode === "Edit" || accountDetailsMode === "View") && accountDetails.account_no}
                        </span>
                    </>)}
                </div>
                <div className="opposite_float opposite_text_align">
                    {(accountDetailsMode === "Edit" || accountDetailsMode === "Draft") && (
                    <>
                        <Tooltip title="Save" placement="bottom" arrow>
                            <IconButton onClick={() => { update_account() }}>
                            <SaveIcon color="inherit" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                    )}
                    {accountDetailsMode === "View" && (
                    <>
                        <Tooltip title="Edit" placement="bottom" arrow>
                            <IconButton onClick={() => {setAccountDetails("Edit")}}>
                                <ModeOutlinedIcon color="inherit" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                    )}
                </div>
            <div style={{ clear: "both" }}></div>
            </div>
            {/*account details*/}
            <div style={{ height: "calc(100vh - 122px)", overflow: "auto", overflowX: "hidden" }} >           
                {(accountDetails && accountDetailsMode === "View" ) &&(
                <> 
                    <div className="data_line">
                        <label className="label_area">{pageLables[0].description}</label>
                        <div className="viewing_field">{accountDetails.account_id}
                        {accountDetails && accountDetails.is_restricted == "1" && (
                            <Tooltip title={pageLables[3].description} placement="bottom" arrow style={{margin:"0px 10px"}}>
                                <IconButton>
                                    <FaUserLock color="gray" fontSize="18" />
                                </IconButton>
                            </Tooltip>
                        )}
                        </div>
                    </div>
                    {accountNames.length > 0 && accountNames.map((account_names) => {
                        return <div className="data_line" key={account_names.branch_language}>
                            <label className="label_area">{pageLables[1].description} {account_names.native_name}</label>                            
                            <div className="viewing_field">{account_names.account_name}</div> 
                        </div>
                    })}
                    <div className="data_line">
                        <label className="label_area">{pageLables[2].description}</label>
                        <div className="viewing_field">{accountDetails.tax}</div>
                    </div>
                    <div className="data_line" style={{marginBottom:"20px"}}>
                        <label className="label_area">{pageLables[4].description}</label>
                        <div className="viewing_field">{accountDetails.predefined}</div>
                    </div> 
                    {accountUsers.length > 0 && accountUsers.map((account_users) => {
                         return <div key={account_users.user_cd} style={{ display: "flex", alignItems: "center", marginBottom:"16px"}}>
                            <div>{set_icon(account_users.user_icon_address)}</div>
                            <div className="space-betw-vertical" style={{ margin: "0px 10px", height:"44px" }}>
                                <div>{account_users.request_user_name}</div>
                                <div>{account_users.branch_name}<span style={{ color: "gray" }}>  |  {account_users.job_title}</span></div>     
                            </div>
                        </div>                 
                    })}

                    
                        
                   
                   
                </>
                )}            
                {/*{(accountDetailsMode === "Draft" || accountDetailsMode === "Edit") && (*/}
                {/*    <> */}
                {/*        {branchLanguage.length > 0 && branchLanguage.map((branch) => {*/}
                {/*            return <div className="data_line" key={branch.code}>*/}
                {/*                <label className="label_area">{pageLables[0].description} {branch.Language}</label>*/}
                {/*                <div className="editing_field">{branch.Description}</div>*/}
                {/*            </div>*/}
                {/*        })}*/}
                {/*        <div className="data_line">*/}
                {/*            <label className="label_area">{pageLables[1].description}</label>*/}
                {/*            <select className="editing_field" style={{ outline: "0" }} value={(accountDetails && accountDetails.branch_type_cd) && accountDetails.branch_type_cd} onChange={(e) => setAccountDetails({ ...accountDetails, branch_type_cd: e.target.value })}>*/}
                {/*                <option value="0"></option>*/}
                {/*                    {branchType.length >= 0 && branchType.map((branch_type, index) => {*/}
                {/*                    return (*/}
                {/*                        <option key={index} value={branch_type.code}>*/}
                {/*                            {branch_type.name}*/}
                {/*                        </option>*/}
                {/*                    );*/}
                {/*                })}*/}
                {/*            </select>*/}
                {/*        </div>*/}
                {/*        {accountDetails.branch_type_cd === "1" && (*/}
                {/*            <>*/}
                {/*                <div className="data_line">*/}
                {/*                    <label className="label_area">{pageLables[18].description}</label>*/}
                {/*                    <div className="viewing_field" style={{ border: "none" }}>*/}
                {/*                        {accountDetails.logo_image_name && (*/}
                {/*                            <>*/}
                {/*                                <img src={accountDetails.logo_image_name} style={{ height: "60px" }} alt="" />*/}
                {/*                            </>)}*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </>)}*/}
                {/*        {accountDetails.branch_type_cd > 1 && (*/}
                {/*            <>*/}
                {/*                <div className="data_line">*/}
                {/*                    <label className="label_area">{pageLables[2].description}</label>*/}
                {/*                                  <select className="editing_field" style={{ outline: "0" }} value={(accountDetails && accountDetails.head_person) && accountDetails.head_person} onChange={(e) => setAccountDetails({ ...accountDetails, head_person: e.target.value })}>*/}
                {/*                        <option value="0"></option>*/}
                {/*                        {branchEmployee.length >= 0 && branchEmployee.map((employee_list, index) => {*/}
                {/*                        return (*/}
                {/*                            <option key={index} value={employee_list.emp_cd}>*/}
                {/*                                {employee_list.full_name}*/}
                {/*                            </option>*/}
                {/*                        );*/}
                {/*                        })}*/}
                {/*                    </select>*/}
                {/*                </div>*/}
                {/*                <div className="data_line">*/}
                {/*                    <label className="label_area">{pageLables[4].description}</label>*/}
                {/*                    <select className="editing_field" style={{ outline: "0" }} value={(accountDetails && accountDetails.format_date) && accountDetails.format_date} onChange={(e) => setAccountDetails({ ...accountDetails, format_date: e.target.value })}>*/}
                {/*                        <option value="0"></option>*/}
                {/*                            {dateFormat.length >= 0 && dateFormat.map((date_format, index) => {*/}
                {/*                            return (*/}
                {/*                                <option key={index} value={date_format.code}>*/}
                {/*                                    {date_format.name}*/}
                {/*                                </option>*/}
                {/*                            );*/}
                {/*                        })}*/}
                {/*                    </select>*/}
                {/*                </div>*/}
                {/*                <div className="data_line">*/}
                {/*                    <label className="label_area">{pageLables[5].description}</label>*/}
                {/*                    <select className="editing_field" style={{ outline: "0" }} value={(accountDetails && accountDetails.branch_curruncy) && accountDetails.branch_curruncy} onChange={(e) => setAccountDetails({ ...accountDetails, branch_curruncy: e.target.value })}>*/}
                {/*                    <option value="0"></option>*/}
                {/*                        {currency.length >= 0 && currency.map((currency_list, index) => {*/}
                {/*                        return (*/}
                {/*                            <option key={index} value={currency_list.code}>*/}
                {/*                                {currency_list.name}*/}
                {/*                            </option>*/}
                {/*                        );*/}
                {/*                    })}*/}
                {/*                    </select>*/}
                {/*                </div> */}
                {/*                <div className="data_line">*/}
                {/*                    <label className="label_area">{pageLables[6].description}</label>*/}
                {/*                    <input type="text" className="editing_field" autoComplete="0"*/}
                {/*                        value={accountDetails && accountDetails.email} onChange={(e) => setAccountDetails({ ...accountDetails, email: e.target.value })} />*/}
                {/*                </div>*/}
                {/*                <div className="data_line">*/}
                {/*                    <label className="label_area">{pageLables[7].description}</label>*/}
                {/*                    <input type="text" className="editing_field" autoComplete="0"*/}
                {/*                    value={accountDetails && accountDetails.phone_no} onChange={(e) => setAccountDetails({ ...accountDetails, phone_no: e.target.value })} />*/}
                {/*                </div>*/}
                {/*                <div className="data_line">*/}
                {/*                    <label className="label_area">{pageLables[8].description}</label>*/}
                {/*                    <input type="text" className="editing_field" autoComplete="0"*/}
                {/*                    value={accountDetails && accountDetails.fax_no} onChange={(e) => setAccountDetails({ ...accountDetails, fax_no: e.target.value })} />    */}
                {/*                </div>*/}
                {/*            </>)}*/}
                {/*    </>*/}
                {/*)}*/}
                    
              
            </div>
        </div>
      </div>
    </>
  );
};

export default ChartOfAccounts;
