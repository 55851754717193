


import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Clients from "../Crm/Clients";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { BsSend } from "react-icons/bs"

import { FiEdit } from "react-icons/fi"


import { useDispatch, useSelector } from "react-redux"; //this import redux functions


import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CaseRemarks = ({ case_cd }) => {

    const dispatch = useDispatch({})
    const [pageMode, setPageMode] = useState("List");
    const [remarks, setRemarks] = useState([]);


    useEffect(() => {

        getCaseRemarks()
    }, []);

    const set_clear_mode = () => {

        switch (pageMode) {
            
            case "Draft":
                setPageMode("List");
                break;
            default:
            // code block
        }
    };

    const getCaseRemarks = async () => {
       
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_case_remarks",
                    case_cd: case_cd
                },
            };

            console.log("getCaseRemarks input ", data)

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/get_case_remarks`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getCaseRemarks output", res);
            if (res.data.output.case_remarks) { setRemarks(res.data.output.case_remarks) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const update_case_remarks = async (update_function) => {
        alert("update_case_chatting")

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "update_case_remarks",
                    case_cd: case_cd,
                    remark: document.getElementById("remark").value
                },
            };
            console.log("update_case_remarks input ", data);
          
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_case_remarks`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("update_case_remarks output ", res);

            if (res.data.output.case_remarks) {
                setRemarks(res.data.output.case_remarks);
            } else {
                setRemarks([])
            }

            setPageMode("List")




        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    return <>
        <div className="row">
            <div style={{ height: "calc(100vh - 86px)", overflow: "auto", overflowX: "hidden" }} >
                <>
                    {/*Button bar ---------------------*/}
                    <div>
                        <div className="normal_float normal_text_align ">
                            {pageMode === "Draft" && (
                                <>
                                    <Tooltip title="Back" placement="bottom" arrow>
                                        <IconButton onClick={set_clear_mode}>
                                            <ArrowBackIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>)}
                        </div>
                        <div className="opposite_float opposite_text_align">
                            {pageMode === "List" && (
                                <>
                                    <Tooltip title="Write Message" placement="bottom" arrow>
                                        <IconButton onClick={() => { setPageMode("Draft"); }}>
                                            <FiEdit color="inherit" size="20" />
                                        </IconButton>
                                    </Tooltip>
                                </>)}
                            {pageMode === "Draft" && (
                                <>
                                    <Tooltip title="Send Message" placement="bottom" arrow>
                                        <IconButton onClick={() => { update_case_remarks() }}>
                                            <BsSend color="inherit" size="20" />
                                        </IconButton>
                                    </Tooltip>
                                </>)}
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>
                    {/*Attachment area*/}
                    {pageMode === "List" && (
                        <>
                            {remarks.length > 0 && remarks.map((remarks_list, index) => (
                                <div key={remarks.case_cd + index} className="remark_area">
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <img src={"https://files.amanrow.com/user/" + remarks_list.image_name} alt=" " className="remark_profile_image" />                                
                                        </div>
                                        <div className="space-betw-vertical" style={{margin:"0px 10px"}}>
                                            <div> {remarks_list.full_name}</div>
                                            <div>{remarks_list.remark_date}</div>
                                        </div>
                                
                                    </div>
                                    <div style={{ padding: "10px 4px 10px 4px" }}>{remarks_list.remark}</div>                          
                                </div>
                            )
                            )}
                        </>)}
                    {pageMode === "Draft" && (
                        <>
                            <div style={{ margin: "2px 10px" }}>
                                <textarea id="remark" className="message_field" style={{ width: "100%", minHeight: "400px" }} maxlength="4000" placeholder="Write remark here"></textarea>
                            </div>
                        </>)}

                </>
            </div>
        </div>
    </>
}

export default CaseRemarks



