

import React from "react"

const Groups = () => {

    return <>
        <div>Group section here</div>
    </>
}

export default Groups