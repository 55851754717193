
import React, { useEffect, useState, useRef } from "react"; 
import axios from "axios";

import { BsXCircle } from "react-icons/bs";

const HlpHelpContent = ({ setHelpContent, function_cd }) => {
    const [pageHeader, setPageHeader] = useState({});
    const [pageTaps, setPageTaps] = useState([]); 

    useEffect(() => {
        get_help_content()
    }, []);

    useEffect(() => {

        if (setHelpContent) {
            document.getElementById("Sidenav2").style.width = "20%";
        } 
    }, [setHelpContent])

    const closeNav = () => {

      
        document.getElementById("Sidenav2").style.width = "0";
        setTimeout(() => { setHelpContent(false) }, 300);
        
    }

    const get_help_content = async () => {

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_help_content",
                   function_cd: function_cd
                },
            };

            console.log("get_help_content input ", data)
           
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/help/get_help_content`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("get_help_content output", res);
            if (res.data.output.page_header) { setPageHeader(res.data.output.page_header) }
            if (res.data.output.page_taps.length > 0) { setPageTaps(res.data.output.page_taps) }
        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    return (
        <>
            <div id="Sidenav2" class="sidenav border-box-right-sidebar" >
                <div className="buttoms_bar_height" style={{padding:"0px 10px" }}>
                    <div className="normal_float normal_text_align ">
                       
                    </div>
                    <div className="opposite_float opposite_text_align">
                        <BsXCircle color="inherit" fontSize="18px" onClick={closeNav} />
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
                
                <div style={{ padding: "0px 10px"}}>
                    {pageHeader && (
                        <>
                            <div style={{ marginBottom: "10px" }}>{pageHeader.page_header && pageHeader.page_header}</div>
                        </>
                    )}

                    {pageTaps.length > 0 && pageTaps.map((procedures_tap, index) => (
                        <div  key={procedures_tap.ser_no}>
                            <div >
                                <div className="hlp_tap_aria">{procedures_tap.tap_name}</div>
                                <div style={{ marginBottom: "10px" }}>{procedures_tap.tap_description}</div>
 
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default HlpHelpContent;
