


import React, { useEffect, useState } from "react";
import axios from "axios";

import Clients from "./Clients";



const ClientsPage = () => {
    const [clientCode, setClientCode] = useState(0);
    const [systemCd, setSystemCd] = useState("Crm");


    return <>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5  border-right">
            <div style={{ height: "calc(100vh - 100px)", overflow: "auto", overflowX: "hidden" }} >
                <Clients setClientCode={setClientCode} systemCd={systemCd} /> 
               
            </div>
        </div>
                           
    </>
}

export default ClientsPage