import React, { useEffect, useState, useRef } from "react";
import UsersAndPrivileges from "../../component/Auth/UsersAndPrivileges";

const LccUsersAndPrivileges = ({
  branch_code,
  notActiveUsers,
  setNotActiveUsers,
}) => {
  useEffect(() => {}, []);

  return (
    <>
      <div>
        <UsersAndPrivileges
          system_code={"Lcc"}
          branch_code={branch_code}
          notActiveUsers={notActiveUsers}
        />
      </div>
    </>
  );
};

export default LccUsersAndPrivileges;
