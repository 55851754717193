

import React, { useEffect, useState, useRef } from "react";
import UsersAndPrivileges from "../../component/Auth/UsersAndPrivileges";

const AcaUsersAndPrivileges = ({ branch_code }) => {
    useEffect(() => {

    }, []);

    return <>
        <div>
            <UsersAndPrivileges system_code={"Aca"} branch_code={branch_code} />
        </div>
    </>
}

export default AcaUsersAndPrivileges