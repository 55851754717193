import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Clients from "../Crm/Clients";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { TiAttachment } from "react-icons/ti";
import { BsTrash3 } from "react-icons/bs";
import { TbCheckbox } from "react-icons/tb";
import { RiCheckboxBlankLine } from "react-icons/ri";
import { MdOutlineExpandMore } from "react-icons/md";
import { FcCheckmark } from "react-icons/fc";
import { IoCheckmarkOutline } from "react-icons/io5";
import { GiCheckMark } from "react-icons/gi";
import { LuUserPlus } from "react-icons/lu";
import SaveIcon from "@mui/icons-material/Save";
import { MdOutlineCheck } from "react-icons/md";
import { MdOutlineCheckCircle } from "react-icons/md";
import { GrCheckbox } from "react-icons/gr";
import { GoCheckCircle } from "react-icons/go";
import { TfiCheckBox } from "react-icons/tfi";
import { MdOutlineBlock } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
/*import { LuEdit2 } from "react-icons/lu"*/

import { useDispatch, useSelector } from "react-redux"; //this import redux functions

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ImageCard from "../cards/ImageCard";

import Users from "./Users";
import Groups from "./Groups";
import CheckOut from "../CheckOut/CheckOut";

const UsersAndPrivileges = ({ system_code, branch_code }) => {
  let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
  const dispatch = useDispatch({});
  const [currentUserIndex, setCurrentUserIndex] = useState(null);
  const [branchSystemUsers, setBranchSystemUsers] = useState([]);
  const [branchemployees, setBranchemployees] = useState([]);
  const [orgenizationStructure, setOrgenizationStructure] = useState([]);
  const [systemMenu, setSystemMenu] = useState([]);
  const [functionTaps, setFunctionTaps] = useState([]);
  const [adminUser, setAdminUser] = useState({});
  const [userPreveleges, setUserPreveleges] = useState([]);
  const [userGroupsPreveleges, setUserGroupsPreveleges] = useState([]);
  const [tapsAactions, setTapsAactions] = useState([]);
  const [jobTitles, setJobTitles] = useState();
  const [userTypes, setUserTypes] = useState([]);
  const [pageLables, setPageLables] = useState();
  const [updating, setUpdating] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState(new Set());
  const [systemCd, setSystemCd] = useState(system_code);
  const [branchCode, setBranchCode] = useState(branch_code);
  const [branchIndex, setBranchIndex] = useState(null);
  const [systemTypemode, setSystemTypemode] =
    useState(
      null,
    ); /*this is to check if the user_type_cd is applicable or not*/

  const [branchTree, setBranchTree] = useState(false);
  const [tapActive, setTapActive] = useState("Users");
  const [usersAreasMode, setUsersAreasMode] = useState("List system user");
  const [inActiveUserCount, setInactiveUserCount] = useState(0);
  const [notActiveUsers, setNotActiveUsers] = useState([]);
  useEffect(() => {
    get_authintication_initial();
    set_user_typemode();
  }, []);

  function set_user_typemode() {
    if (systemCd === "Lcc") {
      /*her we should inclue allystems which abblicable for user_type_cd*/
      setSystemTypemode(true);
    }
  }

  function select_branch(branch_cd, index) {
    setUsersAreasMode("List system user");
    setBranchIndex(index);
    setBranchCode(branch_cd);
    get_branch_users(branch_cd);
    setBranchTree(!branchTree);
    setBranchemployees([]);
    setCurrentUserIndex(null);
  }

  function toggle_branch(branch_id) {
    if (document.getElementById(branch_id).style.display === "block") {
      document.getElementById(branch_id).style.display = "none";
    } else {
      document.getElementById(branch_id).style.display = "block";
    }
  }

  function toggle_node(system_node_id) {
    if (document.getElementById(system_node_id).style.display === "block") {
      document.getElementById(system_node_id).style.display = "none";
    } else {
      document.getElementById(system_node_id).style.display = "block";
    }
  }

  function toggle_tap(function_tap_id) {
    if (document.getElementById(function_tap_id).style.display === "block") {
      document.getElementById(function_tap_id).style.display = "none";
    } else {
      document.getElementById(function_tap_id).style.display = "block";
    }
  }

  const set_tab_active = (active_tab) => {
    setTapActive(active_tab);
  };

  const orgenization_subnode = (parent_id) => {
    return orgenizationStructure.map((tree_node, i) => {
      if (parent_id === tree_node.parent_id) {
        return (
          <div key={i}>
            <div key={i} className="user_line">
              <div className="node_line">
                <div style={{ display: "flex" }}>
                  <div className="icon_tree_area">
                    <ImageCard
                      icon={tree_node.branch_type_cd}
                      iconType={"organization tree"}
                    />
                  </div>
                  <div
                    className="node_name_area"
                    onClick={() => {
                      toggle_branch(`branch_${tree_node.branch_id}`);
                    }}
                  >
                    {tree_node.branch_name}
                  </div>
                  <div style={{ padding: "0px 4px", width: "20px" }}>
                    {tree_node.branch_cd === branchCode ? (
                      <GoCheckCircle size="20" style={{ color: "green" }} />
                    ) : (
                      <div
                        className="flip_display_area"
                        style={{ width: "100%" }}
                        onClick={() => {
                          select_branch(tree_node.branch_cd, i);
                        }}
                      >
                        <MdOutlineCheck size="18" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              id={`branch_${tree_node.branch_id}`}
              className="node_block_section left_block_border"
              style={{ display: "none" }}
            >
              {orgenization_subnode(tree_node.branch_id)}
            </div>
          </div>
        );
      }
    });
  };

  const place_icon = (tap_action_code) => {
    if (
      userPreveleges.find(
        (action) =>
          action.action_cd === tap_action_code && action.blocked === "1",
      )
    ) {
      return <MdOutlineBlock size="16" color="gray" />;
    } else {
      if (
        userPreveleges.find(
          (action) =>
            action.action_cd === tap_action_code && action.blocked !== "1",
        ) ||
        userGroupsPreveleges.find(
          (action) => action.action_cd === tap_action_code,
        )
      ) {
        return <TfiCheckBox size="14" />;
      } else {
        /*return <GrCheckbox size="13" />*/
        return <div className="unchecked_checkbox"></div>;
      }
    }
  };

  const system_subNode = (parent_id) => {
    return systemMenu.map((menu_node, i) => {
      if (parent_id === menu_node.parent_id) {
        return (
          <div key={i}>
            <div key={i} className="user_line">
              <div className="node_line">
                <div
                  style={{ display: "flex" }}
                  onClick={() => {
                    toggle_node(`menu_${menu_node.node_id}`);
                  }}
                >
                  <div className="icon_tree_area">
                    <ImageCard
                      icon={menu_node.icon_name}
                      iconType={"system menu tree"}
                    />
                  </div>
                  <div className="node_name_area">{menu_node.page_name}</div>
                </div>
                <div style={{ width: "16px" }}>
                  {(userPreveleges.find(
                    (page) => page.function_cd === menu_node.function_cd,
                  ) ||
                    userGroupsPreveleges.find(
                      (page) => page.function_cd === menu_node.function_cd,
                    )) && <MdOutlineCheck size="18" color="darkgray" />}
                </div>
              </div>
            </div>
            <div
              id={`menu_${menu_node.node_id}`}
              className="node_block_section  hedden"
            >
              {menu_node.icon_name !== "Page" &&
                system_subNode(menu_node.node_id)}
              {menu_node.icon_name === "Page" && (
                <>
                  {menu_node.page_note && (
                    <>
                      <div
                        style={{
                          backgroundColor: "#e8e8e8",
                          padding: "6px",
                          fontSize: "14px",
                        }}
                      >
                        {menu_node.page_note}
                      </div>
                    </>
                  )}
                  {/*tap section*/}
                  {functionTaps.length > 0 &&
                    functionTaps.map((function_taps, index) => {
                      if (menu_node.function_cd === function_taps.function_cd) {
                        return (
                          <div key={function_taps.function_cd + index}>
                            <div className="node_line">
                              <div
                                style={{ display: "flex" }}
                                onClick={() => {
                                  toggle_tap(
                                    `tap_${
                                      function_taps.function_cd +
                                      "_" +
                                      function_taps.tap_cd
                                    }`,
                                  );
                                }}
                              >
                                <div className="icon_tree_area">
                                  <ImageCard
                                    icon={"Tap"}
                                    iconType={"system menu tree"}
                                  />
                                </div>
                                <div className="node_name_area">
                                  {function_taps.tap_name}
                                </div>
                              </div>
                              <div style={{ width: "16px" }}>
                                {(userPreveleges.find(
                                  (tap) => tap.tap_cd === function_taps.tap_cd,
                                ) ||
                                  userGroupsPreveleges.find(
                                    (tap) =>
                                      tap.tap_cd === function_taps.tap_cd,
                                  )) && (
                                  <MdOutlineCheck size="18" color="darkgray" />
                                )}
                              </div>
                            </div>
                            <div
                              id={`tap_${
                                function_taps.function_cd +
                                "_" +
                                function_taps.tap_cd
                              }`}
                              className="node_block_section hedden"
                            >
                              {" "}
                              {/*actions arae*/}
                              {function_taps.tap_note && (
                                <>
                                  <div
                                    style={{
                                      backgroundColor: "#e8e8e8",
                                      padding: "6px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {function_taps.tap_note}
                                  </div>
                                </>
                              )}
                              {/* action section */}
                              {tapsAactions.length > 0 &&
                                tapsAactions.map((taps_actions, index) => {
                                  if (
                                    function_taps.function_cd ===
                                      taps_actions.function_cd &&
                                    function_taps.tap_cd === taps_actions.tap_cd
                                  ) {
                                    return (
                                      <div key={taps_actions.action_cd + index}>
                                        <div className="node_line">
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {updating.length > 0 &&
                                            updating.find(
                                              (action_cd) =>
                                                action_cd ===
                                                taps_actions.action_cd,
                                            ) ? (
                                              <div className="icon_tree_area">
                                                <span className="loader-spiner"></span>
                                              </div>
                                            ) : (
                                              <div
                                                className="icon_tree_area"
                                                onClick={() => {
                                                  update_user_prevelege(
                                                    taps_actions.function_cd,
                                                    taps_actions.tap_cd,
                                                    taps_actions.action_cd,
                                                  );
                                                }}
                                              >
                                                {place_icon(
                                                  taps_actions.action_cd,
                                                )}
                                              </div>
                                            )}
                                            <div className="icon_tree_area">
                                              <ImageCard
                                                icon={
                                                  taps_actions.action_type_cd
                                                }
                                                iconType={"taps actions"}
                                              />
                                            </div>
                                            <div className="node_name_area">
                                              {taps_actions.tap_name}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        );
                      }
                    })}
                </>
              )}
            </div>
          </div>
        );
      }
    });
  };

  // Read ----------------------
  const get_authintication_initial = async () => {
    try {
      /* let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));*/

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "authintication_initial",
          branch_cd: "2",
          system_cd: systemCd,
        },
      };

      console.log("get_authintication_initial input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/auth/get_authintication_initial`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_authintication_initial output", res);
      if (res.data.output.branch_system_users) {
        setBranchSystemUsers(res.data.output.branch_system_users);
      }
      if (res.data.output.orgenization_strucure.length > 0) {
        setOrgenizationStructure(res.data.output.orgenization_strucure);
        res.data.output.orgenization_strucure.map((tree_node, i) => {
          if (branch_code === tree_node.branch_cd) {
            setBranchIndex(i);
          }
        });
      }
      if (res.data.output.menue_tree && res.data.output.menue_tree.length > 0) {
        setSystemMenu(res.data.output.menue_tree);
      }
      if (
        res.data.output.function_taps &&
        res.data.output.function_taps.length
      ) {
        setFunctionTaps(res.data.output.function_taps);
      }
      if (
        res.data.output.taps_actions &&
        res.data.output.taps_actions.length > 0
      ) {
        setTapsAactions(res.data.output.taps_actions);
      }
      setPageLables(res.data.output.page_lables);

      if (res.data.output.inactiv_users) {
        setInactiveUserCount(res.data.output.inactiv_users);
      }

      if (res.data.output.non_active_users.length > 0) {
        setNotActiveUsers(res.data.output.non_active_users);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_branch_users = async (branch_cd) => {
    try {
      /* let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));*/

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_branch_users",
          system_cd: systemCd,
          branch_cd: branch_cd,
        },
      };

      console.log("get_branch_users input ", data);
      return;
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/auth/get_branch_users`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_branch_users output", res);
      //if (res.data.output.branch_users) { setSystemUsers(res.data.output.branch_users) }
      //if (res.data.output.orgenization_strucure.length > 0) { setOrgenizationStructure(res.data.output.orgenization_strucure) }
      //if (res.data.output.menue_tree.length > 0) { setSystemMenu(res.data.output.menue_tree) }
      //if (res.data.output.function_taps.length > 0) { setFunctionTaps(res.data.output.function_taps) }
      //if (res.data.output.taps_actions.length > 0) { setTapsAactions(res.data.output.taps_actions) }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  // Update ----------------------

  const update_user_prevelege = async (function_cd, tap_cd, action_cd) => {
    setUpdating([action_cd, ...updating]);

    try {
      /* let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));*/

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_user_prevelege_action",
          system_cd: systemCd,
          prevelage_user_cd: branchSystemUsers[currentUserIndex].user_cd,
          function_cd: function_cd,
          tap_cd: tap_cd,
          action_cd: action_cd,
        },
      };

      console.log("update_user_prevelage_action input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/auth/update_user_prevelege_action`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("update_user_prevelage_action output", res);
      if (res.data.output.user_preveleges && res.data.output.user_preveleges) {
        setUserPreveleges(res.data.output.user_preveleges);
      } else {
        setUserPreveleges([]);
      }
      const newArray = updating.filter(
        (item) => item !== res.data.output.action_code,
      );
      setUpdating(newArray);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  var inActiveTap = "tap_head_area";
  var activeTap = "tap_head_area active_tap_head_area";

  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5  border-right">
          <div>
            {/*header bar*/}
            {/*Orgenization Structure*/}
            {branchTree && (
              <div className="branch_menu">
                {orgenizationStructure.map((tree_node, i) => {
                  if (i === 0)
                    return (
                      <>
                        <div key={i} className="user_line">
                          <div className="node_line">
                            <div style={{ display: "flex" }}>
                              <div className="icon_tree_area">
                                <ImageCard
                                  icon={tree_node.branch_type_cd}
                                  iconType={"organization tree"}
                                />
                              </div>
                              <div
                                className="node_name_area"
                                onClick={() => {
                                  toggle_branch(
                                    `branch_${tree_node.branch_id}`,
                                  );
                                }}
                              >
                                {tree_node.branch_name}
                              </div>
                              <div
                                style={{ padding: "0px 4px", width: "20px" }}
                              >
                                {tree_node.branch_cd === branchCode ? (
                                  <GoCheckCircle
                                    size="20"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <div
                                    className="flip_display_area"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                      select_branch(tree_node.branch_cd, i);
                                    }}
                                  >
                                    <MdOutlineCheck size="18" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={`branch_${tree_node.branch_id}`}
                          className="node_block_section left_block_border"
                        >
                          {orgenization_subnode(tree_node.branch_id)}
                        </div>
                      </>
                    );
                })}
              </div>
            )}
            {usersAreasMode !== "Not active useres" && (
              <div
                className="prevelages_header"
                onClick={() => setBranchTree(!branchTree)}
              >
                <div>
                  {orgenizationStructure.length > 0 &&
                    branchIndex !== null &&
                    orgenizationStructure[branchIndex].branch_name}
                </div>
                <div style={{ padding: "0px 6px" }}>
                  <MdOutlineExpandMore size="18" />
                </div>
              </div>
            )}
            {/*Taps bar*/}
            {usersAreasMode !== "Not active useres" && (
              <div className="tap_menu">
                <div
                  className={tapActive === "Users" ? activeTap : inActiveTap}
                  onClick={() => {
                    set_tab_active("Users");
                  }}
                >
                  {pageLables && pageLables[0].description}
                </div>
                <div
                  className={tapActive === "Groups" ? activeTap : inActiveTap}
                  onClick={() => {
                    set_tab_active("Groups");
                  }}
                >
                  {pageLables && pageLables[1].description}
                </div>
              </div>
            )}
            {tapActive === "Users" && (
              <>
                <Users
                  pageLables={pageLables}
                  branchSystemUsers={branchSystemUsers}
                  setBranchSystemUsers={setBranchSystemUsers}
                  setCurrentUserIndex={setCurrentUserIndex}
                  systemCd={systemCd}
                  branchCode={branchCode}
                  branchemployees={branchemployees}
                  setBranchemployees={setBranchemployees}
                  usersAreasMode={usersAreasMode}
                  setUsersAreasMode={setUsersAreasMode}
                  setAdminUser={setAdminUser}
                  setUserPreveleges={setUserPreveleges}
                  setUserGroupsPreveleges={setUserGroupsPreveleges}
                  jobTitles={jobTitles}
                  setJobTitles={setJobTitles}
                  userTypes={userTypes}
                  setUserTypes={setUserTypes}
                  systemTypemode={systemTypemode}
                  inActiveUserCount={inActiveUserCount}
                  setNotActiveUsers={setNotActiveUsers}
                  notActiveUsers={notActiveUsers}
                  setSelectedEmployees={setSelectedEmployees}
                  selectedEmployees={selectedEmployees}
                />
              </>
            )}
            {tapActive === "Groups" && (
              <>
                <Groups />
              </>
            )}
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7  border-right">
          <div
            style={{
              height: "calc(100vh - 86px)",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            {/*header bar*/}
            <div className="prevelages_header">
              {currentUserIndex !== null && (
                <>
                  <div className="normal_float">
                    <div className="user_line">
                      <div style={{ display: "flex" }}>
                        <div>
                          <img
                            src={
                              "https://files.amanrow.com/user/" +
                              branchSystemUsers[currentUserIndex].image_name
                            }
                            alt=" "
                            className="user_profile_image"
                          />
                        </div>
                        <div
                          className="space-betw-vertical"
                          style={{ margin: "0px 10px" }}
                        >
                          <div>
                            {" "}
                            {branchSystemUsers[currentUserIndex].full_name}
                          </div>
                          <div>
                            {branchSystemUsers[currentUserIndex].job_title}
                            {branchSystemUsers[currentUserIndex].user_type && (
                              <span style={{ color: "gray" }}>
                                {" "}
                                |{" "}
                                {
                                  branchSystemUsers[currentUserIndex].user_type
                                }{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opposite_float">
                    <div
                      className="space-betw-vertical"
                      style={{ color: "gray" }}
                    >
                      {adminUser && adminUser.supper_admin && (
                        <>
                          <div>
                            <MdOutlineCheck size="16" />{" "}
                            {pageLables && pageLables[5].description}
                          </div>
                        </>
                      )}
                      {adminUser && adminUser.system_admin && (
                        <>
                          <div>
                            <MdOutlineCheck size="16" />{" "}
                            {pageLables && pageLables[6].description}
                          </div>
                        </>
                      )}
                      {adminUser && adminUser.branch_admin && (
                        <>
                          <div>
                            <MdOutlineCheck size="16" />{" "}
                            {pageLables && pageLables[7].description}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </>
              )}
            </div>
            {/*sytem tree*/}
            {currentUserIndex !== null && (
              <>
                {systemMenu.map((menu_node, i) => {
                  if (i === 0)
                    return (
                      <>
                        <div style={{ margin: "14px 0px 0px 20px" }}>
                          <div key={i} className="user_line">
                            <div className="node_line">
                              <div style={{ width: "16px" }}></div>
                              <div
                                style={{ display: "flex" }}
                                onClick={() => {
                                  toggle_node(`menu_${menu_node.node_id}`);
                                }}
                              >
                                <div className="icon_tree_area">
                                  <ImageCard
                                    icon={menu_node.icon_name}
                                    iconType={"system menu tree"}
                                  />
                                </div>
                                <div className="node_name_area">
                                  {menu_node.page_name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id={`menu_${menu_node.node_id}`}
                            className="node_block_section"
                          >
                            {system_subNode(menu_node.node_id)}
                          </div>
                        </div>
                      </>
                    );
                })}
              </>
            )}
          </div>

          {/* {CheckOutPage === true && (
            <CheckOut
              selectedItems={selectedEmployees}
              setSelectedItems={setSelectedEmployees}
            />
          )} */}
        </div>
      </div>
    </>
  );
};

export default UsersAndPrivileges;
