import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import Select from "react-select";
// import {language_return} from '../../JSfiles/inventory/ItemLabelsLoad'

import Chattings from "../Request/Chattings";
import InitialPage from "../Request/InitialPage";
import ComplaintPage from "../Request/complaint/ComlpaintPage";
import Menu_1 from "../Request/complaint/Menu_1";
import InvoiceBody from "../../Acc/Invoice/InvoiceBody";
import newtIcon from "../../../SVG_images/new.svg";
import { IoSearch } from "react-icons/io5";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineBorderBottom,
} from "react-icons/ai";
import saveIcon from "../../../SVG_images/save.svg";
import editIcon from "../../../SVG_images/edit.svg";
import attacmentsIcon from "../../../SVG_images/attachment.png";
import remarksIcon from "../../../SVG_images/note.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import blankprofile from "../../../Images/blankprofile.png";
import { BsAt } from "react-icons/bs";
import { IoArrowForwardSharp } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { BsChatText } from "react-icons/bs";

import { useSelector } from "react-redux"; //to get the value from the redux

import { Dropdown, Collapse } from "react-bootstrap";

const CallCenter = () => {
  const [attachmentsRemarks, setAttachmentsRemarks] = useState({});
  const [clienttData, setclienttData] = useState();
  const [clientDetail, setClientDetail] = useState();
  const [clientRequests, setClientRequests] = useState();
  const [customerTypetData, setCustomerTypetData] = useState();
  const [orgenizationStructure, setOrgenizationStructure] = useState([]);
  const [invoicePageLables, setInvoicePageLables] = useState();
  const [progresList, setProgresList] = useState([]);
  const [progressCoversation, setProgressCoversation] = useState([]);
  const [requestsListData, setRequestsListData] = useState();
  const [requestData, setRequestData] = useState();
  const [requestTypetData, setrequestTypetData] = useState();
  const [requesInitialstData, setRequesInitialstData] = useState();
  const [requestType, setrequestType] = useState();
  const [requestdetail, setRequestdetail] = useState({});
  const [requestInvoice, setRequestInvoice] = useState({});
  const [requestNotes, setRequestNotes] = useState([]);
  const [systemUsers, setSystemUsers] = useState([]);

  const [invoiceMode, setInvoiceMode] = useState("Retrieve");
  const [listMode, setListMode] = useState("my role");
  const [open, setOpen] = useState(false);
  const [openInvoices, setOpenInvoices] = useState(false);
  const [PageMode, setPageMode] = useState("");
  const [requestMode, setRequestMode] = useState("");
  const [sessionUser, setSessionUser] = useState(
    JSON.parse(localStorage.getItem("amanrowuserData")).user_cd,
  );
  const [userNotesSection, setUserNotesSections] = useState(false);
  const [countries, setCountries] = useState(false);
  const [countryIndex, setCountryIndex] = useState(0);
  const [countryAreaCode, setCountryAreaCode] = useState(965);

  const dispatch = useDispatch();
  var user_code = 0;

  const [RequestInput, setRequestInput] = useState({
    request_year: 0,
    branch_cd: 0,
    request_cd: 0,
  });

  const { user } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    setListMode("");
    getCountries();
    getRequestInitial();
  }, []);

  const set_edit_mode = () => {
    setRequestMode("Edit");
    document.getElementById("edit_status").innerText = "Edit";
  };

  const getCountries = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
        },
        input: {
          actioncode: "COUNTRY_DAILCODES",
        },
      };

      console.log("get_counties ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/countries`,
        data,
      );
      console.log("get_counties output ", res.data.output.countries);

      const countryIndex = res.data.output.countries.findIndex(
        (country) => country.dial_cd === "965",
      );

      console.log("kuwait index", countryIndex);
      setCountryIndex(countryIndex);

      let options = res.data.output.countries.map((country) => {
        return {
          value: country.dial_cd,
          label: "+" + country.dial_cd,
          _id: country.country_cd,
          country_id: country.country_id,
          dial_cd: country.dial_cd,
        };
      });

      console.log("kuwait dailcd", options[countryIndex]);
      setCountries(options);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const go_to_request = (request_year, branch_cd, request_cd) => {
    alert("change onClick triger");
    setRequestInput({
      request_year: request_year,
      branch_cd: branch_cd,
      request_cd: request_cd,
    });
    setPageMode("Request");
  };

  const set_clear_mode = () => {
    setPageMode("Initial");
  };

  const phone_keyEvent = (e) => {
    if (e.charCode === 13) {
      get_client_details_and_invoices(requestdetail.phone_no);
    }
  };

  function set_icon(employee_image_name, user_image_name) {
    if (user_image_name) {
      return (
        <img
          src={"https://files.amanrow.com/user/" + user_image_name}
          alt=" "
          className="progress_profile_image"
        />
      );
    } else {
      if (employee_image_name) {
        return (
          <img
            src={employee_image_name}
            alt=" "
            className="progress_profile_image"
          />
        );
      } else {
        return (
          <img src={blankprofile} className="progress_profile_image" alt="" />
        );
      }
    }
  }

  /*Read ------------*/
  const getRequestInitial = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_request_initials",
          branch_cd: userDataLocal.branch_cd,
          system_cd: "Wkf",
          function_cd: "20",
        },
      };

      console.log("get_request_initials input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/wf/get_request_initials`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );
      console.log("get_request_initials output ", res);

      if (
        res.data.output.request_type &&
        res.data.output.request_type.length > 0
      ) {
        setrequestTypetData(res.data.output.request_type);
      }
      if (
        res.data.output.customer_type &&
        res.data.output.customer_type.length > 0
      ) {
        setCustomerTypetData(res.data.output.customer_type);
      }
      if (
        res.data.output.invoice_page_lables &&
        res.data.output.invoice_page_lables.length > 0
      ) {
        setInvoicePageLables(res.data.output.invoice_page_lables);
      }
      if (
        res.data.output.orgenization_strucure &&
        res.data.output.orgenization_strucure.length > 0
      ) {
        setOrgenizationStructure(res.data.output.orgenization_strucure);
      } else {
        setOrgenizationStructure({});
      }
      if (
        res.data.output.system_users &&
        res.data.output.system_users.length > 0
      ) {
        setSystemUsers(res.data.output.system_users);
      } else {
        setSystemUsers({});
      }

      setRequesInitialstData(res.data.output);
      setPageMode("Initial");
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_my_role = async () => {
    setListMode("my role");
  };

  const get_new_task = async () => {
    setListMode("new task");
  };

  const getrequest = async (request_year, branch_cd, request_cd) => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_request_details",
          request_year: request_year,
          branch_cd: branch_cd,
          request_cd: request_cd,
        },
      };

      console.log("getrequest input", data);
      let res = await axios.post(
        process.env.REACT_APP_BACK_END_URL + "/api/iip/wf/get_request_details",
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getrequest output", res);

      if (
        res.data.output.request_details &&
        res.data.output.request_details.request_cd
      ) {
        setRequestdetail(res.data.output.request_details);
        if (res.data.output.request_invoice) {
          setRequestInvoice(res.data.output.request_invoice);
        } else {
          setRequestInvoice([]);
        }

        if (
          res.data.output.progres_list &&
          res.data.output.progres_list.length > 0
        ) {
          setProgresList(res.data.output.progres_list);
        } else {
          setProgresList([]);
        }
        if (
          res.data.output.progress_coversation &&
          res.data.output.progress_coversation.length > 0
        ) {
          setProgressCoversation(res.data.output.progress_coversation);
        } else {
          setProgressCoversation([]);
        }
        if (res.data.output.attachments_remarks) {
          setAttachmentsRemarks(res.data.output.attachments_remarks);
        } else {
          setAttachmentsRemarks([]);
        }

        if (
          res.data.output.request_notes &&
          res.data.output.request_notes.length > 0
        ) {
          setRequestNotes(res.data.output.request_notes);
        } else {
          setRequestNotes([]);
        }
        if (res.data.output.client_details) {
          setclienttData(res.data.output.client_details);
        } else {
          setclienttData({});
        }
      } else {
        setRequestdetail({});
      }

      if (res.data.output) {
        setRequestData(res.data.output);
      }

      var request = res.data.output.request_details;
      setRequestMode("View");

      setPageMode("Request");
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_client_requests = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client_requests",
          country_area_cd: countryAreaCode,
          phone_no: document.getElementById("phone_no").value,
          request_type_cd: "1",
        },
      };

      console.log("get_client_request input", data);

      let res = await axios.post(
        process.env.REACT_APP_BACK_END_URL + "/api/iip/wf/get_client_requests",
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_client_request output", res);

      if (
        res.data.output.client_details &&
        res.data.output.client_details.client_cd
      ) {
        setClientDetail(res.data.output.client_details);
        if (
          res.data.output.requests_list &&
          res.data.output.requests_list.length > 0
        ) {
          setClientRequests(res.data.output.requests_list);
        } else {
          setClientRequests([]);
        }
      }

      setListMode("client requests");
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_client_details = async (phone_no) => {
    //setclienttData();
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      /*let client_mobil_no = document.getElementById('client_mobile').value*/
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client_invoices",
          country_area_cd: "965",
          mobile_no: phone_no,
        },
      };

      console.log("get_client_details data", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_client_invoices`,
        data,
      );

      console.log("get_client_details output", res);
      return;
      if (res.data.output.client_name) {
        setclienttData(res.data.output);
      } else {
        alert("no data");
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_client_details_and_invoices = async (mobile_no) => {
    alert("okay");
    /*setclienttData();*/
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      /*let client_mobil_no = document.getElementById('client_mobile').value*/
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client_invoices",
          country_area_cd: "965",
          mobile_no: mobile_no,
        },
      };
      console.log("get_client_details_and_invoices input", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_client_invoices`,
        data,
      );
      console.log("get_client_details_and_invoices output", res);
      if (res.data.output.client_name) {
        setclienttData(res.data.output);
      } else {
        alert("no data");
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const setInvoice = async (invoice_year, branch_cd, invoice_cd) => {
    document.getElementById("invoice_no").innerHTML =
      "INV-" + invoice_year + "-" + branch_cd + "-" + invoice_cd;
    document.getElementById("invoice_collapse").style.display = "none";

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_invoice",
          invoice_year: "2022",
          branch_cd: "69",
          invoice_cd: "1",
        },
      };
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/fin/get_invoice`,
        data,
      );

      console.log("get invoice output", res);
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 border-right">
          <div className="panel-body  mostly-customized-scrollbar start_padding">
            {PageMode === "Initial" && (
              <div>
                <div
                  className=""
                  //   onClick={() => {
                  //     get_client_request("965", "65133329");
                  //   }}
                >
                  <div
                    className="request_menu_item"
                    style={{ display: "flex" }}
                  >
                    <div className="search-input-container">
                      {/* <select id="dial-code" className="select-dailcode">
                            <option value="+1">+1</option>
                            <option value="+44">+44</option>
                            <option value="+91">+91</option>
                            </select> */}

                      <Select
                        id="country_area_cd"
                        options={countries}
                        defaultValue={countries[117]}
                        formatOptionLabel={(country) => (
                          <div className="country-option">
                            <img
                              src={`https://files.amanrow.com/flags/${country.country_id.toLowerCase()}.png`}
                              alt="country-image"
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {country.label}
                            </span>
                          </div>
                        )}
                        onChange={(e) => {
                          console.log(e);
                          setCountryAreaCode(e.value);
                        }}
                      />

                      <input
                        type="text"
                        id="phone_no"
                        name="phone"
                        className="phoneno-input-container"
                        placeholder="Enter your phone number"
                      />
                      <button id="search-button">
                        <IoSearch
                          size={15}
                          onClick={() => {
                            get_client_requests();
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="request_menu_line"
                  onClick={() => {
                    setPageMode("Request");
                    setRequestMode("Draft");
                  }}
                >
                  <div className="request_menu_item">
                    {" "}
                    <img src={newtIcon} className="buton_icon" /> New Request{" "}
                  </div>
                </div>
                <div
                  className="request_menu_line"
                  onClick={() => {
                    get_new_task();
                  }}
                >
                  <div className="request_menu_item">
                    {" "}
                    <img src={newtIcon} className="buton_icon" /> New Tasks{" "}
                  </div>
                </div>
                <div
                  className="request_menu_line"
                  onClick={() => {
                    get_my_role();
                  }}
                >
                  <div className="request_menu_item">
                    {" "}
                    <img src={newtIcon} className="buton_icon" /> My Role{" "}
                  </div>
                </div>
                <div className="request_menu_line bottom_line">
                  <div className="request_menu_item">
                    {" "}
                    <img src={newtIcon} className="buton_icon" /> recent opend
                    Requests{" "}
                  </div>
                </div>
                <div
                  className="request_menu_line"
                  onClick={() => {
                    getrequest(2022, 9, 1);
                  }}
                >
                  <div className="request_menu_item">First request</div>
                </div>
                <div
                  className="request_menu_line"
                  onClick={() => {
                    getrequest(2022, 9, 2);
                  }}
                >
                  <div className="request_menu_item">Second request</div>
                </div>
              </div>
            )}
            {PageMode === "Request" && (
              <Chattings
                requestdetail={requestdetail}
                progresList={progresList}
                setProgresList={setProgresList}
                setProgressCoversation={setProgressCoversation}
                progressCoversation={progressCoversation}
                sessionUser={sessionUser}
                orgenizationStructure={orgenizationStructure}
                systemUsers={systemUsers}
              ></Chattings>
            )}
          </div>
        </div>
        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
          {PageMode === "Initial" && (
            <div>
              {listMode === "client requests" && (
                <>
                  {clientDetail && clientDetail.client_cd && (
                    <div style={{ marginBottom: "20px" }}>
                      <div className="row data_line">
                        <div
                          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          style={{ display: "flex" }}
                        >
                          <label className="label_area">Client name</label>
                          <div className="viewing_field">
                            {" "}
                            {clientDetail && clientDetail.client_name}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="row data_line">
                        <div
                          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          style={{ display: "flex" }}
                        >
                          <label className="label_area">Client Id</label>
                          <div className="viewing_field">
                            {" "}
                            {clientDetail && clientDetail.client_id}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "15%" }}>Request Time</th>
                        <th style={{ width: "45%" }}>Request title</th>
                        <th style={{ width: "20%" }}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientRequests &&
                        clientRequests.length >= 0 &&
                        clientRequests.map((client_requests, index) => (
                          <tr
                            onClick={() => {
                              getrequest(
                                client_requests.request_year,
                                client_requests.branch_cd,
                                client_requests.request_cd,
                              );
                            }}
                          >
                            <td>{client_requests.request_time}</td>
                            <td>{client_requests.request_title}</td>
                            <td>{client_requests.request_status}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
              {listMode === "new task" && (
                <>
                  <div>Tew Task</div>
                </>
              )}
              {listMode === "my role" && (
                <>
                  <div>my role</div>
                </>
              )}
            </div>
          )}
          {PageMode === "Request" && (
            <div>
              {/*buttons bar*/}
              <div id="request_header" className="buttons_div ">
                <div
                  className="subbutton_div normal_float"
                  onClick={set_clear_mode}
                >
                  <div className="buton_icon normal_float">
                    {" "}
                    <AiOutlineArrowLeft style={{ fontSize: "18px" }} />
                  </div>
                  <div id="edit_status" className="normal_float"></div>
                  <div style={{ clear: "both" }}></div>
                </div>
                <div
                  className="subbutton_div normal_float"
                  style={{ paddingLeft: "10px", borderLeft: "solid 1px gray" }}
                >
                  <div id="request_type_name" className=" normal_float"></div>
                  <div
                    id="request_code"
                    className=" normal_float"
                    style={{ paddingRight: "20px", paddingLeft: "2px" }}
                  ></div>
                  <div style={{ clear: "both" }}></div>
                </div>
                <div
                  id="request_buttons"
                  className="opposite_float subbutton_div"
                >
                  {requestMode === "View" && (
                    <BsThreeDotsVertical className="buton_icon opposite_float" />
                  )}
                  {(requestMode === "Edit" || requestMode === "Draft") && (
                    <img
                      id="item_save_btn"
                      src={saveIcon}
                      className="buton_icon opposite_float "
                    />
                  )}
                  {requestMode === "View" && (
                    <>
                      <img
                        src={remarksIcon}
                        className="buton_icon opposite_float"
                        onClick={() => {
                          dispatch({
                            type: "attachmentInfo",
                            payload: { data1: "good morening" },
                          });
                        }}
                        alt=""
                      />
                      <img
                        src={attacmentsIcon}
                        className="buton_icon opposite_float"
                        onClick={() => {
                          dispatch({
                            type: "attachmentInfo",
                            payload: { data1: "good morening" },
                          });
                        }}
                        alt=""
                      />
                      <img
                        src={editIcon}
                        className="buton_icon opposite_float"
                        onClick={() => {
                          set_edit_mode();
                        }}
                        alt=""
                      />
                    </>
                  )}
                  <div style={{ clear: "both" }}></div>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <div>
                {requestMode === "Draft" && (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        id="client_mobile"
                        type="text"
                        className="editing_field"
                        style={{ outline: "none", width: "40px" }}
                        value="965"
                      />
                      <input
                        type="text"
                        className="editing_field"
                        style={{ outline: "none", width: "auto" }}
                        autoComplete="0"
                        placeholder="Client Phone"
                        value={requestdetail && requestdetail.phone_no}
                        onChange={(e) =>
                          setRequestdetail({
                            ...requestdetail,
                            phone_no: e.target.value,
                          })
                        }
                        onKeyPress={phone_keyEvent}
                      />
                    </div>

                    <table className="table-verticle-middle table-frm table-frm-ed">
                      <tbody>
                        <tr>
                          <th style={{ width: "35%" }} />
                          <th style={{ width: "65%" }} />
                        </tr>

                        {clienttData && (
                          <tr>
                            <th
                              id="client_name_l"
                              style={{ verticalAlign: "top" }}
                            >
                              Client
                            </th>
                            <td>
                              <div className="form-control  input-sm ">
                                <div>
                                  <div
                                    id="client_name"
                                    className="normal_float field_text_alighn"
                                  >
                                    {clienttData.client_name}
                                  </div>
                                  <div
                                    className="opposite_float"
                                    onClick={() => setOpen(!open)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={open}
                                  >
                                    X
                                  </div>
                                  <div style={{ clear: "both" }}></div>
                                </div>
                                <Collapse in={open}>
                                  <div
                                    id="example-collapse-text"
                                    className="field_text_alighn"
                                    style={{ marginTop: "10px" }}
                                  >
                                    {clienttData.country_area_cd +
                                      " " +
                                      clienttData.mobil_no}
                                    <br />
                                    {clienttData.email_address}
                                    <br />
                                    {clienttData.country_name +
                                      ", " +
                                      clienttData.state_name +
                                      ", " +
                                      clienttData.city_name}
                                    <br />
                                    {clienttData.client_address}
                                  </div>{" "}
                                </Collapse>
                              </div>
                            </td>
                          </tr>
                        )}
                        {clienttData && (
                          <tr>
                            <th
                              id="invoice_no_l"
                              style={{ verticalAlign: "top" }}
                            >
                              Invoice No.
                            </th>
                            <td>
                              <div className="form-control  input-sm ">
                                <div>
                                  {clienttData &&
                                    clienttData.client_invoices.length == 1 && (
                                      <div
                                        id="invoice_no"
                                        className="normal_float field_text_alighn"
                                      >
                                        {"INV -" + clienttData &&
                                          clienttData.client_invoices[0]
                                            .invoice_year +
                                            "-" +
                                            clienttData &&
                                          clienttData.client_invoices[0]
                                            .branch_cd +
                                            "-" +
                                            clienttData.client_invoices[0]
                                              .invoice_cd}
                                      </div>
                                    )}
                                  {clienttData &&
                                    clienttData.client_invoices &&
                                    clienttData.client_invoices.length >= 1 && (
                                      <div>
                                        <div
                                          id="invoice_no"
                                          className="normal_float field_text_alighn"
                                        ></div>
                                        <div
                                          className="opposite_float"
                                          aria-controls="invoice_collapse"
                                          aria-expanded={openInvoices}
                                          onClick={() =>
                                            setOpenInvoices(!openInvoices)
                                          }
                                        >
                                          X
                                        </div>
                                        <div style={{ clear: "both" }}></div>
                                      </div>
                                    )}
                                </div>
                              </div>
                              <Collapse in={openInvoices}>
                                {" "}
                                <div
                                  id="invoice_collapse"
                                  className="field_text_alighn input-sm"
                                >
                                  {clienttData &&
                                    clienttData.client_invoices && (
                                      <div
                                        style={{
                                          pading: "10px",
                                          marginTop: "15px",
                                        }}
                                      >
                                        <table className="table table-hover">
                                          <thead>
                                            <tr>
                                              <th style={{ width: "50%" }}>
                                                {" "}
                                                Invoice No.{" "}
                                              </th>
                                              <th style={{ width: "50%" }}>
                                                {" "}
                                                Invoice Date{" "}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {clienttData &&
                                              clienttData.client_invoices
                                                .length > 0 &&
                                              clienttData.client_invoices.map(
                                                (invoices, index) => (
                                                  <tr
                                                    onClick={() =>
                                                      setInvoice(
                                                        invoices.invoice_year,
                                                        invoices.branch_cd,
                                                        invoices.invoice_cd,
                                                      )
                                                    }
                                                  >
                                                    <td className="field_text_alighn">
                                                      {"INV-" +
                                                        invoices.invoice_year +
                                                        "-" +
                                                        invoices.branch_cd +
                                                        "-" +
                                                        invoices.invoice_cd}{" "}
                                                    </td>
                                                    <td className="field_text_alighn">
                                                      {invoices.invoice_date}
                                                    </td>
                                                  </tr>
                                                ),
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                </div>
                              </Collapse>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th id="request_desc_l">Complaint Desc.</th>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <textarea
                              id="requext_text_v"
                              className="form-control input-sm"
                              style={{ width: "100%", height: "70px" }}
                              Placeholder="enter the complaint her"
                            ></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
                {requestMode === "View" && (
                  <>
                    {requestdetail && (
                      <>
                        <table
                          className="table-verticle-middle table-frm table-frm-ed"
                          style={{ width: "100%" }}
                        >
                          <tbody>
                            <tr>
                              <th style={{ width: "35%" }} />
                              <th style={{ width: "65%" }} />
                            </tr>
                            {(requestMode === "Edit" ||
                              requestMode === "Draft") && (
                              <>
                                <tr>
                                  <th id="requested_by_l">Requested By</th>
                                  <td>
                                    <div
                                      id="request_user_name_v"
                                      className="form-control input-sm view_field_no_border field_text_alighn "
                                    >
                                      {
                                        requestData.request_details
                                          .request_user_name
                                      }
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th id="request_type_cd_l">Request Type</th>
                                  <td>
                                    <select
                                      id="request_type_cd"
                                      className="form-control input-sm "
                                      selectedValue={
                                        requestData.request_details
                                          .request_type_cd
                                      }
                                    >
                                      {requestTypetData.length >= 0 &&
                                        requestTypetData.map(
                                          (request_type, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={
                                                  request_type.request_type_cd
                                                }
                                              >
                                                {request_type.request_type}
                                              </option>
                                            );
                                          },
                                        )}
                                    </select>
                                  </td>
                                </tr>
                                <tr>
                                  <th id="customer_type_cd_l">
                                    Applicant Type
                                  </th>
                                  <td>
                                    {console.log(
                                      "code = ",
                                      requestData.request_details.code,
                                    )}
                                    <select
                                      id="customer_type_cd"
                                      className="form-control input-sm "
                                      selectedValue={
                                        requestData.request_details.code
                                      }
                                    >
                                      {customerTypetData.length >= 0 &&
                                        customerTypetData.map(
                                          (customer, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={customer.code}
                                              >
                                                {customer.name_txt}
                                              </option>
                                            );
                                          },
                                        )}
                                    </select>
                                  </td>
                                </tr>
                                <tr>
                                  <th id="customer_id_l">Mobile</th>
                                  <td>
                                    <input
                                      id="client_mobile"
                                      type="text"
                                      className="form-control input-sm"
                                      style={{ outline: "none" }}
                                      placeholder="Client Mobile"
                                      autoComplete="0"
                                      onChange={get_client_details}
                                    />
                                  </td>
                                </tr>
                                {clienttData && (
                                  <tr>
                                    <th
                                      id="client_name_l"
                                      style={{ verticalAlign: "top" }}
                                    >
                                      Client
                                    </th>
                                    <td>
                                      <div className="form-control  input-sm ">
                                        <div>
                                          <div
                                            id="client_name"
                                            className="normal_float field_text_alighn"
                                          >
                                            {clienttData.client_name}
                                          </div>
                                          <div
                                            className="opposite_float"
                                            onClick={() => setOpen(!open)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={open}
                                          ></div>
                                          <div style={{ clear: "both" }}></div>
                                        </div>
                                        <Collapse in={open}>
                                          <div
                                            id="example-collapse-text"
                                            className="field_text_alighn"
                                            style={{ marginTop: "10px" }}
                                          >
                                            {clienttData.country_area_cd +
                                              " " +
                                              clienttData.mobil_no}
                                            <br />
                                            {clienttData.email_address}
                                            <br />
                                            {clienttData.country_name +
                                              ", " +
                                              clienttData.state_name +
                                              ", " +
                                              clienttData.city_name}
                                            <br />
                                            {clienttData.client_address}
                                          </div>
                                        </Collapse>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {clienttData && (
                                  <tr>
                                    <th
                                      id="invoice_no_l"
                                      style={{ verticalAlign: "top" }}
                                    >
                                      Invoice No.
                                    </th>
                                    <td>
                                      <div className="form-control  input-sm ">
                                        <div>
                                          <div
                                            id="Ivoive_no"
                                            className="normal_float field_text_alighn"
                                          ></div>
                                          <div
                                            className="opposite_float"
                                            onClick={() =>
                                              setOpenInvoices(!openInvoices)
                                            }
                                            aria-controls="invoice_collapse"
                                            aria-expanded={openInvoices}
                                          >
                                            X
                                          </div>
                                          <div style={{ clear: "both" }}></div>
                                        </div>
                                      </div>
                                      <Collapse in={openInvoices}>
                                        <div
                                          id="invoice_collapse"
                                          className="field_text_alighn input-sm"
                                        >
                                          {clienttData.client_invoices && (
                                            <div
                                              style={{
                                                pading: "10px",
                                                marginTop: "15px",
                                              }}
                                            >
                                              <table className="table table-hover">
                                                <thead>
                                                  <tr>
                                                    <th
                                                      style={{ width: "50%" }}
                                                    >
                                                      Invoice No.
                                                    </th>
                                                    <th
                                                      style={{ width: "50%" }}
                                                    >
                                                      Invoice Date
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {/* {clienttData.client_invoices.length >= 0 && clienttData.client_invoices.map((invoices, index) => ( */}
                                                  {/*     <tr onClick={() => setInvoice(invoices.invoice_year, invoices.branch_cd, invoices.invoice_cd)}> */}
                                                  {/*         <td className="field_text_alighn">{"INV-" + invoices.invoice_year + "-" + invoices.branch_cd + "-" + invoices.invoice_cd} </td> */}
                                                  {/*         <td className="field_text_alighn">{invoices.invoice_date}</td> */}
                                                  {/*     </tr> */}
                                                  {/* ) */}
                                                  {/*)  */}
                                                </tbody>
                                              </table>
                                            </div>
                                          )}
                                        </div>
                                      </Collapse>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <th id="request_desc_l">Complaint Desc.</th>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td colSpan="2">
                                    <textarea
                                      id="requext_text_v"
                                      className="form-control input-sm"
                                      style={{ width: "100%", height: "70px" }}
                                      Placeholder="enter the complaint her"
                                    >
                                      {requestData.request_details.requext_text}
                                    </textarea>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                        <div className="row">
                          <div
                            className="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"
                            style={{
                              marginBottom: "10px",
                              borderBottom: "solid 1px grey",
                              paddingBottom: "10px",
                            }}
                          >
                            {requestdetail.request_text}
                          </div>
                          <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                            <div
                              style={{
                                margin: "0px 6px",
                                border: "solid 2px #c4c4c4",
                                borderRadius: "6px",
                                position: "absolute",
                                zIndex: "150",
                                backgroundColor: "white",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  color: "black",
                                  backgroundColor: "ButtonFace",
                                  padding: "7px",
                                }}
                              >
                                <div>{set_icon(null, null)}</div>
                                <div
                                  className="space-betw-vertical"
                                  style={{ margin: "0px 10px", width: "100%" }}
                                >
                                  <div>
                                    {" "}
                                    {requestdetail.edited_by_user_name}
                                  </div>
                                  <div>
                                    <div className="normal_float">
                                      job_title here and again
                                    </div>
                                    <div className="opposite_float">
                                      {requestdetail.request_time}
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                  </div>
                                </div>
                              </div>
                              {userNotesSection && (
                                <div style={{ color: "gray", padding: "7px" }}>
                                  {requestNotes.length > 0 &&
                                    requestNotes.map((request_notes, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          padding: "4px",
                                          color: "#333333",
                                          borderBottom: "solid 1px grey",
                                        }}
                                      >
                                        <div>{request_notes.request_note}</div>
                                        <div style={{ color: "#878787" }}>
                                          {request_notes.note_time}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {requestInvoice && requestInvoice.invoice_cd && (
                  <>
                    <div>
                      <InvoiceBody
                        parent="Call center"
                        invoiceMode={invoiceMode}
                        setInvoiceMode={setInvoiceMode}
                        pageLables={invoicePageLables}
                        invoiceYear={requestInvoice.invoice_year}
                        branchCd={requestInvoice.invoice_branch_cd}
                        invoiceCd={requestInvoice.invoice_cd}
                        clienttData={clienttData}
                        setclienttData={setclienttData}
                      />
                    </div>
                  </>
                )}
                {!requestInvoice.invoice_cd && (
                  <>
                    <div style={{ marginTop: "20px" }}>
                      client detal will be here
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CallCenter;
