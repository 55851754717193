import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux"; //import useSelector for redux

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//import EmptyUserIcon from '/images/blank_employee.jpg';

import SaveIcon from "@mui/icons-material/Save";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
/*import { HiOutlineBellAlert } from "react-icons/hi";*/

import blankprofile from "../../Images/blankprofile.png";
import { RiMoreLine } from "react-icons/ri";

import CaseProgress from "./CaseProgress";
import CaseChatting from "./CaseChatting";
import CaseRemarks from "./CaseRemarks";

const MyRole = () => {
  const { user } = useSelector((state) => state); //define redux

  console.log(user.userData.branch_cd);
  const [tapActive, setTapActive] = useState("Progress");
  const [caseDetailsMode, setCaseDetailsMode] = useState("Progress");
  const [caseMode, setCaseMode] = useState("initial");
  const [caseDetails, setCaseDetails] = useState();

  const [caseLevels, setCaseLevels] = useState([]);
  const [caseClients, setCaseClients] = useState([]);
  const [caseOpponent, setCaseOpponent] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [courtLevels, setCourtLevels] = useState([]);
  const [courtHearings, setCourtHearings] = useState([]);
  const [clientAs, setClientAs] = useState([]);
  const [accessBy, setAccessBy] = useState([]);
  const [courtsNames, setCourtsNames] = useState([]);
  const [states, setStates] = useState([]);
  const [judgements, setJudgements] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [myRole, setMyRole] = useState([]);

  const [pageLables, setPageLables] = useState();

  const [showCrop, setShowCrop] = useState(false);

  const profileimgRef = useRef(null);

  var inActiveTap = "tap_head_area";
  var activeTap = "tap_head_area active_tap_head_area";
  var inActiveTapOposit = "opposite_float opposite_text_align tap_head_area";
  var activeTapOposit =
    "opposite_float opposite_text_align tap_head_area active_tap_head_area";

  useEffect(() => {
    checkDevice();
  }, []);

  useEffect(() => {
    getCaseInitial();
    getMyRole();
  }, []);

  function checkDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setTapActive("MobileDetails");
    } else {
      setTapActive("Details");
    }
  }

  function clear_data() {
    setCaseDetails();
    setCaseLevels([]);
    setCaseClients([]);
    setCaseOpponent([]);
    setAccessBy([]);
    setCourtHearings([]);
  }

  const set_detailstab_active = (active_tab) => {
    setCaseDetailsMode(active_tab);
    //setCaseMode(active_tab);
    setTapActive(active_tab);
  };

  const set_tab_active = (active_tab) => {
    //setCaseMode(active_tab);
    setTapActive(active_tab);
  };

  const set_clear_mode = () => {
    switch (caseMode) {
      case "view":
      case "Draft":
        clear_data();
        setCaseMode("initial");
        break;
      default:
      // code block
    }
  };

  const case_search_keyEvent = (e) => {
    if (e.charCode === 13) {
      var code_str = document.getElementById("case_cd_s").value.trim();
      if (code_str.length > 0) {
        getCaseDetails("case_cd_s", code_str);
        //} else {
        //    getCaseDetails("phone_no",document.getElementById("phone_no_s").value.trim(),
        //    );
      }
    }
  };

  // Read ----------------------
  const getCaseInitial = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      console.log(userDataLocal.token);
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_case_initial",
          system_cd: "Hrs",
          function_cd: "20",
          system_rout_cd: "19",
          branch_cd: user.userData.branch_cd, //from redux
          //branh_cd: userDataLocal.branvh_cd
        },
      };

      console.log("case initials  input", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/get_case_initial`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("case initials  output", res);
      setPageLables(res.data.output.pages_lables);
      if (res.data.output.case_type) {
        setCaseTypes(res.data.output.case_type);
      }
      if (res.data.output.court_levels) {
        setCourtLevels(res.data.output.court_levels);
      }
      if (res.data.output.client_as) {
        setClientAs(res.data.output.client_as);
      }
      if (res.data.output.case_status) {
        setCaseStatus(res.data.output.case_status);
      }
      if (res.data.output.court_names) {
        setCourtsNames(res.data.output.court_names);
      }
      if (res.data.output.judgments) {
        setJudgements(res.data.output.judgments);
      }
      if (res.data.output.state_names) {
        setStates(res.data.output.state_names);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const getMyRole = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      console.log(userDataLocal.token);
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_my_role",
        },
      };

      console.log("get_my_role  input", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_my_role`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("get_my_role  output", res);

      if (res.data.output.get_my_role) {
        setMyRole(res.data.output.get_my_role);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const getCaseDetails = async (case_cd) => {
    clear_data();
    console.log("getCaseDetails");
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_case_details",
          case_cd: case_cd,
          unique_number: null,
          case_no: null,
        },
      };
      console.log("getCaseDetails input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/get_case_details`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getCaseDetails output ", res);
      if (res.data.output.case_details.case_cd) {
        setCaseDetails(res.data.output.case_details);
        if (res.data.output.case_levels) {
          setCaseLevels(res.data.output.case_levels);
        }
        if (res.data.output.case_client) {
          setCaseClients(res.data.output.case_client);
        }
        if (res.data.output.case_oponent) {
          setCaseOpponent(res.data.output.case_oponent);
        }
        if (res.data.output.case_access_by) {
          setAccessBy(res.data.output.case_access_by);
        }
        if (res.data.output.case_progress) {
          setCourtHearings(res.data.output.case_progress);
        }

        setCaseMode("view");
      } else {
        alert("no case exist");
        /*also we shoul check if case is exist but he dos not hase previliges*/
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  // Update ----------------------

  return (
    <>
      <div className="container">
        {pageLables && (
          <>
            <div className="row" style={{ padding: "0px" }}>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 border-right"
                style={{ padding: "0px" }}
              >
                <div
                  style={{
                    height: "calc(100vh - 140px)",
                    overflow: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {/*my role area*/}
                  <div style={{ padding: "0px 6px" }}>
                    {/* <div
                      className="table_header_div"
                      style={{ height: "36px" }}
                    >
                      <div style={{ width: "10%" }}>Code</div>
                      <div style={{ width: "80%" }}>Case subject type</div>
                      <div style={{ width: "10%" }}>
                        <HiOutlineBellAlert size={14} />{" "}
                      </div>
                    </div> */}

                    {myRole.length > 0 &&
                      myRole.map((my_role, index) => (
                        // <div
                        //   key={my_role.case + index}
                        //   onClick={() => {
                        //     getCaseDetails(my_role.case_cd);
                        //   }}
                        // >
                        //   <div className="table_line_div">
                        //     <div style={{ width: "10%" }}>
                        //       {my_role.case_cd}
                        //     </div>
                        //     <div style={{ width: "80%" }}>
                        //       {my_role.case_subject}
                        //     </div>
                        //     <div style={{ width: "10%" }}>
                        //       {my_role.alert_cd && (
                        //         <>
                        //           <div>ok</div>
                        //         </>
                        //       )}
                        //     </div>
                        //   </div>
                        // </div>

                        <div
                          style={{
                            padding: "5px",
                            margin: "5px",
                            boxShadow:
                              "0px 12px 25px 1px rgb(105 109 112 / 10%)",
                            borderRadius: "8px",
                            background: "#f7f8fa",
                          }}
                          onClick={() => getCaseDetails(my_role.case_cd)}
                        >
                          <div style={{ display: "flex", gap: "4px" }}>
                            <label style={{ fontWeight: "700" }}>case:</label>
                            <div>
                              {my_role.case_cd}, {my_role.case_subject}
                            </div>
                          </div>
                          <div style={{ display: "flex", gap: "4px" }}>
                            <label style={{ fontWeight: "700" }}>alert:</label>
                            <div>
                              {my_role.alert_cd ? my_role.alert_cd : "N/A"}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7"
                style={{ padding: "0px" }}
              >
                {/*Button bar ---------------------*/}
                <div>
                  <div className="normal_float normal_text_align ">
                    {caseMode !== "initial" && (
                      <>
                        <Tooltip title="Back" placement="bottom" arrow>
                          <IconButton onClick={set_clear_mode}>
                            <ArrowBackIcon color="inherit" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <span className="separator_border">
                          {caseDetails && caseDetails.case_subject}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="opposite_float opposite_text_align"></div>
                  <div style={{ clear: "both" }}></div>
                </div>
                {/*case body ---------------------*/}
                {caseMode === "view" && caseDetails && (
                  <>
                    <div className="table_header_background">
                      <div className="row data_line">
                        <div
                          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          style={{ display: "flex" }}
                        >
                          <label className="label_area">
                            {pageLables[9].description}
                          </label>
                          <div className="viewing_field">
                            {caseDetails &&
                              caseDetails.unique_number +
                                " - " +
                                caseTypes.find(
                                  (el) => el.code === caseDetails.case_type_cd,
                                ).description}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="row data_line">
                        <div
                          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          style={{ display: "flex" }}
                        >
                          <label className="label_area">
                            {pageLables[8].description}
                          </label>
                          <div className="viewing_field">
                            {caseLevels.length > 0 &&
                              caseLevels[0].case_no +
                                " - " +
                                courtLevels.find(
                                  (el) => el.code === caseLevels[0].level_cd,
                                ).description +
                                " - " +
                                clientAs.find(
                                  (el) => el.code === caseLevels[0].client_as,
                                ).description}
                          </div>
                        </div>
                      </div>
                      <div className="row data_line">
                        <div
                          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          style={{ display: "flex" }}
                        >
                          <label className="label_area">
                            {pageLables[15].description}
                          </label>
                          <div
                            className="viewing_field"
                            style={{ border: "0" }}
                          >
                            {caseClients.length > 0 &&
                              caseClients.map((client, index) => {
                                if (caseClients.length === 1) {
                                  return (
                                    <div key={client.id_no}>
                                      {client.client_name}
                                    </div>
                                  );
                                } else {
                                  if (index === caseClients.length - 1) {
                                    return (
                                      <span key={client.id_no}>
                                        {client.client_name}
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span key={client.id_no}>
                                        {client.client_name},{" "}
                                      </span>
                                    );
                                  }
                                }
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="row data_line">
                        <div
                          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          style={{ display: "flex" }}
                        >
                          <label className="label_area">
                            {pageLables[16].description}
                          </label>
                          <div
                            className="viewing_field"
                            style={{ border: "0" }}
                          >
                            {caseOpponent.length > 0 &&
                              caseOpponent.map((opponent, index) => {
                                if (caseOpponent.length === 1) {
                                  return (
                                    <div key={opponent.id_no}>
                                      {opponent.opponent_name}
                                    </div>
                                  );
                                } else {
                                  if (index === 0)
                                    return (
                                      <div key={opponent.id_no}>
                                        {opponent.opponent_name}{" "}
                                        <span>
                                          <RiMoreLine size={14} />
                                        </span>
                                      </div>
                                    );
                                }
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/*tap menu*/}

                <div className="tap_menu">
                  <div
                    className={
                      tapActive === "Progress" ? activeTap : inActiveTap
                    }
                    onClick={() => {
                      set_tab_active("Progress");
                    }}
                  >
                    {pageLables[1].description}
                  </div>
                  <div
                    className={
                      tapActive === "Remarks" ? activeTap : inActiveTap
                    }
                    onClick={() => {
                      set_tab_active("Remarks");
                    }}
                  >
                    {pageLables[3].description}
                  </div>
                  <div
                    className={
                      tapActive === "conversation" ? activeTap : inActiveTap
                    }
                    onClick={() => {
                      set_tab_active("conversation");
                    }}
                  >
                    {pageLables[6].description}
                  </div>
                </div>

                {(tapActive === "Progress" || tapActive === "Details") &&
                  caseDetails &&
                  caseDetails.case_cd && (
                    <>
                      <div style={{ height: "calc(100vh - 140px)" }}>
                        {caseLevels.length > 0 && (
                          <CaseProgress
                            case_cd={caseDetails.case_cd}
                            caseClients={caseClients}
                            courtLevels={courtLevels}
                            clientAs={clientAs}
                            caseLevels={caseLevels}
                            courtHearings={courtHearings}
                            setCourtHearings={setCourtHearings}
                            pageLables={pageLables}
                            courtsNames={courtsNames}
                            judgements={judgements}
                            caseStatus={caseStatus}
                            states={states}
                          />
                        )}
                      </div>
                    </>
                  )}

                {tapActive === "Remarks" && (
                  <>
                    <div style={{ height: "calc(100vh - 140px)" }}>
                      {caseDetails && caseDetails.case_cd && (
                        <CaseRemarks case_cd={caseDetails.case_cd} />
                      )}
                    </div>
                  </>
                )}

                {tapActive === "conversation" && (
                  <>
                    <div style={{ height: "calc(100vh - 140px)" }}>
                      {caseDetails && caseDetails.case_cd && (
                        <CaseChatting
                          case_cd={caseDetails.case_cd}
                          accessBy={accessBy}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyRole;
