
import React, { useEffect, useState } from "react"
import axios from "axios";

import { BsThreeDotsVertical } from "react-icons/bs";
import blankprofile from "../../../Images/blankprofile.png";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BsChatLeftText } from "react-icons/bs";
import { HiOutlineReply } from "react-icons/hi";
import saveIcon from "../../../SVG_images/save.svg";
import { IoArrowForwardSharp } from "react-icons/io5";
import { FiSend } from "react-icons/fi";

import ImageCard from "../../cards/ImageCard";

const Chattings = ({requestdetail, progresList, setProgresList, setProgressCoversation, progressCoversation, sessionUser, orgenizationStructure, systemUsers}) => {
    const [activeChat, setActiveChat] = useState(JSON.parse(localStorage.getItem("amanrowuserData")).user_cd);
    const [pageMode, setPageMode] = useState("Chatting");
    const [chatMode, setChatMode] = useState("Initial");
    const [currentUserIndex, setCurrentUserIndex] = useState(null);
    const [UserCd, setUserCd] = useState(null);

    function select_user(index, user_cd) {     
        setCurrentUserIndex(index)
        setUserCd(user_cd)
        setPageMode("Send Message")
    }
   
    const tugle_chatt = (progress_user_cd) => {
        if(activeChat === progress_user_cd){
            setActiveChat(0)
        }else{
            setActiveChat(progress_user_cd)
        } 
    };

    function set_user_icon(user_image_name,) {
        if (user_image_name) {
            return <img src={"https://files.amanrow.com/user/" + user_image_name} alt=" " className="system_user_profile_image"/>
        }else {
            return <img src={blankprofile} className="system_user_profile_image" alt="" />
        }
    }

    function set_icon(user_image_name,) {
        if (user_image_name) {
            return <img src={"https://files.amanrow.com/user/" + user_image_name} alt=" " className="progress_profile_image"/>
        }else {
            return <img src={blankprofile} className="progress_profile_image" alt="" />
        }
    }

    function toggle_branch(branch_id, branch_cd) {       
        if (document.getElementById(branch_id).style.display === "block") {
            document.getElementById(branch_id).style.display = "none";
        } else {
            document.getElementById(branch_id).style.display = "block";
        }
    }

    const subNode = (parent_id) => {
        return orgenizationStructure.map((tree_node, i) => {
            if (parent_id === tree_node.parent_id) {
                return (
                    <div key={i}>
                        <div key={i} className="user_line">
                            <div className="node_line">
                                <div style={{ display: "flex" }} onClick={() => { toggle_branch(`branch_${tree_node.branch_id}`, tree_node.branch_cd) }}>
                                    <div className="icon_tree_area"><ImageCard icon={tree_node.branch_type_cd} iconType={"organization tree"} /></div>
                                    <div className="node_name_area">{tree_node.branch_name}</div>
                                </div>
                            </div>
                        </div>
                        <div id={`branch_${tree_node.branch_id}`} className="node_block_section left_block_border" style={{ display: "none" }}>
                            {/*set users list*/}
                            {systemUsers.length > 0 && systemUsers.map((system_users, index) => {
                                if (system_users.branch_no === tree_node.branch_cd && system_users.user_cd !== sessionUser ) {
                                    return (
                                        <div className="user_line" style={{ margin: "0px 10px", marginBottom:"6px" }} onClick={() =>select_user(index, system_users.user_cd)}>
                                            <div key={system_users.user_cd + index} className="user_line_area" style={{padding:"0px"}}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div>{set_user_icon(system_users.image_name)}</div>
                                                    <div className="space-betw-vertical" style={{ margin: "0px 10px" }}>
                                                        <div> {system_users.full_name}</div>
                                                        <div>{system_users.job_title}{system_users.user_type && <span style={{ color: "gray" }}>  |  {system_users.user_type}  </span>}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                            )}

                            {/*set child structure*/} 
                            {subNode(tree_node.branch_id)}
                        </div>
                    </div>
                );
            }
        });
    };

   /* --updates ----*/
   const update_message = async () => {

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "update_message",
                    request_year: requestdetail.request_year,
                    branch_cd: userDataLocal.branch_cd,
                    request_cd: requestdetail.request_cd,
                    sender_user_cd: sessionUser,
                    reciever_user_cd: systemUsers[currentUserIndex].user_cd,
                    chatt_text: document.getElementById("chatt_text").value
                },
            };

            console.log("update_message input ", data);
           

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/wf/update_message`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("update_message output ", res);
             if(res.data.output.progres_list && res.data.output.progres_list.length > 0){
                setProgresList(res.data.output.progres_list)
            }else{
                setProgresList([])
            }
            if(res.data.output.progress_coversation && res.data.output.progress_coversation.length > 0){
                setProgressCoversation(res.data.output.progress_coversation)
            }else{
                setProgressCoversation([])
            }
            setPageMode("Chatting")

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    return <>
        {pageMode === "Select user" && (
            <div>
                <div style={{marginTop:"2px"}}>                   
                    <div className="normal_float">
                        <>
                            <Tooltip title="Back" placement="bottom" arrow>
                                <IconButton > <ArrowBackIcon color="inherit" fontSize="small" onClick={() => {setPageMode("Chatting") }}/> </IconButton>
                            </Tooltip>
                        </>         
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
                <div style={{ height: "calc(100vh - 122px)", overflow: "auto", overflowX: "hidden", padding:"0px 0px 0px 4px", marginTop:"10px" }} >
               
                    {orgenizationStructure.length > 0 && orgenizationStructure.map((tree_node, i) => {
                        if (tree_node.parent_id === "0")
                        return (
                        <>
                            <div key={i} className="user_line">
                                <div className="node_line">
                                    <div style={{ display: "flex" }} onClick={() => { toggle_branch(`branch_${tree_node.branch_id}`, tree_node.branch_cd) }}>
                                        <div className="icon_tree_area"><ImageCard icon={tree_node.branch_type_cd} iconType={"organization tree"} /></div>
                                        <div className="node_name_area">{tree_node.branch_name}</div>  
                                    </div>
                                </div>
                                          
                            </div>
                            <div id={`branch_${tree_node.branch_id}`} className="node_block_section left_block_border">
                                {/*set users list*/}
                                {systemUsers.length > 0 && systemUsers.map((system_users, index) => {
                                    if (system_users.branch_no === tree_node.branch_cd) {
                                        return (
                                            <div className="user_line bottom_border_div" style={{ margin: "0px 10px" }} onClick={() =>select_user(index, system_users.user_cd)}>
                                                <div key={system_users.user_cd + index} className="user_line_area">
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div>{set_user_icon(system_users.image_name)}</div>
                                                        <div className="space-betw-vertical" style={{ margin: "0px 10px" }}>
                                                            <div> {system_users.full_name}</div>
                                                            <div>{system_users.job_title}{system_users.user_type && <span style={{ color: "gray" }}>  |  {system_users.user_type}  </span>}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                                )}

                                {/*set child structure*/}   
                                {subNode(tree_node.branch_id)} 
                            </div>
                        </>);
                    })}
                         
                </div>
            </div>
        )}
        {pageMode === "Chatting" && ( 
            <div style={{padding:"10px 6px 6px 6px", height: "calc(100vh - 86px)", overflow: "auto", overflowX: "hidden" , backgroundColor:"#f2f2f2"}}>
                {progresList.length > 0 && progresList.map((progre_List, index) => (                      
                    <div className="progress_box" > {/*progress block*/}
                        <div key={index}  className="progress_header" style={{backgroundColor:"#dbdbdb"}} onClick={() => {tugle_chatt(progre_List.progress_user_cd) }}>
                            <div style={{ display: "flex", alignItems: "center"}}>
                                <div>{set_icon(progre_List.user_icon_address)}</div>
                                <div className="space-betw-vertical" style={{ margin: "0px 10px", height:"44px" }}>
                                    <div>{progre_List.request_user_name}</div>
                                    <div>{progre_List.branch_name}<span style={{ color: "gray" }}>  |  {progre_List.job_title}</span></div>
                                    
                                </div>
                            </div>
                        </div>
                        {activeChat === progre_List.progress_user_cd && ( 
                            <div  style={{backgroundColor:"white"}}> 
                                {/*Butomn bar*/}
                                {sessionUser === progre_List.progress_user_cd &&( 
                                    <div style={{marginTop:"2px"}}>                   
                                        <div className="normal_float">
                                            {chatMode !== "Initial" && (
                                                <>
                                                    <Tooltip title="Back" placement="bottom" arrow>
                                                        <IconButton > <ArrowBackIcon color="inherit" fontSize="small" /> </IconButton>
                                                    </Tooltip>
                                                    </>
                                            )}
                                        </div>
                                        <div className="opposite_float">
                                            {(chatMode === "Edit" || chatMode === "Draft") && (
                                                <>
                                                    <Tooltip title="Save" placement="bottom" arrow>
                                                        {/*<IconButton > <SaveIcon color="inherit" fontSize="small" /> </IconButton>*/}
                                                    </Tooltip>
                                                </>
                                            )}
                                            {chatMode === "Initial" && (
                                                <>
                                                    <Tooltip title="Chat" placement="bottom" arrow>
                                                        <IconButton > <BsChatLeftText color="inherit" fontSize="16" onClick={() => {setPageMode("Select user") }}/> </IconButton>
                                                    </Tooltip>
                                                        <Tooltip title="Options" placement="bottom" arrow>
                                                        <IconButton ><BsThreeDotsVertical color="inherit" fontSize="16"/> </IconButton>
                                                    </Tooltip>     
                                                </>
                                            )}
                                        </div>
                                        <div style={{ clear: "both" }}></div>
                                    </div>
                                )}
                                <div style={{padding:"10px", maxHeight:"400px", overflow: "auto", overflowX: "hidden"}}>
                                    {progressCoversation.length > 0 && progressCoversation.map((progress_coversation, index) => {
                                        if (progress_coversation.reciever_user_cd === progre_List.progress_user_cd || progress_coversation.sender_user_cd === progre_List.progress_user_cd) {
                                            return (
                                                <div>     
                                                    <div key={index} className={`${progress_coversation.sender_user_cd === activeChat ? "opposite_float":"normal_float"} coversation_header_area`}>
                                                        <div>
                                                            <div  className="normal_float">
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <div>{set_user_icon(progress_coversation.profile_image_name)}</div>
                                                                    
                                                                    <div>
                                                                        <div style={{margin:"0px 6px" }}>{progress_coversation.sender_user_name}</div> 
                                                                        {progress_coversation.reciever_user_cd && (
                                                                            <div>
                                                                                <div style={{ display: "flex", alignItems: "center", color:"#686868" }} >
                                                                                    <div><IoArrowForwardSharp color="inherit" fontSize="16px" /></div>
                                                                                    <div style={{ padding: "0px 4px" }}>{progress_coversation.receverr_user_name}</div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="opposite_float">
                                                                {sessionUser === progress_coversation.reciever_user_cd &&(
                                                                    <Tooltip title="Replay" placement="bottom" arrow>
                                                                        <IconButton > <HiOutlineReply color="inherit" fontSize="16" /> </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </div>  
                                                            <div style={{ clear: "both" }}></div>
                                                        </div>
                                                        <div style={{ padding: "4px", color:"#333333" }}>
                                                            <div>{progress_coversation.chatt_text}</div>
                                                            <div style={{color:"#878787" }}>{progress_coversation.chat_time}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                    )}
                                </div>
                                <div style={{clear:"both"}}></div>
                            </div>
                                            
                        )}
                    </div>
                )
                )}
            </div>
        )} 
        {pageMode === "Send Message" && (
            <div>
                {/*Butomn bar*/}      
                <div style={{marginTop:"2px"}}>                   
                    <div className="normal_float">
                        <Tooltip title="Back" placement="bottom" arrow  onClick={() => {setPageMode("Select user") }}>
                            <IconButton > <ArrowBackIcon color="inherit" fontSize="small" /> </IconButton>
                        </Tooltip>         
                    </div>
                    <div className="opposite_float">
                        <Tooltip title="Send message" placement="bottom" arrow onClick={() => {update_message()}}>
                            <IconButton > <FiSend color="inherit" fontSize="18" onClick={() => {setPageMode("Select user") }}/> </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
                <div style={{ display: "flex", marginTop:"10px", marginBottom:"10px"  }}>
                    <div>{set_icon(systemUsers[currentUserIndex].image_name)}</div>
                    <div className="space-betw-vertical" style={{ margin: "0px 10px" }}>
                        <div>{systemUsers[currentUserIndex].full_name}</div>
                        <div>{systemUsers[currentUserIndex].job_title}{systemUsers[currentUserIndex].user_type && <span style={{ color: "gray" }}>  |  {systemUsers[currentUserIndex].user_type}  </span>}</div>
                    </div>
                </div>
                <textarea id="chatt_text" className="textarea_editing_field" style={{height: "150px"}} Placeholder="enter the complaint her" ></textarea>
            </div>                                     
        )}
    </>
}


export default Chattings