 import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { LuUserPlus } from "react-icons/lu";
import { BsTrash3 } from "react-icons/bs";
/*import { LuEdit2 } from "react-icons/lu"*/
import { TbCheckbox } from "react-icons/tb";
import SaveIcon from "@mui/icons-material/Save";

import { useDispatch, useSelector } from "react-redux"; //this import redux functions
import { Link } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageCard from "../cards/ImageCard";
import { startTransition } from "react";

const CaseOpenent = ({setShowSideBar,case_cd,caseOpponent,setCaseOpponent,pageLables,}) => {
  const [areasMode, setAreasMode] = useState("List");
  const [opponentDetails, setOpponentDetails] = useState({});


  useEffect(()=>{
  
  },[])
  

    const set_clear_mode = () => {
        setOpponentDetails({});
        switch (areasMode) {
            case "Edit":
                setAreasMode("View");
                break;
            case "View":
            case "Draft":
                setAreasMode("List");
                break;
            case "List":
                alert("ok")
                setShowSideBar(false);
                break;
        default:
        }
    };

    const get_opponent_detail = async (ser_no) => {
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "get_case_opponent",
            case_cd: case_cd,
            ser_no: ser_no,
        },
        };
        console.log("get_opponent_detail input ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_case_opponent`,

        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );

        console.log("get_opponent_detail output ", res);

        if (res.data.output.case_oponent) {
            setOpponentDetails(res.data.output.case_oponent);
        } else {
        alert("no record found");
        }
        setAreasMode("View");
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    /* update -------------------------*/
    const update_case_opponent = async (update_function) => {
    var ser_number = opponentDetails.ser_no;
    if (!ser_number) {
        ser_number = 0;
    }

    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "update_case_opponent",
            case_cd: case_cd,
            ser_no: ser_number,
            opponent_name: opponentDetails.opponent_name,
            mobile_no: opponentDetails.mobile_no,
            id_no: opponentDetails.id_no,
            update_function: update_function,
        },
        };
        console.log("update_case_opponent input ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_case_opponent`,
        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );

        console.log("update_case_opponent output ", res);

        if (res.data.output.get_case_opponents && res.data.output.get_case_opponents.length > 0) {
            setCaseOpponent(res.data.output.get_case_opponents);
        }else{
             setCaseOpponent([])
        }
        //if (res.data.output.get_case_opponents) {
        //setCaseOpponent(res.data.output.get_case_opponents);
        //} else {
        //setCaseOpponent([]);
        //}

        setAreasMode("List");
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    return (
    <>
        <div>
            {/*buttomn bar*/}
            <div>
                <div className="normal_float" style={{display:"flex", textAlign:"center", height: "46px" }}>
                        <Tooltip title="Back" placement="bottom" arrow>
                            <IconButton onClick={() => {set_clear_mode()}}>
                                <ArrowBackIcon color="inherit" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                
                </div>
                <div className="opposite_float"  style={{display:"flex", textAlign:"center", height: "46px" }}>
                    {areasMode === "List" && (
                    <>
                        <Tooltip title="add New" placement="bottom" arrow>
                        <IconButton onClick={() => { setOpponentDetails({}); setAreasMode("Draft")}}>
                            <LuUserPlus color="inherit" size={18} />
                        </IconButton>
                        </Tooltip>
                    </>
                    )}
                    {areasMode === "View" && (
                    <>
                        <Tooltip title="Edit" placement="bottom" arrow>
                        <IconButton
                            onClick={() => {
                            setAreasMode("Edit");
                            }}
                        >
                            <ModeOutlinedIcon color="inherit" fontSize="small" />
                        </IconButton>
                        </Tooltip>
                    </>
                    )}
                    {(areasMode === "Edit" || areasMode === "Draft") && (
                    <>
                        <Tooltip title="Save" placement="bottom" arrow>
                        <IconButton
                            onClick={() => {
                            update_case_opponent("update");
                            }}
                        >
                            <SaveIcon color="inherit" fontSize="small" />
                        </IconButton>
                        </Tooltip>
                    </>
                    )}
                    {areasMode === "Edit" && (
                    <>
                        <Tooltip title="Delete" placement="bottom" arrow>
                        <IconButton
                            onClick={() => {
                            update_case_opponent("delete");
                            }}
                        >
                            <BsTrash3 color="inherit" fontSize="medium" />
                        </IconButton>
                        </Tooltip>
                    </>
                    )}
                </div>
                <div style={{ clear: "both" }}></div>
            </div>

            {/*case clients list area*/}
            {areasMode === "List" && (
            <>
                {console.log("caseOpponent = ", caseOpponent)}
                <div className="table_header_div top_border_div table_header_background"style={{ display: "flex" }}>
                    <div style={{ width: "5%" }}></div>
                    <div style={{ width: "45%" }}>{pageLables[32].description}</div>
                    <div style={{ width: "25%" }}>{pageLables[33].description}</div>
                    <div style={{ width: "25%" }}>{pageLables[34].description}</div>
                </div>
                {caseOpponent.length > 0 && caseOpponent.map((opponent_list, index) => (
                    <div className="row_area" key={opponent_list.ser_no} onClick={() => {get_opponent_detail(opponent_list.ser_no, "delete")}}>
                        <div className="table_line_div bottom_border_div">
                            <div style={{ width: "5%" }}>{opponent_list.ser_no}</div>
                            <div style={{ width: "45%" }}>{opponent_list.opponent_name}</div>
                            <div style={{ width: "25%" }}>{opponent_list.mobile_no}</div>
                            <div style={{ width: "25%" }}>{opponent_list.id_no}</div>
                        </div>
                    </div>
                ))}
            </>
            )}

            {areasMode === "View" && (
            <>
                {/*Client details*/}
                <div style={{ display: "flex" }}>
                <label className="label_area">
                    {pageLables[32].description}
                </label>
                <div class="viewing_field">
                    {opponentDetails && opponentDetails.opponent_name}
                </div>
                </div>
                <div style={{ display: "flex" }}>
                <label className="label_area">
                    {pageLables[33].description}
                </label>
                <div class="viewing_field">
                    {opponentDetails && opponentDetails.mobile_no}
                </div>
                </div>
                <div style={{ display: "flex" }}>
                <label className="label_area">
                    {pageLables[34].description}
                </label>
                <div class="viewing_field">
                    {opponentDetails && opponentDetails.id_no}
                </div>
                </div>
            </>
            )}
            {(areasMode === "Edit" || areasMode === "Draft") && (
            <>
                {/*Client details*/}
                <div style={{ display: "flex" }}>
                <label className="label_area">
                    {pageLables[32].description}
                </label>
                <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={opponentDetails && opponentDetails.opponent_name}
                    onChange={(e) =>
                    setOpponentDetails({
                        ...opponentDetails,
                        opponent_name: e.target.value,
                    })
                    }
                />
                </div>
                <div style={{ display: "flex" }}>
                <label className="label_area">
                    {pageLables[33].description}
                </label>
                <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={opponentDetails && opponentDetails.mobile_no}
                    onChange={(e) =>
                    setOpponentDetails({
                        ...opponentDetails,
                        mobile_no: e.target.value,
                    })
                    }
                />
                </div>
                <div style={{ display: "flex" }}>
                <label className="label_area">
                    {pageLables[34].description}
                </label>
                <input
                    id="case_subject"
                    type="text"
                    className="editing_field"
                    autoComplete="0"
                    value={opponentDetails && opponentDetails.id_no}
                    onChange={(e) =>
                    setOpponentDetails({
                        ...opponentDetails,
                        id_no: e.target.value,
                    })
                    }
                />
                </div>
            </>
            )}
        </div>
    </>
    );
};

export default CaseOpenent;
