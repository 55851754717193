
import React, { useEffect, useState } from "react"

import checkIcon from '../../../SVG_images/check.svg'

const ItemSpecifications = ({ itemData }) => {
    return <>
        {itemData && itemData.item_specs && (
            <div style={{ pading: "10px", marginTop: "15px" }}>
             
                <div>
                    {itemData.item_specs.length >= 0 && itemData.item_specs.map((specification, index) => (
                        
                        <div style={{ marginBottom: "10px" }}>
                            {console.log(specification.ser_no)}
                            <img src={checkIcon} className="buton_icon normal_float" style={{ height: "14px" }} />
                            <div className=" normal_float">
                                {itemData.item_specs_text.length >= 0 && itemData.item_specs_text.map((specs_text, index) => {
                                    console.log( specs_text.ser_no)

                                    {
                                        specs_text.ser_no == specification.ser_no && (

                                            <div className="normal_float ">{specs_text.specification}</div>
                                        )
                                    }
                                    
                                })}</div>
                          
                            <div className=" opposite_float">{specification.image_url}</div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                    ))}

                 
                    <div style={{ clear: "both" }}></div>
                </div>

            </div>
        )}

    </>
}


export default ItemSpecifications