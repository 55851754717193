

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { HiOutlineHandThumbUp } from "react-icons/hi2";
import { HiOutlineHandThumbDown } from "react-icons/hi2";
import { BsSend } from "react-icons/bs";


const CertificationAccreditation = () => {

    const [countriesList, setCountriesList] = useState([]);
    const [universitiesList, setUniversitiesList] = useState([]);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [sectionsList, setSectionsList] = useState([])
    
    const [decisionsList, setDecisionsList] = useState([]);
    const [decisionMode, setDecisionMode] = useState("initial");
    const [decisionItemEditMode, setDecisionItemEditMode] = useState(false);
    const [decisionsDetails, setDecisionsDetails] = useState({});
    const [certificateDetail, setCertificateDetail] = useState({});
    const [serch, setSerch] = useState();
    const [equivalencyItems, setEquivalencyItems] = useState([]);
    const [itemDetails, setitemDetails] = useState([]);
    const [decisionUrl, setDecisionUrl] = useState(null);
    const [certificateRetrieved, setCertificateRetrieved] = useState(false);
        
    const [pageLables, setPageLables] = useState([]);

    useEffect(() => {
        getDecisionInitial();
    }, []);

    const set_clear_mode = () => {
        switch (decisionMode) {
            case "Edit":
                setDecisionMode("view");
                alert("view")
                break;

            case "view":
                setDecisionsDetails()
                setDecisionMode("initial");
                alert("initial")

                break;

            //case "Initial":
            //    setDecisionMode("Upload document");
            //    alert("Upload document")
            //    break;
            default:

        }

    };

    /* Read -------------------------*/
    const getDecisionInitial = async () => {

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_academic_initials",

                },
            };

            console.log("academic initials output", data);
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_academic_initials`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }

            );

            console.log("academic initials output", res);
            setPageLables(res.data.output.page_lables);
            setCountriesList(res.data.output.country);

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getUniversities = async (e) => {
        setCertificateRetrieved(false)
        setDecisionUrl(null)
        setEquivalencyItems([])
        setitemDetails([])

        setUniversitiesList([]);
        setDepartmentsList([]);
        setSectionsList([])
        setCertificateDetail({ ...certificateDetail, country_cd: e.target.value, university_cd: 0, department_cd: 0, section_cd: 0 })

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_universities_list",
                    country_cd: e.target.value
                },
            };

            //console.log("getUniversities input ", data)
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_university_list`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            //console.log("getUniversities output ", res.data.output.univercities)
            if (res.data.output.univercities) { setUniversitiesList(res.data.output.univercities) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getDepartment = async (e) => {
        setCertificateRetrieved(false)
        setDecisionUrl(null)
        setEquivalencyItems([])
        setitemDetails([])

        setDepartmentsList([]);
        setSectionsList([])
        setCertificateDetail({ ...certificateDetail, university_cd: e.target.value, department_cd: 0, section_cd: 0 })

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_university_departments",
                    university_cd: e.target.value
                },
            };

            console.log("getDepartment input ", data)
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_university_departments`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getDepartment output ", res)
            if (res.data.output.departments) { setDepartmentsList(res.data.output.departments) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getSections = async (e) => {
        setCertificateRetrieved(false)
        setDecisionUrl(null)
        setEquivalencyItems([])
        setitemDetails([])

        setSectionsList([]);
        setCertificateDetail({ ...certificateDetail, department_cd: e.target.value })

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_department_sections",
                    university_cd: certificateDetail.university_cd,
                    department_cd: e.target.value,
                },
            };

            console.log("getSections input ", data)

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_department_sections`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getSections output ", res.data.output.department_sections)
            if (res.data.output.department_sections) { setSectionsList(res.data.output.department_sections) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const get_certificate_equivalency = async (e) => {
        setCertificateDetail({ ...certificateDetail, section_cd: e.target.value })
        setCertificateRetrieved(false)
        setDecisionUrl(null)
        setEquivalencyItems([])
        setitemDetails([])

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_certificate_equivalency",
                    university_cd: certificateDetail.university_cd,
                    start_date: certificateDetail.start_date,
                    end_date: certificateDetail.end_date,
                    degree_cd: certificateDetail.degree_cd,
                    department_cd: certificateDetail.department_cd,
                    section_cd: certificateDetail.section_cd              
                },
            };

            console.log("get_certificate_equivalency input ", data)
           
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/aca/get_certificate_equivalency`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("get_certificate_equivalency output ", res)
            setCertificateRetrieved(true)
            if (res.data.output.DecisionItems && res.data.output.DecisionItems.length > 0) {
                setEquivalencyItems(res.data.output.DecisionItems)
                if (res.data.output.DecisionDetails && res.data.output.DecisionDetails.length > 0) {
                    setitemDetails(res.data.output.DecisionDetails)
                } else {
                    setitemDetails([])
                }

            } else {
                setEquivalencyItems([])
            }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };


    return (

        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  border-right">
                <div style={{ height: "calc(100vh - 100px)", overflow: "auto" }}>
                    {/*Button bar ---------------------*/}
                    <div style={{height:"36px"} }>
                        <div className="normal_float normal_text_align ">
                            {decisionMode !== "initial" && (
                                <>
                                    <Tooltip title="Back" placement="bottom" arrow>
                                        <IconButton onClick={set_clear_mode}>
                                            <ArrowBackIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>                                        
                                </>
                            )}
                        </div>
                        <div className="opposite_float opposite_text_align">
                            <Tooltip title="search" placement="bottom" arrow>
                                <IconButton onClick={get_certificate_equivalency}>
                                    <BsSend color="inherit" fontSize="18" />
                                </IconButton>
                            </Tooltip>                            
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>

                    {/*data entry  body ------------------*/}
                    <div style={{padding:"10px"}}>
                        {decisionMode === "initial" && pageLables.length > 0 && (
                            <>
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[3].description}</label>
                                    <select className="editing_field" value={setSerch && setSerch.country_cd} onChange={(e) => getUniversities(e)}>
                                        <option value="0"></option>
                                        {countriesList.length >= 0 &&
                                            countriesList.map((country, index) => {
                                                return (
                                                    <option key={index} value={country.code}>
                                                        {country.description}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[4].description}</label>
                                    <select className="editing_field" value={setSerch && setSerch.university_cd} onChange={(e) => getDepartment(e)}>
                                        <option value="0"></option>
                                        {universitiesList.length >= 0 &&
                                            universitiesList.map((universiy, index) => {
                                                return (
                                                    <option key={index} value={universiy.code}>
                                                        {universiy.description}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>
                                <div className="row data_line">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                        <label className="label_area">{pageLables[7].description}</label>
                                        <input type="text" className="editing_field" autoComplete="0"
                                        value={decisionsDetails && decisionsDetails.start_date} onChange={(e) => setCertificateDetail({ ...certificateDetail, start_date: e.target.value })} />
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{ display: "flex" }} >
                                        <label className="label_area">{pageLables[8].description}</label>
                                        <input type="text" className="editing_field" autoComplete="0"
                                        value={decisionsDetails && decisionsDetails.end_date} onChange={(e) => setCertificateDetail({ ...certificateDetail, end_date: e.target.value })} />
                                    </div>
                                </div>    
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[10].description}</label>
                                    <select className="editing_field" value={setSerch && setSerch.degree_cd} onChange={(e) => setCertificateDetail({ ...certificateDetail, degree_cd: e.target.value })}>
                                        <option value="0"></option>
                                        <option value="1">دبلوم</option>
                                        <option value="1">شهادة جامعية</option>
                                        <option value="1">شهادة ماجستير</option>
                                        <option value="1">شهادة دكتوراه</option>
                                    </select>
                                </div>
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[5].description}</label>
                                    <select className="editing_field" value={setSerch && setSerch.department_cd} onChange={(e) => getSections(e)}>
                                        <option value="0"></option>
                                        {departmentsList.length >= 0 &&
                                            departmentsList.map((department, index) => {
                                                return (
                                                    <option key={index} value={department.code}>
                                                        {department.description}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[6].description}</label>
                                    <select className="editing_field" value={setSerch && setSerch.section_cd} onChange={(e) => get_certificate_equivalency(e)}>
                                        <option value="0"></option>
                                        {sectionsList.length >= 0 &&
                                            sectionsList.map((section, index) => {
                                                return (
                                                    <option key={index} value={section.code}>
                                                        {section.description}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>
                                
                            </>
                        )}

                        {decisionMode === "view" && pageLables.length > 0 && (
                            <>
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[0].description}</label>
                                    <div className="viewing_field">{decisionsDetails && decisionsDetails.decision_no}</div>
                                </div>
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[1].description}</label>
                                    <div className="viewing_field">{decisionsDetails && decisionsDetails.decision_date}</div>
                                </div>
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[2].description}</label>
                                    <div className="viewing_field">{decisionsDetails && decisionsDetails.decision_title}</div>
                                </div>
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[3].description}</label>
                                    <div className="viewing_field">{countriesList.find((el) => el.code === decisionsDetails.country_cd,).description}</div>
                                </div>
                                <div className="data_line" style={{ display: "flex" }} >
                                    <label className="label_area">{pageLables[4].description}</label>
                                    <div className="viewing_field">{universitiesList.find((el) => el.code === decisionsDetails.university_cd,).description}</div>
                                </div>
                            </>
                        )}
                    </div>

                    {/*result list ------------------*/}
                    {equivalencyItems.length == 0 && certificateRetrieved && (<h4 style={{textAlign:"center", marginTop:"20px"}}>لايوجد مانع من اعتماد هذه الشهادة</h4>)}
                    {equivalencyItems.length > 0 && equivalencyItems.map((equivalency_item, index) =>                       
                        <div style={{ padding: "10px", marginTop: "10px", borderBottom: "solid 1px gray" }} onClick={() => setDecisionUrl(equivalency_item.decision_url )} >
                            <div style={{ display: "flex"}} >
                                {/*<div style={{lineHeight: "32x", margin: "0px 2px" }}>*/}
                                {/*    {equivalency_item.approved === "1" ?*/}
                                {/*        (<HiOutlineHandThumbUp style={{ margin: "5px", fontSize: "22px", color: "green" }} />)*/}
                                {/*        :*/}
                                {/*        (<HiOutlineHandThumbDown style={{ margin: "5px", fontSize: "22px", color: "red" }} />)*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div style={{ lineHeight: "32px", margin: "0px 4px" }}>{equivalency_item.item_start_date}</div>
                                <div style={{ lineHeight: "32px", margin: "0px 2px" }}>{equivalency_item.item_desc}</div>
                            </div>
                            {itemDetails.map((item_detal, index) => {
                                if (equivalency_item.item_no === item_detal.item_no)
                                    return (
                                        <div key={item_detal.ser_no} style={{ display: "flex", alignItems:"center"}} >
                                            <div style={{ lineHeight: "32x", margin: "0px 2px" }}>
                                                {item_detal.approved === "1" ?
                                                    (<HiOutlineHandThumbUp style={{ margin: "5px", fontSize: "22px", color: "green" }} />)
                                                    :
                                                    (<HiOutlineHandThumbDown style={{ margin: "5px", fontSize: "22px", color: "red" }} />)
                                                }
                                            </div>
                                            <div>{item_detal.department_name}</div>
                                            <div style={{ margin: "0px 4px" }}>-</div>
                                            <div>{item_detal.section_name}</div>
                                          
                                        </div>
                                    )
                            }
                            )}
                        </div>                           
                    )}
                   
                </div>
            </div>           
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                {/*attachment area ----------------------*/}
                <div style={{ height: "calc(100vh - 100px)" }}>
                    {decisionUrl &&
                        (<div className="pdf-viewer">
                        <object data={decisionUrl} type="application/pdf" className="pdf-viewer" >
                            <embed src={decisionUrl} type="application/pdf" />
                            </object>
                        </div>)}
                </div>
            </div>
        </div>

    );
};

export default CertificationAccreditation;
