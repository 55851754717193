

import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//import EmptyUserIcon from '/images/blank_employee.jpg';

import { RiMoreLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";




const PrintCaseDetails = ({ caseDetails, caseTypes, clientAs, caseOpponent, accessBy, caseClients, courtLevels, courtHearings, pageLables, caseLevels, courtsNames, judgements, caseStatus, states, setPrintComponent }) => {
    const printRef = useRef();

    useEffect(() => { 
        handlePrint()
        setPrintComponent(false);
    }, [printRef])
    
  
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });
    return (
        <div className="print_section" ref={printRef}>
            {/*case body ---------------------*/}
            <div style={{ padding: "0px 10px" }}>                  
                <div className="row data_line">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                        <label className="label_area">{pageLables[9].description}</label>
                        <div className="viewing_field">{caseDetails && caseDetails.unique_number}</div>
                    </div>
                </div>
                <div className="row data_line">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                        <label className="label_area">{pageLables[10].description}</label>
                        <div className="viewing_field">{caseTypes.find((el) => el.code === caseDetails.case_type_cd,).description}</div>
                    </div>
                </div>                   
                {/*<div className="row data_line">*/}
                {/*    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >*/}
                {/*        <label className="label_area">{pageLables[8].description}</label>*/}
                {/*        <div className="viewing_field" style={{ display: "flex"}}>*/}
                {/*            <div style={{ margin: "0px 4px", color: "#686868" }}>{caseLevels.length > 0 && caseLevels[0].case_no }</div>*/}
                {/*            <div style={{ margin: "0px 4px", color: "#686868" }}>{' - ' + clientAs.find((el) => el.code === caseLevels[0].client_as).description}</div>*/}
                {/*            <div style={{ margin: "0px 4px", color: "#686868" }}>{' - ' + courtLevels.find((el) => el.code === caseLevels[0].level_cd).description}</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>                  */}
                <div className="row data_line">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                        <label className="label_area">{pageLables[14].description}</label>
                        <div className="viewing_field">{caseDetails && caseDetails.case_subject}</div>
                    </div>
                </div>
                <div className="row data_line">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                        <label className="label_area">{pageLables[19].description}</label>
                        <div className="viewing_field">{caseDetails && caseDetails.other_details}</div>
                    </div>
                </div>
                       
                {/*clints and attornies part*/}                   
                <div>
                    <div className="row data_line">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                            <label className="label_area">{pageLables[15].description}</label>
                            <div className="viewing_field">
                                {caseClients.length > 0 && caseClients.map((client, index) => {
                                    if (caseClients.length === 1) {
                                        return <div key={client.id_no} >{client.client_name}</div>
                                    } else {
                                        if (index === 0)
                                            return <div key={client.id_no} >{client.client_name} <span><RiMoreLine size={14} /></span></div>
                                    }
                                }
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row data_line">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                            <label className="label_area">{pageLables[16].description}</label>
                            <div className="viewing_field">
                                {caseOpponent.length > 0 && caseOpponent.map((opponent, index) => {
                                    if (caseOpponent.length === 1) {
                                        return <div key={opponent.id_no} >{opponent.opponent_name}</div>
                                    } else {

                                        if (index === 0)
                                            return <div key={opponent.id_no} >{opponent.opponent_name} <span ><RiMoreLine size={14} /></span></div>
                                    }
                                }
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row data_line">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                            <label className="label_area">{pageLables[17].description}</label>
                            {caseDetails.Assigned_to && (
                                <>
                                    <div style={{ lineHeight: "28px", padding: "0px 6px" }}>{caseDetails.Assigned_to}</div>
                                </>)}
                        </div>
                    </div>
                    <div className="row data_line">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: "flex" }} >
                            <label className="label_area">{pageLables[18].description}</label>
                            <div>
                                {accessBy.length > 0 && accessBy.map((access_by, index) => (
                                    <div key={access_by.user_cd} className="viewing_field">   
                                        <div style={{ lineHeight: "28px", padding: "0px 6px" }}>{access_by.full_name}</div>
                                    </div>
                                )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div style={{ height: "60px !mortant", border: "solid 1px red" }}>ok</div>
            
            {caseLevels.map((levels, index) => {
                return (
                    <>
                        {courtHearings.length > 0 && (
                            <>
                                {/*case level Header*/}
                                <div style={{ display: "flex", lineHeight: "30px", marginBottom: "4px" }} key={levels.level_cd}>
                                    <div style={{ fontSize: "16px", fontWeight: "600" }}>
                                        {courtLevels.find((el) => el.code === levels.level_cd).description}{": "}
                                    </div>
                                    <div style={{ margin: "0px 4px", color: "#686868" }}>{levels.case_no}</div>
                                    <div style={{ margin: "0px 4px", color: "#686868" }}>{' - ' + clientAs.find((el) => el.code === levels.client_as).description}</div>
                                    <div style={{ margin: "0px 4px", color: "#686868" }}>{' - ' + caseStatus.find((el) => el.code === levels.case_status_cd).description}</div>
                                </div>
                                {/*case court hearing dtailes Header*/}
                                <div className="table_header_div top_border_div table_header_background" style={{ display: "flex" }}>
                                    <div style={{ width: "15%" }}>{pageLables[11].description}</div>
                                    <div style={{ width: "50%" }}>{pageLables[20].description}</div>
                                    <div style={{ width: "35%" }}>{pageLables[21].description}</div>
                                </div>
                                {courtHearings.length > 0 && courtHearings.map((court_hearings, index) => {
                                    if (courtLevels.find((el) => el.code === levels.level_cd).code === court_hearings.level_cd)
                                        return (
                                            <div className="row_area" key={court_hearings.ser}>
                                                <div className="table_line_div bottom_border_div" >
                                                    <div style={{ width: "15%" }}>{court_hearings.court_date}</div>
                                                    <div style={{ width: "50%" }}>{court_hearings.court_name}</div>
                                                    <div style={{ width: "35%" }}>{court_hearings.judgement_cd && judgements.find((el) => el.code === court_hearings.judgement_cd).description}</div>
                                                </div>
                                            </div>
                                        )
                                }
                                )}
                                <div style={{ marginTop: "14px" }}></div>
                            </>)}
                    </>)
            })}
        </div>
    );
};

export default PrintCaseDetails;
