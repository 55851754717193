import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoSearch } from "react-icons/io5";
import newtIcon from "../../../SVG_images/new.svg";

const InitialPage = ({
  listMode,
  setlistMode,
  RequestsListData,
  setRequestsListData,
  RequestInput,
  PageMode,
  setPageMode,
  setRequestInput,
}) => {
  const go_to_request = (request_year, branch_cd, request_cd) => {
    setRequestInput({
      request_year: request_year,
      branch_cd: branch_cd,
      request_cd: request_cd,
    });
    setPageMode("request");
  };

  const get_client_request = async (area_code, mobile_no) => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_client_requests",
          country_area_cd: area_code,
          mobile_no: mobile_no,
        },
      };
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/iip/wf/get_client_requests`,
        data,
      );

      console.log("client requests", res);
      setRequestsListData(res.data.output);
      setlistMode("client requests");
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const get_new_task = async () => {
    setlistMode("new task");
  };

  const get_my_role = async () => {
    setlistMode("my role");
  };

  return (
    <>
      <div className="page-inside-container">
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 border-right">
            <div className="py-3">
              <div className="panel-body  mostly-customized-scrollbar">
                <div>
                  <div
                  // onClick={() => {
                  //   get_client_request("965", "65133329");
                  // }}
                  >
                    <div className="request_menu_item">
                      <div className="search-input-container">
                        <select id="dial-code" className="select-dailcode">
                          <option value="+1">+1</option>
                          <option value="+44">+44</option>
                          <option value="+91">+91</option>
                        </select>
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          className="phoneno-input-container"
                          placeholder="Enter your phone number"
                        />
                        <button id="search-button">
                          <IoSearch size={15} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="request_menu_line"
                    onClick={() => {
                      go_to_request(0, 0, 0);
                    }}
                  >
                    <div className="request_menu_item">
                      {" "}
                      <img src={newtIcon} className="buton_icon" /> New Request{" "}
                    </div>
                  </div>
                  <div
                    className="request_menu_line"
                    onClick={() => {
                      get_new_task();
                    }}
                  >
                    <div className="request_menu_item">
                      {" "}
                      <img src={newtIcon} className="buton_icon" /> New Tasks{" "}
                    </div>
                  </div>
                  <div
                    className="request_menu_line"
                    onClick={() => {
                      get_my_role();
                    }}
                  >
                    <div className="request_menu_item">
                      {" "}
                      <img src={newtIcon} className="buton_icon" /> My Role{" "}
                    </div>
                  </div>
                  <div className="request_menu_line bottom_line">
                    <div className="request_menu_item">
                      {" "}
                      <img src={newtIcon} className="buton_icon" /> recent opend
                      Requests{" "}
                    </div>
                  </div>
                  <div
                    className="request_menu_line"
                    onClick={() => {
                      go_to_request(2022, 9, 1);
                    }}
                  >
                    <div className="request_menu_item">First request</div>
                  </div>
                  <div
                    className="request_menu_line"
                    onClick={() => {
                      go_to_request(2022, 9, 2);
                    }}
                  >
                    <div className="request_menu_item">Second request</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
            {listMode === "client requests" && (
              <>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>Request Time</th>
                      <th style={{ width: "45%" }}>Request Desc.</th>
                      <th style={{ width: "20%" }}>Request Type</th>
                      <th style={{ width: "20%" }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {RequestsListData.requests_list.length >= 0 &&
                      RequestsListData.requests_list.map(
                        (client_requests, index) => (
                          <tr
                            onClick={() => {
                              go_to_request(
                                client_requests.request_year,
                                client_requests.branch_cd,
                                client_requests.request_cd,
                              );
                            }}
                          >
                            <td>{client_requests.request_time}</td>
                            <td>{client_requests.requext_text}</td>
                            <td>{client_requests.request_type}</td>
                            <td>{client_requests.request_status}</td>
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </>
            )}
            {listMode === "new task" && (
              <>
                <div>Tew Task</div>
              </>
            )}
            {listMode === "my role" && (
              <>
                <div>my role</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InitialPage;
