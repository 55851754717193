import React, { useEffect, useState } from "react";
import axios from "axios";
// import {language_return} from '../../JSfiles/inventory/ItemLabelsLoad'
import editIcon from "../../../SVG_images/edit.svg";
import newtIcon from "../../../SVG_images/new.svg";
import saveIcon from "../../../SVG_images/save.svg";

import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

import ItemSpecifications from "../item_control/ItemSpecifications";
import ItemDetails from "../item_control/ItemDetails";
import InStoreItems from "../item_control/InStoreItems";
import Item_discount from "../item_control/Item_discount";
import ItemMedia from "../item_control/ItemMedia";

import { useSelector } from "react-redux"; //to get the value from the redux

const ItemControl = () => {
  const [itemData, setItemData] = useState({});
  const [itemMode, setItemMode] = useState("initial item");
  const [itemBrand, setItemBrand] = useState([]);
  const [itemCategory, setItemCategory] = useState([]);
  const [itemUnits, setItemUnits] = useState([]);

  const { user } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    const load_initials = async () => {
      try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
          info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
          },
          input: {
            actioncode: "get_item_initials",
            company_branch_cd: user.userData.company_branch_cd,
          },
        };
        let res = await axios.post(
          `${process.env.REACT_APP_BACK_END_URL}/api/iip/get_item_initials`,
          data,
        );
        console.log(data);
        setItemBrand(res.data.output.item_brand_ref);
        setItemCategory(res.data.output.item_category);
        setItemUnits(res.data.output.unit_ref);

        console.log(res);
      } catch (err) {
        console.log(err);
        alert("error message" + err.message);
      }
    };

    load_initials();
  }, []);

  const set_clear_mode = () => {
    if (itemMode === "edit item") {
      setItemMode("view item");
    } else {
      setItemMode("initial item");
    }
  };

  return (
    <>
      {console.log(itemData)}
      <div>
        <div className="page-inside-container">
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 border-right">
              {/* //---------------- detail area */}
              <div className="py-3">
                <div id="item_header" className="buttons_div ">
                  {(itemMode === "edit item" || itemMode === "view item") && (
                    <div
                      id="exit_edit"
                      className="subbutton_div normal_float  "
                      style={{ fontSize: "20px" }}
                      onClick={set_clear_mode}
                    >
                      {/*<span className="buton_icon opposite_float"><AiOutlineArrowRight style={{ fontSize: "20px", padding:"0px"}} /></span>*/}
                      <div
                        className="buton_icon normal_float"
                        style={{ fontSize: "24px", lineHeight: "24px" }}
                      >
                        {" "}
                        <AiOutlineArrowLeft />
                      </div>
                      <div className=" normal_float">Back</div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  )}
                  {/*<div id="item_code_area" className="normal_float subbutton_div hedden" style={{ fontSize: "20px" }}>*/}
                  {/*    <div id="item_code" className="normal_float">rer</div>*/}
                  {/*    <div id="item_status" className="normal_float" style={{color:"red", marginLeft:"10px", marginRight:"10px"}}></div>*/}
                  {/*    <div style={{clear:"both"}}></div>              */}
                  {/*</div>*/}
                  <div
                    id="item_buttons"
                    className="opposite_float subbutton_div"
                  >
                    {itemMode === "view item" && (
                      <div
                        id="item_menu_btn"
                        className="buton_icon opposite_float"
                      >
                        <BsThreeDotsVertical style={{ fontSize: "18px" }} />
                      </div>
                    )}
                    {itemMode === "initial item" && (
                      <div
                        id="item_search_btn"
                        className="underlined_Buton opposite_float"
                      >
                        Search
                      </div>
                    )}
                    {itemMode === "initial item" && (
                      <img
                        id="item_new_btn"
                        src={newtIcon}
                        className="buton_icon opposite_float "
                      />
                    )}
                    {itemMode === "edit item" && (
                      <img
                        id="item_save_btn"
                        src={saveIcon}
                        className="buton_icon opposite_float "
                      />
                    )}
                    {itemMode === "view item" && (
                      <img
                        id="item_edit_btn"
                        src={editIcon}
                        className="buton_icon opposite_float"
                        onClick={() => setItemMode("edit item")}
                      />
                    )}
                    <div style={{ clear: "both" }}></div>
                  </div>
                  <div className="hedden">
                    <div id="item_add_auth"></div>
                    <div id="item_edit_auth"></div>
                    <div id="item_log_auth"></div>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>

                <div
                  id="case_details_edit"
                  className="panel-body  mostly-customized-scrollbar"
                >
                  <ItemDetails
                    itemData={itemData}
                    setItemData={setItemData}
                    itemMode={itemMode}
                    setItemMode={setItemMode}
                    itemBrand={itemBrand}
                    itemCategory={itemCategory}
                    itemUnit={itemUnits}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
              {/* Bootstrap CSS */}
              {/* jQuery first, then Bootstrap JS. */}
              {/* Nav tabs */}
              <ul
                className="nav nav-tabs tabdrop-section tabs"
                role="tablist"
                id="nav-main-section"
              >
                <li
                  style={{ position: "absolute", right: "0px" }}
                  className="dropdown pull-right tabdrop show"
                  id="drop_down"
                >
                  <span
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    href="#"
                    aria-expanded="false"
                  >
                    <span className="display-tab">
                      <BsThreeDotsVertical />
                    </span>
                    <b className="caret"></b>
                  </span>
                  <ul
                    className="dropdown-menu"
                    id="drop-down-menu-con"
                    style={{}}
                  ></ul>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a
                    className="nav-link active"
                    id="spicifications-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#spicifications"
                    role="tab"
                    aria-controls="spicifications"
                    aria-selected="true"
                  >
                    specifications
                  </a>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a
                    className="nav-link"
                    id="item_in_stores-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#item_in_stores"
                    role="tab"
                    aria-controls="item_in_stores"
                    aria-selected="true"
                  >
                    Item In Stores
                  </a>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a
                    className="nav-link"
                    id="descount-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#descount"
                    role="tab"
                    aria-controls="descount"
                    aria-selected="true"
                  >
                    Descount
                  </a>
                </li>
                <li className="nav-item nav-bar-menu">
                  <a
                    className="nav-link"
                    id="photo-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#photo"
                    role="tab"
                    aria-controls="photo"
                    aria-selected="true"
                  >
                    Photo
                  </a>
                </li>
              </ul>
              {/* -------------Tab panes --------------------------------------------*/}
              <div className="tab-content" id="myTabContent">
                <div
                  id="spicifications"
                  className="tab-pane fade show active"
                  role="tabpanel"
                  aria-labelledby="spicifications-tab"
                >
                  <ItemSpecifications itemData={itemData} />
                </div>
                <div
                  id="item_in_stores"
                  className="tab-pane fade"
                  role="tabpanel"
                  aria-labelledby="item_in_stores-tab"
                >
                  <InStoreItems itemData={itemData} />
                </div>
                <div
                  id="descount"
                  className="tab-pane fade"
                  role="tabpanel"
                  aria-labelledby="descount-tab"
                >
                  <Item_discount itemData={itemData} />
                </div>

                <div
                  id="photo"
                  className="tab-pane fade"
                  role="tabpanel"
                  aria-labelledby="photo-tab"
                >
                  <ItemMedia itemData={itemData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemControl;
