import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "../../utils/sharedfunction";
import styles from "./PYMstatus.module.css";

const PYMstatus = () => {
  let query = useQuery();
  let paramid = query.get("paymentId");
  const [loader, setLoader] = useState(false);
  const [pymstatus, setPymstatus] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const CheckOutPayStatus = async () => {
      try {
        setLoader(true);
        setError(null);

        const data = {
          info: {
            lang: "en",
          },
          input: {
            actioncode: "payment.status",
            PaymentId: paramid,
            keyType: "PaymentId",
          },
        };

        let res = await axios.post(
          `${process.env.REACT_APP_BACK_END_URL}/api/auth/payment_status`,
          data,
        );

        if (res.data.result.rstatus === "1") {
          setPymstatus(res.data.pymstatus);
        } else {
          throw new Error("Something went wrong with checkout initiate");
        }
      } catch (err) {
        console.log(err);
        setError(err.message);
      } finally {
        setLoader(false);
      }
    };

    CheckOutPayStatus();
  }, [paramid]);

  if (loader) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      {error ? (
        <div className={`${styles.message} ${styles.failure}`}>
          Payment failed for payment ID: {paramid}. If the amount has been
          debited, please contact our support. please note down payment id for
          reference
        </div>
      ) : pymstatus ? (
        <div className={styles.success}>
          <h2>Payment Status</h2>
          <div className={styles.details}>
            <div
              className={`${styles.detailItem} ${
                pymstatus.status !== "Succss" ? styles.failure : ""
              }`}
            >
              <strong>Status:</strong> {pymstatus.status}
            </div>
            <div className={styles.detailItem}>
              <strong>Invoice Status:</strong> {pymstatus.invoicestatus}
            </div>
            <div className={styles.detailItem}>
              <strong>Invoice:</strong> {pymstatus.invoice}
            </div>
            <div className={styles.detailItem}>
              <strong>Track ID:</strong> {pymstatus.trackid}
            </div>
            <div className={styles.detailItem}>
              <strong>Registered Code:</strong> {pymstatus.registed_cd}
            </div>
            <div className={styles.detailItem}>
              <strong>Item Name:</strong> {pymstatus.item_name}
            </div>
            <div className={styles.detailItem}>
              <strong>Item Price:</strong> {pymstatus.item_price}
            </div>
            <div className={styles.detailItem}>
              <strong>Customer Name:</strong> {pymstatus.customer_name}
            </div>

            <div className={styles.detailItem}>
              <strong>Payment ID:</strong> {paramid}
            </div>
          </div>
          <Link to="/main">Readirect to Dashboard</Link>
          {pymstatus.status !== "Succss" && (
            <div className={`${styles.message} ${styles.failure}`}>
              If the amount has been debited, please contact our support. please
              note down all above details for references
            </div>
          )}
        </div>
      ) : (
        <>
          {!loader && (
            <div>
              No payment information available. payment ID: {paramid}
              {/* <div className={`${styles.message} ${styles.failure}`}>
                Payment failed for payment ID: {paramid}. If the amount has been
                debited, please contact our support. please note down payment id
                for reference
              </div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PYMstatus;
