






import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Clients from "../Crm/Clients";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { FiEdit } from "react-icons/fi"
import { TiAttachment } from "react-icons/ti"
import { RiPlayListAddFill } from "react-icons/ri"
import { BsTrash3 } from "react-icons/bs"


import { useDispatch, useSelector } from "react-redux"; //this import redux functions


import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const EmployeeBonus = ({ emp_cd, pageLables }) => {

    const dispatch = useDispatch({})
    const [pageMode, setPageMode] = useState("List");
    const [proceduresList, setProceduresList] = useState([]);
    const [procedureDetail, setProcedureDetail] = useState([]);

    const filePickerRef = useRef(null)
    const { filePercentageReducers } = useSelector((state) => state)


    useEffect(() => {

        getCaseProcedures()
    }, []);

    const set_clear_mode = () => {

        switch (pageMode) {
            case "Edit":
                setPageMode("View");
                break;
            case "View":
            case "Draft":
                //clear_data()
                setPageMode("List");
                break;
            default:
            // code block
        }
    };

    const getCaseProcedures = async () => {

        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_case_procedurs",
                    emp_cd: emp_cd
                },
            };

            console.log("get_case_procedurs input ", data)

            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/get_case_procedurs`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("get_case_procedurs output", res);
            if (res.data.output.case_procedure) { setProceduresList(res.data.output.case_procedure) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const getCaseAction = async (ser_no) => {
        setProcedureDetail({})
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_case_action",
                    emp_cd: emp_cd,
                    set_no: ser_no
                },
            };

            console.log("get_case_action input ", data)
            setPageMode("View")
            return
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/iip/lcc/get_case_procedurs`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("get_case_action output", res);
            if (res.data.output.case_procedure) { setProceduresList(res.data.output.case_procedure) }

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    const update_case_procedure = async (update_function) => {
        alert("update_case_procedure")
        var ser_number = procedureDetail.ser_no
        if (!ser_number) { ser_number = 0 }
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "update_case_procedure",
                    emp_cd: emp_cd,
                    ser_no: ser_number,
                    procedure_date: procedureDetail.procedure_date,
                    procedure_type_cd: procedureDetail.procedure_type_cd,
                    procedure_cd: null,
                    procedure_name: procedureDetail.procedure_name,
                    procedure_desc: procedureDetail.procedure_desc,
                    update_function: update_function
                },
            };
            console.log("update_case_procedure input ", data);
            return
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_case_procedure`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("update_case_opponent output ", res);

            //if (res.data.output.update_case_opponent_details) { setOpponentDetails(res.data.output.update_case_opponent_details); }
            //if (res.data.output.get_case_opponents) {
            //    setCaseOpponent(res.data.output.get_case_opponents);
            //} else {
            //    setCaseOpponent([])
            //}

            //setAreasMode("List")

        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };

    return <>
        <div className="row">
            <div style={{ height: "calc(100vh - 86px)", overflow: "auto", overflowX: "hidden" }} >
                <>
                    {/*Button bar ---------------------*/}
                    <div>
                        <div className="normal_float normal_text_align ">
                            {pageMode !== "List" && (
                                <>
                                    <Tooltip title="Back" placement="bottom" arrow>
                                        <IconButton onClick={set_clear_mode}>
                                            <ArrowBackIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>)}
                        </div>
                        <div className="opposite_float opposite_text_align">
                            {(pageMode === "Edit" || pageMode === "Draft") && (
                                <>
                                    <Tooltip title="Save" placement="bottom" arrow>
                                        <IconButton onClick={() => { update_case_procedure("update") }}>
                                            <SaveIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                            {pageMode === "Edit" && (
                                <>
                                    <Tooltip title="Delete" placement="bottom" arrow>
                                        <IconButton onClick={() => { update_case_procedure("delete") }}>
                                            <BsTrash3 color="inherit" fontSize="medium" />
                                        </IconButton>
                                    </Tooltip>

                                </>)}
                            {pageMode === "List" && (
                                <>
                                    <Tooltip title="Add new remark" placement="bottom" arrow>
                                        <IconButton onClick={() => { setPageMode("Draft") }}>
                                            <RiPlayListAddFill color="inherit" size="20" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                            {pageMode === "View" && (
                                <>
                                    <Tooltip title="Edit" placement="bottom" arrow>
                                        <IconButton onClick={() => { setPageMode("Edit"); }}>
                                            <ModeOutlinedIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Search" placement="bottom" arrow>
                                        <IconButton>
                                            <TiAttachment color="inherit" fontSize="20px" onClick={() => filePickerRef.current.click()} />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>
                    {/*Attachment area*/}
                    {pageMode === "List" && (
                        <>
                            <div className="table_header_div" style={{ display: "flex", borderBottom: "solid 2px gray" }}>
                                <div style={{ width: "20%" }}>{pageLables[43].description}</div>
                                <div style={{ width: "60%" }}>{pageLables[46].description}</div>
                                <div style={{ width: "20%" }}>{pageLables[40].description}</div>
                            </div>
                            <div className="row_area" >
                                <div className="table_line_div bottom_border_div" >
                                    <div style={{ width: "20%" }}>02-02-2024</div>
                                    <div style={{ width: "60%" }}>إجازة </div>
                                    <div style={{ width: "20%" }}>30</div>
                                </div>
                            </div>
                            <div className="row_area">
                                <div className="table_line_div bottom_border_div" >
                                    <div style={{ width: "20%" }}>02-02-2023</div>
                                    <div style={{ width: "60%" }}>إجازة دورية</div>
                                    <div style={{ width: "20%" }}>45</div>
                                </div>
                            </div>
                            <div className="row_area" >
                                <div className="table_line_div bottom_border_div" >
                                    <div style={{ width: "20%" }}>02-02-2022</div>
                                    <div style={{ width: "60%" }}>إجازة مرضية</div>
                                    <div style={{ width: "20%" }}>3</div>
                                </div>
                            </div>

                        </>)}

                    {pageMode === "View" &&
                        (<>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[11].description}</label>
                                <div class="viewing_field">{procedureDetail && procedureDetail.procedure_date}</div>
                            </div>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[35].description}</label>
                                <div class="viewing_field">{procedureDetail && procedureDetail.procedure_type_cd}</div>
                            </div>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[36].description}</label>
                                <div class="viewing_field">{procedureDetail && procedureDetail.procedure_name}</div>
                            </div>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[27].description}</label>
                                <div class="viewing_field">{procedureDetail && procedureDetail.procedure_desc}</div>
                            </div>
                        </>)}
                    {(pageMode === "Edit" || pageMode === "Draft") && (
                        <>
                            {/*Client details*/}
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[11].description}</label>
                                <input id="case_subject" type="text" className="editing_field" autoComplete="0"
                                    value={procedureDetail && procedureDetail.procedure_date} onChange={(e) => setProcedureDetail({ ...procedureDetail, procedure_date: e.target.value })} />
                            </div>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[35].description}</label>
                                <input id="case_subject" type="text" className="editing_field" autoComplete="0"
                                    value={procedureDetail && procedureDetail.procedure_type_cd} onChange={(e) => setProcedureDetail({ ...procedureDetail, procedure_type_cd: e.target.value })} />
                            </div>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[36].description}</label>
                                <input id="case_subject" type="text" className="editing_field" autoComplete="0"
                                    value={procedureDetail && procedureDetail.procedure_name} onChange={(e) => setProcedureDetail({ ...procedureDetail, procedure_name: e.target.value })} />
                            </div>
                            <div style={{ display: "flex" }} >
                                <label className="label_area">{pageLables[27].description}</label>
                                <input id="case_subject" type="text" className="editing_field" autoComplete="0"
                                    value={procedureDetail && procedureDetail.procedure_desc} onChange={(e) => setProcedureDetail({ ...procedureDetail, procedure_desc: e.target.value })} />
                            </div>
                        </>
                    )}
                </>
            </div>
        </div>
    </>
}

export default EmployeeBonus



