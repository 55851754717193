import React, { useEffect, useState } from "react"



const InStoreItems = ({ itemData }) => {


    return <>
        {itemData.item_in_store && (
        <div style={{ pading: "10px", marginTop: "15px" }}>
            <table className="table table-hover">
                <thead>
                <tr>
                    <th style={{ width: "35%" }}>Store Name</th>
                    <th style={{ width: "13%" }}>Arrived</th>
                    <th style={{ width: "12%" }}>Shelf</th>
                    <th style={{ width: "13%" }}>Expire</th>
                    <th style={{ width: "12%" }}>qty</th>
                    <th style={{ width: "15%" }}>Same Unit</th>
                </tr>
                </thead>
                <tbody>                  
                    {itemData.item_in_store.length >=0 && itemData.item_in_store.map((inStore, index) =>  (
                        <tr>
                            <td>{inStore.branch_name}</td>
                            <td>{inStore.shipment_date}</td>
                            <td>{inStore.store_shelf_no}</td>
                            <td>{inStore.expire_date}</td>
                            <td>{inStore.quantity}</td>
                            <td>{inStore.unit_name}</td>
                        </tr>
                    )

                    )}
                </tbody>
            </table>
        </div>
        )}
    </>
}


export default InStoreItems