import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import SaveIcon from "@mui/icons-material/Save";
import blankprofile from "../../Images/blankprofile.png";

import { FiEdit } from "react-icons/fi";
import { BsAt } from "react-icons/bs";
import { BsSend } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux"; //this import redux functions

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
let userDetails = JSON.parse(localStorage.getItem("amanrowuserData"));
const CaseChatting = ({ case_cd, accessBy }) => {
  const dispatch = useDispatch({});
  const [pageMode, setPageMode] = useState("List");
  const [chattings, setChattings] = useState([]);
  const [chattDetails, setChattDetails] = useState();

  useEffect(() => {
    getCaseChattings();
  }, []);

  const set_clear_mode = () => {
    switch (pageMode) {
      case "Edit":
        setPageMode("view");
        break;
      case "Draft":
        setPageMode("List");
        break;
      default:
      // code block
    }
  };

  const getCaseChattings = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_case_chattings",
          case_cd: case_cd,
        },
      };

      console.log("getCaseChattings input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_case_chattings`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getCaseChattings output", res);
      if (res.data.output.case_chattings) {
        setChattings(res.data.output.case_chattings);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  const update_case_chatting = async (update_function) => {
    alert("update_case_chatting");

    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "update_case_chatting",
          case_cd: case_cd,
          sending_user_id: userDataLocal.user_cd,
          recieving_user_id: document.getElementById("recieving_user_id").value,
          message_txt: document.getElementById("message_txt").value,
        },
      };
      console.log("update_case_chatting input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_case_chatting`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("update_case_chatting output ", res);

      if (res.data.output.case_chattings) {
        setChattings(res.data.output.case_chattings);
      } else {
        setChattings([]);
      }

      setPageMode("List");
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  return (
    <>
      <div className="row">
        <div
          style={{
            height: "calc(100vh - 150px)",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <>
            {/*Button bar ---------------------*/}
            <div>
              <div className="normal_float normal_text_align ">
                {pageMode === "Draft" && (
                  <>
                    <Tooltip title="Back" placement="bottom" arrow>
                      <IconButton onClick={set_clear_mode}>
                        <ArrowBackIcon color="inherit" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
              <div className="opposite_float opposite_text_align">
                {pageMode === "List" && (
                  <>
                    <Tooltip title="Write Message" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          setPageMode("Draft");
                        }}
                      >
                        <FiEdit color="inherit" size="20" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {pageMode === "Draft" && (
                  <>
                    <Tooltip title="Send Message" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          update_case_chatting();
                        }}
                      >
                        <BsSend color="inherit" size="20" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
            {/*Chatting area*/}
            {pageMode === "List" && (
              <>
                {console.log(chattings)}
                {chattings.length > 0 &&
                  chattings.map((chattings_list, index) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent:
                          userDetails.user_cd === chattings_list.sending_user_id
                            ? "end"
                            : "start",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      <div
                        key={chattings_list.case_cd + index}
                        className={`chatting_area`}
                        style={{
                          display: "flex",
                          justifyContent:
                            userDetails.user_cd ===
                            chattings_list.sending_user_id
                              ? "end"
                              : "start",
                        }}
                      >
                        <div>
                          <div style={{ display: "flex" }}>
                            <div>
                              <img
                                src={
                                  "https://files.amanrow.com/user/" +
                                  chattings_list.image_name
                                }
                                alt=" "
                                className="chatting_profile_image"
                              />
                            </div>
                            <div
                              className="space-betw-vertical"
                              style={{ margin: "0px 10px" }}
                            >
                              <div> {chattings_list.full_name}</div>
                              <div>{chattings_list.sending_time}</div>
                            </div>
                          </div>
                          {chattings_list.recieving_user_id && (
                            <>
                              <div
                                className="user_line_div"
                                style={{ height: "22px", marginTop: "2px" }}
                              >
                                <div style={{ width: "48px" }}></div>
                                <div>
                                  <BsAt color="inherit" fontSize="26px" />
                                </div>
                                <div style={{ display: "flex" }}>
                                  <div>
                                    {chattings_list.reciever_image_name ? (
                                      <img
                                        src={`https://files.amanrow.com/user/${chattings_list.reciever_image_name}`}
                                        className="profile_image"
                                        style={{
                                          height: "22px",
                                          width: "22px",
                                        }}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src={blankprofile}
                                        className="profile_image"
                                        style={{
                                          height: "22px",
                                          width: "22px",
                                        }}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div style={{ padding: "0px 6px" }}>
                                    {chattings_list.reciever_user_name}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div style={{ padding: "10px 4px 10px 4px" }}>
                            <div>{chattings_list.message_txt}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}

            {pageMode === "Draft" && (
              <>
                <div style={{ margin: "2px 10px" }}>
                  <div
                    className="message_field"
                    style={{
                      display: "flex",
                      marginBottom: "6px",
                      width: "auto",
                    }}
                  >
                    <div style={{ width: "36Px", textAlign: "center" }}>
                      <Tooltip title="at user" placement="bottom" arrow>
                        <IconButton onClick={set_clear_mode}>
                          <BsAt color="inherit" fontSize="24px" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div style={{ width: "auto" }}>
                      <select
                        id="recieving_user_id"
                        className="editing_field"
                        style={{ outline: "0", border: "none" }}
                      >
                        <option value="0"></option>
                        {accessBy.length >= 0 &&
                          accessBy.map((access_by, index) => {
                            return (
                              <option key={index} value={access_by.user_cd}>
                                {access_by.full_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <textarea
                    id="message_txt"
                    className="message_field"
                    style={{ width: "100%", minHeight: "400px" }}
                    maxlength="4000"
                    placeholder="Write message here"
                  ></textarea>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default CaseChatting;
