import React, { useState } from "react";
import styles from "./PaymentOptions.module.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
const CheckOutFatoora = ({ paymentMethods, selectedEmployees }) => {
  const history = useHistory();
  const [selectedMethod, setSelectedMethod] = useState("");
  const [serviceCharge, setServiceCharge] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChange = (paymentmethodid, service_charge) => {
    setSelectedMethod(paymentmethodid);
    setServiceCharge(service_charge);
  };

  const HandleSubmit = () => {
    if (!selectedMethod) {
      alert("select Any one and submit");
      return;
    }
    CheckOutPay(selectedMethod, serviceCharge, 1);
  };

  const CheckOutPay = async (paymentID, service_charge, price = 1) => {
    try {
      const purchaseItems = Array.from(selectedEmployees).map((item) => ({
        itemCD: item.user_cd,
        price: price,
        itemName: "amanrow_user_package",
      }));

      setLoader(true);
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "checkout_initiate",
          PaymentMethodId: paymentID,
          purchase_items: purchaseItems,
          serviceCharge: service_charge,
        },
      };

      console.log("checkout_initiate input");
      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/auth/purchase_user`,

        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );
      console.log(typeof res.data.result.rstatus);
      if (res.data.result.rstatus === "1") {
        console.log(res.data.output.data);
        window.location.href = res.data.output.data.PaymentURL;
      } else {
        throw new Error("something went wrong on checkout initiate");
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className={styles.paymentWrapperBox}>
      <div className={styles.paymentWrapper}>
        {paymentMethods.map((method) => (
          <div className={styles.paymentOption} key={method.paymentmethodid}>
            <img src={method.imageurl} alt={method.paymentmethodname} />
            <span className={styles.paymentOptionName}>
              {method.paymentmethodname}
            </span>
            <span className={styles.serviceCharge}>
              Service Charge: {method.service_charge}
            </span>
            <input
              type="radio"
              name="paymentMethod"
              value={method.paymentmethodid}
              checked={selectedMethod === method.paymentmethodid}
              onChange={() =>
                handleChange(method.paymentmethodid, method.service_charge)
              }
            />
          </div>
        ))}
      </div>
      <div className="btn btn-primary" onClick={HandleSubmit}>
        Submit
      </div>

      {loader && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span className="loader-spiner"></span>
        </div>
      )}
    </div>
  );
};

export default CheckOutFatoora;
