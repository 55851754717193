const defaultState = {
    userData: {},
  }
  
  const loginCredentails = (state = defaultState, action) => {
    switch (action.type) {
      case 'login':
        return { ...state, userData: action.payload }
    
      default:
        return state
    }
  }
  
  export default loginCredentails