import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Clients from "../Crm/Clients";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { TiAttachment } from "react-icons/ti";
import { BsTrash3 } from "react-icons/bs";
/*import { LuEdit2 } from "react-icons/lu"*/

import pdf from "../../Images/icons/pdf.png";
import cam from "../../Images/icons/cam.png";
import pdfsolid from "../../Images/icons/pdfsolid.png";
import camsolid from "../../Images/icons/camsolid.png";

import { useDispatch, useSelector } from "react-redux"; //this import redux functions
import { Link } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CaseAttachments = ({ case_cd }) => {
  const dispatch = useDispatch({});
  const [pdfFileBase64, setPdfFileBase64] = useState("");
  const [caseAttachments, setCaseAttachments] = useState([]);
  const [clientAttachments, setClientAttachments] = useState([]);
  const [powerOfAttornyAttachments, setPowerOfAttornyAttachments] = useState(
    [],
  );
  const [filesNames, setFileNames] = useState([]);
  const [uploadprecentage, setUploadPercentage] = useState(0);

  const filePickerRef = useRef(null);
  const { filePercentageReducers } = useSelector((state) => state);

  useEffect(() => {
    getCasetAttachments();
  }, []);

  // upload ----------------------
  /*this function to get input files to be uploded*/
  const filePickedHandler = (event) => {
    let pickedFile;
    if (event.target.files) {
      for (var i = 0; i < event.target.files.length; i++) {
        let fileExttype = event.target.files[i].type.split("/")[i];
        let filExt = event.target.files[i].type.split("/")[1];

        pickedFile = event.target.files[i];

        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.addEventListener("load", () => {
          setPdfFileBase64(reader.result);
        });
        /*  handle the input files for list*/
        console.log("files to uploaded = ", pickedFile);
        uploadFile(pickedFile, i);
      }
    }
  };

  const uploadFile = (pickedFile) => {
    console.log(pickedFile.name);

    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    let formData = new FormData();
    formData.append("file", pickedFile);
    formData.append("case_cd", case_cd);
    formData.append("system_cd", "lcc");
    formData.append("company", userDataLocal.company_cd);
    formData.append("flocation", "Lcc");

    dispatch({
      type: "FILEUPLOADS",
      payload: { [pickedFile.name]: 0 },
    });

    const options = {
      headers: { Authorization: `Bearer ${userDataLocal.token}` },

      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
          dispatch({
            type: "FILEUPLOADS",
            payload: { [pickedFile.name]: percent },
          });
        }
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_attachment`,
        formData,
        options,
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.result.rstatus === "1") {
          dispatch({
            type: "FILEUPLOADS",
            payload: { [pickedFile.name]: 0 },
          });

          setFileNames((old) => [
            ...old,
            {
              attachment_name: res.data.attachment_name,
              attactment_type: res.data.attachtment_type,
              fileurl: res.data.fileurl,
            },
          ]);
        }
        setUploadPercentage(0);
      })
      .catch((err) => {
        dispatch({
          type: "FILEUPLOADS",
          payload: { [pickedFile.name]: 0 },
        });
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionWay");
            window.location.replace("/");
          }
        }

        setUploadPercentage(0);
      });
  };

  // read ----------------------
  const getCasetAttachments = async () => {
    try {
      let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

      const data = {
        info: {
          lang: userDataLocal.lang,
          company: userDataLocal.company_cd,
          user_cd: userDataLocal.user_cd,
        },
        input: {
          actioncode: "get_case_attachments",
          case_cd: case_cd,
        },
      };

      console.log("getCasetAttachments input ", data);

      let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_case_attachments`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
          },
        },
      );

      console.log("getCasetAttachments output", res);
      if (res.data.output.case_attachments) {
        setCaseAttachments(res.data.output.case_attachments);
      }
      if (res.data.output.client_attachments) {
        setClientAttachments(res.data.output.client_attachments);
      }
      if (res.data.output.attorneies_attachments) {
        setPowerOfAttornyAttachments(res.data.output.attorneies_attachments);
      }
    } catch (err) {
      console.log(err);
      alert("error message" + err.message);
    }
  };

  return (
    <>
      <div className="row">
        <div
          style={{
            height: "calc(100vh - 150px)",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <>
            {/*Buttons bar*/}
            <div>
              <div className="normal_float normal_text_align "></div>
              <div className="opposite_float opposite_text_align">
                <Tooltip title="Search" placement="bottom" arrow>
                  <IconButton>
                    <TiAttachment
                      color="inherit"
                      size="26"
                      onClick={() => filePickerRef.current.click()}
                    />
                  </IconButton>
                </Tooltip>
                <input
                  ref={filePickerRef}
                  style={{ display: "none" }}
                  type="file"
                  multiple
                  onChange={filePickedHandler}
                  accept="*"
                />
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
            {/*Attachment area*/}
            <div style={{ padding: "5px" }}>
              <h6 className="mb-2">Case Attachments List</h6>
              {caseAttachments.length <= 0 && (
                <div>No attachment found for Case</div>
              )}

              {/*case Attachment*/}
              {caseAttachments.length > 0 &&
                caseAttachments.map((attachments_list, index) => (
                  <Link
                    className="file-wrapper"
                    key={attachments_list.case_cd + index}
                    to={{ pathname: attachments_list.case_url_address }}
                    target="_blank"
                  >
                    <div
                      className="normal_float"
                      style={{ display: "flex", height: "30px" }}
                    >
                      {attachments_list.attactment_type === "pdf" && (
                        <>
                          <div style={{ padding: "4px" }}>
                            <img src={pdf} alt="" style={{ height: "22px" }} />
                          </div>
                        </>
                      )}
                      {attachments_list.attactment_type === "image" && (
                        <>
                          <div style={{ padding: "4px" }}>
                            <img src={cam} alt="" style={{ height: "22px" }} />
                          </div>
                        </>
                      )}
                      <div style={{ padding: "4px" }}>
                        {attachments_list.attachment_name}
                      </div>
                    </div>
                    <div className="opposite_float row1">
                      {/*   <LuEdit2 size="16" style={{ margin: "0px 3px" }} />*/}
                      <BsTrash3 size="16" style={{ margin: "0px 3px" }} />
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </Link>
                ))}

              {/*new uploaded Attachments*/}
              {filesNames.map((newattchlist) => {
                return (
                  <>
                    <Link
                      className="file-wrapper"
                      key={newattchlist.attachment_name}
                      to={{ pathname: newattchlist.fileurl }}
                      target="_blank"
                    >
                      <div
                        className="normal_float"
                        style={{ display: "flex", height: "30px" }}
                      >
                        <div style={{ padding: "4px" }}>
                          {newattchlist.attactment_type === "pdf" && (
                            <>
                              <div style={{ padding: "4px" }}>
                                <img
                                  src={pdf}
                                  alt=""
                                  style={{ height: "22px" }}
                                />
                              </div>
                            </>
                          )}
                          {newattchlist.attactment_type === "image" && (
                            <>
                              <div style={{ padding: "4px" }}>
                                <img
                                  src={cam}
                                  alt=""
                                  style={{ height: "22px" }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div style={{ padding: "4px" }}>
                          {newattchlist.attachment_name}
                        </div>
                      </div>

                      <div className="opposite_float row1">
                        {/*   <LuEdit2 size="16" style={{ margin: "0px 3px" }} />*/}
                        <BsTrash3 size="16" style={{ margin: "0px 3px" }} />
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </Link>
                  </>
                );
              })}

              {Object.entries(filePercentageReducers).map((pdfFile, i) => {
                if (pdfFile[1] > 0) {
                  return (
                    <div className="progress-wrapper">
                      <div>{pdfFile[0]}</div>
                      <div className="progress">
                        <div
                          className="progress-bar progress-bg-color rogress-bar-striped"
                          style={{ width: `${pdfFile[1]}%`, height: "100%" }}
                          role="progressbar"
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="col-auto">
                        <span className="progress-color cursor-pointer">
                          {pdfFile[1]}%
                        </span>
                      </div>
                    </div>
                  );
                } else {
                  return "";
                }
              })}

              {/*clients Attachment*/}
              <hr />
              <h6 className="mb-2 mt-2">Client Attachments List</h6>
              {clientAttachments.length === 0 && (
                <div>No attachment found for clients</div>
              )}
              {clientAttachments.length > 0 &&
                clientAttachments.map((client_attachments_list, index) => (
                  <Link
                    key={client_attachments_list.client_url_address + index}
                    to={{
                      pathname: client_attachments_list.client_url_address,
                    }}
                    target="_blank"
                    className="file-wrapper"
                  >
                    <div style={{ display: "flex", height: "30px" }}>
                      {client_attachments_list.attactment_type === "pdf" && (
                        <>
                          <div style={{ padding: "4px" }}>
                            <img src={pdf} alt="" style={{ height: "22px" }} />
                          </div>
                        </>
                      )}
                      {client_attachments_list.attactment_type === "image" && (
                        <>
                          <div style={{ padding: "4px" }}>
                            <img src={cam} alt="" style={{ height: "22px" }} />
                          </div>
                        </>
                      )}
                      <div style={{ padding: "4px" }}>
                        {client_attachments_list.attachment_name}
                      </div>
                    </div>
                  </Link>
                ))}

              {/*powee of attorny Attachment*/}
              <hr />
              <h6 className="mb-2">Power of attorny Attachments List</h6>
              {powerOfAttornyAttachments.length === 0 && (
                <div>No attachent found for power of attorny</div>
              )}
              {powerOfAttornyAttachments.length > 0 &&
                powerOfAttornyAttachments.map((poa_attachments_list, index) => (
                  <Link
                    key={poa_attachments_list.power_of_attorney_cd + index}
                    to={{
                      pathname: poa_attachments_list.attachment_url_address,
                    }}
                    target="_blank"
                    className="file-wrapper"
                  >
                    <div style={{ display: "flex", height: "30px" }}>
                      {poa_attachments_list.attactment_type === "pdf" && (
                        <>
                          <div style={{ padding: "4px" }}>
                            <img src={pdf} alt="" style={{ height: "22px" }} />
                          </div>
                        </>
                      )}
                      {poa_attachments_list.attactment_type === "image" && (
                        <>
                          <div style={{ padding: "4px" }}>
                            <img src={cam} alt="" style={{ height: "22px" }} />
                          </div>
                        </>
                      )}
                      <div style={{ padding: "4px" }}>
                        {poa_attachments_list.attachment_name}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default CaseAttachments;
